.hm_topnav {
    overflow: hidden;
    background-color: $color-12;
}

.right_elements {
    padding: 20px 30px;
    float: right;
    display: flex;
}

.hm_topnav li {
    list-style-type: none;
}

.Lang_hm_dropdown {
    color: $color-white;
    display: inline;
    padding: 7px 20px;
}

.hm_topnav li a {
    float: left;
    display: block;
    color: $color-13;
    text-align: center;
    padding: 22px 16px;
    text-decoration: none;
    font-size: 17px;
}

.hm_topnav a:not(.logo) {
    padding: 5px 10px;
    display: inline;
}

.menu_icon {
    display: inline-block;
}

span a.logo {
    padding: 12px 20px;
}

.hm_navigation li.brand_li {
    width: 175px;
}

.hm_logo img {
    height: 100%;
    width: 100%;
    margin: 12px 0 0 40px;
}

.hm_navigation > a::after {
    content: '';
    border: 4px solid transparent;
    border-top: 4px solid white;
    margin-left: 2px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: bottom;
}
  .hm_nav_item > li {
        color: #fff;
    }
.hm_topnav .icon {
    display: block;
}

.hm_searchContainer {
    vertical-align: top;
    display: inline-block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $color-14;
    opacity: .9;
}

.hm_searchBox {
    border: 0;
    font-size: 1rem;
    padding: .55rem .85rem .55rem 0;
    flex: 1;
    color: $color-white;
    background-color: $color-14;
    width: auto;
    margin: 0;
}

.fa {
    color: $color-white;
    padding: .5rem;
}

::placeholder {
    color: $color-white;
}

:-ms-input-placeholder {
    color: $color-white;
}

::-ms-input-placeholder {
    color: $color-white;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

ul.hm_main_nav_new {
    padding: 30px 100px;
    display: flex;
    margin: 0;
}

ul.hm_main_nav {
    padding: 50px 0;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

li.hm_dropdown {
    list-style-type: none;
}

li.hm_dropdown a {
    color: $color-white;
    text-decoration: none;
}

ul.hm_dropdown-menu {
    list-style-type: none;
    padding: 10px 0;
}

.hm_main_nav ul.hm_dropdown-menu {
    padding-bottom: 0;
}

ul.hm_dropdown-menu li {
    padding: 2px 0;
    opacity: .8;
}

.hm_nav_item {
    width: 25%;
}

#hm_menu_nav hr {
    margin: 0 auto;
    max-width: 1200px;
    color: $color-15;
    background-color: $color-15;
    height: 2px;
    border: none;
}

.hm_nav_item .hm_dropdown span {
    font-size: 22px;
}

.hm_navigation .hm_dropdown.open .hm_dropdown-menu {
    display: block;
}

.hm_navigation .hm_dropdown .hm_dropdown-menu li a {
    padding: 15px 20px;
}

.hm_navigation li.hm_dropdown span a {
    font-weight: bold;
}

.fa-navicon::before,
.fa-reorder::before,
.fa-bars::before {
    font-size: 18px;
}

.fa.open::before {
    content: "\f00d";
}

.mobile_header {
    background-color: $color-white;
    color: $color-12;
}

.hm_topnav .mobile_header a.logo {
    display: none;
}

.hm_full_nav {
    display: none;
    background-color: $color-16;
}

.hm_full_nav.responsive {
    display: block;
}

@media only screen and (min-device-width: 993px) and (max-device-width: 1024px) {
    ul.hm_main_nav,
    ul.hm_main_nav_new {
        padding: 50px;
    }
}

@media screen and (max-width: 992px) {
    ul.hm_main_nav,
    ul.hm_main_nav_new {
        display: block;
        padding: 25px;
    }

    hr {
        margin-left: 50px;
        margin-right: 50px;
    }

    .hm_nav_item {
        width: fit-content;
        display: inline-flex;
        padding: 20px;
    }

    ul.hm_dropdown-menu {
        padding: 10px 0 0;
    }

    .right_elements {
        padding: 0;
    }

    .hm_searchContainer {
        display: inline-block;
        margin: 25px 10px;
    }

    .hm_searchBox input {
        width: auto;
        padding: .25rem;
        margin: 0;
    }

    .hm_topnav a.icon {
        float: none;
        display: block;
    }

    .hm_topnav ul li a:not(.icon) {
        display: none;
    }

    .hm_topnav .responsive {
        position: relative;
        height: auto;
    }

    .hm_topnav.responsive .icon {
        position: relative;
        right: 0;
        top: 0;
        width: fit-content;
    }

    .hm_topnav.responsive a {
        float: none;
        display: block;
    }

    .hm_navigation .hm_dropdown .hm_dropdown-menu {
        width: auto;
        padding: 0;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }

    #search_menu {
        display: block;
        margin: 40px auto;
        width: fit-content;
    }

    .hm_searchBox {
        text-align: center;
    }
}

@media screen and (max-width: 560px) {
    .hm_searchContainer {
        display: none;
    }

    #Search_bar {
        display: inline-block;
        margin: 50px;
        position: relative;
    }

    ul.hm_main_nav,
    ul.hm_main_nav_new {
        padding: 50px;
        padding-top: 0;
        padding-left: 20px;
    }
}
