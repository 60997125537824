/// Mixin to add base icon properties
/// @author Arno Wolkers
/// @example
///      @include icon();
@mixin icon {
    content: '';
    pointer-events: none;
    display: block;
    background-image: url('../img/sprite.svg');
    background-repeat: no-repeat;
}

/// Mixin to add icon dimensions, position or both based on the given icon name
/// @author Arno Wolkers
/// @param {string} $name - The name of the svg icon to use
/// @param {string} $type [all] - Type of properties to return (all, dimensions, position)
/// @example
///      @include icon-props(arrow);
///      @include icon-props(arrow, dimensions);
///      @include icon-props(arrow, position);
@mixin icon-props($name, $type: all) {
    $icon: map-get($svgsprite, $name);

    @if $icon == null {
        @error 'Can\'t find an icon with the name #{$name}';
    }

    // Outputs dimensions
    @if $type == all or $type == dimensions {
        width: map-get($icon, width);
        height: map-get($icon, height);
    }

    // Outputs background position
    @if $type == all or $type == position {
        background-position: map-get($icon, relativeX) map-get($icon, relativeY);
    }
}

.icon {
    position: relative;
    display: inline-block;

    &::before {
        @include icon;
        @include position(absolute, 50% false false 50%);
        transform: translate(-50%, -50%);
    }
}

.icon-left {
    position: relative;
    display: inline-block;
    padding-left: em(25px);

    &::before {
        @include icon;
        @include position(absolute, 50% false false 0);
        transform: translateY(-50%);
    }
}

.icon-right {
    position: relative;
    display: inline-block;
    padding-right: em(25px);

    &::before {
        @include icon;
        @include position(absolute, 50% 0 false false);
        transform: translateY(-50%);
    }
}

@each $name, $props in $svgsprite {
    .icon-#{$name} {
        &::before {
            @include icon-props($name);
        }
    }
}
