.quiz-zincdode {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    padding-left: 5rem;
    font-size: 12px;
    font-weight: bold;
    color: $color-60;
}

section.sup-quiz-mainsection {
    position: relative;
}

.sup-quizbtn-area.sup-quizbtn-prev-area {
    position: absolute;
    top: 0;
}

.quiz-sm-heading {
    margin-top: 2rem;
    font-size: 15px;
    color: $color-61;
    font-weight: bold;
}

span.quiz-iconleft {
    margin-right: .5rem;
}

.sup-quiz-maincontainer {
    background-image: url("/static/amgone_umb/img/series-4/quizbg.jpg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sup-quiz-innercontainer {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 5rem 2rem;
    position: relative;

    .feedback-option-input-col {
        .supquiz-options {
            input {
                + {
                    label.check-radio.wrong-ans {
                        &::after {
                            content: "";
                            background-image: url("/static/amgone_umb/img/series-4/wrongicon.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-color: transparent;
                            width: 30px;
                            height: 30px;
                            left: 0;
                            top: -5px;
                        }
                    }

                    label.check-radio.right-ans {
                        &::after {
                            content: "";
                            background-image: url("/static/amgone_umb/img/series-4/tickicon.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-color: transparent;
                            width: 30px;
                            height: 30px;
                            left: 0;
                            top: -5px;
                            opacity: 1;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }

            input[type="checkbox"] {
                &:checked {
                    + {
                        label.check-checkbox.wrong-ans {
                            &::after {
                                content: "";
                                background-image: url("/static/amgone_umb/img/series-4/wrongicon.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-color: transparent;
                                width: 30px;
                                height: 30px;
                                left: 0;
                                top: -5px;
                                position: absolute;
                            }
                        }

                        label.check-checkbox.right-ans {
                            &::after {
                                content: "";
                                background-image: url("/static/amgone_umb/img/series-4/tickicon.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-color: transparent;
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                left: 0;
                                top: -5px;
                                opacity: 1;
                                -webkit-transform: scale(1);
                                transform: scale(1);
                            }
                        }
                    }
                }

                &:not(:checked) {
                    + {
                        label.check-checkbox.wrong-ans {
                            &::after {
                                content: "";
                                background-image: url("/static/amgone_umb/img/series-4/wrongicon.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-color: transparent;
                                width: 30px;
                                height: 30px;
                                left: 0;
                                top: -5px;
                                position: absolute;
                            }
                        }

                        label.check-checkbox.right-ans {
                            &::after {
                                content: "";
                                background-image: url("/static/amgone_umb/img/series-4/tickicon.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-color: transparent;
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                left: 0;
                                top: -5px;
                                opacity: 1;
                                -webkit-transform: scale(1);
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }
}

.quiz-heading-area {
    h2.quizmainheading {
        font-size: 3.5rem;
        color: $color-60;
        margin-bottom: 1rem;
    }
}

.quiz-content {
    .p {
        font-size: 1.3rem;
    }
}

.sup-quizbtn-area {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

a.quizbtn {
    padding: 1rem 2rem;
    background-color: $color-61;
    color: $color-white;
    cursor: pointer;
}

span.quiz-iconright {
    margin-left: 1rem;
}

.quizimg-area {
    width: 45%;
    margin: 0 0 0 auto;
    position: absolute;
    right: 0;
    bottom: 0;

    img {
        margin-bottom: 0;
    }
}

.quizimg-area.quiz-autowidthimg {
    width: 30%;
}

.feedback-option-input-col {
    .supquiz-options {
        input[type="radio"] {
            &:checked {
                position: absolute;
                left: -9999px;

                + {
                    label.check-radio {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        color: $color-7;
                        font-weight: bold;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 22px;
                            height: 22px;
                            border: 1px solid $color-4;
                            border-radius: 100%;
                            background: $color-white;
                        }

                        &::after {
                            content: '';
                            width: 14px;
                            height: 14px;
                            background-color: $color-62;
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            border-radius: 100%;
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                        }
                    }
                }
            }

            &:not(:checked) {
                position: absolute;
                left: -9999px;

                + {
                    label.check-radio {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        color: $color-7;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 22px;
                            height: 22px;
                            border: 1px solid $color-4;
                            border-radius: 100%;
                            background: $color-white;
                        }

                        &::after {
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                            content: '';
                            width: 14px;
                            height: 14px;
                            background-color: $color-62;
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            border-radius: 100%;
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                        }
                    }
                }
            }
        }

        input[type="checkbox"] {
            position: absolute;
            left: -9999px;

            &:checked {
                + {
                    label.check-checkbox {
                        &::after {
                            content: '';
                            width: 14px;
                            height: 14px;
                            background-color: $color-62;
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                        }
                        font-weight: bold;
                    }
                }
            }
        }

        label.check-checkbox {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: $color-7;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 22px;
                height: 22px;
                border: 1px solid $color-4;
                background: $color-white;
            }
        }
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
    position: relative;
}

.supquiz-options {
    margin-bottom: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 30%;
    z-index: 1;

    label.right-ans {
        span.opt-text {
            position: relative;

            &::before {
                position: absolute;
                border-left: solid 15px transparent;
                border-right: solid 15px transparent;
                border-bottom: solid 15px rgba(255, 255, 255, .8);
                left: rem;
                top: 2.4rem;
                content: "";
                display: block;
            }
        }
    }
}

span.opt-text {
    display: block;
    font-size: 22px;
    margin-left: 8px;
    color: $color-60;
}

.quiz-sm-heading {
    font-size: 15px;
    color: $color-61;
    font-weight: bold;
}

h3.quiz-number {
    font-size: 35px;
    color: $color-60;
    margin: .5rem 0;
}

.quizquestionheading-area {
    font-size: 35px;
    color: $color-60;
    margin-bottom: 1.5rem;
    width: 65%;
}

.sup-correct-ans {
    position: relative;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: $color-4;
    width: 80%;
    top: 1rem;
    padding: 1rem;
    border-radius: 10px;
}

span.suptooltip-greentxt {
    color: $color-64;
}

.suptooltip-txt {
    font-size: 22px;
    color: $color-60;
}

.supquiz-correctoption.supquiz-correctoption-trigger {
    &::before {
        position: absolute;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        border-bottom: solid 15px $color-4;
        left: 2rem;
        top: 2.1rem;
        content: "";
        display: block;
    }
}

.sup-correct-ans.tooltipactivated {
    &::before {
        position: absolute;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        border-bottom: solid 15px $color-4;
        left: 2rem;
        top: -15px;
        content: "";
        display: block;
    }
}

.supquiz-options.supquiz-options-fullwidth {
    label.right-ans {
        span.opt-text {
            &::before {
                display: none;
            }
        }
    }
    width: 65%;
    margin-bottom: 1.5rem;
}

.quiz-ans-ref {
    margin-top: 3rem;
    width: 65%;
}

ol.ans-ref-area {
    margin-left: 0;
    padding-left: 1.2rem;
    font-size: 11px;
    font-weight: bold;
    color: $color-60;

    a {
        color: $color-60;
    }
}

.quiz-ref-txt {
    font-size: 11px;
    font-weight: bold;
    color: $color-60;
}

.quiz-submit-heading {
    font-size: 35px;
    color: $color-60;
    margin-bottom: 1.5rem;
}

.quizsubmitimg-area {
    img {
        margin-bottom: 0;
        width: 65%;
    }
    margin: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
}

.quiz-submit {
    .sup-quiz-innercontainer {
        padding-bottom: 0;
        display: block;
    }
}

@media screen and (max-width: 1024px) {
    .sup-quiz-innercontainer {
        padding: 1rem;
    }

    .quizquestionheading-area {
        font-size: 30px;
        width: 100%;
    }

    h3.quiz-number {
        font-size: 30px;
    }

    .quiz-ans-ref {
        margin-top: 3rem;
        width: 100%;
    }
}
