.layout-row--hcp-registration {
    display: block;
}

.layout-column--hcp-registration {
    img {
        max-width: 33%;
    }
}

@media screen and (min-width: $layout-breakpoint-tablet) {
    .hcp-registration__form-title {
        img {
            width: 75px;
            margin: rem(64px) auto 0 auto;
        }
    }

    .layout-column--hcp-registration {
        width: layout-column-width(6);
    }
}

@media screen and (min-width: $layout-breakpoint-desktop) {
    .hcp-registration__form-title {
        position: relative;
        text-align: center;

        img {
            position: absolute;
            top: 0;
            left: $layout-gutter-large / 2;
            margin: 0;
        }
    }

    .layout-column--hcp-registration {
        h2 {
            margin: rem(90px) 0 rem(80px);
        }
    }
}
