.passcode-mainwrapper {
    background-color: $color-6;
}

.passcode-maincontainer {
    max-width: 1200px;
    padding: 0 10px;
    margin: auto;
}

.passcode-innercontainer {
    text-align: center;
    padding: 2rem 0;
}

h2.passcode-heading {
    color: $color-53;
}

.passcode-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
    max-width: 500px;
    margin: auto;
}

.passcode-area input.passcodeinput {
    width: 15%;
    height: 5rem;
    text-align: center;
    font-size: 2rem;
}

.passcode-area input[type=number]::-webkit-inner-spin-button, 
.passcode-area input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.passcode-erormsg {
    margin-top: 1rem;
    color: $color-1;
    display: none;
}

.passcode-submit-area {
    margin: 2rem auto auto;
}

button.passcode-submitbtn {
    background-color: $color-57;
    border: 0;
    color: #fff;
    padding: 1rem;
    position: relative;
    width: 20rem;
    cursor: pointer;
}

button.passcode-submitbtn:before {
    content: "";
    background-image: url("assets/right-arrow.svg");
    background-repeat: no-repeat;
    right: 1rem;
    position: absolute;
    width: 20px;
    height: 20px;
}

@media screen and (max-width:  700px){
    .passcode-area input.passcodeinput {
        height: auto;
	}
	
	button.passcode-submitbtn {
        width: 100%;
	}	
}

