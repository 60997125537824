$list-ul-bullet-color: $color-1 !default;
$list-arrowlist-icon: arrow-small-right-gray-repatha !default;
$list-arrowlist-color: $color-5 !default;
$list-linklist-color: $color-1 !default;

/// Mixin to give a list item no bullets and indention
/// @example
///      @include blank-list;
@mixin blank-list {
    list-style-type: none;
    padding-left: 0;
}

ul,
ol {
    margin: 0 0 $layout-element-margin-default;
    padding: 0 0 0 1.6em;

    ul,
    ol {
        margin-bottom: 0;
    }
}

ul {
    &:not([class]) {
        list-style: none;

        li {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -1em;
                top: .4em;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $list-ul-bullet-color;
            }
        }
    }
}

.link-list {
    @include blank-list;

    &_item {
        color: $list-linklist-color;
    }
}

.arrow-list {
    @include blank-list;

    &_item {
        position: relative;
        padding-left: 1em;

        &:not(:first-child) {
            margin-top: .5em;
        }

        &::before {
            @include icon;
            @include icon-props($list-arrowlist-icon);
            @include position(absolute, .3em false false 0);
        }
    }

    &_link {
        color: $list-arrowlist-color;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.icon-list {
    @include blank-list;

    &_item {
        position: relative;
        padding-left: 45px;

        &:not(:first-child) {
            margin-top: 20px;
        }

        &::before {
            @include icon;
            @include position(absolute, 50% false false 0);
            transform: translateY(-50%);
        }
    }

    @media (min-width: $layout-breakpoint-tablet) {
        &_item {
            position: relative;
            padding-left: 60px;
        }
    }
}
