@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--three {
        &:nth-child(-n+2) {
            width: layout-column-width(6);
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--three {
        &,
        &:nth-child(-n+2) {
            width: layout-column-width(4);
        }
    }

    .layout-row--equal-width-columns {
        display: flex;
        flex-direction: row;

        > .layout-column {
            flex: 1 1 0;
        }
    }
}
