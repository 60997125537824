.pcast-player-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.pcast-player-content {
    padding: 40px 0 56px;
}

.pcast-player-content .pcast-play-detail {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: baseline;
}

.pcast-player-content .pcast-play-detail:first-child {
    margin-bottom: 2rem;
}

.pcast_play_desktop .pcast-player-content .pcast-play-detail {
    margin-left: 0;
    padding: 6px 0 6px 2rem;
}

.pcast-player-content .pcast-play-detail .col-1 {
    width: 48%;
}

.pcast-player-content .pcast-play-detail .col-2 {
    width: 22%;
}

.pcast-player-content .pcast-play-detail .col-3 {
    width: 9%;
}

.pcast-player-content .pcast-play-detail .col-4 {
    width: 11%;
}

.pcast-player-content .pcast-head {
    padding-left: 22px;
}

.pcast-player-content .pcast-head,
.pcast-player-content .pcast-completed {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .75px;
    line-height: 15px;
    text-transform: uppercase;
}

.pcast-player-content .video-name {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.pcast-player-content .pcast-clock img {
    height: 16px;
    width: 16px;
    margin: 0;
}

.pcast-player-content .pcast-calendar img {
    height: 14px;
    width: 14px;
    margin: 0;
}

.download-image {
    margin: 0;
}

.pcast-player-content .pcast-timer,
.pcast-player-content .pcast-date {
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.pcast-player-content .ap-time-rail {
    box-sizing: border-box;
    height: 4px;
    width: 75%;
    background-color: $color-4;
}

.pcast-player-content .ap-time-rail .ap-time-loaded {
    height: 100%;
    width: 0;
    background-color: $color-22;
}

.pcast-player-container .pcast-play-detail .col-6 .pcast-sd-container ul.block-grid {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
}

.pcast-sd-container ul.block-grid li:first-child {
    padding: 0 21px;
}

.pcast-sd-container .sd-icons ul.block-grid span.btn-tooltip {
    position: relative;
    cursor: pointer;
}

.pcast-sd-container .sd-icons ul.block-grid span.btn-tooltip .tooltip-coming-soon {
    min-width: 111px;
    max-width: 300px;
    height: 16px;
    top: -40px;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    text-transform: uppercase;
    opacity: .6;
    background-color: $color-black;
}

.podcast-top-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.podcast-article {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.podcast-article .pcast-cont {
    width: 100%;
    max-width: 491px;
}

.podcast-article .pcast-cont .pcast-name {
    color: $color-black;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
    margin-bottom: 8px;
}

.podcast-article .pcast-cont .pcast-title {
    color: $color-black;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 12px;
}

.podcast-article .pcast-cont .doctor {
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 8px;
}

.podcast-article .pcast-cont .play-pause-wrap {
    margin-bottom: 28px;
}

.podcast-article .pcast-cont .play-pause-button a.pcast-btn {
    box-sizing: border-box;
    height: 49px;
    width: 179px;
    border: 1px solid $color-22;
    border-radius: 24px;
    background-color: transparent;
    outline: none;
    margin-right: 2rem;
    color: $color-22;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.podcast-article .pcast-cont .pcast-text {
    margin-bottom: 24px;
}

.podcast-article .pcast-cont .play-pause-button {
    display: flex;
    align-items: center;
}

.podcast-article .pcast-cont .play-pause-button a {
    text-decoration: none;
    text-transform: uppercase;
}

.podcast-article .pcast-cont .play-pause-button a .download-link {
    color: $color-22;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none;
}

.podcast-article .pcast-cont .play-pause-button a .download-link::after {
    background-image: url("/static/img/download-awb.png");
    background-size: 9px 13px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 9px;
    height: 13px;
    content: "";
}

.podcast-article .pcast-cont .p {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
}

.pcast-event-cont .event-box .event-img-container img.pcast-img {
    width: 100%;
    max-width: 560px;
}

.podcast-article .play-pause-button a.pcast-btn.player-pause {
    background: $color-22;
    color: $color-white;
}

.podcast-article .play-pause-button a.pcast-btn.player-pause i.ap-pause {
    display: block;
}

.podcast-article .play-pause-button a.pcast-btn.player-pause i.ap-play {
    display: none;
}

.podcast-article .play-pause-button a.pcast-btn i.ap-pause {
    display: none;
}

.podcast-article .play-pause-button a.pcast-btn.player-pause span.go-on {
    display: block;
}

.podcast-article .play-pause-button a.pcast-btn.player-pause span.go-pause {
    display: none;
}

.podcast-article .play-pause-button a.pcast-btn span.go-on {
    display: none;
}

.pcast-episodeNo {
    display: none;
}

.pcast_episodes .pcast-player-content {
    padding: 20px 0 10px;
}

h3.podcast-epiTitle {
    margin-top: 40px;
}

.pcast-epiTitle {
    color: $color-black;
    font-family: "ExoSemiBd";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
}

.pcast-epiConsole .p {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10px;
    max-width: 72.5%;
}

.pcast_episodes .pcast-player-content .pcast-play-detail {
    margin-bottom: 0;
}

.pcast_episodes .pcast-play-detail.podcast-head {
    margin-bottom: 1rem;
}

.pcast_episodes .pcast-play-detail.podcast-console {
    padding: 10px 24px 20px;
}

.pcast-play-detail.podcast-console.is-active {
    background-color: $color-7;
}

.pcast-play-detail.podcast-console.is-active .video-name {
    color: $color-22;
    font-weight: bold;
}

.pcast-divider {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    max-width: 1200px;
    background-color: $color-4;
    margin: 1.5rem 0 1rem;
}

i.ap::before {
    display: inline-block;
    height: 15px;
    width: 13px;
    margin-right: 16px;
    content: "";
}

i.ap-play::before {
    background: url("/static/img/play-wb.png") left bottom/13px 14px no-repeat;
}

i.ap-pause::before {
    background: url("/static/img/pause-blue.png") left bottom/13px 14px no-repeat;
}

i.ap-pause-audio::before {
    background: url("/static/img/audio-play.png") left bottom/12px 14px no-repeat;
}

@media (max-width: 1024px) {
    .pcast-player-content .pcast-play-detail .col-1 {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .pcast-player-content .pcast-play-detail .col-1 {
        width: 40%;
    }

    .pcast-epiConsole .p {
        line-height: 18px;
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .pcast-divider {
        margin: 0;
    }

    .podcast-article {
        flex-direction: column-reverse;
    }

    .podcast-article .pcast-cont .pcast-name {
        color: $color-6;
        font-family: "IBMPlexSemiBd";
        margin-top: 8px;
    }

    .podcast-article .pcast-cont .pcast-title {
        font-family: "IBMPlexSemiBd";
        font-size: 18px;
        line-height: 28px;
    }

    .podcast-article .pcast-cont .doctor,
    .podcast-article .pcast-cont .play-pause-button a .download-link {
        font-family: "IBMPlexMd";
    }

    .podcast-article .pcast-cont .p {
        font-size: 1rem;
        margin-bottom: 40px;
    }

    .pcast-player-content .video-name {
        font-family: "IBMPlexMd";
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 35px;
    }

    .pcast-player-content {
        padding: 39.5px 0;
    }

    .pcast-play-detail.podcast-head {
        display: none;
    }

    .pcast-play-detail.podcast-console {
        flex-direction: column;
        position: relative;
    }

    .pcast_play_desktop .pcast-player-content .pcast-play-detail {
        padding-left: 0;
    }

    .pcast-play-detail.podcast-console.is-active {
        background-color: transparent;
    }

    .pcast-player-content .pcast-play-detail .col-1,
    .pcast-player-content .pcast-play-detail .col-2 {
        width: 100%;
    }

    .pcast-player-content .pcast-play-detail .col-3 {
        position: absolute;
        top: 60%;
        right: 0;
    }

    .pcast-player-content .pcast-play-detail .col-4 {
        display: none;
    }

    .pcast-player-content .pcast-play-detail .col-6 {
        position: absolute;
        top: -12px;
        right: 17px;
    }

    .pcast-player-content .ap-time-rail {
        width: 100%;
        max-width: 84%;
    }

    .pcast-player-content .pcast-timer {
        font-size: 12px;
        line-height: 15px;
    }

    .pcast-sd-container ul.block-grid li:first-child {
        padding: 0 35px;
    }

    .pcast_episodes .pcast-player-content {
        padding: 0;
    }

    .pcast_episodes .pcast-player-content .video-name {
        display: inline-flex;
        padding-right: 5rem;
        margin-bottom: 10px;
    }

    .pcast_episodes .pcast-player-content .pcast-play-detail .col-6 {
        top: 4px;
    }

    .pcast-epiConsole .p {
        padding: 0 40px 40px 33px;
        font-size: 12px;
        line-height: 19px;
    }

    .pcast_episodes .pcast-play-detail.podcast-console {
        padding: 20px 0;
    }

    .pcast_episodes .pcast-player-content .pcast-play-detail {
        margin-bottom: 0;
        line-height: 0;
    }

    .pcast_episodes .pcast-player-content .pcast-play-detail .col-3 {
        top: 81.5%;
    }
}
