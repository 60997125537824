$accordion-color-active: $color-2 !default;
$accordion-color-passive: $color-2 !default;
$accordion-color-divider: $color-6 !default;

.layout-row--accordion {
    @include component-margin;
}

.accordion {
    [aria-selected] {
        text-decoration: none;
    }

    &_trigger {
        position: relative;
    }

    &_labels {
        position: absolute;
        right: em(10px);
        top: em(10px);
        text-transform: uppercase;

        &::before {
            font-size: rem($heading-h3-font-size);
            content: '+';
            color: $color-white;
            display: inline-block;
        }

        [aria-selected='true'] & {
            &::before {
                content: '-';
            }
        }
    }

    &_trigger--h3 {
        color: $color-white;
        background: $accordion-color-passive;
        padding: em(10px) em(20px) em(6px) em(10px);

        [aria-selected='true'] & {
            background: $accordion-color-active;
        }
    }

    &_label--open {
        display: none;
        cursor: pointer;
    }

    &_label--close {
        display: none;
        cursor: pointer;
    }

    &_intro {
        font-weight: bold;
    }

    &_content {
        overflow: hidden;

        &--image {
            width: auto;
            max-width: 320px;
            margin: 0 auto  em(32px);
        }
    }

    .collapsible-transition {
        transition: height .3s ease-in-out;
        overflow: hidden;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .accordion {
        [aria-selected] {
            text-decoration: none;
        }

        &_trigger {
            border-top: solid 1px $accordion-color-divider;
        }

        &_trigger--h3 {
            color: $accordion-color-passive;
            background: none;
            margin: rem(14px) rem(100px) rem(8px) 0;
            padding: 0;

            [aria-selected='true'] & {
                background: none;
                color: $accordion-color-active;
            }
        }

        &_init {
            margin-right: rem(100px);
        }

        &_labels {
            top: em(10px);

            &::before {
                padding-right: rem(10px);
                color: $accordion-color-passive;
            }

            [aria-selected='true'] & {
                &::before {
                    color: $accordion-color-active;
                }
            }
        }

        &_label--open {
            color: $accordion-color-passive;

            [aria-selected='false'] & {
                display: inline-block;
            }
        }

        &_label--close {
            color: $accordion-color-active;

            [aria-selected='true'] & {
                display: inline-block;
            }
        }

        &_content {
            margin-right: rem(100px);

            &--image {
                float: left;
                margin-right: em(32px);
            }
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--accordion {
        margin-left: layout-column-width(1);
        width: layout-column-width(10);
    }
}
