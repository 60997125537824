.search .h3 {
    margin: .2rem 0;
    font-size: 1.25rem;
}

.search h3 {
    margin: .2rem 0;
    font-size: 1.25rem;
}

.search p {
    margin: 0 0 .3em;
}

.search p + p {
    margin: 0;
}

.search ul {
    padding: 0;
}

.search ul:not([class]) li::before {
    display: none;
}

.search ul {
    margin-bottom: 1.5rem;
}

$border_color_1: #000;

.pagination {
    li {
        margin-bottom: .5em;

        a {
            display: inline-block;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            text-transform: uppercase;
            padding: .6875rem 1.5rem .5rem;
            width: 100%;
            min-height: 3.0625rem;
            transition: background .3s ease, border-color .3s ease, color .3s ease;
            border: 1px solid;
            color: $color_2;
            border-color: $border_color_1;
        }
    }
}

.pagination_item {
    &:not(:last-child) {
        margin-right: .5em;
    }
}

$color_1: #fff;

$color_2: #000;

.pagination {
    .active {
        background: #54b948;
        color: $color_1;

        a {
            color: $color_1;
        }
    }
}
