#theopsplogin .container #login-row #login-column #login-box {
    max-width: 650px;
    background-color: $color-19;
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
}

#theopsplogin .container #login-row #login-column #login-box #login-form {
    padding: 20px;
}

#theopsplogin .container #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px;
}

#theopsplogin .form-group {
    width: 65%;
    margin: 0 auto;
}

#theopsplogin p {
    color: $color-20;
    margin-bottom: .5rem;
}

#theopsplogin .s {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    color: $color-18;
}

#theopsplogin .h5,
#theopsplogin h5 {
    font-size: 19px;
    color: $color-20;
    margin: .75rem;
}

#theopsplogin .h3,
#theopsplogin h3 {
    color: $color-20;
    text-align: center;
    margin: .5rem;
}

#theopsplogin .btn {
    background-color: $color-21;
    color: $color-black;
    min-width: 150px;
    margin: 1rem auto;
    border: none;
    font-size: 1rem;
    min-height: 2rem;
    line-height: 1;
}

#theopsplogin .bn {
    background-color: $color-17;
    border: 1px solid $color-20;
    color: $color-black;
    width: 150px;
}

#theopsplogin .strong {
    line-height: inherit;
    color: inherit;
    text-align: center;
}

#theopsplogin button,
#theopsplogin button.button {
    background-color: $color-21;
    width: 205px;
    text-align: center;
    color: $color-20;
    padding: .5em 0;
    display: inline-block;
    border: 0;
    margin: 1em auto;
    margin-left: 100px;
}

#theopspreg button,
#theopspreg button.button {
    background-color: $color-20;
    width: 150px;
    text-align: center;
    color: $color-white;
    padding: .5em 0;
    display: inline-block;
    border: 0;
    margin: 1em auto;
}

#theopspreg {
    max-width: 650px;
    background-color: $color-19;
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
}

#theopspreg .container #login-row #login-column #login-box #login-form {
    padding: 20px;
}

#theopspreg .container #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px;
}

#theopspreg .form-group {
    width: 65%;
    margin: 0 auto;
}

#theopspreg p {
    color: $color-20;
    margin-bottom: .5rem;
}

#theopspreg .h5,
#theopspreg h5 {
    font-size: 19px;
    color: $color-20;
    margin: .75rem;
}

#theopspreg .h3,
#theopspreg h3 {
    color: $color-20;
    text-align: center;
    margin: .5rem;
}

#theopspreg fieldset.textbox {
    position: relative;
}

#theopspreg fieldset.textbox label {
    position: absolute;
    top: 20px;
    left: 20px;
    line-height: 1;
    color: $color-18;
    font-size: 1rem;
}

#theopspreg fieldset.textbox input {
    height: 30px;
    width: 100%;
    padding-left: 90px;
}

#theopspreg fieldset.textbox.error input {
    border: 1px solid $color-error;
}

#theopspreg fieldset.checkbox {
    margin-top: 30px;
}

#theopspreg fieldset.checkbox p {
    margin-bottom: 20px;
}

#theopspreg fieldset .checkbox .error label .label_checkbox::before {
    border: 1px solid $color-error;
}

#theopspreg fieldset.radiobox {
    margin-top: 30px;
}

#theopspreg fieldset.radiobox p {
    margin-bottom: .5em;
}

#theopspreg fieldset .radiobox .error label .label_radio::before {
    border: 1px solid $color-error;
}

#theopspreg fieldset.fieldset-batchcode {
    margin-top: 30px;
}

#theopspreg fieldset.fieldset-batchcode p {
    margin-bottom: 10px;
}

#theopspreg fieldset.fieldset-batchcode input {
    padding-left: 10px;
}

#theopspreg fieldset.fieldset-batchcode label {
    display: none;
}

#theopspreg fieldset.fieldset-agree {
    margin-top: 20px;
    margin-bottom: 20px;
}

#theopspreg fieldset.fieldset-agree label.label_radio {
    height: auto;
}

#theopspreg fieldset p {
    color: $color-18;
    text-align: left;
}

#theopspreg fieldset label.label_checkbox {
    position: relative;
    padding-left: 40px;
    display: block;
    margin-top: 10px;
    height: 26px;
    line-height: 26px;
    text-align: left;
    color: $color-18;
    font-size: 14px;
    font-size: .875rem;
}

#theopspreg fieldset label.label_checkbox input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

#theopspreg fieldset label .label_checkbox::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 26px;
    height: 26px;
    border: 1px solid $color-4;
    background-color: $color-4;
}

#theopspreg fieldset label .label_checkbox .checked::before {
    background-image: url("https://theo.dev16.evokeclients.com/wp-content/uploads/checkbox-checked-2x.jpg");
    background-size: 13px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

#theopspreg fieldset label.label_checkbox.error::before {
    border: 1px solid $color-error;
    background-image: none;
}

#theopspreg fieldset label.label_radio {
    position: relative;
    padding-left: 40px;
    display: block;
    margin-top: 10px;
    height: 26px;
    line-height: 26px;
    text-align: left;
    color: $color-18;
    font-size: 14px;
    font-size: .875rem;
}

#theopspreg fieldset label.label_radio input[type="radio"] {
    position: absolute;
    left: -9999px;
}

#theopspreg fieldset label.label_radio::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 26px;
    height: 26px;
    border: 1px solid $color-4;
    background-color: $color-4;
    border-radius: 26px;
}

#theopspreg fieldset label.label_radio.checked::before {
    background-size: 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

#theopspreg fieldset label.label_radio.error::before {
    background-position: 0 0;
    border: 1px solid $color-error;
}

#theopspreg fieldset #radiobox_recieved {
    text-align: left;
}

#theopspreg fieldset #radiobox_recieved label.label_radio {
    display: inline-block;
}

#theopspreg fieldset #radiobox_recieved label.label_radio + label.label_radio {
    margin-left: 20px;
}

#theopspreg fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

#theopspreg input.text,
#theopspreg input.email {
    background-color: $color-17;
    border: 1px solid $color-17;
    font-family: "Heebo", sans-serif;
    font-weight: 400;
    font-size: .875rem;
    padding: 4px;
}

#theopspreg .bulleted {
    text-align: left;
    color: $color-20;
}

#theopspreg a {
    color: $color-20;
}

/* Custom Radio Button Start */
.radiotextsty {
    color: $color-20;
}

.customradio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.customradio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $color-17;
    border-radius: 50%;
    border: 1px solid $color-17;
}

/* On mouse-over, add a grey background color */
.customradio:hover input ~ .checkmark {
    background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.customradio input:checked ~ .checkmark {
    background-color: $color-17;
    border: 1px solid $color-17;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.customradio input:checked ~ .checkmark::after {
    display: block;
}

/* Style the indicator (dot/circle) */
.customradio .checkmark::after {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-20;
}

/* Custom Radio Button End */
#theopspreg .radioList {
    display: flex;
}

.error {
    border: 1px solid $color-error !important;
}

.error_radio {
    border-color: $color-error !important;
}

.form .radiobox .customradio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.form .radiobox .customradio input:checked ~ .checkmark::after {
    opacity: 1;
    -webkit-animation: pulse-input .8s ease-in-out;
    animation: pulse-input .8s ease-in-out;
}

.bm-reg-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 4rem 0;
    text-align: center;
}

.bm-reg-container .want-reg,
.bm-reg-container .already-reg {
    width: 47%;
    padding: 1rem;
    background: $color-25;
}

.bm-reg-container .p,
.bm-reg-container h4 {
    color: $color-white;
}

.bm-reg-container h4 {
    font-size: 1.5rem;
}

.bm-reg-container .already-reg .p {
    min-height: 2.4rem;
}

.bm-reg-container .p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.bm-reg-container .form-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
}

.bm-reg-container input[type="text"],
.bm-reg-container input[type="number"] {
    width: auto;
    text-align: center;
    min-height: 2.3rem;
    background: $color-white;
    padding: .5rem 1rem;
    margin: .5rem 0;
    border: none;
}

.bm-reg-container a {
    color: $color-white;
    border: 1px solid $color-white;
    padding: 1rem;
    text-decoration: none;
}

@media (max-width: 1024px) {
    .bm-reg-container .want-reg,
    .bm-reg-container .already-reg {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .bm-reg-container {
        display: block;
    }

    .bm-reg-container .want-reg,
    .bm-reg-container .already-reg {
        width: 92%;
    }

    .bm-reg-container .already-reg {
        margin-top: 2rem;
    }
}

@media (max-width: 540px) {
    .bm-reg-container .form-content {
        width: 75%;
    }
}

.bm-threg {
    font-size: 16px;
    color: $color-5;

    .page-title {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    .p {
        font-size: 1rem;
        margin-bottom: .8rem;
    }

    .link {
        color: $color-12;
        text-decoration: none;
    }

    .page-container {
        max-width: 1200px;
        margin: auto;
        padding: 0 15px;
    }

    .form-heading {
        margin-bottom: 2rem;
    }

    .form-group {
        margin-bottom: 1.5rem;
        position: relative;
    }

    .form-label {
        display: block;
        font-size: 15px;
        margin-bottom: 10px;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $color-4;
        background: $color-7;

        &:focus {
            border-color: $color-6;
        }

        &:active {
            border-color: $color-6;
        }
    }

    .from-row {
        display: flex;
        justify-content: space-between;
    }

    .form-column {
        width: calc(50% - 10px);
    }

    .error-msg {
        color: $color-error;
        display: none;
        left: 0;
    }

    .privacy-notice-button {
        display: block;
        text-decoration: none;
        max-width: 1200px;
        text-align: center;
        text-transform: uppercase;
        margin: 0 auto;
        width: max-content;
        color: $color-white;
        border: 1px solid $color-56;
        background-color: $color-56;
        padding: .8rem 2rem;
        border-radius: 25px;

        &:hover {
            border: 1px solid $color-56;
            background-color: $color-white;
            color: $color-56;
        }
    }

    .btn-submit {
        &:hover {
            border: 1px solid $color-56;
            background-color: $color-white;
            color: $color-56;
        }
        display: block;
        padding: 10px;
        text-decoration: none;
        max-width: 1200px;
        text-align: center;
        width: 100%;
        cursor: pointer;
        margin: 0 auto;
        color: $color-white;
        border: 1px solid $color-56;
        background-color: $color-56;
        border-radius: 25px;
    }

    .register-wrap {
        max-width: 680px;
        margin: 50px auto;
        padding: 15px;
    }

    .note {
        margin-top: 2rem;
    }

    .hide {
        display: none;
    }

    .mesage-box {
        background: $color-valid;
        padding: 5px 10px;
        margin-bottom: .6rem;
        border-radius: 4px;
    }

    .register-content {
        list-style: none;
        padding-left: 1rem;

        li {
            position: relative;
            padding-left: 1.5rem;

            &::before {
                content: "-";
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.ph-number {
    flex-direction: row;
    justify-content: space-between;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
}

.form-group {
    .ph-number {
        .ph-numbercol-code {
            width: 25%;
        }

        .ph-numbercol {
            width: 73%;
        }
    }

    .ph-numberarea {
        width: 100%;
    }

    input[type=number] {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }

    input[type="date"] {
        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
        }
    }
}

select.form-control.countryselect {
    color: $color-6;
}

input.form-control {
    &:focus {
        outline: 0;
    }
}

select.form-control {
    &:focus {
        outline: 0;
    }
}

small.error-msg.msg-ph-validmsg {
    display: none;
}

button#register-btn[disabled] {
    cursor: not-allowed;
}

@media (max-width: 500px) {
    .bm-threg {
        .from-row {
            flex-direction: column;
        }

        .form-column {
            width: 100%;

            + {
                .form-column {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}
