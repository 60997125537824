.overlay {
    @include position(fixed, 0 0 0 0);
    z-index: 1000;
    background: rgba($color-black, .2);
    padding: 0 10px;
    opacity: 1;
    transition: opacity .15s, top 0s;

    &[data-toggle-active='false'] {
        top: 101%;
        opacity: 0;
        transition: opacity .1s, top 0s .1s;

        .overlay_wrapper {
            transform: scale(.85);
        }
    }

    &_wrapper {
        background: $color-white;
        margin: 10vh auto 0;
        max-width: 700px;
        width: 100%;
        position: relative;
        transition: transform cubic-bezier(.175, .885, .32, 1.275) .15s;
    }

    &_content {
        padding: 20px;
    }

    &_closer {
        @include position(absolute, -12px -12px false false);
        cursor: pointer;
        border-radius: 50%;
        padding: 3px 10px;
        font-weight: bold;
        color: $color-white;
        background: #b90000;
        border: none;
    }

    &_iframe {
        width: 100%;
        height: 80vh;
        border: 0;
    }

    &_hidden {
        .has-js & {
            display: none;
        }
    }
}
