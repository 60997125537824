.static-output {
    display: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-9;
    cursor: pointer;
}

input[type="range"].disabled::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-5;
    opacity: .2;
}

.combination .required::before {
    position: absolute;
    top: 65px;
    right: 0;
    content: "Required";
    padding: 2px 8px;
    background: $color-21;
    border-radius: 20px;
    color: $color-white;
    font-size: 15px;
    font-weight: bold;
}

.combination {
    font-size: 18px;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.accordion-container {
    margin: 0 auto;
}

.combination .question-container {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 10px 0 15px;
}

.combination .accordion-trigger {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
}

.combination h2 {
    padding: 30px 0;
    font-size: 30px;
    margin: 0;
}

.h2::after,
h2::after {
    display: none;
}

.combination .accordion-controls {
    display: none;
    align-items: center;
    min-width: 5%;
    padding: 30px 0;
}

.combination .accordion.opened {
    display: block;
}

.combination .question-container > legend,
.combination .question-container label:first-child {
    font-weight: 600;
}

.combination .question-container input[type=text],
.combination .question-container input[type=number] {
    padding: 11px 23px;
    border: 1px solid $color-5;
    border-radius: 30px;
}

.combination .question-container.radio legend {
    width: inherit;
    min-width: 200px;
    margin: 8px 15px 0 0;
}

.combination .question-container.radio legend {
    float: left;
    margin-bottom: 10px;
}

.combination .question-container.radio label {
    float: left;
    margin-bottom: 10px;
    padding: 0 25px 0 0;
    width: auto;
}

.combination .question-container.radio label {
    float: left;
    margin-bottom: 10px;
    padding: 0 25px 0 0;
}

.combination .question-container.radio .discheckmark {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 6px 0 0;
    border: 1px solid $color-5;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
}

/* ////////////////////////////// */

.combination .question-container.radio input ~ .discheckmark {
    transition: all 300ms 0ms ease-in-out;
    color: $color-22;
}

.combination .question-container.radio .discheckmark::after {
    content: "";
    position: absolute;
    opacity: 0;
    background: $color-9;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.combination .question-container:not(.radio) legend,
.combination .question-container:not(.radio) label {
    width: 22%;
    max-width: 400px;
    margin-bottom: 10px;
    padding: 0 15px 0 0;
}

.combination .question-container.select select {
    position: relative;
    -webkit-appearance: none;
    width: calc(100% - 213px);
    z-index: 2;
    padding: 12px 23px;
    background: transparent;
    border: 1px solid $color-5;
    border-radius: 30px;
}

.combination .question-container.select::after {
    position: absolute;
    content: "";
    top: 10px;
    right: 13px;
    width: 35px;
    height: 42px;
    padding-left: 47px;
    padding-top: 0;
    background: url('/static/img/select-arrow-down.png') no-repeat 14px 20px;
    background-size: 20px;
    border-left: 1px solid gray;
    z-index: 1;
}

.combination .question-container.radio legend.inline {
    max-width: 372px;
}

.combination .question-container.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.combination .distitle {
    display: block;
    margin-top: 14px;
    padding-right: 55px;
    font-weight: 600;
}

.combination .distitle.with-comment {
    margin-top: 0;
}

.combination .question-container.radio .comment {
    display: inline-block;
    width: 90%;
}

.combination .question-container.bordered label input {
    float: right;
    margin-top: 19px;
}

.combination .question-container.bordered label .discheckmark {
    float: right;
    margin: 5px 0 -12px 0;
}

.combination .question-container.bordered label {
    position: relative;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $color-6;
    text-align: left;
    min-height: 61px;
    cursor: pointer;
}

.combination .question-container.radio input ~ .discheckmark {
    transition: all 300ms 0ms ease-in-out;
    color: $color-22;
}

.combination .question-container.radio input:checked ~ .discheckmark::after {
    opacity: 1;
    -webkit-animation: pulse-input .8s ease-in-out;
    animation: pulse-input .8s ease-in-out;
}

a.accordions-validation.btn.no-arrow.transparent {
    box-shadow: none;
    outline: none;
    color: $color-23;
    border: 1px solid $color-23;
    font-size: 16px;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 40px;
}

.combination .text-center {
    text-align: center;
    margin: 4rem 0;
}

.combination .blue {
    color: $color-24;
}

.combination .question-container.radio .helper {
    float: left;
    display: block;
    width: 100%;
    font-size: 16px;
}

.combination .image-question-container {
    display: flex;
    justify-content: center;
    margin: 60px 0;
}

.combination .image-question-container .left-col {
    padding-right: 10px;
}

.combination .image-question-container .left-col h3 {
    margin-bottom: 20px;
    text-align: right;
}

.combination .image-question-container .left-col label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 0;
    text-align: left;
    min-height: 57px;
}

.combination .image-question-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.combination .image-question-container .left-col label input {
    float: right;
    margin-left: 20px;
}

.combination .image-question-container .discheckmark {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 6px 0 4px;
    border: 1px solid $color-5;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
}

.combination .image-question-container .disable {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 6px 0 4px;
    border: 1px solid $color-5;
    border-radius: 50%;
    cursor: auto;
    vertical-align: middle;
}

.combination .image-question-container .disable::after {
    content: "";
    position: absolute;
    opacity: .5;
    background: #666768;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: auto;
}

.combination .question-container.bordered .disable {
    position: absolute;
    top: 50%;
    right: 6px;
    height: 40px;
    width: 40px;
    border: 1px solid $color-5;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: auto;
}

.combination .question-container.radio .disable::after {
    content: "";
    position: absolute;
    opacity: .4;
    background: $color-5;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: auto;
}

.section7 .radio .discheckmark::after {
    content: "";
    position: absolute;
    opacity: 1;
    background: $color-9;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.combination .image-question-container .disimage {
    padding: 0 15px;
}

.combination .image-question-container .right-col {
    padding-left: 10px;
}

.combination .image-question-container .right-col h3 {
    margin-bottom: 20px;
    text-align: left;
}

.combination .image-question-container .right-col label {
    display: flex;
    padding: 5px 0;
    text-align: left;
    align-items: center;
    min-height: 57px;
}

.combination .image-question-container .right-col label {
    display: flex;
    padding: 5px 0;
    text-align: left;
    align-items: center;
    min-height: 57px;
}

.combination .image-question-container .right-col label input {
    float: left;
    margin-right: 20px;
}

.combination .image-question-container .discheckmark::after {
    content: "";
    position: absolute;
    opacity: 0;
    background: $color-9;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.combination .image-question-container input:checked ~ .discheckmark::after {
    opacity: 1;
    -webkit-animation: pulse-input .8s ease-in-out;
    animation: pulse-input .8s ease-in-out;
}

.combination .slider-question-container .range-input-wrapper {
    width: 100%;
    margin: 20px auto 0;
    position: relative;
    padding: 10px 0;
    overflow-x: hidden;
    border: 1px solid $color-5;
    border-radius: 26px;
}

.slider-question-container .range-input-wrapper input[type=range] {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    height: 19px;
    width: 91%;
    margin: 0;
    background: transparent;
    cursor: pointer;
    margin-left: 4%;
}

.combination .slider-question-container .range-input-wrapper .reel {
    display: none;
}

.combination .slider-question-container .range-input-wrapper .static-output {
    font: bold 17px/25px Sniglet;
    color: $color-white;
    background: $color-30;
    position: absolute;
    bottom: 9px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    pointer-events: none;
    transition: all .25s;
}

.combination .slider-question-container .slider-info {
    width: 100%;
    padding: 5px 0;
}

.combination .slider-question-container .slider-info span {
    display: inline-block;
    float: left;
    width: calc(99% / 10);
    margin: 0;
    font-size: 13px;
    text-align: center;
}

.combination .slider-question-container .left-legend {
    float: left;
    max-width: 130px;
    margin: 20px 0;
    font-size: 14px;
}

.combination .slider-question-container .right-legend {
    float: right;
    max-width: 130px;
    margin: 20px 0;
    text-align: right;
    font-size: 14px;
}

.combination .slider-question-container .right-legend .distitle {
    padding-right: 0;
}

.combination .question-container textarea {
    min-height: 100px;
    margin-top: 15px;
    padding: 11px 23px;
    border: 1px solid $color-5;
    border-radius: 30px;
    transition: all .25s;
}

.combination .question-container .full-width {
    width: 100%;
    resize: vertical;
}

.combination .question-container:not(.radio) label.distitle {
    width: 100%;
}

.combination .question-container.bordered .discheckmark {
    position: absolute;
    top: 50%;
    right: 0;
    height: 40px;
    width: 40px;
    border: 1px solid $color-5;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
}

.combination .question-container.radio input ~ .discheckmark {
    transition: all 300ms 0ms ease-in-out;
    color: $color-22;
}

.btn.transparent {
    box-shadow: none;
    outline: none;
    color: $color-23;
    border: 1px solid $color-23;
    font-size: 16px;
    padding: 15px 20px;
    text-decoration: none;
    border-radius: 40px;
}

.combination .accordion {
    display: none;
    max-width: 900px;
    margin: 0 auto;
}

.combination .accordion-container {
    background-color: $color-10;
    border-bottom: 1px solid $color-6;
}

.combination .accordion-container.active {
    background-color: $color-white;
}

.combination .dicontainer {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
}

.combination .accordion.show {
    display: block;
}

@media only screen and (min-width: 575px) {
    .combination .slider-question-container .left-legend {
        max-width: 230px;
        font-size: 100%;
    }

    .combination .slider-question-container .right-legend {
        max-width: 230px;
        font-size: 100%;
    }
}

.accordion-status img {
    width: 35px;
}

.accordion-edit {
    margin-right: 1rem;
}

.usurvey {
    position: absolute;
    bottom: 2rem;
    right: 0;
    width: 75px;
    height: 75px;
    z-index: 99999;
}

.surveypopup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, .2);
    padding: 0 10px;
    transition: opacity .15s, top 0s;
    display: none;
}

.overlay_surveypopup {
    background: $color-white;
    margin: 10vh auto 0;
    max-width: 700px;
    width: 100%;
    position: relative;
    transition: -webkit-transform cubic-bezier(.175, .885, .32, 1.275) .15s;
    transition: transform cubic-bezier(.175, .885, .32, 1.275) .15s;
    transition: transform cubic-bezier(.175, .885, .32, 1.275) .15s, -webkit-transform cubic-bezier(.175, .885, .32, 1.275) .15s;
}

.surveypop_closer {
    position: absolute;
    top: -12px;
    left: -12px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px 10px;
    font-weight: 700;
    color: $color-white;
    background: $color-25;
    border: none;
}

.popup-questionnaire.wider {
    width: 610px;
    max-width: 100%;
}

.popup-questionnaire {
    display: block;
    padding: 36px 30px;
}

.popup-questionnaire .steps-container {
    position: relative;
    display: flex;
    margin-bottom: 20px;
}

.popup-questionnaire .steps-container .line {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $color-26;
}

.popup-questionnaire .combination .distitle {
    color: $color-24;
    text-align: center;
    margin-bottom: 15px;
    padding-right: 0;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
}

.blue {
    color: $color-24;
}

.popup-questionnaire .combination .content-text {
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}

.popup-questionnaire .buttons {
    float: right;
    margin-top: 5px;
}

.popup-questionnaire .btn {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 20px;
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 40px;
    box-shadow: none;
    outline: none;
    white-space: inherit;
    transition: all .6s;
    background: $color-24;
    color: $color-white;
    border: 1px solid $color-24;
}

.popup-questionnaire .combination .subtitle {
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}

.popup-questionnaire .tab .subtitle {
    text-align: center;
}

.popup-questionnaire .combination .divider {
    display: block;
    width: 100%;
    height: 1px;
    margin: 20px 0 25px;
    background-color: $color-27;
    opacity: .5;
}

.popup-questionnaire .combination .question-container.select select {
    width: 100%;
    color: $color-24;
    font-size: 1rem;
}

.combination .question-container.select select {
    position: relative;
    -webkit-appearance: none;
    width: calc(100% - 213px);
    z-index: 2;
    padding: 12px 23px;
    background: transparent;
    border: 1px solid $color-5;
    border-radius: 30px;
}

.combination .question-container {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 10px 0 15px;
}

.popup-questionnaire .combination .question-container.select::after {
    content: "";
    top: 25px;
    right: 20px;
    z-index: 1;
    width: 15px;
    height: 5px;
    border-left: none;
    padding-left: 10px;
    padding-top: 16px;
    background-image: url(/static/img/select-arrow-down.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.popup-questionnaire .combination .question-container.radio {
    display: flex;
    flex-wrap: wrap;
}

.popup-questionnaire .combination .question-container.radio label {
    display: flex;
    align-items: center;
    width: 33%;
    margin-bottom: 10px;
    padding: 0;
}

.popup-questionnaire .combination .question-container.half-size label {
    width: 48%;
    margin-right: 2%;
}

.combination .question-container.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.combination .question-container.radio label {
    float: left;
}

.combination .question-container.radio .discheckmark::after {
    content: "";
    position: absolute;
    opacity: 0;
    background: $color-22;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popup-questionnaire .combination .question-container.radio label .distitle {
    margin-left: 1rem;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    color: $color-22;
    font-weight: 600;
    word-break: break-word;
    text-align: left;
}

.popup-questionnaire .combination .question-container.radio label .discheckmark {
    min-width: 40px;
    margin-right: 0;
}

.combination .question-container.radio input ~ .discheckmark {
    transition: all 300ms 0ms ease-in-out;
    color: $color-22;
}

.combination .question-container.radio .discheckmark {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 6px 0 0;
    border: 1px solid $color-5;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
}

.popup-questionnaire .combination .info-caption {
    float: left;
    margin-top: 20px;
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}

.combination .question-container.radio input:checked ~ .discheckmark::after {
    opacity: 1;
    -webkit-animation: pulse-input .8s ease-in-out;
    animation: pulse-input .8s ease-in-out;
}

.popup-questionnaire .combination .fields-mix {
    display: flex;
}

.popup-questionnaire .combination .fields-mix .question-container.radio {
    min-width: 73px;
    margin-right: 20px;
}

.popup-questionnaire .combination .fields-mix .question-container.radio label {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.popup-questionnaire .combination .fields-mix .question-container.radio label .distitle {
    padding: 7px 0;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
}

.popup-questionnaire .combination .fields-mix .question-container.radio label .distitle {
    padding: 7px 0;
    font-size: 13px;
    line-height: 17px;
}

.popup-questionnaire .combination .fields-mix .question-container.radio label .distitle {
    padding: 7px 0;
    font-size: 13px;
    line-height: 17px;
}

.popup-questionnaire .combination .fields-mix .slider-question-container {
    min-width: calc(100% - 93px);
}

.combination .slider-question-container .range-input-wrapper {
    width: 100%;
    margin: 20px auto 0;
    position: relative;
    padding: 10px 0;
    overflow-x: hidden;
    border: 1px solid $color-5;
    border-radius: 26px;
}

.popup-questionnaire .combination .fields-mix .slider-question-container .range-input-wrapper {
    margin: 8px auto 0;
}

.combination .slider-question-container .range-input-wrapper input[type=range] {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    height: 19px;
    width: 91%;
    margin: 0;
    background: transparent;
    cursor: pointer;
    margin-left: 4%;
    border: none;
}

.combination .slider-question-container .range-input-wrapper .reel {
    display: none;
}

.combination .slider-question-container .range-input-wrapper .static-output {
    font: bold 17px/25px Sniglet;
    color: $color-white;
    background: $color-30;
    position: absolute;
    bottom: 9px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    pointer-events: none;
    transition: all .25s;
}

.popup-questionnaire .combination .fields-mix .slider-question-container .slider-info {
    padding: 7px 0;
}

.popup-questionnaire .combination .slider-question-container .slider-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
}

.popup-questionnaire .combination .fields-mix .slider-question-container .slider-info {
    padding: 7px 0;
}

.combination .slider-question-container .slider-info span {
    display: inline-block;
    float: left;
    width: calc(99% / 10);
    margin: 0;
    font-size: 13px;
    text-align: center;
}

.popup-questionnaire .combination .slider-question-container .slider-info span {
    width: calc(100% / 6);
    text-align: center;
    font-weight: bold;
}

.fhc-site .combination .slider-question-container .slider-info span {
    width: calc(100% / 5);
}

.popup-questionnaire .combination .mobile-view {
    display: none !important;
}

.popup-questionnaire .combination .question-container.radio.vertical label {
    position: relative;
    width: auto;
    height: 146px;
    padding: 0 25px 0 0;
    margin-right: 40px;
}

.popup-questionnaire .combination .question-container.radio.vertical .discheckmark {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

.popup-questionnaire .combination .question-container.radio label .vertical-text {
    position: absolute;
    left: 2px;
    bottom: 10px;
    transform: rotate(-90deg);
    transform-origin: left top;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn.disabled,
.btn:disabled {
    opacity: .65;
}

@media screen and (max-width: 768px) {
    .combination .question-container.radio legend {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .popup-questionnaire .combination .question-container.radio label .discheckmark::after {
        width: 16px;
        height: 16px;
        z-index: -1;
    }

    .popup-questionnaire .combination .question-container.radio label {
        width: 50%;
    }

    .popup-questionnaire .combination .mobile-view {
        display: flex !important;
    }

    .popup-questionnaire .combination .desktop-view {
        display: none;
    }

    .popup-questionnaire .combination .question-container.radio label .discheckmark {
        min-width: 24px;
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }
}

.static-output {
    display: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-22;
    cursor: pointer;
}
