#agecheck {
    display: none;
}

.redirectpopup {
    width: 500px;
    height: 400px;
    padding: 1rem;
    z-index: 99999;
    background: #fff;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.redirectpopup img {
    width: 200px;
    height: auto;
    margin: 2rem auto;
}

.redirectpopup .btn {
    margin-bottom: 25px;
}
