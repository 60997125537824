.layout-column--textimg-header {
    display: flex;
    flex-direction: column;
}

.textimg {
    @include component-margin;

    &-header {
        display: flex;
        flex-direction: column;

        &--reverse {
            flex-direction: column-reverse;
        }
    }

    &_img {
        &--stretched {
            margin-left: -($layout-row-margin + $layout-gutter-small);
            max-width: none;
            width: 100vw;
        }
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .textimg {
        &--left,
        &--right {
            display: flex;

            > * {
                flex: 0 1 auto;
            }
        }

        &--right {
            flex-direction: row-reverse;
        }

        &--centered {
            > .layout-column {
                text-align: center;
            }
        }

        &_img {
            &--stretched {
                margin-left: 0;
                max-width: 100%;
            }
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .textimg {
        &_img {
            &--stretched {
                margin-left: 0;
                width: inherit;
            }
        }

        &--centered {
            .layout-column--textimg-content {
                margin-left: layout-column-width(2);
                width: layout-column-width(8);
            }
        }
    }

    .layout-column--textimg-content {
        margin-left: layout-column-width(1);
        width: layout-column-width(10);
    }
}
