$scrolltop-btn-size: 34px !default;
$scrolltop-btn-color: $color-2 !default;

.scrolltop {
    background: $scrolltop-btn-color;
    width: $scrolltop-btn-size;
    height: $scrolltop-btn-size;
    display: block;
    position: fixed;
    right: $layout-row-margin + ($layout-gutter-small / 2);
    bottom: $layout-row-margin + ($layout-gutter-small / 2);
    transition: opacity .3s ease;
    pointer-events: none;
    opacity: 0;
}

.scrolltop1 {
    display: block;
    position: fixed;
    right: $layout-row-margin + ($layout-gutter-small / 2);
    bottom: $layout-row-margin + ($layout-gutter-small / 2);
    transition: opacity .3s ease;
    pointer-events: none;
    opacity: 0;
    text-decoration: none;
    color: $color-12;
    width: 100%;
    text-align: center;
}

.scrolltop--active {
    pointer-events: auto;
    opacity: 1;
}

.scrolltop--active1 {
    pointer-events: auto;
    opacity: 1;
}

@media (min-width: $layout-breakpoint-tablet) {
    .scrolltop {
        right: $layout-row-margin + ($layout-gutter-large / 2);
        bottom: $layout-row-margin + ($layout-gutter-large / 2);
    }
}
