.tabContainer > div {
    margin-top: 30px;
}

.top_container {
    max-width: 800px;
    margin: 2rem auto;
}

.text_section {
    margin: 0 auto;
}

.text_container {
    margin: 2rem auto;
    padding: 15px;
}

.top_video {
    cursor: pointer;
    width: 100%;
    background: $color-4;
    border-radius: 8px;
    z-index: 1;
}

.top_container .play_icon {
    position: relative;
    text-align: center;
    width: max-content;
    margin: 0 auto;
}

.top_container img,
.top_container .play_icon img {
    width: 65%;
    margin-top: -30px;
}

.title_class {
    text-align: center;
}

.title_class span {
    background-color: $color-12;
    color: $color-white;
    padding: 10px;
    position: relative;
}

.title_container h1 {
    color: $color-12;
    text-align: center;
    font-size: 1.5em;
    margin: 2rem auto 3rem;
}

.title_container p {
    text-align: center;
}

.image_holder {
    min-height: 400px;
    background: $color-4;
    border-radius: 8px;
    margin: 3rem 0;
}

.site_tabs {
    text-align: center;
}

.site_tabs .list-inline {
    width: auto;
    padding-left: 0;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.list-inline li {
    color: $color-12;
    font-size: 1em;
    text-decoration: none;
    margin: 15px 25px;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
}

.list-inline li.active {
    border-bottom: 2px solid $color-12;
}

.tab_content > .Sitemenu {
    display: none;
    visibility: hidden;
}

.tab_content > .active {
    display: block;
    visibility: visible;
}

@media only screen and (max-width: 667px) {
    .list-inline li {
        font-size: .85em;
        margin: 5px;
        display: inline-block;
    }
}

@media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .tabContainer .play_icon {
        width: 15%;
    }
}
