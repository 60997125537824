.main-parrent .para {
    margin-bottom: 15px;
    line-height: 1.6rem;
}

.main-parrent .page-title {
    font-size: 28px;
    color: $color-2;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.main-parrent .link {
    color: $color-12;
    text-decoration: none;
}

.main-parrent .page-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
}

.main-parrent .heading-text {
    margin-top: 50px;
}

.main-parrent .heading-text h3 {
    margin-bottom: 30px;
    text-align: center;
}

.main-parrent .heading-text .para {
    font-size: 16px;
}

.main-parrent .form-group {
    margin-bottom: 24px;
}

.main-parrent .form-label {
    display: block;
    font-size: 15px;
    margin-bottom: 10px;
}

.main-parrent .form-control {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $color-4;
}

.main-parrent .auth-link-block a {
    display: inline-block;
    font-size: 16px;
    margin: 15px auto;
}

.main-parrent .auth-link-block a + a {
    padding-left: 15px;
    border-left: 2px solid $color-5;
    margin-left: 15px;
}

.main-parrent .forgot-password-wrap {
    max-width: 820px;
    margin: 50px auto;
    padding: 30px;
}

.main-parrent .forgot-password-wrap .form-heading {
    text-align: center;
    margin-bottom: 30px;
}

.main-parrent .forgot-password-wrap .form-heading h3,
.main-parrent .register-wrap .form-heading h3 {
    margin-bottom: 60px;
}

.main-parrent .forgot-password-wrap .form-heading .para {
    font-size: 16px;
    margin-bottom: 40px;
}

.main-parrent .inner-form .button-block {
    width: 250px;
    margin-top: 30px;
}

.main-parrent .inner-form .button-block,
.main-parrent .inner-form .auth-link-block {
    text-align: center;
}

.main-parrent .inner-form .form-group {
    display: flex;
    align-items: center;
    position: relative;
}

.main-parrent .inner-form .form-group .form-label {
    width: 123px;
    margin-bottom: 0;
}

.main-parrent .inner-form .form-group .form-control {
    width: calc(100% - 123px);
}

.main-parrent .inner-form .form-group .error-msg {
    left: 124px;
}

.main-parrent .inner-form .form-group .checkbox-content {
    width: calc(100% - 123px);
    line-height: 1.4rem;
}

.main-parrent .inner-form .form-group .checkbox-content .form-check-label {
    position: relative;
    padding-left: 25px;
    display: block;
}

.main-parrent .inner-form .form-group .checkbox-content .form-checkbox {
    position: absolute;
    left: 2px;
    top: 5px;
}

.main-parrent .login-wrapper {
    max-width: 488px;
    padding: 20px 30px 30px;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 10px 24px 3px rgba(0, 19, 45, .4);
    box-sizing: border-box;
    margin: 40px auto;
}

.main-parrent .login-wrapper .login-form-header img {
    max-width: 80px;
    margin: 0 0 -10px auto;
    display: block;
}

.main-parrent .login-wrapper .form-group {
    margin-bottom: 30px;
    position: relative;
}

.main-parrent .login-wrapper .form-label {
    display: block;
    font-size: 15px;
}

.main-parrent .login-wrapper .form-control {
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: 2px solid $color-black;
    border-radius: 0;
}

.main-parrent .login-wrapper .form-control:focus {
    outline: none;
}

.main-parrent .error-msg {
    color: $color-error;
    position: absolute;
    bottom: -17px;
    display: none;
}

.main-parrent .btn-submit {
    padding: 12px 20px;
    cursor: pointer;
    width: 100%;
    background: $color-2;
    border: 0;
    color: $color-white;
    line-height: 22px;
    font-size: 15px;
    text-transform: uppercase;
}

.main-parrent .btn-submit:hover {
    background: $color-33;
}

.main-parrent .auth-link-block {
    text-align: center;
}

.main-parrent .form-control-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: .8125em 5em .5625em 1.5625em;
    background: $color-white url(/static/img/bg-dropdown-blue.svg) no-repeat 100% 50%;
}

.main-parrent .register-wrap {
    max-width: 680px;
    margin: 50px auto;
    padding: 15px;
}

.main-parrent .register-wrap .form-heading {
    text-align: center;
    margin-bottom: 20px;
}

.main-parrent .register-wrap .form-heading .para {
    font-size: 16px;
}

.note-block {
    display: flex;
}

@media (max-width: 500px) {
    .main-parrent .login-wrapper {
        max-width: calc(100% - 30px);
    }

    .main-parrent .inner-form .form-group {
        align-items: flex-start;
        flex-direction: column;
    }

    .main-parrent .inner-form .form-group .form-label,
    .main-parrent .inner-form .form-group .form-control,
    .main-parrent .inner-form .form-group .checkbox-content,
    .main-parrent .inner-form .button-block {
        width: 100%;
    }

    .main-parrent .inner-form .form-group .error-msg {
        left: 0;
    }

    .main-parrent .forgot-password-wrap {
        padding: 30px 15px;
    }
}
