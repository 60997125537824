$btn-font-size: 18px !default;

$btn-disabled-color-background: $color-4 !default;
$btn-disabled-color-border: $color-4 !default;
$btn-disabled-color-text: $color-white !default;

$btn-primary-color-background: $color-1 !default;
$btn-primary-color-background-hover: $color-2 !default;
$btn-primary-color-border: $color-1 !default;
$btn-primary-color-border-hover: $color-2 !default;
$btn-primary-color-text: $color-white !default;
$btn-primary-color-text-hover: $btn-primary-color-text !default;

$btn-secondary-color-background: $color-white !default;
$btn-secondary-color-background-hover: $color-white !default;
$btn-secondary-color-border: currentColor !default;
$btn-secondary-color-border-hover: currentColor !default;
$btn-secondary-color-text: $color-5 !default;
$btn-secondary-color-text-hover: $btn-secondary-color-text !default;

$btn-tertiary-color-background: $color-white !default;
$btn-tertiary-color-background-hover: $color-white !default;
$btn-tertiary-color-border: $color-white !default;
$btn-tertiary-color-border-hover: $color-white !default;
$btn-tertiary-color-text: $color-1 !default;
$btn-tertiary-color-text-hover: $btn-tertiary-color-text !default;

@mixin btn--disabled() {
    &,
    &:hover,
    &:focus {
        background: $btn-disabled-color-background;
        border-color: $btn-disabled-color-border;
        color: $btn-disabled-color-text;
        cursor: not-allowed;
        pointer-events: none;
    }
}

button {
    appearance: none;
    line-height: inherit;
}

@mixin btn() {
    @include font-primary;
    font-size: rem($btn-font-size);
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    padding: rem(11px 24px 8px);
    width: 100%;
    min-height: rem(49px);
    transition: background .3s ease, border-color .3s ease, color .3s ease;
    border: 2px solid;

    &.icon-left {
        padding-left: 3.35em;

        &::before {
            left: 1.5em;
        }
    }

    &.icon-right {
        padding-right: 3.35em;

        &::before {
            right: 1.5em;
        }
    }

    &[disabled],
    &.btn--disabled {
        @include btn--disabled;
    }

    &.btn-increment,
    &.btn-decrement {
        font-weight: bold;
        width: auto;
        height: 30px;
        padding-top: rem(10px);
    }

    .form-layout--disabled & {
        @include btn--disabled;
    }
}

.btn {
    @include btn;
}

@mixin btn-primary() {
    background: $btn-primary-color-background;
    border-color: $btn-primary-color-border;
    color: $btn-primary-color-text;

    &:hover,
    &:focus {
        background: $btn-primary-color-background-hover;
        border-color: $btn-primary-color-border-hover;
        color: $btn-primary-color-text-hover;
    }
}

.btn-primary {
    @include btn-primary;
}

.btn-secondary {
    background: $btn-secondary-color-background;
    border-color: $btn-secondary-color-border;
    color: $btn-secondary-color-text;

    &:hover,
    &:focus {
        background: $btn-secondary-color-background-hover;
        border-color: $btn-secondary-color-border-hover;
        color: $btn-secondary-color-text-hover;
    }
}

.btn-tertiary {
    background: $btn-tertiary-color-background;
    border-color: $btn-tertiary-color-border;
    color: $btn-tertiary-color-text;

    &:hover,
    &:focus {
        background: $btn-tertiary-color-background-hover;
        border-color: $btn-tertiary-color-border-hover;
        color: $btn-tertiary-color-text-hover;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .btn {
        width: auto;
    }
}
