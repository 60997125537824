.loginpage-forms-popup .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .4);
}

.loginpage-forms-popup .modal-content {
    background-color: $color-white;
    margin: 7% auto;
    padding: 10px 30px 0;
    border: 1px solid $color-6;
    width: 100%;
    max-width: 560px;
    height: 375px;
    border-radius: 7px;
}

.loginpage-forms-popup .sol-close {
    color: $color-25;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.loginpage-forms-popup .sol-close:hover,
.loginpage-forms-popup .sol-close:focus {
    color: $color-black;
    text-decoration: none;
    cursor: pointer;
}

.loginpage-forms-popup .form-control {
    display: block;
    width: 97%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: $color-5;
    background-color: $color-white;
    background-image: none;
    border: 1px solid $color-4;
    border-radius: 4px;
}

.loginpage-forms-popup .modal-title {
    margin: 0;
    line-height: 1.4;
    font-size: 18px;
    color: $color-5;
    font-weight: 500;
}

.loginpage-forms-popup .btunbg {
    border: none;
    background: none;
}

.loginpage-forms-popup .form-item {
    width: 100%;
}

.loginpage-forms-popup .form-group {
    margin-bottom: 15px;
}

.loginpage-forms-popup .label-clr {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    color: $color-5;
    font-size: 14px;
}

.loginpage-forms-popup .form-required {
    color: $color-error;
}

.loginpage-forms-popup .form-item {
    width: 100%;
}

.loginpage-forms-popup .form-control:focus {
    border-color: $color-22;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 8px rgba(102, 175, 233, .6);
}

.loginpage-forms-popup .form-control {
    display: block;
    width: 96%;
    height: 20px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: $color-5;
    background-color: $color-white;
    background-image: none;
    border: 1px solid $color-4;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.loginpage-forms-popup .uneditable-input,
input,
select,
#block-user-login ul {
    list-style: none;
    padding: 0;
}

.loginpage-forms-popup .request-new {
    color: $color-33;
    text-decoration: none;
}

#block-user-login button {
    float: right;
}

div .loginpage-forms-popup .error,
table tr.error {
    background-color: $color-3;
    color: $color-error;
    font-size: 13px;
    display: none;
}

.loginpage-forms-popup .error-box {
    color: $color-error;
    background-color: $color-3;
    border-color: $color-error;
}

.loginpage-forms-popup .error-box-dismissable,
.loginpage-forms-popup .error-box-dismissible {
    padding-right: 35px;
}

.loginpage-forms-popup .error-box {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.loginpage-forms-popup .error-box > p,
.loginpage-forms-popup .error-box > ul {
    margin-bottom: 0;
}

.loginpage-forms-popup .modal-header {
    border: none;
    margin: 20px 0;
}

.loginpage-forms-popup .login-intro {
    color: $color-5;
    font-size: 18px;
}

.loginpage-forms-popup .tolog a {
    color: $color-white;
    float: right;
    background-color: $color-33;
    text-decoration: none;
    border-color: $color-36;
    padding: 8px 10px 10px 8px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

.loginpage-forms-popup .tolog a:hover {
    color: $color-white;
    background-color: $color-36;
    border-color: $color-36;
}

@media only screen and (max-width: 640px) {
    .loginpage-forms-popup .modal-content {
        width: 85%;
    }

    .loginpage-forms-popup .modal-content {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .loginpage-forms-popup .login-intro {
        font-size: 14px;
    }

    .loginpage-forms-popup .modal-content {
        margin: 11% auto;
    }
}

@media only screen and (max-width: 425px) {
    #block-user-login button {
        font-size: 14px;
    }

    .loginpage-forms-popup .modal-content {
        height: 330px;
        padding: 0 30px 10px 15px;
    }
}

@media only screen and (max-width: 375px) {
    .loginpage-forms-popup .modal-content {
        width: 76%;
    }

    .loginpage-forms-popup .sol-close {
        padding-left: 10px;
    }

    .loginpage-forms-popup .modal-content {
        height: 330px;
    }
}

@media only screen and (max-width: 360px) {
    .loginpage-forms-popup .modal-content {
        height: 350px;
    }

    .loginpage-forms-popup .btn-primary {
        font-size: 14px;
    }

    .loginpage-forms-popup .modal-content {
        height: 330px;
    }
}

@media only screen and (max-width: 320px) {
    .loginpage-forms-popup .modal-content {
        width: 68%;
    }

    .loginpage-forms-popup .modal-content {
        background-color: $color-4;
        margin: 15% auto;
    }

    .loginpage-forms-popup .error-box {
        padding: 7px;
        margin-bottom: 10px;
    }

    .loginpage-forms-popup .modal-content {
        height: 495px;
    }

    .loginpage-forms-popup .modal-content {
        height: 350px;
        padding: 0 30px 21px 15px;
    }
}

.as-userLogin-content .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .4);
}

.as-userLogin-content .modal-content {
    background-color: $color-10;
    margin: 15% auto;
    padding: 40px;
    border: 1px solid $color-5;
    width: 45%;
    height: 330px;
    border-radius: 7px;
}

.as-userLogin-content .close {
    color: $color-error;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.as-userLogin-content .close:hover,
.as-userLogin-content .close:focus {
    color: $color-black;
    text-decoration: none;
    cursor: pointer;
}

.as-userLogin-content .userform-control {
    display: block;
    width: 97%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: $color-5;
    background-color: $color-white;
    background-image: none;
    border: 1px solid $color-4;
    border-radius: 4px;
}

.as-userLogin-content .modal-title {
    margin: 0;
    line-height: 1.4;
    font-size: 18px;
    color: $color-black;
    font-weight: 500;
}

.as-userLogin-content .btunbg {
    border: none;
    background: none;
}

.as-userLogin-content .form-item {
    width: 100%;
}

.as-userLogin-content .form-group {
    margin-bottom: 15px;
}

.as-userLogin-content .user-align {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    color: $color-5;
    font-size: 14px;
}

.as-userLogin-content .form-required {
    color: $color-error;
}

.as-userLogin-content .as-userLogin-content .form-item {
    width: 100%;
}

.as-userLogin-content .userform-control:focus {
    border-color: $color-15;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 8px rgba(102, 175, 233, .6);
}

.as-userLogin-content .userform-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 0;
    font-size: 14px;
    line-height: 1.4;
    color: $color-5;
    background-color: $color-white;
    background-image: none;
    border: 1px solid $color-4;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8);
    -webkit-transition: border-color ease-in-out .15s,;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.as-userLogin-content .btn-primary {
    color: $color-white;
    background-color: $color-33;
    border-color: $color-36;
    padding: 8px 10px 10px 8px;
    border: none;
    font-size: 14px;
}

.as-userLogin-content .btn-primary:hover {
    color: $color-white;
    background-color: $color-36;
    border-color: $color-36;
}

.as-userLogin-content .small-p {
    font-size: 12px;
}

.as-userLogin-content .email-sender a {
    color: $color-white;
    background-color: $color-33;
    border-color: $color-36;
    padding: 8px 10px 10px 8px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-decoration: none;
}

.as-userLogin-content .email-sender a:hover {
    color: $color-white;
    background-color: $color-36;
    border-color: $color-36;
}

.as-userLogin-content .alert-danger2 {
    color: $color-white;
    background-color: $color-error;
    border-color: $color-error;
}

.as-userLogin-content .alert-dismissable2 {
    padding-right: 35px;
}

.as-userLogin-content .alert2 {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.as-userLogin-content .danger-label {
    color: $color-white;
    font-weight: 600;
}

.as-userLogin-content .password-restp {
    font-size: 14px;
}

.as-userLogin-content .resp-tabs-list p {
    margin: 0;
    padding: 0;
}

.as-userLogin-content .resp-tabs-list li {
    font-weight: 600;
    font-size: 13px;
    display: inline-block;
    padding: 13px 15px;
    margin: 0;
    list-style: none;
    cursor: pointer;
    float: left;
    color: $color-33;
}

.as-userLogin-content .resp-tabs-container {
    padding: 0;
    background-color: $color-white;
    clear: left;
}

.as-userLogin-content h2.resp-accordion {
    cursor: pointer;
    padding: 5px;
    display: none;
}

.as-userLogin-content .resp-tab-content {
    display: none;
    padding: 15px;
}

.as-userLogin-content .resp-tab-active {
    border: 1px solid $color-4;
    border-bottom: none;
    margin-bottom: -1px !important;
    padding: 12px 14px 14px !important;
}

.as-userLogin-content .resp-tab-active {
    background-color: $color-white;
    border: 1px solid $color-7;
    border-bottom-color: transparent;
    color: $color-black !important;
}

.as-userLogin-content .resp-content-active,
.as-userLogin-content .resp-accordion-active {
    display: block;
}

.as-userLogin-content .resp-tab-content {
    border-top: 1px solid $color-7;
    padding: 10px 0 0 !important;
}

.as-userLogin-content h2.resp-accordion {
    font-size: 13px;
    border: 1px solid $color-4;
    border-top: 0 solid $color-4;
    margin: 0;
    padding: 10px 15px;
}

.as-userLogin-content h2.resp-tab-active {
    border-bottom: 0 solid $color-4 !important;
    margin-bottom: 0 !important;
    padding: 10px 15px !important;
}

.as-userLogin-content h2.resp-tab-title:last-child {
    border-bottom: 12px solid $color-4 !important;
    background: blue;
}

.as-userLogin-content .resp-vtabs ul.resp-tabs-list {
    float: left;
    width: 30%;
}

.as-userLogin-content .resp-vtabs .resp-tabs-list li {
    display: block;
    padding: 15px !important;
    margin: 0;
    cursor: pointer;
    float: none;
}

.as-userLogin-content .resp-vtabs .resp-tabs-container {
    padding: 0;
    background-color: $color-white;
    border: 1px solid $color-4;
    float: left;
    width: 68%;
    min-height: 250px;
    border-radius: 4px;
    clear: none;
}

.as-userLogin-content .resp-vtabs .resp-tab-content {
    border: none;
}

.resp-vtabs li.resp-tab-active {
    border: 1px solid $color-4;
    border-right: none;
    background-color: $color-white;
    position: relative;
    z-index: 1;
    margin-right: -1px !important;
    padding: 14px 15px 15px 14px !important;
}

.as-userLogin-content .resp-arrow {
    width: 0;
    height: 0;
    float: right;
    margin-top: 3px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid $color-4;
}

.as-userLogin-content h2.resp-tab-active span.resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid $color-6;
}

.as-userLogin-content h2.resp-tab-active {
    background: $color-7 !important;
}

.as-userLogin-content .resp-easy-accordion h2.resp-accordion {
    display: block;
}

.as-userLogin-content .resp-easy-accordion .resp-tab-content {
    border: 1px solid $color-4;
}

.as-userLogin-content .resp-easy-accordion .resp-tab-content:last-child {
    border-bottom: 1px solid $color-4 !important;
}

.as-userLogin-content .resp-jfit {
    width: 100%;
    margin: 0;
}

.as-userLogin-content .resp-tab-content-active {
    display: block;
}

.as-userLogin-content h2.resp-accordion:first-child {
    border-top: 1px solid $color-4 !important;
}

@media only screen and (max-width: 768px) {
    .as-userLogin-content ul.resp-tabs-list {
        display: none;
    }

    .as-userLogin-content h2.resp-accordion {
        display: block;
    }

    .as-userLogin-content .resp-vtabs .resp-tab-content {
        border: 1px solid $color-6;
    }

    .as-userLogin-content .resp-vtabs .resp-tabs-container {
        border: none;
        float: none;
        width: 100%;
        min-height: initial;
        clear: none;
    }

    .as-userLogin-content .resp-accordion-closed {
        display: none !important;
    }

    .as-userLogin-content .resp-vtabs .resp-tab-content:last-child {
        border-bottom: 1px solid $color-4 !important;
    }
}

#branding {
    overflow: hidden;
    padding: 20px 0 0;
    position: relative;
    background-color: $color-7;
    width: 100%;
}

#branding .branding-tabs-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#branding .bc-wrap {
    align-self: flex-start;
}

#branding .user-navs {
    align-self: flex-end;
}

#branding .bc-wrap .breadcrumb {
    font-size: .846em;
    padding-bottom: 5px;
    border: none;
    text-transform: none;
}

#branding h1.page-title {
    color: $color-black;
    margin: 0;
    padding-bottom: 10px;
    font-size: 1rem;
    font-weight: normal;
    text-transform: none;
}

#branding h1::after {
    display: none;
}

#branding .user-navs ul.primary {
    border-bottom: none;
    text-transform: uppercase;
    font-size: .923em;
    height: 2.6em;
    margin: 0;
    padding-top: 0;
    display: flex;
}

#branding .user-navs ul.primary li {
    list-style: none;
    margin: 0 2px;
}

#branding .user-navs ul.primary li a:link,
#branding .user-navs ul.primary li a.active,
#branding .user-navs ul.primary li a:active,
#branding .user-navs ul.primary li a:visited,
#branding .user-navs ul.primary li a:hover,
#branding .user-navs ul.primary li.active a {
    display: block;
    height: 2.6em;
    line-height: 2.6em;
    padding: 0 18px 8px;
    background-color: $color-4;
    color: $color-black;
    font-weight: bold;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $color-5;
    -moz-border-radius: 8px 8px 0 0;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    border-radius: 8px 8px 0 0;
    text-decoration: none;
}

#branding .user-navs ul.primary li.active a,
#branding .user-navs ul.primary li.active a.active,
#branding .user-navs ul.primary li.active a:active,
#branding .user-navs ul.primary li.active a:visited {
    background-color: $color-white;
    border-color: $color-7;
}

#branding .user-navs ul.primary li a:hover {
    color: $color-white;
}

#branding .user-navs ul.primary li.active a:hover {
    color: $color-black;
}

.aia-user-navs ul.user-inner-navtabs {
    list-style: none;
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid $color-5;
    margin-bottom: 10px;
}

.aia-user-navs .user-inner-navtabs li {
    margin-bottom: -1px;
    position: relative;
}

.aia-user-navs .user-inner-navtabs li a {
    position: relative;
    color: $color-36;
    display: block;
    padding: 10px 15px;
    margin-right: 2px;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    text-decoration: none;
}

.aia-user-navs .user-inner-navtabs > li.active > a,
.aia-user-navs .user-inner-navtabs > li.active > a:hover,
.aia-user-navs .user-inner-navtabs > li.active > a:focus {
    color: $color-5;
    cursor: default;
    background-color: $color-white;
    border: 1px solid $color-5;
    border-bottom-color: transparent;
}

.aia-user-navs .user-inner-navtabs > li > a:hover,
.aia-user-navs .user-inner-navtabs > li > a:focus {
    text-decoration: none;
    background-color: $color-4;
    color: $color-5;
    border-color: $color-5;
}

.modifier-page,
.amgen--day-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.modifier-page .content-edit {
    padding: 20px 0 40px;
    background: $color-white;
    position: relative;
    color: $color-5;
}

.modifier-page .form-item {
    display: block;
    padding: 9px 0;
    margin: 0 0 10px;
}

.modifier-page .form-type-input {
    display: flex;
    flex-direction: column;
}

.modifier-page .form-item input {
    width: max-content;
    padding: 2px;
    border: 1px solid $color-6;
    border-top-color: $color-5;
    background-color: $color-white;
    color: $color-5;
}

.modifier-page label.form-item-label {
    display: block;
    font-weight: bold;
    margin-bottom: 2px;
}

.modifier-page .form-item .form-required {
    color: $color-error;
}

.description {
    margin: 5px 0;
    line-height: 1.231em;
    font-size: .923em;
    color: $color-5;
}

.description a {
    color: $color-22;
    text-decoration: none;
}

.description a:hover {
    text-decoration: underline;
}

.modifier-page .form-item .password-suggestions {
    padding: .2em .5em;
    margin: .7em 0;
    width: 31.5em;
    border: 1px solid $color-5;
    display: none;
}

.modifier-page .password-suggestions ul {
    list-style-type: disc;
    list-style-image: none;
    margin: .25em 0 .25em -1.5em;
}

.modifier-page .password-suggestions ul li {
    font-size: .923em;
    line-height: 1.231em;
    color: $color-5;
}

.modifier-page .form-item.password-parent,
.modifier-page .form-item.confirm-parent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
}

.modifier-page .form-item.confirm-parent .password-confirm-text {
    width: 15.2em;
    margin-left: 2.5rem;
    display: none;
}

.modifier-page .form-item.password-parent .password-strength {
    display: flex;
    flex-direction: column;
    width: 14.2em;
    margin-left: 2.5rem;
}

.modifier-page .form-item.password-parent .password-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.password-indicator {
    background-color: $color-6;
    height: .3em;
    width: 100%;
}

.password-indicator div {
    height: 100%;
    width: 0%;
    background-color: $color-3;
}

.password-strength-text {
    font-weight: bold;
    padding-left: 1rem;
}

#err-msg-console {
    margin: 9px 0 10px;
    display: none;
}

div.messages {
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid;
    margin: 6px 0;
    padding: 10px 10px 10px 50px;
}

#err-msg-console div.status {
    background-image: url('/static/img/message-24-ok.png');
    border-color: $color-17;
    color: $color-3;
    background-color: $color-7;
}

div.messages ul {
    margin: 0 0 0 1em;
    padding: 0;
}

div.messages ul li {
    margin: 0 0 0 1em;
    padding: 0;
    color: $color-25;
    font-size: .923em;
    line-height: 1.4;
}

div.error {
    background-image: url('/static/img/message-24-error.png');
    border-color: $color-error;
    background-color: $color-11;
}

.modifier-page .form-item input.error-input {
    border: 2px solid $color-error;
}

.modifier-page .form-radios .form-item.form-type-radio {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-bottom: 9px;
}

.modifier-page .form-item label.option {
    font-size: .923em;
    text-transform: none;
    font-weight: normal;
}

.modifier-page .form-item label.option a {
    color: $color-22;
    text-decoration: none;
}

.modifier-page .form-item label.option a:hover {
    text-decoration: underline;
}

.modifier-page .form-radios .form-item.form-type-radio input[type=radio] {
    margin-left: 0;
}

.modifier-page fieldset {
    border: 1px solid $color-6;
    padding: 2.5em 0 0;
    position: relative;
    margin: 1em 0;
}

.modifier-page fieldset legend {
    font-weight: bold;
    padding: 0;
}

.modifier-page fieldset .fieldset-legend {
    margin-top: .75em;
    padding-left: 15px;
    position: absolute;
    text-transform: uppercase;
    font-weight: bold;
}

.modifier-page fieldset .fieldset-wrapper {
    padding: 0 13px 13px 15px;
}

.modifier-page .fieldset-title {
    color: $color-22;
    text-decoration: none;
}

.modifier-page .fieldset-title:hover {
    text-decoration: underline;
}

.modifier-page fieldset.collapsed .fieldset-legend {
    background-image: url('/static/img/menu-collapsed.png');
    background-position: 5px 50%;
    background-repeat: no-repeat;
}

.modifier-page fieldset.collapsible .fieldset-legend.active {
    background: url('/static/img/menu-expanded.png') 5px 65% no-repeat;
    padding-left: 15px;
}

.field-type-list-boolean .form-item.form-type-checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

.field-type-list-boolean .description {
    margin-left: 1.5em;
}

.form-item.form-type-textarea {
    display: flex;
    flex-direction: column;
}

.form-textarea-wrapper textarea {
    display: block;
    margin: 0;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.modifier-page textarea.form-textarea {
    padding: 2px;
    border: 1px solid $color-6;
    border-top-color: $color-5;
    background: $color-white;
    color: $color-5;
}

.form-item.form-type-checkboxes,
.form-item.form-type-textfield {
    display: flex;
    flex-direction: column;
}

.form-item.form-type-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

.form-item.form-type-select {
    display: flex;
    flex-direction: column;
    width: max-content;
}

.tabledrag-toggle-weight-wrapper {
    text-align: right;
    margin-bottom: 2px;
}

.tabledrag-toggle-weight {
    font-size: .9em;
    color: $color-22;
    text-decoration: none;
}

.tabledrag-toggle-weight:hover {
    text-decoration: underline;
}

.modifier-page table.field-multiple-table {
    width: 100%;
    font-size: .923em;
    margin: 0 0 10px;
    border: 1px solid $color-4;
    border-collapse: collapse;
    border-spacing: 0;
}

table.field-multiple-table th {
    font-size: .923em;
    text-transform: uppercase;
    background: $color-4;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    border-color: $color-4;
    padding: 3px 8px;
    text-align: left;
}

.field-multiple-table th.field-label {
    padding-left: 0;
    text-align: left;
    font-weight: bold;
}

.field-multiple-table td.field-multiple-drag {
    width: 30px;
    padding-right: 0;
}

.field-multiple-table td {
    vertical-align: middle;
    padding: 8px 10px;
    border: 0;
    color: $color-black;
}

.field-multiple-table td.delta-order select.form-select {
    padding: 2px;
    border: 1px solid $color-6;
    border-top-color: $color-5;
    background: $color-white;
    color: $color-5;
}

.field-multiple-table td .form-item {
    padding: 0;
    margin: 0;
}

a.tabledrag-handle .handle {
    background: url('/static/img/draggable.png') no-repeat 6px 7px;
    height: 13px;
    margin: -.4em .5em;
    padding: .42em .5em;
    width: 13px;
    cursor: move;
}

a.tabledrag-handle .handle:hover {
    background-position: 6px -13px;
}

.tabledrag-hide {
    opacity: 0;
}

.tabledrag-show {
    opacity: 1 !important;
}

.tabledrag-changed-warning {
    display: none;
}

table.field-multiple-table tr:nth-child(even) {
    border-width: 0 1px;
    border-style: solid;
    border-color: $color-4;
    background: $color-7;
}

table.field-multiple-table thead tr {
    background: $color-4;
}

table.field-multiple-table thead tr th:first-child {
    border-right-color: transparent !important;
}

tr.dark-background {
    background: #fe7 !important;
}

tr.dropped-background {
    background: #ffb !important;
}

input.form-autocomplete {
    background-image: url('/static/img/throbber-inactive.png');
    background-position: 100% center;
    background-repeat: no-repeat;
}

.addMoreList-btn input.form-submit,
.form-actions input.form-submit,
.image-widget input.form-submit,
.pdf-widget input.form-submit,
.uploaded-preview-widget input.form-submit {
    cursor: pointer;
    padding: 4px 17px;
    margin-right: 1em;
    color: $color-5;
    text-align: center;
    font-weight: normal;
    font-size: 1.077em;
    border: 1px solid #e4e4e4;
    border-bottom: 1px solid $color-5;
    border-left-color: $color-4;
    border-right-color: $color-4;
    background: url('/static/img/buttons.png') 0 0 repeat-x;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
}

div.warning {
    background-image: url('/static/img/message-24-warning.png');
    border-color: $color-error;
    color: $color-error;
    background-color: $color-white;
}

.imce-filefield-wrapper {
    display: block;
    margin: 2px 0;
}

a.imce-filefield-opener {
    display: inline-block;
    min-height: 18px;
    padding-left: 18px;
    background: url('/static/img/imce_filefield.png') no-repeat 0 50%;
    color: $color-22;
    text-decoration: none;
}

a.imce-filefield-opener:hover {
    text-decoration: underline;
}

.mission-widget-date-select .field-mission-year {
    display: flex;
    padding: 0 10px 10px;
}

.mission-widget-date-select .container-inline-date .form-item {
    margin-bottom: 0;
}

.mission-widget-date-select .date-clear .form-select-date {
    padding: 2px;
    border: 1px solid $color-6;
    border-top-color: $color-5;
    background: $color-white;
    color: $color-5;
    margin-right: 5px;
}

div.vertical-tabs {
    background: $color-white url('/static/img/fc.png') repeat-y 0 0;
    border: 1px solid $color-6;
    margin: 10px 0;
    position: relative;
}

fieldset.vertical-tabs-pane {
    border: 0;
    padding: 0;
    margin: 0;
}

div.vertical-tabs .vertical-tabs-list {
    border-bottom: 1px solid $color-6;
    float: left;
    font-size: 1em;
    line-height: 1;
    margin: 0 -100% -1px 0;
    padding: 0;
    width: 240px;
    list-style-type: none;
    list-style-image: none;
}

div.vertical-tabs ul li.vertical-tab-button {
    list-style: none;
    list-style-image: none;
    margin: 0;
}

div.vertical-tabs ul li.vertical-tab-button a {
    color: $color-22;
    text-decoration: none;
}

div.vertical-tabs ul li.vertical-tab-button strong {
    font-size: .923em;
}

div.vertical-tabs ul li.selected a,
div.vertical-tabs ul li.selected a:hover,
div.vertical-tabs ul li.selected a:focus,
div.vertical-tabs ul li.selected a:active {
    background: $color-white;
    border-right-color: $color-white;
    border-top: 1px solid $color-6;
}

div.vertical-tabs ul li.first.selected a,
div.vertical-tabs ul li.first.selected a:hover {
    border-top: 0;
}

div.vertical-tabs ul li.first a {
    border-top: 0 !important;
}

div.vertical-tabs ul li.vertical-tab-button a {
    border-top: 1px solid $color-6;
    display: block;
    padding: 10px;
}

div.vertical-tabs ul li.vertical-tab-button .summary {
    color: $color-5;
    display: block;
    font-size: .846em;
    padding-top: .4em;
}

div.vertical-tabs .vertical-tabs-panes {
    margin: 0 0 0 265px;
    padding: 10px 15px 10px 0;
}

fieldset.vertical-tabs-pane {
    border: 0;
    padding: 0;
    margin: 0;
}

fieldset.vertical-tabs-pane legend {
    display: none;
}

fieldset.vertical-tabs-pane fieldset legend {
    display: block;
}

.vertical-tabs .form-type-textfield input {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.clearfix-v-tabs::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.uploaded-preview-widget a {
    color: $color-22;
    text-decoration: none;
}

.uploaded-preview-widget a:hover {
    text-decoration: underline;
}

.richTxt-format-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 2px;
    border: 1px solid $color-6;
    border-top-color: transparent;
}

.richTxt-format-wrap .tips {
    padding-left: 0;
}

.richTxt-format-wrap ul.tips li {
    margin: .25em 0 .25em 1.5em;
    line-height: 1.231em;
    font-size: .923em;
    color: $color-5;
}

.rich-editor {
    margin-bottom: 12px;
}

.rich-editor .tox-tinymce {
    border-radius: 3px;
}

div#body-rich-editor-height .rich-editor .tox-tinymce {
    height: 400px !important;
}

.rich-editor a,
.link-edit-summary {
    color: $color-22;
    text-decoration: none;
}

.rich-editor a:hover,
.link-edit-summary:hover {
    text-decoration: underline;
}

.rich-editor .tox .tox-editor-container {
    padding: 0 3px;
    background-color: $color-4;
}

.rich-editor .tox .tox-menubar,
.rich-editor .tox .tox-statusbar {
    background-color: $color-4;
}

.rich-editor .tox .tox-statusbar {
    border-top: 0;
}

.rich-editor .tox .tox-toolbar__primary {
    border: 1px solid $color-6;
    border-bottom: 0;
}

.rich-editor .tox .tox-sidebar-wrap {
    border: 1px solid $color-6;
    border-top: 0;
}

.rich-editor .tox .tox-mbtn {
    background: $color-white;
    height: 26px;
    margin: 5px 3px;
    padding: 0 10px;
}

.richtxt-tips {
    background: transparent url('/static/img/help.png') right center no-repeat;
    padding: 0 20px;
}

.rich-editor .tox .tox-tbtn--disabled,
.rich-editor .tox .tox-tbtn--disabled:hover,
.rich-editor .tox .tox-tbtn:disabled,
.rich-editor .tox .tox-tbtn:disabled:hover {
    cursor: auto;
}

.rich-editor .tox .tox-tbtn--disabled svg,
.rich-editor .tox .tox-tbtn--disabled:hover svg,
.rich-editor .tox .tox-tbtn:disabled svg,
.rich-editor .tox .tox-tbtn:disabled:hover svg {
    fill: #222f3e;
}

.rich-editor .tox .tox-statusbar__text-container {
    visibility: hidden;
}

#revision-table form {
    width: 100%;
    max-width: 1200px;
    margin: 1rem auto auto;
}

table.diff-revisions-table {
    width: 100%;
    font-size: .923em;
    margin: 0 0 10px;
    border: 1px solid $color-4;
    border-collapse: collapse;
    border-spacing: 0;
}

table.diff-revisions-table th {
    text-transform: uppercase;
    background: $color-4;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    border-color: $color-4;
    padding: 10px;
    text-align: left;
    color: $color-black;
}

table.diff-revisions-table tr.revision-published td {
    background-color: $color-11;
}

table.diff-revisions-table tr.selected td.active,
table tr.selected td {
    background: $color-10;
    border-color: $color-10;
}

table.diff-revisions-table tr.even,
table.diff-revisions-table tr.odd {
    border-width: 0 1px;
    border-style: solid;
    border-color: $color-4;
    background: $color-7;
}

table.diff-revisions-table tr.odd {
    background: $color-white;
}

table.diff-revisions-table td {
    vertical-align: middle;
    padding: 8px 10px;
    border: 0;
    color: $color-black;
}

table.diff-revisions-table a {
    color: $color-22;
    text-decoration: none;
}

table.diff-revisions-table a:hover {
    text-decoration: underline;
}

table.diff-revisions-table .revision-log {
    margin: 1em 0;
}

#missions-page .table-bordered,
#recap-page .table-bordered {
    border: 1px solid $color-5;
    margin-bottom: 20px;
    width: 100%;
    border-collapse: collapse;
}

#missions-page span.red {
    color: $color-error;
}

#missions-page .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $color-7;
}

#missions-page table tbody tr > td {
    border: solid 1px $color-12;
    vertical-align: middle;
}

#missions-page table .places,
#missions-page table .people,
#missions-page table .radio {
    text-align: center;
}

#missions-page table .places {
    font-weight: bold;
}

#missions-page .table > thead > tr > th,
#missions-page .table > tbody > tr > th,
#missions-page .table > tfoot > tr > th,
#missions-page .table > thead > tr > td,
#missions-page .table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.5;
}

#missions-page a:hover,
#missions-page a:focus,
#missions-page a {
    color: $color-26;
    text-decoration: none;
}

#btn-missions.btn.btn-primary,
#btn-finish.btn.btn-primary {
    color: $color-white;
    background: $color-12;
    border-color: $color-white;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3;
    border-radius: 6px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;
    cursor: pointer;
}

#btn-finish.btn.btn-primary a {
    color: $color-white;
}

#recap-page .table-bordered > tbody > tr > td {
    border: 1px solid $color-5;
}

#recap-page td {
    vertical-align: middle;
}

#recap-page th {
    text-align: left;
}

.table > tbody > tr > th {
    border: 1px solid $color-5;
    padding: 8px;
    line-height: 1.5;
    vertical-align: top;
}

.mission-v1-show .mission-head-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mission-v1-show .mission-title {
    width: 60%;
    margin: 1rem;
}

.mission-v1-show .mission-title h1 {
    font-size: 1.25rem;
    margin: 0 0 1rem;
    text-align: left;
    line-height: 1.5;
}

.mission-v1-show .mission-title h1::after {
    display: none;
}

.mission-v1-show .mission-details {
    width: 37%;
    display: flex;
}

.mission-v1-show .mission-title img.star {
    float: left;
    width: 30px;
    margin: 0;
}

.mission-v1-show .mission-image {
    width: 50%;
    margin: 1rem;
}

.mission-v1-show .mission-text {
    width: 50%;
    margin: 1rem;
}

.mission-v1-show .mission-image .img-responsive {
    width: 100%;
}

.mission-v1-show .mission-place,
.mission-v1-show .mission-planned-activites,
.mission-v1-show .mission-list-back {
    margin-bottom: 1rem;
    text-align: center;
}

.mission-v1-show .mission-place {
    font-weight: bold;
}

.mission-v1-show .mission-body {
    display: flex;
}

.mission-v1-show .mission-body-left {
    width: 50%;
    margin: 0 1rem;
}

.mission-v1-show .left-box-one,
.mission-v1-show .last-body {
    margin-bottom: 15px;
    background: $color-6;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 15px;
}

.mission-v1-show .last-body {
    height: 228px;
    margin: 0 1rem;
    background-color: rgba(237, 119, 19, .5);
}

.mission-v1-show .last-body.grey {
    background-color: $color-6;
    margin-top: 1rem;
}

.mission-v1-show .left-box-one.large {
    height: 600px;
}

.mission-v1-show .left-box-one.medium {
    height: 150px;
}

.mission-v1-show .left-box-one.small {
    height: 405px;
}

.mission-v1-show .star {
    margin-right: 10px;
    width: 30px;
    margin-bottom: 0;
}

.mission-v1-show .case-title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    color: $color-black;
    display: flex;
}

.mission-v1-show .open-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.mission-v1-show .button-group-details {
    display: inline-block;
    margin: 0 5px;
    margin-bottom: 1rem;
}

.mission-v1-show .btn.btn-primary {
    background: $color-12;
    border-color: $color-white;
    color: $color-white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 12px;
}

.btn.disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.mission-v1-show .remaining-place {
    width: 100px;
    text-align: center;
    margin-top: .75rem;
    font-size: 14px;
    line-height: 1.5;
    color: $color-black;
}

.mission-v1-show .row-last {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mission-v1-show .info-box {
    margin: 1rem;
}

.mission-v1-show .info img {
    margin: 0 auto;
    display: block;
    height: 50px;
    width: auto;
}

.mission-v1-show .info-des {
    text-align: center;
    font-size: 12px;
    font-style: italic;
}

.confirm-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-36;
    border-color: $color-33;
}

.region-content-tab form a {
    color: $color-36;
    text-decoration: none;
}

.region-content-tab form a:hover {
    text-decoration: underline;
}

table.diff-table-compare {
    border-spacing: 4px;
    margin-bottom: 20px;
    table-layout: fixed;
    width: 100%;
    font-size: .923em;
    border: 1px solid $color-4;
    border-collapse: collapse;
    line-height: 1.5;
}

table.diff-table-compare a {
    color: $color-22;
    text-decoration: none;
}

table.diff-table-compare a:hover {
    text-decoration: underline;
}

table.diff-table-compare col.diff-marker {
    width: 1.4em;
}

table.diff-table-compare th {
    text-transform: uppercase;
    background: $color-4;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    border-color: $color-4;
    padding: 3px 10px;
    padding-right: inherit;
    text-align: left;
}

table.diff-table-compare th a {
    display: block;
    position: relative;
    line-height: 1.5;
}

table.diff-table-compare td {
    padding: .1ex .4em;
    text-align: left;
}

table.diff-table-compare td.diff-nextlink {
    text-align: right;
}

table.diff-table-compare tr td:last-child {
    border-right: 1px solid $color-4;
}

table.diff-table-compare tr.even,
table.diff-table-compare tr.odd {
    background-color: $color-white;
    border: none;
}

table.diff-table-compare tr td ul.links {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

table.diff-table-compare tr td ul.links li {
    padding-right: 1em;
}

table.diff-table-compare tr td.diff-section-title {
    background-color: $color-11;
    font-size: .83em;
    font-weight: bold;
    padding: .1em 1em;
}

table.diff-table-compare td div {
    overflow: auto;
    padding: .1ex .5em;
    word-wrap: break-word;
}

table.diff-table-compare td div span.diffchange {
    color: $color-error;
    font-weight: bold;
}

table.diff-table-compare td.diff-context {
    background-color: $color-7;
}

table.diff-table-compare td.diff-deletedline {
    background-color: $color-13;
    width: 50%;
}

table.diff-table-compare td.diff-addedline {
    background-color: $color-10;
    width: 50%;
}

.compared--table {
    width: 100%;
    max-width: 1200px;
    margin: 1rem auto auto;
}

#missions-page,
#recap-page {
    max-width: 1200px;
    margin: 0 auto;
}

#recap-page h1 {
    font-size: 1.75rem;
    margin: 0 auto 1.5rem;
}

#recap-page h1::after {
    display: none;
}

#missions-page .table-bordered,
#recap-page .table-bordered {
    border: 1px solid $color-4;
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
}

#missions-page span.red {
    color: $color-error;
}

#missions-page .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $color-7;
}

#missions-page table tbody tr > td {
    border: solid 1px $color-11;
    vertical-align: middle;
}

#missions-page table .places,
#missions-page table .people,
#missions-page table .radio {
    text-align: center;
}

#missions-page table .places {
    font-weight: bold;
}

#missions-page .table > thead > tr > th,
#missions-page .table > tbody > tr > th,
#missions-page .table > tfoot > tr > th,
#missions-page .table > thead > tr > td,
#missions-page .table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.5;
}

#missions-page a:hover,
#missions-page a:focus,
#missions-page a {
    color: $color-33;
    text-decoration: none;
}

#btn-missions.btn.btn-primary,
#btn-finish.btn.btn-primary {
    color: $color-white;
    background: $color-38;
    border-color: $color-white;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3;
    border-radius: 6px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;
    cursor: pointer;
}

#btn-finish.btn.btn-primary a {
    color: $color-white;
}

#recap-page .table-bordered > tbody > tr > td {
    border: 1px solid $color-4;
}

#recap-page td {
    vertical-align: middle;
}

#recap-page th {
    text-align: left;
}

.table > tbody > tr > th {
    border: 1px solid $color-4;
    padding: 8px;
    line-height: 1.5;
    vertical-align: top;
}

.mission-v1-show .mission-head-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mission-v1-show .mission-title {
    width: 60%;
    margin: 1rem;
}

.mission-v1-show .mission-details {
    width: 37%;
    display: flex;
}

.mission-v1-show .mission-title img.star {
    float: left;
}

.mission-v1-show .mission-image {
    width: 50%;
    margin: 1rem;
}

.mission-v1-show .mission-text {
    width: 50%;
    margin: 1rem;
}

.mission-v1-show .mission-image .img-responsive {
    width: 100%;
}

.mission-v1-show .mission-place,
.mission-v1-show .mission-planned-activites,
.mission-v1-show .mission-list-back {
    margin-bottom: 1rem;
    text-align: center;
}

.mission-v1-show .mission-place {
    font-weight: bold;
}

.mission-v1-show .mission-body {
    display: flex;
}

.mission-v1-show .mission-body-left {
    width: 50%;
    margin: 0 1rem;
}

.mission-v1-show .left-box-one,
.mission-v1-show .last-body {
    margin-bottom: 15px;
    background: $color-4;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 15px;
}

.mission-v1-show .last-body {
    height: 228px;
    margin: 0 1rem;
    background-color: rgba(237, 119, 19, .5);
}

.mission-v1-show .last-body.grey {
    background-color: $color-4;
    margin-top: 1rem;
}

.mission-v1-show .left-box-one.large {
    height: 600px;
}

.mission-v1-show .left-box-one.medium {
    height: 150px;
}

.mission-v1-show .left-box-one.small {
    height: 405px;
}

.mission-v1-show .star {
    margin-right: 10px;
}

.mission-v1-show .case-title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    color: $color-35;
    display: flex;
}

.mission-v1-show .open-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.mission-v1-show .button-group-details {
    display: inline-block;
    margin: 0 5px;
    margin-bottom: 1rem;
}

.mission-v1-show .btn.btn-primary {
    background: $color-38;
    border-color: $color-white;
    color: $color-white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px 12px;
}

.btn.disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.mission-v1-show .remaining-place {
    width: 100px;
    text-align: center;
    margin-top: .75rem;
    font-size: 14px;
    line-height: 1.5;
    color: $color-35;
}

.mission-v1-show .row-last {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mission-v1-show .info-box {
    margin: 1rem;
}

.mission-v1-show .info img {
    margin: 0 auto;
    display: block;
}

.mission-v1-show .info-des {
    text-align: center;
    font-size: 12px;
    font-style: italic;
}

.appels-main-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    color: $color-35;
}

.appels-block-system label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

.appels-block-system .form-control:focus {
    border-color: $color-36;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.appels-block-system .error-input {
    border-color: $color-error !important;
}

.appels-block-system .form-control.error-input:focus {
    border-color: $color-error;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-8;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-8;
}

.appels-block-system .resizable-textarea .grippie {
    background: $color-7 url('/static/img/misc/grippie.png') no-repeat center 2px;
    border: 1px solid $color-4;
    border-top-width: 0;
    cursor: s-resize;
    height: 9px;
    overflow: hidden;
    padding: 0 12px;
    width: 100%;
}

.appels-block-system label.errormessage {
    font-weight: normal;
    color: $color-error;
}

.appels-block-system .form-actions,
.appels-block-system fieldset .panel-body,
.form-item-organization-contact-fax,
.form-item-organization-annual-budget,
.form-item-organization-treasurer {
    clear: both;
}

.appels-block-system .panel-body {
    padding: 15px;
}

.appels-block-system .panel-body::before,
.appels-block-system .panel-body::after {
    content: "";
    clear: both;
    display: table;
}

.appels-block-system input[type="text"],
.appels-block-system fieldset .resizable-textarea textarea,
.appels-block-system fieldset .resizable-textarea .grippie {
    max-width: 350px;
    width: 100%;
}

.appels-block-system .form-group {
    margin-bottom: 15px;
}

.appels-block-system .resizable-textarea textarea {
    height: 60px;
    resize: vertical;
    border-radius: 4px;
}

.appels-block-system .clear {
    clear: both;
}

.appels-block-system .hide {
    display: none;
}

.appels-block-system .show {
    display: block;
}

.appels-block-system .panel-body .form-item,
.appels-block-system .form-type-managed-file {
    float: left;
    max-width: 430px;
    width: 100%;
    margin: 10px 20px 15px 0;
}

.appels-block-system span.form-required {
    color: $color-error;
}

.appels-block-system .form-control {
    display: block;
    width: 100%;
    height: 22px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: $color-5;
    background-color: $color-white;
    background-image: none;
    border: 1px solid $color-4;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.appels-block-system .actions .print {
    font-family: "Calibri";
    color: $color-35;
}

.appels-block-system .actions .print a {
    color: $color-black;
    font-weight: bold;
    text-decoration: none;
}

.appels-block-system .actions label {
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: normal;
}

.appels-block-system .actions .btn-large {
    float: right;
}

.appels-block-system .actions .hide {
    display: none;
}

.appels-block-system .actions .show {
    display: block;
}

.appels-block-system .actions .errormessage {
    color: $color-error;
    padding: 5px 0 5px 20px;
    pointer-events: none;
}

.appels-block-system .actions .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4285;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.appels-block-system .actions .btn-default {
    background-color: $color-36;
    border-color: $color-4;
    color: $color-white;
}

.appels-block-system .actions .btn-default:hover {
    background: $color-8;
    color: $color-5;
}

.appels-block-system .actions .form-checkbox {
    color: $color-5;
    cursor: pointer;
}

.appels-block-system .actions .form-checkbox {
    color: $color-error;
    border-color: $color-8;
}

.appels-block-system .ajax-group {
    margin-top: 15px;
    border-top: solid $color-6 1px;
}

.appels-block-system.input-group-addon,
.appels-block-system.input-group-btn,
.appels-block-system.input-group .form-control {
    display: table-cell;
}

.appels-block-system.input-group-addon:not(:first-child):not(:last-child),
.appels-block-system .input-group-btn:not(:first-child):not(:last-child),
.appels-block-system .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
    min-height: 40px;
}

.appels-block-system .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.appels-block-system .input-group-addon,
.appels-block-system .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid $color-4;
    border-radius: 5px;
}

.appels-block-system .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}

.appels-block-system .input-group-addon,
.appels-block-system .input-group-btn,
.appels-block-system .input-group .form-control {
    display: table-cell;
}

.appels-block-system .input-group-btn:last-child > .btn,
.appels-block-system .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px;
}

.appels-block-system .input-group .form-control:last-child,
.appels-block-system .input-group-addon:last-child,
.appels-block-system .input-group-btn:last-child > .btn,
.appels-block-system .input-group-btn:last-child > .btn-group > .btn,
.appels-block-system .input-group-btn:last-child > .dropdown-toggle,
.appels-block-system .input-group-btn:first-child > .btn:not(:first-child),
.appels-block-system .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.appels-block-system .input-group-btn > .btn {
    position: relative;
}

.appels-block-system .btn-primary {
    color: $color-white;
    background-color: $color-22;
    border-color: $color-22;
}

.appels-block-system .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.appels-block-system .btn .glyphicon.icon-before,
.appels-block-system .btn.icon-before .glyphicon {
    margin-left: -.25em;
    margin-right: .25em;
}

.appels-block-system .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    line-height: 1;
}

.appels-block-system .glyphicon-upload::before {
    content: "";
    background: url('/static/img/circle-empty-up.png') left center/16px 16px no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.appels-block-system .glyphicon-trash::before {
    content: "";
    background: url('/static/img/trash-icon.png') left center/20px 20px no-repeat;
    width: 20px;
    height: 16px;
    display: inline-block;
}

.appels-block-system .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.appels-block-system .file-uploaded-detail {
    display: none;
}

.appels-block-system .file-uploaded-detail a {
    color: $color-22;
    text-decoration: none;
}

.appels-block-system .file-uploaded-detail .form-control {
    border-radius: 0 4px 4px 0;
}

.appels-block-system .control-group .help-inline,
.appels-block-system .help-block {
    color: $color-5;
    font-size: 12px;
    margin: 5px 0 10px;
    padding: 0;
}

.appels-block-system input[type="text"].min-text {
    width: 140px;
}

.appels-block-system #edit-organization-company {
    border: none;
    padding: 0 30px;
}

.appels-block-system .form-group.error .control-label,
.appels-block-system .form-group.error label,
.appels-block-system .form-group.has-error .control-label,
.appels-block-system .form-group.has-error label {
    color: $color-error;
    font-weight: 600;
}

.appels-main-container .alert-success {
    color: $color-8;
    background-color: $color-9;
    border-color: $color-9;
}

.appels-main-container .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: "Calibri";
    color: $color-35;
}

.appels-main-container .alert-dismissable,
.appels-main-container .alert-dismissible {
    padding-right: 35px;
}

.appels-main-container .alert-dismissable .close,
.appels-main-container .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.appels-main-container .close {
    text-decoration: none;
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: $color-black;
    text-shadow: 0 1px 0 $color-white;
    filter: alpha(opacity=20);
    opacity: .2;
}

.appels-block-system .btn-danger {
    color: $color-white;
    background-color: $color-error;
    border-color: $color-error;
    margin-right: -1px;
}

.appels-block-system .btn-danger:hover {
    color: $color-white;
    background-color: $color-error;
    border-color: $color-error;
}

#branding {
    overflow: hidden;
    padding: 20px 20px 0;
    position: relative;
    background-color: $color-4;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

#branding .bc-wrap .breadcrumb {
    font-size: .846em;
    padding-bottom: 5px;
}

#branding h1.page-title {
    color: $color-black;
    margin: 0;
    padding-bottom: 10px;
    font-size: 1.385em;
    font-weight: normal;
}

#branding .user-navs ul.primary {
    border-bottom: none;
    text-transform: uppercase;
    font-size: .923em;
    height: 2.6em;
    margin: 0;
    padding-top: 0;
    display: flex;
}

#branding .user-navs ul.primary li {
    list-style: none;
    margin: 0 2px;
}

#branding .user-navs ul.primary li a:link,
#branding .user-navs ul.primary li a.active,
#branding .user-navs ul.primary li a:active,
#branding .user-navs ul.primary li a:visited,
#branding .user-navs ul.primary li a:hover,
#branding .user-navs ul.primary li.active a {
    display: block;
    height: 2.6em;
    line-height: 2.6em;
    padding: 0 18px 8px;
    background-color: $color-6;
    color: $color-black;
    font-weight: bold;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $color-6;
    -moz-border-radius: 8px 8px 0 0;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    border-radius: 8px 8px 0 0;
    text-decoration: none;
}

#branding .user-navs ul.primary li.active a,
#branding .user-navs ul.primary li.active a.active,
#branding .user-navs ul.primary li.active a:active,
#branding .user-navs ul.primary li.active a:visited {
    background-color: $color-white;
    border-color: $color-6;
}

#branding .user-navs ul.primary li a:hover {
    color: $color-white;
}

#branding .user-navs ul.primary li.active a:hover {
    color: $color-black;
}

.create-personnes .content-edit {
    padding: 20px 0 40px;
    margin-right: 40px;
    margin-left: 40px;
    background: $color-white;
    position: relative;
    color: $color-5;
}

.create-personnes .form-item {
    display: block;
    padding: 9px 0;
    margin: 0 0 10px;
}

.create-personnes .form-type-input {
    display: flex;
    flex-direction: column;
}

.create-personnes .form-item input {
    width: max-content;
    padding: 2px;
    border: 1px solid $color-7;
    border-top-color: $color-5;
    background-color: $color-white;
    color: $color-5;
}

.create-personnes .form-item label {
    display: block;
    font-weight: bold;
}

.create-personnes .form-item .form-required {
    color: $color-error;
}

.description {
    margin: 5px 0;
    line-height: 1.231em;
    font-size: .923em;
    color: $color-5;
}

.description a {
    color: $color-22;
    text-decoration: none;
}

.description a:hover {
    text-decoration: underline;
}

.create-personnes .form-item .password-suggestions {
    padding: .2em .5em;
    margin: .7em 0;
    width: 31.5em;
    border: 1px solid $color-6;
    display: none;
}

.create-personnes .password-suggestions ul {
    list-style-type: disc;
    list-style-image: none;
    margin: .25em 0 .25em -1.5em;
}

.create-personnes .password-suggestions ul li {
    font-size: .923em;
    line-height: 1.231em;
    color: $color-5;
}

.create-personnes .form-item.password-parent,
.create-personnes .form-item.confirm-parent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
}

.create-personnes .form-item.confirm-parent .password-confirm-text {
    width: 15.2em;
    margin-left: 2.5rem;
    display: none;
}

.create-personnes .form-item.password-parent .password-strength {
    display: flex;
    flex-direction: column;
    width: 14.2em;
    margin-left: 2.5rem;
}

.create-personnes .form-item.password-parent .password-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.password-indicator {
    background-color: $color-4;
    height: .3em;
    width: 100%;
}

.password-indicator div {
    height: 100%;
    width: 0%;
    background-color: $color-valid;
}

.password-strength-text {
    font-weight: bold;
    padding-left: 1rem;
}

.create-personnes #err-msg-console {
    margin: 9px 0 10px;
    display: none;
}

.create-personnes div.messages {
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid;
    margin: 6px 0;
    padding: 10px 24px;
}

.create-personnes div.messages ul {
    line-height: 21px;
    margin: 0;
}

.create-personnes #err-msg-console div.error {
    background-image: url('..//Images/message-24-error.png');
    border-color: $color-25;
    color: $color-error;
    background-color: $color-29;
}

.create-personnes .form-radios .form-item.form-type-radio {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-bottom: 9px;
}

.create-personnes .form-item label.option {
    font-size: .923em;
    text-transform: none;
    font-weight: normal;
}

.create-personnes .form-item label.option a {
    color: $color-22;
    text-decoration: none;
}

.create-personnes .form-item label.option a:hover {
    text-decoration: underline;
}

.create-personnes .form-radios .form-item.form-type-radio input[type=radio] {
    margin-left: 0;
}

.create-personnes fieldset {
    border: 1px solid $color-7;
    padding: 2.5em 0 0;
    position: relative;
    margin: 1em 0;
}

.create-personnes fieldset legend {
    font-weight: bold;
    padding: 0;
}

.create-personnes fieldset .fieldset-legend {
    margin-top: .75em;
    padding-left: 15px;
    position: absolute;
    text-transform: uppercase;
    font-weight: bold;
}

.create-personnes fieldset .fieldset-wrapper {
    padding: 0 13px 13px 15px;
}

.create-personnes .fieldset-title {
    color: $color-22;
    text-decoration: none;
}

.create-personnes .fieldset-title:hover {
    text-decoration: underline;
}

.create-personnes fieldset.collapsed .fieldset-legend {
    background-image: url('/static/img/menu-collapsed.png');
    background-position: 5px 50%;
    background-repeat: no-repeat;
}

.create-personnes fieldset.collapsible .fieldset-legend.active {
    background: url('/static/img/menu-expanded.png') 5px 65% no-repeat;
    padding-left: 15px;
}

.create-personnes .form-item.form-type-textarea {
    display: flex;
    flex-direction: column;
}

.create-personnes .form-textarea-wrapper textarea {
    display: block;
    margin: 0;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.create-personnes textarea.form-textarea {
    padding: 2px;
    border: 1px solid $color-7;
    border-top-color: $color-5;
    background: $color-white;
    color: $color-5;
}

.create-personnes .form-item.form-type-checkboxes,
.create-personnes .form-item.form-type-textfield {
    display: flex;
    flex-direction: column;
}

.create-personnes .form-item.form-type-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

.create-personnes .form-item.form-type-select {
    display: flex;
    flex-direction: column;
    width: max-content;
}

div.warning {
    background-image: url('/static/img/message-24-warning.png');
    border-color: $color-27;
    color: $color-error;
    background-color: $color-8;
}

input.error-input {
    border: 2px solid $color-error !important;
}

.form-actions .form-submit-btn {
    cursor: pointer;
    padding: 4px 17px;
    margin-right: 1em;
    color: $color-35;
    text-align: center;
    font-weight: normal;
    font-size: 1.077em;
    border: 1px solid $color-7;
    border-bottom: 1px solid $color-6;
    border-left-color: $color-4;
    border-right-color: $color-4;
    background: url('/static/img/buttons.png') 0 0 repeat-x;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
}

#personnes-tablecontainer {
    padding: 20px 0 40px;
    background: $color-white;
    position: relative;
    color: $color-5;
    font-size: 81.3%;
    line-height: 1.538em;
}

#personnes-tablecontainer .clearfix::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

#personnes-tablecontainer ul li {
    list-style: none;
}

#personnes-tablecontainer ul:not([class]) li::before {
    display: none;
}

#personnes-tablecontainer ul.action-links {
    margin: 1em 0;
    padding: 0 20px;
    list-style-type: none;
    overflow: hidden;
}

#personnes-tablecontainer ul.action-links a {
    padding-left: 15px;
    background: transparent url('/static/img/add.png') no-repeat 0 center;
    line-height: 30px;
}

#personnes-tablecontainer .item-list ul {
    list-style-type: disc;
    list-style-image: none;
    margin: .25em 0 .25em 1.5em;
    padding: 0;
    border: 0;
}

#personnes-tablecontainer a {
    color: $color-36;
    text-decoration: none;
}

#personnes-tablecontainer a:hover {
    text-decoration: underline;
}

#personnes-tablecontainer .views-exposed-widgets {
    margin-bottom: .5em;
}

#personnes-tablecontainer .views-exposed-form .views-exposed-widget {
    float: left;
    padding: .5em 1em 0 0;
}

#personnes-tablecontainer .views-exposed-form label {
    font-weight: bold;
}

#personnes-tablecontainer .formItem {
    padding: 9px 0;
    margin: 0 0 10px;
}

#personnes-tablecontainer .views-exposed-form .formItem,
.views-exposed-form .form-submit {
    margin-top: 0;
    margin-bottom: 0;
}

#personnes-tablecontainer .vbo-views-form thead .form-type-checkbox {
    margin: 0;
}

#personnes-tablecontainer .form-type-checkbox {
    padding: 0;
    text-align: center;
}

#personnes-tablecontainer input.form-text,
#personnes-tablecontainer select.form-select {
    padding: 2px;
    border: 1px solid $color-7;
    border-top-color: $color-5;
    background: $color-white;
    color: $color-5;
}

#personnes-tablecontainer .admin-views-view .views-exposed-form .form-text {
    width: 10em;
}

#personnes-tablecontainer .views-exposed-form .formItem,
.views-exposed-form .form-submit {
    margin-top: 0;
    margin-bottom: 0;
}

#personnes-tablecontainer .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 1.9em;
}

#personnes-tablecontainer input.form-submit,
#personnes-tablecontainer a.button {
    cursor: pointer;
    padding: 4px 17px;
    margin: 1em 1em .5em 0;
    color: $color-35;
    text-align: center;
    font-weight: normal;
    font-size: 1.077em;
    border: 1px solid $color-7;
    border-bottom: 1px solid $color-6;
    border-left-color: $color-4;
    border-right-color: $color-4;
    background: url('/static/img/images/buttons.png') 0 0 repeat-x;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    transition: opacity .5s;
}

#personnes-tablecontainer input.form-submit:hover,
#personnes-tablecontainer a.button:hover {
    opacity: .5;
}

#personnes-tablecontainer .container-inline {
    border: 1px solid $color-7;
    padding: .5em 1em 1em;
    position: relative;
    margin: 1em 0;
}

#personnes-tablecontainer .container-inline.collapsed {
    background-color: transparent;
}

#personnes-tablecontainer legend {
    font-weight: bold;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

#personnes-tablecontainer .form-item-operation {
    float: left;
    margin: 5px 1em 0 0;
    display: inline-block;
}

#personnes-tablecontainer table {
    width: 100%;
    font-size: .923em;
    margin: 0 0 10px;
    border: 1px solid $color-6;
    border-collapse: collapse;
    border-spacing: 0;
}

#personnes-tablecontainer table td,
#personnes-tablecontainer table th {
    vertical-align: middle;
    padding: 8px 10px;
    border: 0;
    color: $color-black;
    text-align: left;
}

#personnes-tablecontainer tr.even,
#personnes-tablecontainer tr.odd {
    border-width: 0 1px;
    border-style: solid;
    border-color: $color-6;
    background: $color-7;
}

#personnes-tablecontainer tr.odd .formItem,
#personnes-tablecontainer tr.even .formItem {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
}

#personnes-tablecontainer input.form-checkbox,
#personnes-tablecontainer input.form-radio {
    vertical-align: middle;
    margin: 0;
}

#personnes-tablecontainer tr.odd {
    background: $color-white;
}

#personnes-tablecontainer .views-table-row-select-all {
    display: none;
}

#personnes-tablecontainer table th {
    text-transform: uppercase;
    background: #e1e2dc;
    font-weight: normal;
    padding: 5px 10px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    border: 1px solid $color-6;
}

#personnes-tablecontainer .sticky-top-ie thead {
    position: fixed;
    width: 1200px;
    z-index: 99;
    top: 0;
}

#personnes-tablecontainer table th.active img {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 15px;
}

#personnes-tablecontainer table th.select-all {
    width: 1px;
}

#personnes-tablecontainer table th.active {
    background: #bdbeb9;
}

#personnes-tablecontainer table th a,
#personnes-tablecontainer#personnes-tablecontainer table th span {
    display: block;
    position: relative;
}

#personnes-tablecontainer table th.active a {
    padding: 5px 10px;
}

#personnes-tablecontainertable th.active img {
    position: absolute;
    top: 3px;
    right: 3px;
}

#personnes-tablecontainer table td.active {
    background: #e9e9dd;
}

#personnes-tablecontainer table tr.odd td.active {
    background: $color-7;
}

#personnes-tablecontainer table tr.selected td.active,
#personnes-tablecontainer table tr.selected td {
    background: $color-7;
    border-color: $color-6;
}

#personnes-tablecontainer tr td:last-child {
    border-right: 1px solid $color-6;
}

#personnes-tablecontainer .item-list .pager {
    clear: both;
    text-align: center;
    list-style: none;
}

#personnes-tablecontainer .item-list .pager li {
    padding: .5em;
    background-image: none;
    display: inline;
    list-style-type: none;
}

#personnes-tablecontainer .pager-current {
    font-weight: bold;
}

.sticky-top-ie thead th:nth-child(1) {
    width: 22px;
}

.sticky-top-ie thead th:nth-child(2) {
    width: 245px;
}

.sticky-top-ie thead th:nth-child(3) {
    width: 83px;
}

.sticky-top-ie thead th:nth-child(4) {
    width: 70px;
}

.sticky-top-ie thead th:nth-child(5) {
    width: 119px;
}

.sticky-top-ie thead th:nth-child(6) {
    width: 100px;
}

.sticky-top-ie thead th:nth-child(7) {
    width: 119px;
}

.sticky-top-ie thead th:nth-child(8) {
    width: 170px;
}

.sticky-top-ie thead th:nth-child(9) {
    width: 203px;
}

@media (min-width: 1200px) {
    .view-admin-wrapper {
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
    }
}

.appels-cgu-actions .print {
    color: $color-34;
}

.appels-cgu-actions .print a {
    color: $color-black;
    font-weight: bold;
    text-decoration: none;
}

.appels-cgu-actions label {
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}

.appels-cgu-actions .btn-large {
    float: right;
}

.appels-cgu-actions .hide {
    display: none;
}

.appels-cgu-actions .show {
    display: block;
}

.appels-cgu-actions .errormessage {
    color: $color-error;
    padding: 5px 0 5px 20px;
    pointer-events: none;
}

.appels-cgu-actions .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4285;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.appels-cgu-actions .btn-default {
    background-color: $color-12;
    border-color: $color-4;
    color: $color-white;
}

.appels-cgu-actions .btn-default:hover {
    background: $color-8;
    color: $color-5;
}

.appels-cgu-actions .form-checkbox {
    color: $color-5;
    cursor: pointer;
    margin-right: 10px;
}
