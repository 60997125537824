section.infl-kongress-wrapper {
    background: linear-gradient(201.86deg, $color-49  31.84%, $color-22 85.68%);
}

.kongressinfl-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 10px;
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 32px;
}

.kongressinfl-heading-filter-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: baseline;
    -webkit-align-items: baseline;
}

.kongressinfl-heading-area h2.infl-heading-1 {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: $color-white;
}

.kongressinfl-heading-area span.infl-bold-heading {
    font-weight: 600;
}

.kongressinfl-heading-area .kongressinfl-para {
    color: $color-white;
    opacity: .7;
}

.kongressinfl-heading-area {
    width: 50%;
}

.infl-kongressinfl-filter-selected {
    position: relative;
    font-size: 14px;
    margin-bottom: 1rem;
}

.kongressinfl-dropdown-filter-conatiner {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.kongressinfl-selectBtn {
    cursor: pointer;
    position: relative;
    padding-right: 1.8rem;
    color: $color-white;
    text-transform: uppercase;
    text-align: right;
}

.kongressinfl-dropdown-option.kongressinfltriggered {
    color: $color-22;
}

.kongressinfl-selectDropdown {
    overflow: hidden;
    z-index: 1;
    border: 1px solid $color-black;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    transition: .2s ease;
    background-color: $color-white;
    margin-top: .5rem;
    position: absolute;
    width: 100%;
    display: none;
    font-weight: 600;
}

.kongressinfl-selectDropdown.kongressinfldropdown-toggle {
    display: block;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.infl-kongressinfl-selectDropdown-conatiner {
    min-width: 212px;
    position: absolute;
    right: .5rem;
}

.kongressinfl-dropdown-option {
    position: relative;
    cursor: pointer;
    padding: .5rem;
}

.kongressinfl-dropdown-option:hover {
    background-color: rgba(0, 99, 195, .1);
    color: $color-22;
}

.kongressinfl-selectBtn:after {
    content: "";
    background: url('/static/img/kongress-white-dropdown.svg');
    width: 22px;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    background-position: center;
    background-size: cover;
}

.infl-kongressinfl-maincontainer {
    margin: 3rem auto;
}

.infl-kongressinfl-main-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.inflamationkongressinfl-content {
    width: 31%;
    color: $color-black;
    margin-bottom: 1.5rem;
    position: relative;
    background-color: $color-white;
    margin-right: 1.5rem;
}

.inflamationkongressinfl-content :nth-child(3n) {
    margin-right: 0;
}

.infl-showbtn-container {
    margin: 2rem auto;
    text-align: center;
}

.inflkongress-imag-tagsarea {
    position: relative;
}

.infl-kongressimg {
    position: relative;
}

.infl-kongressimg ::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, .31) 0%, rgba(0, 0, 0, 0) 100%);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

.infl-kongresstags-area {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.infl-uppertags-area {
    display: flex;
    flex-direction: row;
    margin-bottom: .5rem;
}

.infl-uppertags-area .infl-kongresstags {
    margin-right: .7rem;
    background-color: $color-22;
    color: $color-white;
    font-size: 12px;
    text-transform: uppercase;
    padding: .45rem 1rem;
    line-height: 12px;
    font-weight: 500;
}

.infl-uppertags-area .infl-kongresstags:last-child {
    margin-right: 0;
}

.infl-kongresstags {
    color: $color-black;
}

.infl-belowtags-area .infl-kongresstags {
    background-color: $color-white;
    color: $color-22;
    padding: .45rem 1rem;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    display: inline-block;
}

h3.infl-kongress-thumbnail-heading {
    font-size: 20px;
    padding: 1rem;
    padding-top: 0;
}

.infl-kongresss-datetime-flex {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: space-between;
    margin-top: 2rem;
}

.infl-kongressdate-range {
    color: $color-22;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: .5rem;
}

.infl-kongressmonth {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-22;
}

.infl-kongress-time-col {
    background-color: rgba(14, 111, 221, .05);
    padding: 1rem 1.5rem;
}

.infl-kongresstime-range {
    font-size: 12px;
    color: $color-22;
    margin-bottom: .5rem;
}

.infl-kongresscopyright {
    font-size: 12px;
    color: $color-22;
    opacity: .5;
}

.grey-recording-section .infl-uppertags-area .infl-kongresstags,
.grey-recording-section .infl-belowtags-area .infl-kongresstags {
    background-color: $color-white;
    color: $color-black;
}

.grey-recording-section .infl-kongressdate-range,
.grey-recording-section .infl-kongressmonth {
    color: $color-black;
    opacity: .3;
}

.grey-recording-section .infl-kongresstime-range,
.grey-recording-section .infl-kongresscopyright {
    color: $color-black;
    opacity: .5;
}

.grey-recording-section .infl-kongress-time-col {
    background-color: rgba(0, 0, 0, .05);
}

.inflgrey-record {
    color: $color-50;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    right: 2rem;
}

.inflgrey-record ::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $color-50;
    border-radius: 50%;
    left: -18px;
    top: 1px;
}

.inflamationkongressinfl-content {
    display: none;
}

.inflamationkongressinfl-content.inflammation-kongressinflcurrent {
    display: block;
}

.kongressinfl-wrapper {
    position: relative;
    height: 100%;
}

.kongress-thumbnail-btn-area {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    background-color: rgba(0, 99, 195, .7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    display: none;
}

.kongressinfl-wrapper:hover .kongress-thumbnail-btn-area {
    display: flex;
}

a.inflkongress-btn-link {
    display: inline-flex;
    text-decoration: none;
    background-color: $color-white;
    color: $color-22;
    font-size: 14px;
    text-transform: uppercase;
    padding: .8rem 2rem;
    border-radius: 25px;
}

span.infl-kongress-register-icon {
    width: 2rem;
    margin-left: .5rem;
}

span.infl-kongress-register-icon img {
    margin-bottom: 0;
}

.infl-kongressshowbtn-container {
    margin: 2rem auto;
    text-align: center;
}

a.infl-kongressshow-more-btn {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-white;
    border: 1px solid $color-white;
    padding: .8rem 2rem;
    border-radius: 25px;
}

.kongress-meetingpoint-flex {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
}

h3.kongress-meetingpoint-heading {
    font-size: 2rem;
    font-weight: 100;
    color: $color-white;
}

.kongress-meetingpoint-content {
    width: 60%;
}

a.kongressmeeting-btn {
    border: 1px solid $color-white;
    padding: .8rem 2rem;
    text-decoration: none;
    color: $color-white;
    border-radius: 25px;
    display: inline-block;
}

.kongress-meetingpoint-btn {
    margin: 3rem auto;
}

span.arrowmeeting-icon {
    display: inline-block;
    width: 2rem;
    margin-left: .5rem;
}

span.arrowmeeting-icon img {
    margin-bottom: 0;
}

.kongress-meetingpoint-img {
    width: 15%;
}

@media screen and (max-width: 1024px) {
    .inflamationkongressinfl-content {
        width: 48%;
        margin-right: 0;
    }

    .inflamationkongressinfl-content :nth-child(3n) {
        margin-right: rem;
    }

    .infl-kongressinfl-main-flex {
        justify-content: space-between;
        -webkit-justify-content: space-between;
    }

    .kongress-meetingpoint-content {
        width: 80%;
    }

    .kongress-meetingpoint-img {
        width: 18%;
    }
}

@media screen and (max-width: 700px) {
    .inflamationkongressinfl-content {
        width: 100%;
    }

    .kongressinfl-heading-filter-flex {
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .kongressinfl-heading-area {
        margin-bottom: 2rem;
        width: 100%;
    }

    .kongress-meetingpoint-content {
        width: 100%;
    }

    .kongress-meetingpoint-img {
        width: 50%;
        margin: auto;
    }
}
