.grid-check {
    background-color: rgba(0, 255, 0, .5);
    height: 200px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}

.grid-check__column {
    background-color: rgba(0, 0, 255, .25);
    width: layout-column-width(1);
    height: 200px;

    span {
        background-color: rgba(255, 0, 0, .25);
        display: block;
        height: 100%;
        width: 100%;
    }
}
