$header-font-size: 18px !default;
$header-color-background: $color-white !default;
$header-color-nav-text: $color-2 !default;
$header-color-nav-border-mobile: $color-1 !default;
$header-color-nav-border-desktop: $color-4 !default;
$header-color-active: $color-1 !default;
$header-icon-menu: menu-red-repatha !default;
$header-icon-close: close-red-repatha !default;
$header-icon-nav-link: arrow-small-right-blue-repatha !default;
$header-icon-desktop-subnav: arrow-xsmall-right-blue-repatha !default;
$header-icon-desktop-subnav-large: arrow-small-right-blue-repatha !default;
$header-icon-desktop-subnav-active: arrow-xsmall-right-red-repatha !default;
$header-icon-desktop-subnav-active-large: arrow-small-right-red-repatha !default;
$header-icon-separate-link-button: dummy !default;
$header-padding: 24px;
$header-search-width-large: 200px;

// $header-breakpoint was 768 but with a couple more items on everything below 1167 it some items would be impossible to reach
$header-breakpoint: 1167px;
$header-maxwidthbp: ($header-breakpoint - 1px);

.header {
    background: $header-color-background;
    padding: rem(28px) 0 rem(17px);

    &--sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 20;
        box-shadow: 0 0 3px rgba($color-black, .1);
    }

    &_logo {
        display: inline-block;
        overflow: visible;
        position: relative;

        &--primary {
            width: 225px;
            height: 65px;
        }

        &--secondary {
            width: 200px;
            height: 40px;
        }

        > img {
            max-width: 100%; // default behavior, relevant wheneven the image is the same or wider aspect ratio than the default
            max-height: none;
            width: auto; // undo basic 100% width of global stylesheet
            height: auto; // so this just shows 'as is' unless wider than 100%
            margin: 0;
            display: inline-block;
            bottom: 0; // stick to bottom side, which is relevant when logo is smaller than allowed space
            left: 0;
            position: absolute;

            // for any logo that is less landscape than the standard allowed space
            // (primary: 225x65 h/w > 0.289 OR secondary: 200x40 h/w > 0.2)
            &.img--taller-than-default {
                max-width: none;
                max-height: 100%;
            }
        }
    }

    &_quicknav {
        @include blank-list;
        position: absolute;
        right: rem($header-padding);
        top: 0;

        &-link-navigation {
            display: block;
            width: 22px;
            height: 22px;

            &::before {
                @include icon-props($header-icon-menu);
            }

            &[data-toggle-active='true'] {
                &::before {
                    @include icon-props($header-icon-close);
                }
            }
        }
    }

    &_nav,
    &_subnav {
        @include blank-list;
        margin-bottom: 1em;

        &-link,
        &-subnav-heading {
            display: block;
            text-decoration: none;
            color: $header-color-nav-text;
        }
    }

    .has-js &_subnav {
        display: none;
        font-size: $base-font-size;
    }

    &_nav {
        font-size: rem($header-font-size);

        &[data-processed='true'] {
            .header_subnav {
                display: block;
            }
        }

        &-subnav-heading {
            .has-js & {
                cursor: pointer;
            }
        }

        &-link-subnav-container {
            position: relative;
        }
    }

    &_search {
        margin: 1em 0;
    }

    &_header-button {
        min-height: 0;
        padding-bottom: .4rem;
        text-transform: none;
        width: auto;

        @if $header-icon-separate-link-button == dummy {
            &.btn.icon-left {
                padding-left: 1.5rem;
            }

            &::before {
                content: '';
                display: none;
            }
        }

        @if $header-icon-separate-link-button != dummy {
            &::before {
                @include icon;
                @include icon-props($header-icon-separate-link-button);

                // override sizing that is extracted from the svg by the above
                width: 1em;
                height: 1em;
            }
        }
    }

    &__button-group {
        display: flex;
        justify-content: flex-end;

        > * {
            align-self: center;
        }

        > * + * {
            margin-left: rem(16px);
        }

        + .header_logo--secondary > img {
            max-height: 17px;
        }
    }
}

.layout-column--header {
    position: relative;
}

@media (max-width: $header-maxwidthbp) {
    .header {
        &_nav {
            border-top: 1px dotted $header-color-nav-border-mobile;
            text-transform: uppercase;
            line-height: 1.2;

            &-link,
            &-subnav-heading {
                padding: .75em 30px .65em 5px;
                position: relative;
            }

            &-link {
                &::before {
                    @include icon;
                    @include icon-props($header-icon-nav-link);
                    @include position(absolute, 50% 5px false false);
                    transform: translateY(-50%);
                    right: 7px;
                }

                .header_nav-link-subnav-container & {
                    width: calc(100% - 35px); // compensate for padding right and left on -link

                    &::before {
                        display: none;
                    }
                }
            }

            &-subnav-heading {
                &::before,
                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 2px;
                    background-color: currentColor;
                    @include position(absolute, 50% 5px false false);
                    transform: translateY(-50%);
                    transition: opacity .3s ease;
                }

                &::after {
                    width: 2px;
                    height: 12px;
                    right: 10px;
                }

                &[aria-selected='true'] {
                    &::after {
                        opacity: 0;
                    }
                }

                .header_nav-link-subnav-container & {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    padding-right: 1em;
                    transform: translateY(-50%);
                }
            }

            &-item {
                border-bottom: 1px dotted $header-color-nav-border-mobile;
            }
        }

        &_subnav {
            border-top: 1px dotted $header-color-nav-border-mobile;
            margin: 0;
            text-transform: none;

            &-link {
                padding: .6em 0 .3em 1em;
            }
        }

        &_header-button {
            display: none;

            .header_wrapper[data-toggle-active=true] & {
                display: inline-block;
            }
        }
    }
}

@media (min-width: $header-breakpoint) {
    .header {
        &__button-group {
            position: absolute;
            right: 0;
            bottom: rem(84px);

            a {
                padding: 0 5px;
            }
        }

        &_header-button {
            line-height: 1;
        }

        &_quicknav {
            @include screenreader;
        }

        &_wrapper {
            margin-left: $layout-gutter-large;
            flex: 1;
            padding-right: $header-search-width-large + $layout-gutter-large;
            position: relative;
        }

        &_search {
            @include position(absolute, false 0 0 false);
            width: $header-search-width-large;
            margin: 0;
        }

        &_logo--secondary {
            @include position(absolute, false 0 50px false);
        }

        &_nav {
            @include position(absolute, false ($header-search-width-large + $layout-gutter-large) 0 0);
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            &-item {
                flex: 0 1 auto;
                margin: .5em 0 0 -1px;
                position: relative;

                .collapsible-transition {
                    position: absolute;
                    top: calc(100% - 1px);
                    z-index: 1;
                    min-width: 300px;
                }

                + .header_nav-item > .header_nav-link,
                .header_nav-subnav-heading {
                    border-left-color: $header-color-nav-border-desktop;
                }
            }

            &-link,
            &-subnav-heading {
                border: 1px solid transparent;
                padding: .4em 1em .2em;
                display: block;

                &--active {
                    color: $header-color-active;
                }
            }

            &-link-subnav-container {
                border-left: 1px solid $header-color-nav-border-desktop;
            }

            &-link {
                .header_nav-link-subnav-container & {
                    padding-right: 0;
                    margin-right: 2em;
                }
            }

            &-subnav-heading {
                padding-right: 1.8em;

                &::before {
                    @include icon;
                    @include icon-props($header-icon-desktop-subnav);
                    @include position(absolute, 50% 1em false false);
                    transform: rotate(90deg) translateX(-50%);
                }

                .header_nav-link-subnav-container & {
                    position: absolute;
                    top: 50%;
                    right: .5em;
                    transform: translateY(-50%);
                    padding: 10px;
                    border-color: transparent !important;

                    &::before {
                        @include icon-props($header-icon-desktop-subnav-large);
                        @include position(absolute, 50% .334em false false);
                        transform: rotate(90deg) translateX(-50%);
                    }
                }

                &--active {
                    &::before {
                        @include icon-props($header-icon-desktop-subnav-active, position);
                    }

                    .header_nav-link-subnav-container & {
                        &::before {
                            @include icon-props($header-icon-desktop-subnav-active-large, position);
                        }
                    }
                }

                &[aria-selected='true'] {
                    border-color: $header-color-nav-border-desktop $header-color-nav-border-desktop $color-white;
                }
            }
        }

        &_subnav {
            border: 1px solid $header-color-nav-border-desktop;
            padding: .5em 1em .3em;
            background: $color-white;

            &-item {
                &:not(:first-child) {
                    margin-top: .5em;
                }
            }

            &-link {
                position: relative;
                line-height: 1.2;
                padding-right: 16px;

                &::before {
                    @include icon;
                    @include icon-props($header-icon-desktop-subnav);
                    @include position(absolute, 50% 0 false false);
                    transform: translateY(-50%);
                }

                &--active {
                    color: $header-color-active;

                    &::before {
                        @include icon-props($header-icon-desktop-subnav-active, position);
                    }
                }
            }
        }

        + .layout-row {
            margin-top: $layout-element-margin-default;
        }
    }

    // give the header an additional flex container to solve min-height issues in IE
    .header .layout-row {
        display: flex;
        flex-direction: column;
    }

    .layout-column--header {
        flex: 1 0 auto;
        justify-content: flex-end;
        min-height: rem(80px);
        display: flex;
        align-items: flex-end;
    }
}
