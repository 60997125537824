.main-parrent {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

    .header {
        background-color: $color-12;
        padding: 40px 20px 20px;
        display: flex;

        .amgen {
            width: 50%;

            img {
                max-width: 104px;
            }
        }

        .germany {
            width: 50%;

            img {
                max-width: 268px;
                float: right;
            }
        }
    }

    .page-container {
        display: block;
        max-width: 880px;
        margin: auto;
    }

    .body1 {
        margin: 100px auto 40px;
        text-align: center;
    }

    .page-header {
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: 300;
    }

    .body1of2 {
        margin-top: 20px;
    }

    .info {
        display: flex;
        text-align: center;
    }

    .info1 {
        width: 100%;
        padding: 25px 20px;

        img {
            margin: auto;
            max-width: 46px;
            display: block;
        }

        .info1-inner {
            max-width: 600px;
            margin: auto;
        }
    }

    .info-text {
        margin: 10px auto;
        font-size: 18px;
        padding-bottom: 15px;
    }

    .btn-common {
        position: relative;
        cursor: pointer;
        border-radius: 7px;
        font-family: 'Roboto', sans-serif;
        color: $color-5;
        font-size: 14px;
        background: white;
        padding: 15px;
        border: solid $color-12 1px;
        text-align: center;
        text-decoration: none;
        min-width: 150px;
        margin: 26px 10px;

        .checkmark {
            position: absolute;
            height: 25px;
            width: 25px;
            border-radius: 20px;
            background: url(/static/amgone_umb/img/pcg/green-tick.png);
            top: -13px;
            right: -13px;
            display: none;
        }
    }

    .btn-common.active-btn {
        background: $color-12;
        color: $color-white;

        .checkmark {
            display: block;
        }
    }

    .generated-text.active-btn {
        background: $color-12;
        color: $color-white;

        .checkmark {
            display: block;
            border: none;
        }
    }

    .generated-text {
        .checkmark {
            position: absolute;
            height: 25px;
            width: 25px;
            border-radius: 20px;
            background: url(/static/amgone_umb/img/pcg/green-tick.png);
            top: -13px;
            right: -13px;
            display: none;
        }

        + {
            .p {
                font-size: 11px;
                padding: 10px;
                max-width: 350px;
                word-break: break-word;
            }
        }

        font-size: 14px;
        line-height: 20px;
        text-align: center;
        border-radius: 7px;
        border: solid $color-12 1px;
        background-color: $color-white;
        padding: 15px 60px;
        position: relative;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        width: 100%;
        max-width: 350px;
    }

    .not {
        width: 100%;
        background-color: $color-29;
        padding: 25px 15px;
        color: $color-5;
        text-align: center;
        max-width: 495px;
        margin: 50px auto 0;

        img {
            margin: auto;
            max-width: 46px;
            display: block;
        }
    }

    .not-block-text {
        font-size: 15px;
        margin: 10px auto;
    }

    .not-block-sub-text {
        margin: 15px auto 20px;
        font-size: 14px;
    }

    .infoservice {
        border-radius: 7px;
        color: $color-35;
        font-size: 16px;
        background: $color-28;
        padding: 10px 20px;
        border: 2px solid $color-35;
        font-weight: 600;
        width: auto;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
    }

    .infoservice[disabled] {
        cursor: no-drop;
    }

    .correct-wrapper {
        margin-bottom: 65px;
    }

    .correct-tick {
        width: 100%;
        padding: 25px 20px 0;
    }

    .correct-header {
        text-align: center;

        img {
            margin: auto;
            max-width: 46px;
            display: block;
        }
    }

    .correct-main-text {
        margin: 10px auto 50px;
        font-size: 18px;
        padding-bottom: 15px;
        text-align: center;
    }

    .unbreak-statement {
        display: block;
    }

    .block-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .box-4 {
        width: 48%;
        margin-bottom: 45px;
    }

    .correct-footer {
        margin: 10px 0 0;
        width: 100%;

        .btn {
            width: 100%;
            max-width: 350px;
            margin: auto;
            display: block;
        }
    }

    .btn {
        -webkit-border-radius: 7;
        -moz-border-radius: 7;
        border-radius: 7px;
        border-color: $color-53;
        font-family: 'Roboto', sans-serif;
        color: $color-white;
        font-size: 16px;
        background: $color-53;
        padding: 13px 30px;
        cursor: pointer;
    }

    .btn[disabled] {
        background: $color-6;
        border-color: $color-6;
        cursor: no-drop;
    }

    .last-block {
        padding-left: 10px;

        .small-text {
            font-size: 11px;
            font-family: 'Roboto', sans-serif;

            a {
                color: $color-5;
            }
        }

        hr {
            border-top: 1px solid $color-12;
            margin: 20px 0;
        }
    }

    .footer {
        background-color: $color-6;
        height: 100px;
        width: 100%;
        margin-top: 100px;
    }

    .correct-tick.disable {
        pointer-events: none;
        opacity: .4;
    }

    .last-sentence {
        font-size: 14px;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }

    .modal {
        background: $color-black;
        z-index: 9;
        position: fixed;
        width: 100%;
        height: 100%;
        display: none;
        top: 0;
        left: 0;

        .modal-inner {
            padding: 20px;
            max-width: 600px;
            margin: 7rem auto;
            background: $color-white;
            border-radius: 5px;
            position: relative;
            text-align: center;
        }

        .close {
            height: 30px;
            width: 30px;
            background: $color-error;
            color: $color-white;
            text-align: center;
            line-height: 30px;
            border-radius: 100%;
            position: absolute;
            right: -13px;
            top: -13px;
            cursor: pointer;
        }

        .btn-sucess {
            background: $color-12;
            text-align: center;
            margin: 2rem auto 0;
            padding: 8px 20px;
            width: 120px;
            min-height: auto;
            text-transform: capitalize;
        }

        .loader-image {
            max-width: 200px;
            margin: auto;
        }
    }

    .overlay-unotherized {
        .popup-unotherized {
            padding: 50px 20px;
        }

        .popup-text {
            font-size: 14px;
            color: $color-5;
        }
    }

    .modal.show {
        display: block;
    }

    .overlay-success {
        background: $color-black;

        .popup-text {
            font-size: 14px;
        }
    }

    .overlay-fail {
        background: $color-black;

        .popup-text {
            font-size: 14px;
        }
    }

    .overlay-infoservice {
        background: $color-black;

        .popup-text {
            font-size: 14px;
        }
    }

    .overlay-loader {
        background: $color-black;
    }
}

.last-sentence {
    a {
        color: $color-5;
        text-decoration: none;
    }
}

@media (max-width: 1024px) {
    .main-parrent {
        .generated-text {
            padding: 10px 40px;
        }

        .info1 {
            width: 100%;
            max-width: 603px;
            margin: auto;
        }

        .correct-tick {
            width: 100%;
            max-width: 603px;
            margin: auto;
        }

        .correct-wrapper {
            flex-direction: column;
        }

        .not {
            width: 100%;
            max-width: 320px;
            margin: 2rem auto 0;
        }
    }
}

@media (max-width: 767px) {
    .main-parrent {
        .info {
            flex-direction: column;
            width: 100%;
        }

        .header {
            flex-direction: column;
            width: 100%;

            .amgen {
                width: 100%;
            }

            .germany {
                width: 100%;

                img {
                    float: none;
                    margin-top: 20px;
                }
            }
        }

        .block-wrap {
            flex-direction: column;
            width: 100%;
        }

        .correct-wrapper {
            flex-direction: column;
            width: 100%;
        }

        .info1 {
            width: 100%;
        }

        .not {
            width: 100%;
        }

        .correct-tick {
            width: 100%;
            margin-bottom: 20px;
        }

        .box-4 {
            width: 100%;
        }

        .correct-footer {
            width: 100%;
        }

        .modal {
            .modal-inner {
                max-width: calc(100% - 50px);
            }
        }
    }
}

@media (max-width: 500px) {
    .main-parrent {
        .btn-common {
            margin: 10px 0;
            width: 100%;
        }

        .generated-text {
            width: 100%;
        }

        .btn {
            width: 100%;
        }
    }
}
