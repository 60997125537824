.otzsurvey-section {
    background-color: $color-39;
    padding: 2rem 1rem;
    color: $color-white;
}

.otzsurvey-section .survey-wrapper {
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
}

.otzsurvey-section .survey-rounded-content {
    height: 100px;
    width: 100px;
    background-color: $color-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid $color-38;
    color: $color-39;
    font-size: .7rem;
    font-weight: bold;
}

.otzsurvey-section .survey-mainflex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.otzsurvey-section .survey-rounded-col {
    width: 15%;
}

.otzsurvey-section .survey-quiz-area {
    width: 85%;
}

.otzsurvey-section .survey-quiz-main-title {
    font-weight: bold;
    font-size: 1.3rem;
    margin: 1.5rem 0;
}

.otzsurvey-section .survey-answer-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.otzsurvey-section .survey-option-col {
    margin-right: 1rem;
}

.otzsurvey-section .survey-questions {
    margin-bottom: 1.5rem;
}

.otzsurvey-section .survey-questions {
    margin-bottom: 1.5rem;
}

.otzsurvey-section button.survey-btn {
    color: $color-white;
    text-decoration: none;
    font-weight: bold;
    background-color: $color-38;
    padding: .7rem 2rem;
    text-align: center;
    border: 0;
    cursor: pointer;
}

.otzsurvey-section .survey-form-submit-area {
    margin: 2rem 0;
}

.otzsurvey-section .survey-questions {
    margin-bottom: 1.5rem;
}

.otzsurvey-section .survey-answer-area .survey-option-col input[type="radio"]:checked,
.otzsurvey-section .survey-answer-area .survey-option-col input[type="radio"]:not(:checked) {
    position: absolute;
    opacity: 0;
}

.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:checked + label.surveycheck-radio,
.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:not(:checked) + label.surveycheck-radio {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $color-white;
}

.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:checked + label.surveycheck-radio::before,
.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:not(:checked) + label.surveycheck-radio::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $color-6;
    border-radius: 100%;
    background: $color-white;
}

.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:not(:checked) + label.surveycheck-radio::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:checked + label.surveycheck-radio::after,
.otzsurvey-section .survey-answer-area .survey-option-col .surveyinput[type="radio"]:not(:checked) + label.surveycheck-radio::after {
    content: '';
    width: 16px;
    height: 16px;
    background: $color-38;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

@media screen and (max-width: 1024px) {
    .otzsurvey-section .survey-rounded-col {
        width: 25%;
    }

    .otzsurvey-section .survey-quiz-area {
        width: 75%;
    }
}

@media screen and (max-width: 700px) {
    .otzsurvey-section .survey-rounded-col,
    .survey-quiz-area {
        width: 100%;
    }
}
