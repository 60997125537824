.la_academia_container {
    margin: 56px 0;
    max-width: 1200px;
    margin: 0 auto 5rem auto;
}

.la_academia_container .la_academia_form .title,
.la_academia_container .clinical_cases .title {
    color: $color-black;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}

.la_academia_container .form-selectors-container {
    align-items: stretch;
}

.la_academia_container .form-selectors-container .col-1,
.la_academia_container .form-selectors-container .col-2 {
    width: 48%;
    margin-top: 24px;
    border: 1px solid $color-37;
    background-color: $color-white;
}

.la_academia_container .flex {
    display: flex;
    justify-content: space-between;
}

.la_academia_container .d-none {
    display: none;
}

.la_academia_container .text-right {
    text-align: right;
}

.la_academia_container .select-form {
    background-color: $color-white;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .05);
}

.la_academia_container .search-field,
.la_academia_container .search-field input.react-input {
    position: relative;
    width: 98.7%;
}

.la_academia_container #btn-advsearch {
    background: url(/static/img/advsearch_icon.png) right center / 18px 18px no-repeat;
    border: none;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 12px;
    right: 24px;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.la_academia_container .select-form form {
    padding: 90px 100px 46.5px 90px;
    margin-top: 22px;
}

.la_academia_container .btn-submission {
    margin: 32.5px 0 0;
}

.la_academia_container .btn-submission a {
    display: inline-block;
    text-transform: uppercase;
    padding: 12px 50px;
    border: 1px solid $color-39;
    border-radius: 24px;
    text-decoration: none;
    color: $color-39;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 24px;
}

.la_academia_container .btn-submission a:hover {
    color: $color-white;
    background-color: $color-39;
}

.la_academia_container .react-input {
    margin: 0;
    border: 1px solid $color-37;
    background-color: $color-white;
    width: 100%;
    outline: none;
    padding: 0;
    height: 48px;
    padding-left: 24px;
}

.la_academia_container .react-input::placeholder {
    color: $color-black;
}

.la_academia_container .clinical_cases {
    border-top: 1px solid $color-13;
    padding-top: 75px;
}

.la_academia_container .cases_type {
    margin-left: 4rem;
}

.la_academia_container .cases_type ul {
    padding: 0;
    margin: 0;
}

.la_academia_container .cases_type ul li {
    list-style: none;
    display: inline-block;
    margin-right: 16px;
}

.la_academia_container .cases_type ul li:last-child {
    margin-right: 0;
}

.la_academia_container .cases_type ul li a {
    display: inline-block;
    text-decoration: none;
    border-radius: 24px;
    padding: 9px 16px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 14px;
    text-align: center;
}

.la_academia_container .cases_type .case_all {
    border: 1px solid $color-39;
    color: $color-39;
}

.la_academia_container .cases_type .case_all:hover {
    background-color: $color-39;
    color: $color-white;
}

.la_academia_container .cases_type .case_lenalidomide {
    border: 1px solid $color-37;
    color: $color-37;
}

.la_academia_container .cases_type .case_lenalidomide:hover {
    background-color: $color-37;
    color: $color-white;
}

.la_academia_container .cases_type .case_relapse {
    border: 1px solid $color-39;
    color: $color-39;
}

.la_academia_container .cases_type .case_relapse:hover {
    background-color: $color-39;
    color: $color-white;
}

.la_academia_container .cases_type .case_aggressive_disease {
    border: 1px solid $color-38;
    color: $color-38;
}

.la_academia_container .cases_type .case_aggressive_disease:hover {
    background-color: $color-38;
    color: $color-white;
}

.la_academia_container .cases_listing {
    margin-top: 40px;
}

.la_academia_container .cases_listing ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.la_academia_container .cases_listing ul li {
    display: inline-block;
    width: 260px;
    text-align: center;
    margin-bottom: 34px;
}

.la_academia_container .cases_listing ul:not([class]) li::before,
.la_academia_container .cases_type ul:not([class]) li::before {
    display: none;
}

.la_academia_container .detail_case {
    color: $color-white;
    padding: 31px;
}

.la_academia_container .detail_case img {
    height: 43px;
    width: 36px;
    margin: 0 auto 15px;
}

.la_academia_container .detail_case .case_heading {
    font-family: Exo;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
}

.la_academia_container .detail_case .case_content {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 13px auto 28px;
}

.la_academia_container .detail_case a.case_view_btn {
    display: inline-block;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 24px;
    border-radius: 50px;
    border: 1px solid $color-white;
    padding: 12px 35px;
    text-decoration: none;
}

.la_academia_container .relapse_blue {
    background-color: $color-39;
}

.la_academia_container .lenalidomide_red {
    background-color: $color-37;
}

.la_academia_container .aggressive_disease_orange {
    background-color: $color-38;
}

.la_academia_container .relapse_blue .case_view_btn:hover,
.la_academia_container .lenalidomide_red .case_view_btn:hover,
.la_academia_container .aggressive_disease_orange .case_view_btn:hover {
    background-color: $color-white;
}

.la_academia_container .relapse_blue .case_view_btn:hover {
    color: $color-39;
}

.la_academia_container .lenalidomide_red .case_view_btn:hover {
    color: $color-37;
}

.la_academia_container .aggressive_disease_orange .case_view_btn:hover {
    color: $color-38;
}

.la_academia_container .search_result {
    margin-top: 40px;
    justify-content: space-between;
}

.la_academia_container .results_found .searches_found,
.la_academia_container .results_found .delete_search {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}

.la_academia_container .searches_found {
    color: $color-6;
}

.la_academia_container .results_found .delete_search {
    color: $color-black;
    padding-left: 24px;
    position: relative;
}

.la_academia_container .noresult_container {
    color: $color-black;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: 24px;
}

.la_academia_container .noresult_container a {
    color: $color-37;
}

.la_academia_container .input-two,
.la_academia_container .input-one {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 24px;
}

.la_academia_container .input-two span,
.la_academia_container .input-one span {
    display: inline-block;
    font-size: 14px;
    letter-spacing: .58px;
    line-height: 18px;
    font-family: "IBMPlexRg";
}

.la_academia_container .select-list {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 9;
    width: 100%;
}

.la_academia_container .select-list ul {
    overflow-y: scroll;
    margin: 0;
    padding: 0 14px;
    list-style-type: none;
    border: 1px solid $color-37;
    background-color: $color-28;
    border-top: none;
}

.la_academia_container .select-list ul li {
    padding: .75rem .625rem;
    cursor: pointer;
}

.la_academia_container .select-list ul li:hover {
    background-color: $color-19;
}

.la_academia_container .select-list.active {
    display: block;
}

.la_academia_container .input-one::after,
.la_academia_container .input-two::after {
    content: "";
    border: solid $color-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 24px;
    top: 17px;
}

.la_academia_container .input-one span.add-on {
    position: absolute;
    font-family: IBMPlexMd;
    line-height: 3px;
    letter-spacing: .42px;
    color: $color-37;
    font-size: 10px;
    z-index: 1;
    top: 5px;
    margin: 2px 0;
    left: 24px;
    background: 0 0;
}

.la_academia_container .input-two span.add-on {
    position: absolute;
    font-family: IBMPlexMd;
    line-height: 3px;
    letter-spacing: .42px;
    color: $color-37;
    font-size: 10px;
    z-index: 1;
    top: 5px;
    margin: 2px 0;
    left: 24px;
    background: 0 0;
}

.react-input:focus ~ span.add-on,
.react-input:valid ~ span.add-on {
    z-index: 1;
    display: block;
}

input:placeholder-shown.react-input ~ .add-on {
    display: none;
}

span.add-on {
    display: none;
    position: absolute;
    font-family: IBMPlexMd;
    line-height: 3px;
    letter-spacing: .42px;
    color: $color-37;
    font-size: 10px;
    z-index: 9;
    top: 5px;
    margin: 2px 0;
    left: 24px;
    background: 0 0;
}

.delete_search::before {
    background: url(/static/img/Close-black.png) right center / 18px 18px no-repeat;
    border: none;
    height: 11px;
    width: 17px;
    position: absolute;
    top: 13px;
    padding: 0;
    cursor: pointer;
    outline: none;
    content: '';
    left: 0;
}
