$honestybox-color-background: $color-4 !default;

.honestybox_drop {
    width: 50%;
    text-align: center;
    margin: auto;
    background: $color-white !important;
}

#hbxmsg {
    display: none;
    color: $color-1;
    font-size: .85rem;
    position: absolute;
    text-align: center;
    width: 100%;
}

.honestybox_drop h1 {
    color: $color-12;
}

.honestybox_drop select {
    width: auto;
    padding: .5rem 3rem .5rem 2rem;
    margin-bottom: 1rem;
    background: $color-white url(../img/bg-dropdown-honesty.svg) no-repeat 100% 50%;
    border: 1px solid $color-4;
    color: $color-6;
    line-height: 1.5;
}

.honestybox_drop select > option {
    margin-bottom: 1rem;
}

.honestybtn {
    width: auto;
    background-color: $color-12;
    border-radius: 8px;
    font-size: 18px;
    color: $color-white;
    padding: .75rem 3rem;
    border: none;
    cursor: pointer;
    opacity: .9;
    text-align: center;
    margin: 2rem auto;
    text-decoration: none;
}

.layout-row--honestybox {
    padding-top: rem($layout-element-margin-bottom-mobile);
}

.honestybox {
    margin-bottom: rem($layout-element-margin-bottom-mobile);
    background: $honestybox-color-background;
    padding: 20px;

    &_heading {
        margin-top: 0;
    }
}

@media (max-width: $layout-breakpoint-tablet) {
    .honestybox_drop {
        width: 100%;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .honestybox {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;

        .btn {
            margin-top: auto;
        }
    }

    .layout-row--honestybox {
        display: flex;
        padding: rem($layout-element-margin-bottom-tablet) 0;
    }

    .layout-column--honestybox {
        width: layout-column-width(4);
    }
}
