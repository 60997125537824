.teaser_content {
    margin-top: 50px;
}

.cont_teaser {
    margin: 0 15px;
}

.cont_teaser_wrap .cont_teaser_figure {
    cursor: pointer;
    width: 100%;
    background: $color-4;
    border-radius: 8px;
    z-index: 1;
}

.cont_teaser_figure {
    margin: 0 0 20px;
}

.cont_teaser_figure .teaser_image {
    max-width: 100%;
    margin: 0;
    display: inherit;
    border-radius: 12px;
}

.cont_teaser_wrap .play_icon img {
    margin-top: -48px;
}

.cont_teaser_wrap .play_icon {
    position: relative;
    text-align: center;
    width: max-content;
    margin: 0 auto;
}

.cont_teaser_wrap .play_icon:hover {
    cursor: pointer;
}

.cont_teaser_wrap .title_class {
    margin-top: 8px;
    text-align: center;
    position: relative;
}

.cont_teaser_wrap .title_class span {
    color: $color-white;
    cursor: default;
    pointer-events: none;
    padding: 10px;
    background-color: $color-12;
}

.cont_teaser_wrap h3 {
    color: $color-12;
    text-align: center;
}

.cont_teaser_wrap p {
    color: $color-5;
    text-align: center;
}

.cont_teaser_wrap .teaser_button {
    width: 100%;
    color: $color-12;
    padding: 25px 0;
    font-size: 18px;
    margin: 5px 0 15px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    border: 2px solid $color-12;
    background: transparent;
}

button:focus,
button:hover {
    outline: 0;
    cursor: pointer;
}

#cont_sl_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, .6);
    padding: 0 10px;
    opacity: 1;
    transition: opacity .15s, top 0;
    display: none;
}

.con_sld_overlay_wrapper {
    position: absolute;
    background: $color-white;
    width: 800px;
    min-height: 200px;
    padding: 15px;
    display: none;
    z-index: 1001;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

@media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .cont_teaser_wrap .play_icon {
        width: 15%;
    }
}
