.Cross-Brand-material-page-container {
    padding-bottom: 50px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.Cross-Brand-material-page-container-bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
}

.Cross-Brand-material-page-container .material-logo {
    text-align: right;
    margin-top: 60px;
}

.Cross-Brand-material-page-container .material-logo img {
    max-width: 495px;
    width: 100%;
}

.Cross-Brand-material-page-container .vectibix-bg img {
    width: auto;
    display: none;
}

.Cross-Brand-material-page-container .material-outer-container {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 80px;
}

.Cross-Brand-material-page-container .material-img {
    background-color: $color-white;
    padding-top: 20px;
    padding-bottom: 15px;
    border-radius: 12px 12px 0 0;
}

.Cross-Brand-material-page-container .material-img img {
    width: 100%;
}

.Cross-Brand-material-page-container .material-outer-container {
    display: flex;
    flex-wrap: wrap;
    color: $color-white;
}

.Cross-Brand-material-page-container .material-card {
    width: 31.1%;
    margin-bottom: 40px;
    cursor: pointer;
    border: 1px solid #d7ab3f;
    border-radius: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.vect-material-card {
    background-color: rgba(215, 171, 63, .8);
}

.Cross-Brand-material-page-container .material-text-area {
    display: flex;
    align-items: center;
    padding: 20px 20px 30px;
    border-radius: 0 0 11px 11px;
}

.Cross-Brand-material-page-container .material-text .material-title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
}

.Cross-Brand-material-page-container .material-date {
    font-size: 18px;
    font-weight: 600;
}

.Cross-Brand-material-page-container .material-text {
    width: 75%;
}

.Cross-Brand-material-page-container .material-text .p {
    font-size: 16px;
    line-height: 23px;
}

.Cross-Brand-material-page-container .material-play-btn {
    align-self: center;
    width: 25%;
}

.Cross-Brand-material-page-container .material-pagination {
    text-align: center;
}

.Cross-Brand-material-page-container .material-page {
    display: inline-block;
    cursor: pointer;
}

.Cross-Brand-material-page-container .material-page a {
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    border-radius: 20px;
    background: $color-55;
}

.Cross-Brand-material-page-container .material-page.vec-page-active a {
    background-color: $color-54;
}

.Cross-Brand-material-page-container .vect-popup-video {
    display: none;
}

.vect-material-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, .8);
    box-sizing: border-box;
    padding: 10px;
    z-index: 100;
    display: none;
}

.vect-material-video-popup {
    width: 50%;
    height: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    border: 3px solid $color-white;
}

.vect-material-close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    color: $color-white;
    font-size: 40px;
    cursor: pointer;
}

.vect-material-close-btn:hover {
    color: $color-4;
}

@media (max-width: 1024px) {
    .Cross-Brand-material-page-container .material-logo img {
        max-width: 340px;
    }
}

@media (max-width: 768px) {
    .Cross-Brand-material-page-container .material-card {
        width: 45.75%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .vect-material-video-popup {
        width: 80%;
    }
}

@media (max-width: 700px) {
    .Cross-Brand-material-page-container .material-outer-container {
        flex-direction: column;
    }

    .Cross-Brand-material-page-container .material-card {
        width: 90%;
        align-self: center;
    }

    .Cross-Brand-material-page-container .material-logo img {
        max-width: 220px;
    }

    .Cross-Brand-material-page-container .material-logo {
        margin-top: 30px;
    }

    .Cross-Brand-material-page-container .material-outer-container {
        margin-top: 50px;
    }
}
