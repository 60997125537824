label.error-message {
    font-family: 'IBMPlexMd';
    color: $color-25;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: .5rem;
}

.col-1,
.col-2 {
    width: 48%;
}

input:checked ~ .checkmark-lotNum {
    border: 1px solid $color-22;
}

.container .checkmark-lotNum::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: 1px solid $color-22;
    border-width: 0 1.2px 1.2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

input:checked ~ .checkmark-lotNum::after {
    display: block;
}

input#calendar {
    padding: 16px;
}

.data-input {
    padding: 14px;
}

.select-box1 {
    position: absolute;
    z-index: 9;
    width: 20rem;
    right: 0;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
    background: $color-white;
    display: none;
}

.apply-btn input {
    cursor: pointer;
    float: right;
    -webkit-appearance: none;
    background-color: $color-white;
    box-shadow: none;
    color: $color-22;
    border: none;
    padding: .5rem 1rem;
    font-size: 14px;
    font-weight: 700;
}

.apply-btn input {
    text-align: right;
    font-family: 'IBMPlexRg';
}

.farmacovigilancia .title {
    margin-bottom: 2.5rem;
}

.pharma-vigilence-form {
    border: 1px solid $color-7;
    padding: 3.5rem 6.25rem;
    margin-bottom: 2rem;
}

.pv-title-section {
    display: flex;
    justify-content: space-between;
}

.pv-info-title {
    color: $color-black;
    font-family: "ExoSemiBd";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 1.5rem;
}

.obligatory-txt {
    color: $color-black;
    font-family: "IBMPlexMd";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: right;
}

.pharma-vigilence-info {
    margin-top: -1rem;
}

.pharma-vigilence-form .form-selectors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1.5rem;
}

.pharma-vigilence-form .form-section {
    width: 100%;
    border: 1px solid $color-4;
    background-color: $color-28;
    position: relative;
    margin-bottom: 1.5rem;
}

.pharma-vigilence-form .form-section .select-dd-list {
    display: none;
    position: absolute;
    top: 47px;
    left: 0;
    z-index: 9;
    width: 100%;
}

.form-section.appearance-calendar,
.form-section.bday-calendar {
    margin-bottom: 8px;
}

.pharma-vigilence-form .form-section .select-dd-list ul {
    max-height: 100px;
    overflow-y: scroll;
    margin: 0;
    padding: .625rem;
    list-style-type: none;
    border: 1px solid $color-27;
    background-color: $color-28;
    border-top: none;
}

.dont-know-lot.last-check {
    margin-top: 1rem;
}

.pharma-vigilence-form .form-section .select-dd-list ul li {
    position: relative;
    padding: .75rem .625rem;
    color: $color-20;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    font-family: 'IBMPlexMd';
}

.pharma-vigilence-form .form-section .select-dd-list ul li:hover {
    background-color: $color-19;
}

.data-input.input-one::after {
    content: "";
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    position: absolute;
    right: 25px;
    top: 17px;
}

.pharma-placehold,
.pharma-vigilence-form .form-section input::placeholder,
.form-input-section input::placeholder,
textarea[name="case-description"] {
    color: $color-20;
    font-family: "IBMPlexSemiBd";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 15px;
}

.pharma-vigilence-form .bday-calendar::after,
.pharma-vigilence-form .appearance-calendar::after {
    content: "";
    background: url('Calendar.png') right center/16px 16px no-repeat;
    position: absolute;
    right: 20px;
    top: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.form-input-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.form-input-section input {
    width: 100%;
    max-width: 445px;
    padding: 16px;
    border: 1px solid $color-4;
    background-color: $color-28;
    position: relative;
    margin-bottom: 8px;
    outline: none;
}

span.add-on {
    position: absolute;
    font-family: 'IBMPlexMd';
    line-height: 3px;
    letter-spacing: .42px;
    color: $color-24;
    font-size: 10px;
    z-index: 1000;
    top: 5px;
    margin: 2px 0;
    left: 7px;
    background: transparent;
}

input:placeholder-shown.addtodo ~ .add-on {
    display: none;
}

.dont-know-lot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.checkmark-lotNum {
    padding: 12px;
    border: 1px solid $color-27;
    background-color: $color-28;
    margin-right: 10px;
    position: absolute;
    top: -6px;
    left: 0;
}

label.lot-num-txt {
    position: relative;
    padding-left: 45px;
}

span.checkmark-lotNum::after {
    content: "";
    position: absolute;
    left: 9px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: 1px solid $color-22;
    border-width: 0 1.2px 1.2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}

.warning-icon {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    opacity: 0;
    position: absolute;
    top: 25%;
    right: 10px;
}

.case-desc-title {
    color: $color-black;
    font-family: "ExoSemiBd";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
}

textarea[name="case-description"] {
    padding: 16px 0 0 16px;
    height: 121px;
    border: 1px solid $color-27;
    background-color: $color-28;
    width: 100%;
    max-width: 980px;
    margin-top: 10px;
    resize: none;
}

.form-input-section#exp-reaction {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 23px;
}

.form-input-section#exp-reaction input {
    width: 100%;
    max-width: 964px;
}

.appearance-calendar {
    margin-top: 18px;
}

.bday-calendar input#calendar,
.appearance-calendar input#calendar {
    border: none;
    outline: none;
    background: none;
}

.pv-report-submission {
    text-align: right;
}

a.pv-report-btn {
    display: inline-block;
    text-transform: uppercase;
    padding: 12px 30px;
    border: 1px solid $color-22;
    border-radius: 50px;
    text-decoration: none;
    color: $color-22;
    font-size: .875rem;
    line-height: 24px;
    text-align: center;
    letter-spacing: .5px;
    font-weight: 600;
    font-family: "IBMPlexSemiBd";
}

.amgen-trata-informac .p {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 10px;
}

.no-recuerdo-la-fecha {
    color: $color-black;
    font-family: "IBMPlexMd";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 15px;
    padding-left: 6px;
}

.visit-amgen a img {
    margin-left: 8px;
    width: 15px;
    margin-bottom: 0;
    display: inline-block;
}

.adcModal-accept-user {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .8);
}

.adcModal-accept-user .adc-modal-content {
    margin: 0 auto;
    border: none;
    width: 100%;
    max-width: 640px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    padding: 1.5rem 2rem 0 2.5rem;
}

div#adcModal-Solicitar-visita-thankyou .adc-modal-content {
    height: 232px;
}

.adcModal-accept-user .closeAdc {
    color: $color-black;
    float: right;
    font-size: 2rem;
    font-family: ExoRg;
    font-weight: 400;
    cursor: pointer;
}

.adcModal-accept-user .calender-img {
    padding: 2rem 0 0;
}

.adcModal-accept-user .adc-modal-content .p {
    color: $color-black;
    font-family: 'ExoSemiBd';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-align: left;
    padding-bottom: .75rem;
}

.small-text {
    color: $color-black;
    font-family: IBMPlexRg;
    font-size: .875rem;
    letter-spacing: 0;
    line-height: 24px;
}

div#adcModal-Solicitar-visita-thankyou .adc-modal-content {
    height: 232px;
}

.adcModal-accept-user .calender-img img {
    max-width: 40px;
    height: 40px;
    margin-bottom: 8px;
}

.form-contact-container {
    display: flex;
    margin-top: 3rem;
}

.form-contact-container .drop-select-option.active {
    display: block;
}

.form-contact-container .form-contact {
    width: 68%;
}

.form-contact-container .contact-details {
    width: 30%;
    margin-left: 2rem;
}

.form-contact-container .drop-select-option ul li:hover {
    background-color: $color-19;
}

.form-contact-container .contact-details .title {
    font-family: 'ExoSemiBd';
    font-size: 16px;
    letter-spacing: .13px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.form-contact-container .form-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.form-contact-container .contact-details .details {
    color: $color-black;
    font-family: 'IBMPlexRg';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.form-contact-container .input-one::after {
    text-transform: uppercase;
    content: "";
    margin-left: 1rem;
    border: solid $color-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
}

.form-contact-container .drop-select-option {
    display: none;
    position: absolute;
    width: 394px;
}

.form-contact-container .drop-select-option ul {
    max-height: 100px;
    overflow-y: scroll;
    margin: 0;
    padding: .625rem;
    list-style-type: none;
    border: 1px solid $color-27;
    background-color: $color-28;
    border-top: none;
}

.form-contact-container .drop-select-option ul li {
    position: relative;
    padding: .75rem .625rem;
    color: $color-20;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    font-family: 'IBMPlexMd';
}

.form-contact-container .form-field > div:last-child {
    margin-left: 0;
}

.form-contact-container .visita-textarea {
    margin-bottom: 16px;
}

.form-contact-container .visita-textarea textarea {
    color: $color-20;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 15px;
    margin-top: 24px;
    min-height: 121px;
    padding: 16px 0 0 16px;
    height: 121px;
    border: 1px solid $color-27;
    background-color: $color-28;
    width: 98%;
    resize: none;
}

.form-contact-container a.Solicitar-accept:hover {
    color: $color-white;
    border: 1px solid $color-22;
    background-color: $color-22;
}

.form-contact-container .form-contact-container .form-field > div {
    width: 50%;
    position: relative;
}

.form-contact-container .input-one {
    width: 100%;
    max-width: 360px;
    line-height: 14px;
    padding: 16px;
    position: relative;
    border: 1px solid $color-27;
    background-color: $color-28;
    color: $color-20;
    font-family: 'IBMPlexMd';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
}

.Solicitar-accept-container {
    padding-right: 16px;
    text-align: right;
}

.form-contact-container .form-field > div {
    width: 46.392%;
}

.form-contact-container .form-field > div:first-child {
    margin-right: 40px;
}

.form-contact-container .visit-amgen img {
    margin-left: 8px;
}

.form-contact-container .Solicitar-accept-container .Solicitar-accept {
    color: $color-22;
    font-family: 'IBMPlexSemiBd';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 24px;
    text-align: center;
    background: $color-white;
    padding: 12.5px 30.82px;
    display: inline-block;
    border: 1px solid $color-22;
    border-radius: 35px;
    text-decoration: none;
}

.form-contact-container .Solicitar-accept-container .Solicitar-accept:hover {
    color: $color-white;
    background-color: $color-22;
}

.visit-amgen a {
    text-decoration: none;
    color: $color-22;
}

.contacto-title-container {
    display: flex;
}

.visit-amgen {
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    text-align: right;
    width: 100%;
    font-family: 'IBMPlexMd';
    color: $color-22;
    font-size: 14px;
    text-transform: uppercase;
}

.contacto-container {
    margin: 3rem 0;
}

.contacto-box {
    border: 1px solid $color-7;
    padding: 64px 80px;
    display: flex;
}

.location {
    margin-right: 160px;
}

.location img {
    width: 15px;
    height: 20px;
}

.contacto-title {
    font-family: 'ExoSemiBd';
    font-size: 16px;
    letter-spacing: .13px;
    line-height: 30px;
    font-weight: 600;
}

.contacto-discription {
    font-size: 16px;
    letter-spacing: .13px;
    line-height: 30px;
    font-family: 'IBMPlexRg';
}

.customer-service {
    margin-right: 160px;
}

.customer-service img,
.service-info img {
    width: 25px;
    height: 25px;
}

.productos-container .producto-content {
    height: 160px;
    width: 260px;
    background-color: $color-10;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.producto-content img {
    width: 80%;
    height: auto;
    margin-bottom: 0;
    margin-left: 25px;
}

.productos-container .producto-box:hover .producto-content {
    background-color: $color-4;
}

.productos-container .producto-box:hover .title-producto {
    color: $color-22;
}

.productos-container .producto-box {
    float: left;
    margin-right: 2.5rem;
}

.productos-container .producto-box a {
    text-decoration: none;
}

.productos-container .producto-box-container {
    display: block;
    margin-top: 5rem;
}

.productos-container .title-producto {
    color: $color-black;
    font-family: 'ExoMd';
    letter-spacing: 0;
    line-height: 24px;
    font-size: 16px;
    position: relative;
    margin-top: 1rem;
    font-weight: 500;
}

.productos-container .title-producto img {
    position: absolute;
    right: 0;
    top: 8px;
    width: 15px;
}

.amgen-space-container .input-group .search-box form {
    width: 257px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid $color-24;
    height: 42px;
}

.amgen-space-container .input-group .search-box input.form-control {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-family: 'IBMPlexMd';
    outline: 0;
    border: none;
    width: 230px;
    font-size: 14px;
}

.input-group {
    display: flex;
    width: 100%;
}

.amgen-space-container .input-group .search-box {
    width: 22%;
    float: none;
    position: relative;
}

.amgen-space-container .input-group .search-box form button {
    top: 20px;
    right: 0;
    height: 20px;
    border: none;
    background: no-repeat;
    padding-right: 0;
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button;
}

.amgen-space-container .input-group .search-box form button img[alt="search-icon"] {
    width: 20px;
    height: 20px;
}

.amgen-space-container .input-group .event-select {
    width: 78%;
    float: none;
    display: flex;
    justify-content: flex-end;
}

.amgen-space-container div.custom-select {
    height: 40px;
    width: 140px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 15px;
}

.amgen-space-container div.custom-select {
    width: 117px;
}

.amgen-space-container div.custom-select.sm-wid {
    width: 170px;
}

.amgen-space-container div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
}

.amgen-space-container div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-family: IBMPlexRg;
    text-transform: uppercase;
}

.amgen-space-container div.selected > .text,
.amgen-space-container div.select-box ul.select-list {
    color: $color-black;
    font-family: 'IBMPlexMd';
}

div.selected > .text::after {
    top: 13px;
    text-transform: uppercase;
    content: '';
    margin-left: 1rem;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
}

div.selected i.arrow.down {
    display: none;
}

.amgen-space-container div.select-box {
    display: none;
    width: 100%;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
}

.amgen-space-container div.selected > .text,
.amgen-space-container div.select-box ul.select-list {
    color: $color-black;
    font-family: 'IBMPlexMd';
}

.amgen-space-container div.select-box ul.select-list {
    margin: 0;
    padding: 10px;
    list-style-type: none;
    max-height: 300px;
    overflow-y: scroll;
    background-color: $color-white;
}

.amgen-space-container div.select-box ul.select-list li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
}

.amgen-space-container div.select-box ul.select-list li {
    padding: 13px 10px;
    font-family: 'IBMPlexMd';
    white-space: normal;
    word-break: break-word;
}

.amgen-space-container div.select-box.active {
    display: block;
}

div.selected.selected-active > .text::after {
    transform: rotate(-135deg);
}

.especialidad-content .arrow img {
    width: 15px;
    height: 13px;
    position: absolute;
    bottom: 21.4px;
    right: 16px;
    margin-bottom: 0;
}

.especialidad-box-container,
.patologías-container,
.productos-container {
    display: block;
    margin-top: 56px;
}

.especialidad-content:hover img.on-hover {
    display: block;
}

.on-hover {
    display: none;
}

.especialidad-content:hover img.not-hover {
    display: none;
}

.especialidad-content:hover {
    background-color: $color-22;
    color: $color-white;
    box-shadow: 0 0 36px 0 rgba(0, 124, 207, .11);
    transform: scale(1.02);
    transition: .5s ease;
}

.especialidad-box {
    margin-right: 30px;
    float: left;
}

.content-tab {
    margin-top: 72px;
}

.especialidad-box-container a {
    text-decoration: none;
}

.especialidad-content {
    position: relative;
    color: $color-black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    border: 1px solid $color-22;
    height: 184px;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ExoSemiBd';
    margin-bottom: 40px;
    text-align: left;
}

.tab-main-especialidad .title,
.patologías .title,
.productos .title,
.farmacovigilancia .title,
.contacto .title {
    max-width: 544px;
    width: 100%;
    font-family: 'ExoSemiBd';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
}

.amgen-space-container {
    max-width: 1200px;
    margin: 0 auto;
}

.amgen-space-tab {
    display: flex;
    margin: 0 24px;
    border-bottom: 1px solid $color-4;
    justify-content: center;
    align-content: center;
}

.tab-space {
    text-transform: uppercase;
    margin-right: 64px;
    font-family: 'IBMPlexSemiBd';
    color: $color-15;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
    text-align: center;
    padding: 39px 0 24px;
    cursor: pointer;
}

.tab-space:last-child {
    margin-right: 0;
}

.tab-space.active-tab {
    border-bottom: 4px solid $color-22;
    color: $color-22;
}

.pharma-vigilence-form .form-section .select-dd-list.active {
    display: block;
}

.pignose-calendar .icon-arrow-left,
.pignose-calendar .icon-arrow-right {
    font-family: pignose-calendar-icon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.pignose-calendar .icon-arrow-left::before {
    content: '<';
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
}

.pignose-calendar .icon-arrow-right::before {
    content: '>';
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
}

.pignose-calendar-wrapper {
    display: none;
    position: fixed;
    width: 80%;
    max-width: 360px;
    top: 50%;
    left: 50%;
    border-radius: 2px;
    z-index: 50001;
    overflow: hidden;
    -webkit-box-shadow: 0 4px 16px $color-black;
    box-shadow: 0 4px 16px $color-black;
    -webkit-transform: translate3d(0, 160px, 0);
    transform: translate3d(0, 160px, 0);
    opacity: 0;
    -webkit-transition: opacity .3s ease, -webkit-transform .5s ease-out;
    transition: opacity .3s ease, -webkit-transform .5s ease-out;
    transition: opacity .3s ease, transform .5s ease-out;
    transition: opacity .3s ease, transform .5s ease-out, -webkit-transform .5s ease-out;
}

.pignose-calendar-wrapper.pignose-calendar-wrapper-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.pignose-calendar-wrapper .pignose-calendar {
    max-width: auto;
    width: 100%;
    border: none;
}

.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group {
    border-top: 1px solid $color-27;
    overflow: hidden;
}

.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button {
    width: 50%;
    display: block;
    float: left;
    height: 3.2em;
    text-align: center;
    line-height: 3.2em;
    color: $color-5;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button:hover {
    background-color: $color-4;
}

.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button-apply {
    color: $color-white;
    background-color: $color-14;
}

.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button-apply:hover {
    background-color: $color-black;
}

.pignose-calendar-wrapper-overlay {
    background-color: $color-black;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 50000;
}

.pignose-calendar-wrapper-overlay.pignose-calendar-wrapper-overlay-active {
    opacity: .7;
}

.pignose-calendar {
    width: 100%;
    max-width: 360px;
    background-color: $color-white;
    border: none;
    font-size: 50%;
    margin: 0 auto;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
}

.pignose-calendar .pignose-calendar-top {
    padding: 1.5em 0;
    background-color: $color-29;
    border-bottom: 1px solid $color-27;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .125);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .125);
    position: relative;
    overflow: hidden;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-date {
    position: absolute;
    top: 0;
    left: 37%;
    width: 100%;
    height: 100%;
    padding: .8em 0;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-flex;
    font-size: 1rem;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-year,
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-month {
    display: block;
    text-align: center;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-year {
    font-weight: 900;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-month {
    margin-bottom: .4em;
    font-weight: 600;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav {
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    position: relative;
    z-index: 5;
    text-decoration: none;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
    display: inline-block;
    color: $color-5;
    font-size: 115%;
    font-weight: 600;
    vertical-align: middle;
    margin-top: -10px;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
    color: $color-5;
    font-size: 160%;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-prev {
    float: left;
    margin-left: 1.6em;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-prev .pignose-calendar-top-value {
    margin-left: .2em;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-next {
    float: right;
    margin-right: 1.6em;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-next .pignose-calendar-top-value {
    margin-right: .2em;
}

.pignose-calendar .pignose-calendar-header {
    margin-top: 2.2em;
    font-weight: 600;
    overflow: hidden;
    font-size: 10px;
    color: $color-14;
}

.pignose-calendar .pignose-calendar-header .pignose-calendar-week {
    float: left;
    width: 14.28%;
    height: 2.8em;
    text-align: center;
    line-height: 2.8em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pignose-calendar .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sun,
.pignose-calendar .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sat {
    color: $color-14;
}

.pignose-calendar .pignose-calendar-header .pignose-calendar-week:last-child {
    width: 14.32%;
}

.pignose-calendar .pignose-calendar-body {
    padding: .6em;
    height: auto;
}

.pignose-calendar .pignose-calendar-body .pignose-calendar-row {
    overflow: hidden;
    height: 2.3rem;
    font-size: 1rem;
}

.pignose-calendar .pignose-calendar-unit {
    float: left;
    display: block;
    height: 2.3rem;
    width: 14.28%;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pignose-calendar .pignose-calendar-unit:last-child {
    width: 14.32%;
}

.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container {
    line-height: .5em;
}

.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container .pignose-calendar-button-schedule-pin {
    display: inline-block;
    background-color: $color-5;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    margin-right: .2em;
}

.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container .pignose-calendar-button-schedule-pin:last-child {
    margin-right: 0;
}

.pignose-calendar .pignose-calendar-unit a {
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    color: $color-black;
    line-height: 1.4em;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background-color .3s ease, color .3s ease;
    transition: background-color .3s ease, color .3s ease;
}

.pignose-calendar .pignose-calendar-unit a:active {
    background-color: $color-17;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-disabled a {
    opacity: .5;
    background-color: $color-4;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active a {
    background-color: $color-26;
    color: $color-white;
    font-weight: 600;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .5);
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active.pignose-calendar-unit-sun a,
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active.pignose-calendar-unit-sat a {
    color: $color-white;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range a {
    background-color: $color-4;
    border-radius: 0;
    width: 100%;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
    color: $color-6;
    background-color: $color-4;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-range-first a {
    border-top-left-radius: 1.2em;
    border-bottom-left-radius: 1.2em;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-range-last a {
    border-top-right-radius: 1.2em;
    border-bottom-right-radius: 1.2em;
}

.pignose-calendar.pignose-calendar-default .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-active a {
    color: $color-5 !important;
}

.pignose-calendar.pignose-calendar-default.pignose-calendar-reverse .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-inactive a {
    color: $color-5 !important;
}

.pignose-calendar.pignose-calendar-dark {
    border-color: $color-5;
    background-color: $color-15;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-top {
    background-color: $color-5;
    border-bottom-color: $color-5;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .175);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .175);
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-month {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-year {
    color: $color-6;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
    color: $color-14;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
    color: $color-14;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-header .pignose-calendar-week {
    color: $color-6;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-header.pignose-calendar-week-sun,
.pignose-calendar.pignose-calendar-dark .pignose-calendar-header.pignose-calendar-week-sat {
    color: $color-19;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit a {
    color: $color-15;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sun a,
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sat a {
    color: $color-19;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-disabled a {
    color: $color-18;
    background-color: $color-12;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
    color: $color-white;
    background-color: $color-23;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .75);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .75);
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle a {
    color: $color-12;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range a {
    background-color: $color-18;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
    color: $color-12;
    background-color: $color-16;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group {
    border-top: 1px solid $color-5;
    overflow: hidden;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button:hover {
    background-color: $color-18;
}

.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button-apply {
    color: $color-white;
    background-color: $color-12;
}

.pignose-calendar.pignose-calendar-blue {
    background-color: $color-29;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-top {
    background-color: $color-26;
    border-bottom-color: $color-4;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-month {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-year {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
    color: $color-white;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week {
    color: $color-12;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sun,
.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sat {
    color: $color-29;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit a {
    color: $color-12;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sun a,
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sat a {
    color: $color-29;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-disabled a {
    background-color: $color-4;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
    color: $color-white;
    background-color: $color-26;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .75);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .75);
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle a {
    color: $color-5;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range a {
    background-color: $color-4;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
    background-color: $color-4;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-date {
    position: initial;
    left: initial;
}

.pignose-calendar {
    width: 100%;
    max-width: 360px;
    background-color: $color-white;
    border: 1px solid $color-17;
    font-size: 50%;
    margin: 0 auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: capitalize;
    border-bottom: 1px solid $color-17;
}

.pignose-calendar .pignose-calendar-top {
    display: flex;
    padding: 1.5em 0;
    border-bottom: 1px solid rgba(223, 227, 233, .5);
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    background-color: $color-white;
}

.pignose-calendar .pignose-calendar-top .pignose-calendar-top-date {
    position: static;
    top: 0;
    left: initial;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    font-size: 1rem;
}

.pignose-calendar .pignose-calendar-header {
    margin-top: .5em;
    overflow: hidden;
    color: $color-18;
    font-size: 11px;
    padding: 0 1rem;
    font-weight: 500;
}

.pignose-calendar .pignose-calendar-body {
    padding: .6em 1rem;
    height: auto;
}

.pignose-calendar .pignose-calendar-unit a {
    color: $color-18;
    font-size: 15.4px;
    font-weight: 500;
}

.pignose-calendar-unit.pignose-calendar-unit-date.pignose-calendar-unit-sat a {
    color: $color-24;
    font-family: "IBM";
    font-size: 15.4px;
    font-weight: 500;
}

.pignose-calendar-unit.pignose-calendar-unit-date.pignose-calendar-unit-sun a {
    color: $color-24;
    font-family: "IBM Plex Sans";
    font-size: 15.4px;
    font-weight: 500;
}

.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active a {
    background-color: $color-22;
    color: $color-white;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-top: .25rem;
    width: 2em;
    height: 1.75em;
}

#adcModal-Solicitar-visita-success .adc-modal-content {
    height: 290px;
}

@media only screen and (min-width: 730px) {
    .mobile-title {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .form-contact-container .input-one {
        max-width: -webkit-fill-available;
    }

    .location,
    .customer-service {
        margin-right: 63px;
    }
}

@media only screen and (max-width: 768px) {
    .tab-space {
        margin-right: 45px;
    }
}

@media only screen and (max-width: 730px) {
    .mobile-title {
        font-family: "IBMPlexSemiBd";
        text-transform: uppercase;
        color: $color-black;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: .5px;
        line-height: 22px;
        text-align: center;
        padding: 25.83px 0 19px;
        cursor: pointer;
        position: relative;
        width: 215px;
        margin: 0 auto;
    }

    .location,
    .customer-service,
    .service-info {
        margin-right: 0;
        margin-top: 2rem;
    }

    .contacto-box {
        border: none;
        border-bottom: 1px solid $color-7;
        padding: 0 0 2rem;
    }

    .form-input-section input,
    .form-input-section#exp-reaction input,
    textarea[name="case-description"] {
        max-width: -webkit-fill-available;
    }

    .especialidad-content,
    .productos-container .producto-content {
        width: 175px;
        height: 128px;
        text-align: center;
    }

    .amgen-space-container .input-group {
        display: block;
    }

    .amgen-space-container .search-box {
        margin-bottom: 1rem;
    }

    .amgen-space-container .input-group .search-box input.form-control,
    .amgen-space-container .input-group .search-box form,
    .amgen-space-container .input-group .search-box {
        width: 100%;
    }

    .amgen-space-container div.selected {
        justify-content: flex-start;
    }

    .amgen-space-container .input-group .event-select {
        width: 100%;
        justify-content: flex-start;
    }

    .pharma-vigilence-form {
        border: none;
        padding: 3.5rem 0;
    }

    .pharma-vigilence-form .form-selectors {
        display: block;
    }

    .col-1,
    .col-2 {
        width: 100%;
    }

    .contacto-box {
        display: block;
    }

    .form-contact-container .form-contact,
    .form-contact-container .contact-details {
        width: 100%;
    }

    .form-contact-container {
        display: block;
    }

    .tab-space {
        margin-right: 0;
    }

    .amgen-space-tab {
        display: block;
    }

    .tab-space .active-tab {
        border-bottom: none;
        color: $color-black;
        background-color: $color-19;
    }

    .amgen-space-tab {
        display: none;
        position: absolute;
        width: 400px;
        border: 1px solid $color-4;
        background-color: $color-white;
        box-shadow: 0 14px 14px 0 rgba(0, 93, 155, .06);
        z-index: 9;
        left: 45%;
        right: 50%;
        transform: translateX(-50%);
    }

    .tab-space {
        text-transform: capitalize;
        color: $color-black;
        text-align: left;
        padding: 16px 0 15px 8px;
    }

    .mobile-title span.arrow {
        border: solid $color-22;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        margin: 4px;
        color: $color-22;
        font-size: 14px;
    }

    .container-tab {
        position: relative;
    }

    .mobile-title.active span.arrow {
        transform: rotate(225deg);
        top: 34px;
        margin: 0 4px;
    }
}
