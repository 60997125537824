$pagination-icon-prev: arrow-left-gray-repatha !default;
$pagination-icon-next: arrow-right-gray-repatha !default;

.pagination {
    @include blank-list;
    display: flex;
    flex-wrap: wrap;

    &_item {
        margin-bottom: .5em;

        &:not(:last-child) {
            margin-right: .5em;
        }
    }

    &_link {
        &--current {
            cursor: default;
            pointer-events: none;
        }
    }
}

.pagination_btn--prev {
    &::before {
        @include icon-props($pagination-icon-prev);
    }
}

.pagination_btn--next {
    &::before {
        @include icon-props($pagination-icon-next);
    }
}

@media screen and (min-width: $layout-breakpoint-tablet) {
    .pagination {
        justify-content: center;
    }
}
