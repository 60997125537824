.sharerow form.formshare {
    width: 100%;
}

.sharerow .form-fields {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.sharerow .form-fields label {
    width: 20%;
    margin-bottom: 1rem;
    line-height: 3;
}

.sharerow input[type="text"] {
    height: 20px;
    width: 100%;
}

.sharerow button {
    display: block;
    margin: 0 auto;
    background-color: $color-black;
    color: $color-white;
    border: none;
    padding: .5rem 1rem;
    cursor: pointer;
}

#sharefun .share-btn {
    background: no-repeat;
    border: none;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    padding-left: 2.25rem;
    box-sizing: border-box;
    font-size: 1.1rem;
    cursor: pointer;
}

#sharefun .share-btn::before {
    position: absolute;
    display: block;
    content: '';
    background: url(/static/img/share-icon.png) no-repeat;
    width: 1.5rem;
    height: 1.3rem;
    background-size: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: .5rem;
}

.sharerow {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.sharecol1 {
    width: 60%;
    padding: 10px;
}

.sharecol2 {
    width: 40%;
}

#sharefun a {
    text-decoration: none;
    color: inherit;
}
