.toggle_txt_cont {
    position: relative;
    margin-bottom: 5rem;
}

.toggle_txt_cont a {
    position: absolute;
    text-decoration: none;
    bottom: -2.5rem;
    left: 48%;
}

.txt_toggle {
    display: none;
}
