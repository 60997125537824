.main_content {
    padding: 20px;
    width: 80%;
    margin: 0 auto;
}

.search_bar {
    background-color: $color-9;
    text-align: center;
    padding: 15px;
}

.search_bar input {
    border: none;
    padding: 5px;
    width: 47%;
    min-height: 30px;
}

.maps iframe {
    width: 100%;
    height: 25rem;
}

.search_bar button {
    background-color: $color-black;
    color: $color-white;
    border: none;
    padding: 4px;
    width: 23%;
}

#address_cont {
    width: 100%;
    height: 15rem;
    overflow-y: scroll;
}

p.bold_p {
    font-weight: 700;
    margin-bottom: 1rem;
}

.grey_txt {
    color: $color-6;
}

.content_1 {
    background-color: $color-10;
    padding: 10px;
}

.content_1 p + p {
    margin: -1em 0 0;
}

.content_1 h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

div.address_div > div:nth-of-type(odd) {
    background-color: $color-11;
}

#mapinfo p {
    font-size: .9rem;
    line-height: 1.3;
}

@media (max-width: 768px) {
    .main_content {
        padding: 0;
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .main_content {
        padding: 20px;
        width: 80%;
        margin: 0 auto;
    }
}

.maps-marker-pro {
    position: relative;
    box-sizing: border-box;
    border: 1px solid $color-26;
    color: $color-33;
}

.maps-marker-pro .mmp-list {
    font-size: 13px;
    line-height: .8;
}

.maps-marker-pro .mmp-list-header {
    display: flex;
    flex-wrap: wrap;
    padding: 11px 14px 10px 16px;
    border-bottom: 1px solid $color-26;
}

.maps-marker-pro .mmp-list-header .mmp-list-header-left {
    margin-right: 2px;
}

.maps-marker-pro .mmp-list-header .mmp-list-header-left,
.maps-marker-pro .mmp-list-header .mmp-list-header-right {
    display: flex;
    flex: auto;
}

.maps-marker-pro .mmp-list-header .mmp-list-search-wrap {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.mmp-list-sort {
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 6px;
    font-size: 13px;
    background-color: $color-11;
    border: 1px solid $color-19;
    border-radius: 3px;
}

.maps-marker-pro .mmp-list-header .mmp-list-search {
    padding: 0 25px;
    width: 100%;
    height: 30px;
    font-size: 18px;
    border: 1px solid $color-19;
    border-radius: 3px;
}

select.mmp-list-sort {
    color: $color-30;
}

.maps-marker-pro .mmp-list-header .mmp-list-header-right {
    margin-left: 2px;
    justify-content: flex-end;
}

.maps-marker-pro .mmp-list-header .mmp-list-order.mmp-asc {
    background-image: url(image/arrow-up.png);
}

.maps-marker-pro .mmp-list-header .mmp-list-order {
    flex: none;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background: 50% no-repeat;
}

.maps-marker-pro a,
.maps-marker-pro a:active,
.maps-marker-pro a:focus,
.maps-marker-pro a:hover,
.maps-marker-pro a:visited {
    text-decoration: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.maps-marker-pro .mmp-list-body {
    flex: 1 1 0;
    overflow-y: auto;
}

.maps-marker-pro .mmp-list-row {
    display: flex;
    padding: 11px 5px 0;
    border-bottom: 1px solid $color-26;
}

.maps-marker-pro .mmp-list-row-icon {
    flex: none;
}

.maps-marker-pro .mmp-list-icon {
    margin: 0 10px 0 7px;
    line-height: 0;
}

.maps-marker-pro .mmp-list-row-info {
    flex: auto;
    padding-top: 2px;
}

.maps-marker-pro .mmp-list-row-header {
    display: flex;
    margin-bottom: 4px;
}

.maps-marker-pro .mmp-list-name {
    flex: auto;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.mmp-list-icon img {
    width: 21px;
    height: 36px;
}

.maps-marker-pro .mmp-list-api {
    margin-left: auto;
    text-align: right;
}

.maps-marker-pro .mmp-list-api a,
.maps-marker-pro .mmp-panel-api a {
    display: inline-block;
    margin: 0 0 0 5px;
    cursor: pointer;
}

.maps-marker-pro .mmp-list-api img,
.maps-marker-pro .mmp-panel-api img {
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

.maps-marker-pro .mmp-list-row-footer {
    margin-top: 4px;
}

.maps-marker-pro .mmp-list-row:last-child {
    border-bottom: none;
}

.maps-marker-pro .mmp-list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 14px;
    border-top: 1px solid $color-26;
}

.maps-marker-pro .mmp-list-nav button {
    width: 28px;
    height: 28px;
    margin: 0 2px;
    padding: 0 8px;
    color: $color-black;
    font-size: 16px;
    background-color: $color-11;
    border: 1px solid $color-19;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.maps-marker-pro .mmp-list-page input {
    width: 30px;
    height: 24px;
    padding: 3px 5px;
    text-align: center;
    font-size: 12px;
    border: 1px solid $color-19;
}

.mmp-list-page [type="text"] {
    display: inline;
}
