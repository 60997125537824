#map {
    height: 100%;
}

.map-functionality {
    height: 100%;
}

.main-parent {
    display: flex;
    width: 100%;
}

.search-box-wrap {
    width: 300px;
    padding: 20px;
}

.map-wrap {
    width: calc(100% - 300px);
    height: 100vh;
}

.map-wrap #map {
    height: 100%;
}

span.search-icon {
    width: 2.8rem;
    background: $color-7 url('/static/img/search-icon.png');
    display: inline-flex;
    height: 2.9rem;
    position: absolute;
    border: 0;
    outline: none;
    background-size: 1.4rem;
    background-repeat: no-repeat;
    background-position: 12px 12px;
    cursor: pointer;
}

input.seach-input {
    display: inline-flex;
    width: calc(100% - 2.8rem);
    height: 2.8rem;
    padding: 10px 14px;
    border: 0;
    outline: none;
}

.search-box {
    border: 1px solid $color-7;
    margin-bottom: 2rem;
    border-radius: 5px;
    overflow: hidden;
}

.pagination-wrp {
    bottom: 40px;
}

.pagination-wrp .pagination {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}

.pagination-wrp .pagination li {
    display: inline-block;
}

.pagination-wrp .pagination li a {
    padding: 10px 13px;
    border: 1px solid $color-6;
    color: $color-5;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.1rem;
}

.pagination-wrp .pagination li.active a,
.pagination-wrp .pagination li a:hover,
.pagination-wrp .pagination li.active a:hover {
    background: $color-valid;
    color: $color-white;
    border-color: $color-valid;
}
