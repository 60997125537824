.bg-mint {
    background-color: $color-8;
}

section.container-wrapper-area.bg-mint.error-page {
    text-align: center;
    padding-top: 7rem;
}

.main-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
}

.container-wrapper-area {
    padding: 2rem 0 4rem;
}

.p-white {
    color: $color-white;
    font-size: 1.25rem;
    margin-bottom: 2.1rem;
}

.atlas-broucher-video-container-wrapper {
    padding-bottom: 0;
}

.atlas-broucher-video-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.atlas-broucher-video-flex-col {
    width: 32%;
    background-color: $color-white;
    margin: 0 auto 2rem;
}

.atlas-broucher-video-flex-col a {
    text-decoration: none;
}

.atlas-broucher-video-heading-area {
    padding: 1rem;
    color: $color-22;
}

.atlas-broucher-video-para .p {
    font-size: 1rem;
    padding: 1rem;
}

.atlas-broucher-video-para {
    width: 100%;
}

h3.atlas-broucher-video-heading {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
}

h2.atlas-broucher-video-heading {
    font-size: 1.25rem;
    margin-bottom: 0;
    line-height: 1.5;
    margin-top: .5rem;
}

.atlas-broucher-video-hovered-heading-area h3 {
    font-size: 1rem;
}

.atlas-broucher-video-hovered-para {
    display: none;
    padding: 1rem;
}

.atlas-thumbnail-overlay {
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 1rem;
    z-index: 1;
}

.atlas-broucher-video-thumbnail-container {
    position: relative;
}

h4.atlas-thumbnail-overlay-heading {
    background-color: $color-8;
    color: $color-22;
    padding: 2px 4px 0;
}

.atlas-broucher-video-hovered {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
    background-color: rgba(0, 36, 58, .8);
    display: none;
}

.atlas-broucher-video-hovered-heading {
    color: $color-8;
    padding-left: 1rem;
}

.atlas-broucher-video-hovered-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: center;
}

.atlas-broucher-video-icon-area {
    width: 20%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.atlas-broucher-video-hovered-heading-area {
    width: 80%;
}

.atlas-broucher-video-thumbnail-area img {
    margin-bottom: 0;
    width: 100%;
}

.atlas-broucher-video-hovered span.book-icon {
    padding: 15px;
}

.atlas-broucher-video-hovered span.book-icon i {
    font-size: 1.3rem;
    color: $color-white;
}

.atlas-broucher-video-thumbnail-container:hover .atlas-broucher-video-hovered {
    display: block;
}

.atlas-broucher-video-hovered-para {
    font-size: .9rem;
}

.atlas-thumbnail-overlay.atlas-thumbnail-overlay-two h4 {
    display: inline-block;
    margin-left: 1rem;
}

span.book-icon {
    border: 2px solid $color-white;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    .atlas-broucher-video-flex-col {
        width: 31.7%;
        margin: 0 auto 2rem;
    }
}

@media screen and (max-width: 768px) {
    .atlas-broucher-video-flex-col {
        width: 47.8%;
    }
}

@media screen and (max-width: 700px) {
    .atlas-broucher-video-flex-col {
        width: 100%;
    }
}

.container-wrapper-area {
    padding: 2rem 0 4rem;
}

.csmain-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
}

.series-icon {
    color: $color-white;
    display: inline-block;
    background-color: $color-40;
    padding: .5rem;
    font-size: 1.5rem;
    font-weight: bold;
}

h2.slider-title {
    text-align: left;
    margin: 1rem 0 2rem;
    color: $color-40;
}

.csbroucher-slider.owl-carousel .owl-next,
.csbroucher-slider.owl-carousel .owl-prev {
    position: absolute;
    top: 30px;
    width: 40px;
    height: 40px;
}

.csbroucher-heading {
    margin: 1rem 0 .5rem;
    color: $color-40;
    font-weight: bold;
    font-size: 1.4rem;
}

.csbroucher-content .p {
    font-size: 1.4rem;
    color: $color-40;
    margin-bottom: 1.2rem;
}

.owl-carousel .owl-item .csbroucher-thumbnail img {
    width: 86%;
    margin: 0 auto;
}

.owl-carousel .owl-item .series-thumbnail img {
    width: 92%;
    margin: 0 auto;
}

.owl-carousel .owl-item.first-owl .csbroucher-thumbnail img {
    width: 100%;
}

.publications-slider.publications-slider-pink {
    margin-bottom: 2rem;
}

.csbroucher-slider.owl-carousel,
.publications-slider.owl-carousel,
.informe-slider.owl-carousel {
    position: relative;
}

.csbroucher-slider.owl-carousel .owl-dots,
.csbroucher-slider.owl-carousel .owl-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translateY(50%);
}

.csbroucher-slider.owl-carousel .owl-nav button.owl-next,
.csbroucher-slider.owl-carousel .owl-nav button.owl-prev,
.publications-slider.owl-carousel .owl-nav button.owl-next,
.publications-slider.owl-carousel .owl-nav button.owl-prev,
.informe-slider.owl-carousel .owl-nav button.owl-next,
.informe-slider.owl-carousel .owl-nav button.owl-prev {
    padding: 0 !important;
    background-color: $color-40 !important;
    font-size: 1.2rem;
    color: $color-white;
    display: block;
    outline: 0;
    border-radius: 0;
}

.csbroucher-slider.owl-carousel .owl-nav button.owl-next {
    right: 0;
}

.csbroucher-slider.owl-carousel .owl-nav button.owl-prev {
    left: 0;
}

.csbroucher-slider.owl-carousel .owl-nav button.owl-prev,
.publications-slider.owl-carousel .owl-nav button.owl-prev,
.informe-slider.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: 0;
}

.csbroucher-slider.owl-carousel .owl-nav button.owl-next.disabled,
.csbroucher-slider.owl-carousel .owl-nav button.owl-prev.disabled,
.publications-slider.owl-carousel .owl-nav button.owl-next.disabled,
.publications-slider.owl-carousel .owl-nav button.owl-prev.disabled,
.informe-slider.owl-carousel .owl-nav button.owl-next.disabled,
.informe-slider.owl-carousel .owl-nav button.owl-prev.disabled {
    display: none;
}

.informe-slider {
    margin-bottom: 2rem;
}

.csbroucher-thumbnail-container,
.publications-thumbnail-container,
.informe-thumbnail-container {
    position: relative;
    cursor: pointer;
}

.csbroucher-hovered,
.publications-hovered {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 36, 58, .8);
    color: $color-white;
    display: none;
}

.csbroucher-thumbnail-container:hover .csbroucher-hovered,
.publications-thumbnail-container:hover .publications-hovered {
    display: block;
}

.csbroucher-thumbnail-container:hover .csbroucher-hovered {
    width: 86%;
    margin: 0 auto;
}

.csbroucher-wrapper:hover .csbroucher-hovered {
    display: block;
    width: 86%;
    margin: 0 auto;
}

.csbroucher-wrapper a {
    text-decoration: none;
}

.csbroucher-hovered-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
}

span.csbook-icon {
    border: 2px solid $color-white;
    border-radius: 50%;
    padding: 8px;
}

span.csbook-icon i {
    font-size: 2rem;
}

.csbrouch-icon-area {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.csbrouch-hovered-heading {
    font-size: 1.2rem;
    padding-left: 1.5rem;
}

.csbrouch-episode-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
}

.csbrouch-snippet {
    background-color: $color-white;
    color: $color-40;
    font-weight: bold;
    padding: 2px;
}

.csbroucher-heading {
    margin: 1rem 0 .5rem;
    color: $color-40;
    font-weight: bold;
    font-size: 1.4rem;
}

.csbroucher-content {
    text-align: center;
}

.csbroucher-content .p {
    font-size: 1.4rem;
}

.publications-content {
    min-height: 10rem;
}

.owl-carousel .owl-item .publications-thumbnail img {
    margin: 0 auto;
}

.publications-slider.owl-carousel .owl-dots,
.publications-slider.owl-carousel .owl-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 0;
    top: 15%;
    transform: translateY(15%);
}

.publications-hovered-heading {
    color: $color-44;
    font-weight: bold;
    font-size: 1.2rem;
    width: 60%;
}

.publications-hovered-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.publications-icon-area {
    width: 20%;
    text-align: right;
}

.publications-icon-area span.csbook-icon i {
    font-size: 1rem;
}

.publications-icon-area span.csbook-icon {
    padding: 8px 3px;
}

.publications-episode-area {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.publications-episode-area .csbrouch-snippet {
    background-color: $color-41;
    color: $color-white;
}

.publications-wrapper {
    cursor: pointer;
}

.publications-tag {
    font-size: 1.2rem;
    margin: 1rem;
    font-weight: bold;
    color: $color-41;
}

.publications-sub-heading {
    margin: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-40;
}

.txt-grey {
    color: $color-5;
}

.publications-content-hovered {
    margin: 1rem;
    display: none;
}

.publications-wrapper:hover .publications-content {
    display: none;
}

.publications-wrapper:hover .publications-hovered,
.publications-wrapper:hover .publications-content-hovered {
    display: block;
    min-height: 9rem;
    margin: 0 auto;
}

.publications-slider.publications-slider-pink .publications-wrapper:hover .publications-content {
    display: none;
}

.publications-slider.publications-slider-pink .publications-wrapper:hover .publications-hovered,
.publications-slider.publications-slider-pink .publications-wrapper:hover .publications-content-hovered {
    display: block;
    width: 100%;
    min-height: 7.2rem;
    margin: auto;
}

.publications-slider.publications-slider-pink .owl-item .publications-thumbnail img {
    width: 100%;
}

.publications-content-hovered .p.txt-grey {
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0 2rem;
    min-height: 8.8rem;
}

.owl-carousel .owl-item .informe-thumbnail img {
    width: 86%;
    margin: 0 auto;
}

.informe-content {
    width: 86%;
    margin: 0 auto;
}

.informe-slider.owl-carousel .owl-dots,
.informe-slider.owl-carousel .owl-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 0;
    top: 15%;
    transform: translateY(15%);
}

.informe-thumbnail img {
    margin-bottom: 0;
}

.informe-heading {
    padding: 0 1.8rem;
    text-align: center;
    color: $color-white;
    font-size: 1.4rem;
    background-color: $color-40;
    line-height: 1.4;
    min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.informe-para {
    padding: 0 .5rem;
    background-color: $color-42;
    color: $color-40;
    text-align: center;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.informe-para .p {
    margin-bottom: 0;
}

.informe-learn-more {
    background-color: $color-40;
    padding: .5rem;
    text-align: center;
}

.informe-learn-more a {
    text-decoration: none;
    color: $color-43;
    font-size: 1.2rem;
    font-weight: bold;
}

.informe-wrapper:hover .informe-heading {
    color: $color-43;
}

.informe-wrapper:hover .informe-learn-more {
    background-color: $color-42;
    color: $color-40;
}

.informe-wrapper:hover .informe-learn-more a {
    color: $color-40;
}

.informe-thumbnail {
    position: relative;
}

.informe-wrapper:hover .informe-thumbnail::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(242, 191, 190, .8);
    top: 0;
    bottom: 0;
    width: 86%;
    margin: 0 auto;
}

.publications-slider.publications-slider-pink .publications-item.owl-item {
    background-color: $color-white;
}
