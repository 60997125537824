$teaser-stage-color-title: $color-white !default;
$teaser-stage-color-background: $color-4 !default;
$teaser-stage-btn-icon: arrow-right-red-repatha !default;

.teaser-stage {
    @include component-margin;
    height: rem(200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $teaser-stage-color-background no-repeat 50% 50%;
    background-size: cover;
    padding: 2em 0;
    position: relative;
    overflow: hidden;

    &--slim {
        height: rem(120px);
        text-align: center;
        padding: 3em 0;
    }

    &_title {
        color: $teaser-stage-color-title;
        margin: 0;

        &::after {
            display: none;
        }

        + .teaser-stage_btn {
            margin-top: 1em;
        }
    }
}

.stageext {
    width: 75%;
}

.stageext p {
    font-size: 1rem;
}

.stageext_small {
    font-size: .85rem;
    margin-bottom: 1rem;
    color: $color-12;
}

.stageext h1 {
    color: $color-12;
    margin-bottom: 1rem;
    text-align: center;
    margin: 0;
}

.stageext h1::after {
    content: none;
}

.btn_teaser_blue {
    background: $color-12;
    border-color: $color-12;
    color: $color-white;
}

.btn_teaser_white {
    background: $color-white;
    color: $color-12;
    border-color: $color-white;
}

.bgwt {
    color: $color-white;
}

.bgwt h1 {
    color: $color-white;
}

.bgimg {
    z-index: 1;
}

.bgimgcont {
    display: none;
}

.icon-teaser-stage_btn {
    &::before {
        @include icon-props($teaser-stage-btn-icon);
    }
}

.teaser-stage--left-top { background-position: 10% 10%; }
.teaser-stage--left-center { background-position: 10% 50%; }
.teaser-stage--left-bottom { background-position: 10% 90%; }
.teaser-stage--center-top { background-position: 50% 10%; }
.teaser-stage--center-bottom { background-position: 50% 90%; }
.teaser-stage--right-top { background-position: 90% 10%; }
.teaser-stage--right-center { background-position: 90% 50%; }
.teaser-stage--right-bottom { background-position: 90% 90%; }

@media (min-width: $layout-breakpoint-tablet) {
    .teaser-stage {
        text-align: center;
        height: rem(210px);

        &--slim {
            height: rem(155px);
        }

        &_title {
            + .teaser-stage_btn {
                margin-top: 2em;
            }
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .teaser-stage {
        padding: 3em 0;
        height: rem(375px);

        &--slim {
            height: rem(275px);
        }

        &_title {
            + .teaser-stage_btn {
                margin-top: rem(60px);
            }
        }
    }
}

@media (max-width: 768px) {
    .stageext {
        width: 100%;
    }

    .teaser-stage {
        text-align: center;
        height: 20rem;
    }
}
