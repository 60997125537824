.news-home-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
}

.newsgrid h2,
.videopodcastgrid h2,
.training h2 {
    color: $color-black;
    text-transform: none;
    margin-bottom: 2rem;
    text-align: left;
    font-size: 2rem;
}

.newsgrid h2::after,
.videopodcastgrid h2::after,
.training h2::after {
    display: none;
}

.contanier-text-img-share .news-home {
    margin: 2rem 1rem;
}

.contanier-text-img-share {
    width: 100%;
}

.contanier-text-img-share img.textimg_img {
    height: 100%;
    width: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
}

.contanier-text-img-share .date-share-contanier {
    display: flex;
}

.contanier-text-img-share .date {
    width: 50%;
    color: $color-24;
    font-size: .8rem;
    font-weight: 600;
    margin-top: .5rem;
}

.contanier-text-img-share .share-book {
    width: 50%;
    text-align: right;
    margin-top: .5rem;
}

.contanier-text-img-share .info {
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
    margin: .75rem 0;
    display: flex;
}

.contanier-text-img-share .info span img {
    margin: 0;
    padding-right: 5px;
}

.contanier-text-img-share .info span.right-col {
    margin-left: 8px;
}

.book-image {
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    margin-bottom: 15px;
}

.share-image {
    box-sizing: border-box;
    width: 48px;
    height: 24px;
    margin-bottom: 15px;
}

.contanier-text-img-share .share-book ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.contanier-text-img-share .share-book ul li {
    display: inline-block;
}

.contanier-text-img-share h4.title {
    font-weight: 500;
    font-size: 18px;
    color: $color-black;
    text-align: left;
    margin: .8rem 0;
}

.contanier-text-img-share a {
    text-decoration: none;
}

.contanier-text-img-share .hashtag ul {
    list-style: none;
    padding-left: 0;
}

.contanier-text-img-share .hashtag ul li {
    display: inline-block;
    color: $color-26;
    font-size: .85rem;
    padding-left: .5rem;
    cursor: pointer;
}

.contanier-text-img-share .share-book ul li::before,
.contanier-text-img-share .hashtag ul:not([class]) li::before {
    display: none;
}

.contanier-text-img-share .hashtag ul li:first-child {
    padding-left: 0;
}

.contanier-text-img-share:hover h4.title {
    color: $color-26;
}

.contanier-text-img-share:hover .middle {
    opacity: 1;
}

.contanier-text-img-share:hover .textimg_img {
    opacity: .8;
}

.contanier-text-img-share .image-container {
    position: relative;
    height: 184px;
}

.contanier-text-img-share .middle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .3);
    overflow: hidden;
}

.btn-tooltip {
    position: relative;
}

.read-more-btn-container .read-more-btn {
    border: 1px solid $color-26;
    border-radius: 34.5px;
    box-shadow: 0 2px 14px 0 rgba(0, 124, 207, .02);
    padding: 1.167rem 3rem 1.025rem 2rem;
    text-decoration: none;
    color: $color-26;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
}

.read-more-btn-container .read-more-btn:hover {
    background-color: $color-26;
    color: $color-white;
}

.read-more-btn-container .read-more-btn:hover::after {
    background-image: url("/static/img/right-arrow-btn.png");
}

.read-more-btn-container {
    text-align: center;
    margin: 2rem 0;
}

.read-more-btn-container .read-more-btn::after {
    content: '';
    pointer-events: none;
    display: inline-block;
    background-image: url("/static/img/arrow-right-btn.png");
    top: 50%;
    right: 1rem;
    background-repeat: no-repeat;
    position: absolute;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}

span.btn-tooltip .tooltip-coming-soon {
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    min-width: 50px;
    max-width: 300px;
    display: none;
    top: -45px;
    left: 0;
    height: 20px;
    text-transform: uppercase;
    width: 98px;
    opacity: .6;
    background-color: $color-black;
    font-size: 13px;
    line-height: 20px;
}

.videopodcastgrid {
    max-width: 1200px;
    margin: 0 auto;
}

.videopodcastgrid .bottom-row,
.videopodcastgrid .top-row {
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.videopodcastgrid .contanier-text-img-share .image-container {
    height: 169px;
}

.videopodcastgrid .video-name {
    font-size: .8rem;
    color: $color-black;
    margin-bottom: .5rem;
    text-transform: uppercase;
}

.videopodcastgrid .contanier-text-img-share {
    margin: 2rem 1.25rem;
}

.contanier-text-img-share:first-child {
    margin-left: 0;
}

.contanier-text-img-share:last-child {
    margin-right: 0;
}

.play-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 40%;
    left: 45%;
    background-repeat: no-repeat;
    z-index: 9999;
}

.contanier-text-img-share:hover .play-icon {
    background-image: url("/static/img/Save.svg");
}

.top-row .contanier-text-img-share h4.title {
    font-size: 30px;
    margin: 1rem 0;
}

.video-content-left {
    width: 50%;
    margin-right: 2rem;
}

.play-icon {
    background-image: url("/static/img/Share.svg");
}

.videopodcastgrid .top-row .contanier-text-img-share {
    width: 100%;
    display: flex;
}

.videopodcastgrid .top-row .contanier-text-img-share .image-container {
    width: 50%;
    height: auto;
}

.videopodcastgrid .top-row .sub-content {
    color: $color-black;
}

.sub-content {
    color: $color-black;
}

.trainingsg {
    width: 100%;
    margin: 0 auto;
}

.trainingsg .contanier-text-img-share {
    padding: 2rem 1rem 1rem;
    background-color: $color-29;
}

.trainingsg {
    display: flex;
}

.contanier-text-img-share.training-home {
    margin: 2rem .5rem;
}

.contanier-text-img-share:first-child {
    margin-left: 0;
}

.contanier-text-img-share:last-child {
    margin-right: 0;
}

.trainingsg .sub-content {
    color: $color-black;
    font-size: .9rem;
    margin-bottom: 1rem;
}

.trainingsg .contanier-text-img-share {
    position: relative;
}

.trainingsg .label {
    height: 31.92px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 99;
    width: 116px;
    border-radius: 0 100px 100px 0;
    background-color: $color-26;
    font-size: .8rem;
    font-weight: 600;
    letter-spacing: .5px;
    color: $color-white;
}

.trainingsg .label.creditos div {
    padding: .5rem 0 .5rem 1rem;
}

.training {
    width: 100%;
    margin: 0 auto;
}

.training .contanier-text-img-share {
    padding: 2rem 1rem 1rem;
    background-color: $color-29;
}

.contanier-text-img-share.training-home {
    margin: 2rem .5rem;
}

.contanier-text-img-share:first-child {
    margin-left: 0;
}

.contanier-text-img-share:last-child {
    margin-right: 0;
}

.training .sub-content {
    color: $color-black;
    margin-bottom: 1rem;
    font-size: .9rem;
}

.training .contanier-text-img-share {
    position: relative;
}

.training .label {
    height: 31.92px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 99;
    width: 116px;
    border-radius: 0 100px 100px 0;
    background-color: $color-26;
    font-size: .8rem;
    font-weight: 600;
    letter-spacing: .5px;
    color: $color-white;
}

.training .label.creditos div {
    padding: .5rem 0 .5rem 1rem;
}

.news-page .col-1 {
    width: 50%;
    margin-right: 1rem;
}

.news-page .col-2 {
    width: 49%;
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
}

.col-2 .contanier-text-img-share {
    display: flex;
    margin-bottom: 2rem;
}

.contanier-text-img-share .share-book ul li {
    display: inline-block;
}

.news-page {
    display: flex;
}

.col-1 .sub-content {
    color: $color-black;
}

.col-2 .content-container {
    width: 55%;
    border-bottom: 1px solid $color-27;
}

.col-1 .content-container {
    border-bottom: 1px solid $color-27;
}

.col-1 .contanier-text-img-share .image-container {
    height: 408px;
}

.col-1 .contanier-text-img-share h4.title {
    color: $color-black;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin: 1rem 0;
}

.col-2 .contanier-text-img-share .image-container {
    height: 100%;
    margin-right: 2rem;
}

.contanier-text-img-share .middle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .4);
    overflow: hidden;
}

.news-page .col-1 {
    width: 50%;
    margin-right: 1rem;
}

.news-page .col-2 {
    width: 49%;
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
}

.col-2 .contanier-text-img-share {
    display: flex;
    margin-bottom: 2rem;
}

.contanier-text-img-share .share-book ul li {
    display: inline-block;
}

.news-page {
    display: flex;
}

.col-1 .sub-content {
    color: $color-black;
}

.col-2 .content-container {
    width: 55%;
    border-bottom: 1px solid $color-27;
}

.col-2 .contanier-text-img-share {
    width: 100%;
}

@media (min-width: 1024px) {
    .contanier-text-img-share {
        width: 31%;
        float: left;
        margin-left: 3.5%;
    }

    .videopodcastgrid.contanier-text-img-share {
        width: 23%;
    }

    .videopodcastgrid .top-row .contanier-text-img-share {
        width: 100%;
    }

    .news-page .col-1.contanier-text-img-share {
        width: 100%;
    }
}

.display-none {
    display: none;
}

.text-bold {
    font-weight: bold;
}

.close_icon {
    width: 100%;
}

.close_icon img {
    width: 24px;
    height: 24px;
    margin: 15px;
}

.user_form {
    width: 30%;
    margin: 0 auto;
}

.register-container .user_form {
    width: 50%;
    margin: 0 auto;
    float: left;
    background: $color-white;
}

.user_form h3 {
    color: $color-26;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: .11px;
}

.user_form p {
    color: $color-black;
    font-size: .9rem;
    line-height: 1.5;
    letter-spacing: normal;
}

.input_email input {
    color: $color-black;
    font-size: .8rem;
    line-height: 15px;
    letter-spacing: .5px;
}

.user_form input,
.input_select select {
    background-color: $color-28;
    border: 1px solid $color-27;
    padding-bottom: 20px;
}

.user_form input {
    width: 339px;
    padding: 10px;
}

.input_select select {
    width: 378px;
    padding: 16px 12px;
    background: none;
    background-color: $color-28;
    font-size: .8rem;
}

.form_submit {
    margin: 30px 0;
}

.form_submit input {
    background-color: $color-26;
    border-radius: 24px;
    color: $color-white;
    font-size: .95rem;
    line-height: 1;
    letter-spacing: .5px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
}

.container {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkmarkhcp {
    position: absolute;
    top: -6px;
    left: 0;
    height: 24px;
    width: 25px;
    background-color: $color-28;
    border: 1px solid $color-27;
}

.checkmarkhcp::after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmarkhcp {
    border: 1px solid $color-26;
}

.container input:checked ~ .checkmarkhcp::after {
    display: block;
}

.container .checkmarkhcp::after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: 1px solid $color-26;
    border-width: 0 1.2px 1.2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.user_form form {
    border-bottom: 1px solid $color-27;
}

.password img {
    position: absolute;
    top: 16px;
    right: 17px;
    width: 22px;
    height: 22px;
}

.remember_me {
    width: 42%;
    float: left;
}

.remember_me label {
    font-size: .8rem;
    line-height: 15px;
    letter-spacing: .5px;
    color: $color-black;
    text-align: left;
}

.forgot_password {
    width: 100%;
}

.forgot_password p,
.forgot_password p a {
    font-size: .8rem;
    line-height: 15px;
    letter-spacing: .5px;
    color: $color-26;
    text-align: right;
    text-decoration: none;
}

/* input styles on focus */

.input_email {
    position: relative;
    margin-bottom: 12px;
}

.addtodo {
    padding: 16px 17px;
}

.add-on {
    position: absolute;
    left: 17px;
    top: 15px;
    display: none;
    padding: 0 8px;
    background: $color-white;
}

.addtodo:focus + .add-on,
.addtodo:valid + .add-on {
    line-height: 3px;
    letter-spacing: .42px;
    color: $color-24;
    font-size: 10px;
    z-index: 1000;
    top: 6px;
    margin: 2px 0;
    display: block;
}

.user_form input::placeholder {
    color: $color-black;
    font-size: .8rem;
    letter-spacing: .5px;
    line-height: 15px;
}

.user_form input:focus::placeholder {
    color: transparent;
}

.input_select .add-on {
    left: 14px;
}

.registerhcpbt {
    padding: 10px 0;
    position: absolute;
    top: 10rem;
    left: 5rem;
}

.registerhcpbt p {
    font-size: 1rem;
    line-height: 15px;
    letter-spacing: .5px;
}

.registerhcpbt1 p {
    font-size: 1rem;
    line-height: 15px;
    letter-spacing: .5px;
    margin-bottom: .75rem;
}

.registerhcpbt p span a {
    color: $color-26;
    text-decoration: none;
}

.registerhcpbt1 p span a {
    color: $color-26;
    text-decoration: none;
}

.register-hcpahora p span a {
    color: $color-26;
    text-decoration: none;
}

.hcploginbtn {
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid $color-26;
    color: $color-26;
    font-size: .95rem;
    line-height: 1;
    letter-spacing: .5px;
    width: 100%;
    cursor: pointer;
    font-weight: 700;
    padding: .5rem 2rem;
    text-decoration: none;
}

.hcpbtn {
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid $color-26;
    color: $color-26;
    font-size: .95rem;
    line-height: 1;
    letter-spacing: .5px;
    width: 100%;
    cursor: pointer;
    font-weight: 700;
    padding: .5rem 2rem;
    text-decoration: none;
}

.login-button .hcp-loginbtn,
.login-button .hcp-regbtn,
.hcp-logoutbt .hcplogoutbt {
    text-decoration: none;
    color: $color-26;
    font-size: .95rem;
    padding: .5rem 1rem;
}

.error-msg p {
    font-size: .8rem;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: .5px;
    color: $color-25;
    margin: 10px 0;
}

.error-msg p {
    font-size: .8rem;
    letter-spacing: normal;
    line-height: 22px;
    color: $color-25;
}

.user_form input:focus,
.user_form select:focus {
    outline: 1.5px solid $color-26;
    border-style: inset;
    border: none;
}

.user_form.reset_form form,
.user_form.registeration form {
    border-bottom: none;
}

.user_form .registeration {
    width: 50%;
    margin: 0 auto;
    float: right;
    background-color: $color-white;
    min-height: 1176px;
}

.user_form.login_form,
.user_form.reset_form {
    background-color: $color-white;
    width: 100%;
    padding: 0 30% 0 15%;
    float: right;
    min-height: 1176px;
}

.user_form.login_form .close_icon {
    width: 100%;
    margin-left: -84px;
}

.user_form.reset_form .close_icon {
    margin-left: -77px;
}

.user_form.login_form .input_email input {
    width: 100%;
}

.user_form.registeration .input_email input {
    width: 100%;
}

.user_form.registeration .form_submit {
    margin: 65px 0 0;
}

.register_form {
    width: 380px;
    margin: 0 0 0 95px;
    padding: 82px 0 30px;
}

.register_form .registerhcpbt1 {
    border-bottom: 1px solid $color-27;
    margin-bottom: .7rem;
}

.register_form .remember_me {
    width: 100%;
    margin: 10px 0;
}

.register_form .remember_me label a {
    color: $color-black;
}

.popup {
    max-width: 1290px;
    display: block;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
}

.login-container .popup,
.forget-container .popup {
    width: 50%;
    display: block;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color: $color-white;
}

.register-container .popup {
    width: 90%;
    display: block;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color: $color-white;
}

.bg-overlay {
    width: 50%;
    float: left;
    display: flex;
}

.overlay-text {
    color: $color-white;
    background-image: url("/static/img/bg-image01.jpg");
    min-height: 1176px;
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay-text h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: .11px;
    margin: 30px 10px 20px 65px;
    color: $color-white;
}

.why-register {
    padding-left: 23px;
    padding-right: 177px;
}

.overlay-text ul li {
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: normal;
    padding: 15px 0;
    list-style: none;
    position: relative;
    padding-left: 40px;
}

.overlay-text ul li::before {
    content: "^";
    color: $color-26;
    border: 1px solid $color-26;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 800;
    padding: 5px 5px 1px;
    width: 25px;
    height: 25px;
    position: absolute;
    line-height: 1;
    left: 0;
    top: auto;
    background-color: transparent;
}

#overlay-color {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 0 10px;
    opacity: 1;
    transition: opacity .15s, top 0s;
}

/* select arrow down styles */
.input_select {
    position: relative;
    display: inline-block;
}

.input_select select {
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input_select::after {
    background-image: url("/static/img/select-arrow-down.png");
    content: "";
    z-index: 999;
    width: 8px;
    height: 4px;
    border-left: none;
    padding-left: 10px;
    padding-top: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 15px;
    right: 16px;
}

.calender-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

.calender-col1 {
    width: 65%;
    background-image: url("/static/img/bg.jpg");
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.calender-col2 {
    width: 35%;
    background-color: $color-29;
    padding: 3rem 0;
}

.calender-col2 .calender-icon img {
    height: 40px;
    width: 40px;
    color: $color-black;
}

.calender-col2 h3.calender-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-26;
    padding-left: 1.5rem;
}

.calender-col2 .event-title {
    font-size: 1.15rem;
    font-weight: 600;
    color: $color-26;
    letter-spacing: .5px;
    text-transform: uppercase;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.calender-col2 .calender-events {
    padding: .5rem 2rem;
    padding-left: 1.5rem;
}

.calender-col2 .event-content {
    color: $color-black;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 1rem;
    width: 85%;
}

.calender-col2 .line-event {
    height: 9px;
    margin: 0 .5rem;
    width: 68px;
    border-bottom: 1px solid $color-6;
}

.calender-col2 .event-date {
    display: flex;
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: .5rem;
}

.calender-icon {
    padding-left: 1.5rem;
}

.calender-col2 .calender-events:hover {
    background-color: $color-white;
    margin-left: -2.5rem;
    padding: .5rem 2rem;
}

.calender-col2 .calender-events:hover .event-content {
    color: $color-26;
    text-decoration: none;
}

.calender-col2 .calender-events:hover .event-date {
    color: $color-black;
}

.calender-events a {
    text-decoration: none;
}

.calender-col1 .event-title {
    color: $color-white;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 2rem;
}

.calender-col1 .event-date {
    display: flex;
    color: $color-white;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: .5rem;
    padding-bottom: 2rem;
}

.calender-col1 .line-event {
    height: 9px;
    margin: .5rem;
    width: 68px;
    border-bottom: 1px solid $color-white;
}

.calender-col1 .main-event {
    max-width: 350px;
    margin-left: 7rem;
}

.transparent-botton {
    text-decoration: none;
    border: 1px solid $color-white;
    padding: .5rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
    transition: .3s ease;
    color: $color-white;
}

.news-page .col-1 {
    width: 50%;
    margin-right: 1rem;
}

.news-page .col-2 {
    width: 49%;
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
}

.news-page .col-2 .contanier-text-img-share {
    display: flex;
    margin-bottom: 2rem;
}

.news-page .col-1 .contanier-text-img-share:hover h4.title {
    color: $color-26;
}

.news-page .contanier-text-img-share .share-book ul li {
    display: inline-block;
}

.news-page {
    display: flex;
}

.news-page .col-1 .sub-content {
    color: $color-black;
}

.news-page .col-2 .content-container {
    width: 55%;
    border-bottom: 1px solid $color-27;
}

.news-page .col-1 .content-container {
    border-bottom: 1px solid $color-27;
}

.news-page .col-1 .contanier-text-img-share {
    width: 100%;
    margin: 1rem 0;
}

.news-page .col-2 .contanier-text-img-share {
    margin: 1rem .5rem;
}

.news-page .col-1 .contanier-text-img-share .image-container {
    height: 408px;
}

.news-page .col-1 .contanier-text-img-share h4.title {
    color: $color-black;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin: 1rem 0;
}

.news-page .col-2 .contanier-text-img-share .image-container {
    height: 100%;
    margin-right: 2rem;
}

.news-page .contanier-text-img-share .middle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .3);
    overflow: hidden;
}

.news-page .col-1 {
    width: 49%;
    margin-right: 1%;
}

.news-page .col-2 {
    width: 48%;
    display: flex;
    margin-left: 2%;
    flex-direction: column;
}

.contanier-text-img-share .share-book ul li {
    display: inline-block;
}

.news-page {
    display: flex;
}

.col-1 .sub-content {
    color: $color-black;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.popup-center {
    display: block;
}

.logout-popup {
    display: none;
}

.popup-center .content {
    padding: 24px;
    max-width: 592px;
    width: 100%;
    display: block;
    z-index: 1000;
    background-color: $color-white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.alert-icon {
    width: 9%;
    float: left;
    text-align: center;
}

.popup-content {
    width: 85%;
    float: right;
}

.popup-content p {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: normal;
    color: $color-black;
}

.popup-content span {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: normal;
    color: $color-black;
}

.text-popup {
    margin-bottom: 20px;
}

.confirm-btns {
    margin: 5px 0 20px;
}

.confirm-btns button {
    cursor: pointer;
    outline: none;
    padding: 12.5px 24px;
    text-align: center;
    font-weight: 600;
    border-radius: 24px;
    background-color: $color-white;
    line-height: 1.3;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.grey-btn {
    color: $color-6;
    border: 1px solid $color-6;
    margin-right: 16px;
}

.yellow-btn {
    color: $color-30;
    border: 1px solid $color-30;
}

.blue-btn {
    color: $color-26;
    border: 1px solid $color-26;
}

.blue-title {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 600;
    margin: 0 0 25px;
    color: $color-26;
}

.content.identification .text-popup p {
    font-size: 1.25rem;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.4;
}

.content.identification .popup-content {
    width: 65%;
    float: none;
    margin: 0 0 0 20px;
}

.welcome-user p {
    color: $color-black;
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5;
    margin: 0;
}

.content.user-interests {
    padding: 40px 60px;
    max-width: 760px;
    width: 100%;
}

.listInterests ul {
    padding: 0;
}

.listInterests ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid $color-24;
    border-radius: 30px;
    padding: 9px 16px;
    margin-bottom: 16px;
    margin-right: 13px;
    cursor: pointer;
    line-height: 1;
}

.listInterests ul li a {
    text-decoration: none;
    color: $color-24;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .5px;
    text-align: center;
}

.user-interests .divider {
    margin: 25px 0 40px;
}

.blue-btn.finalize {
    position: relative;
    min-width: 163px;
    text-align: left;
}

.blue-btn.finalize span img {
    position: absolute;
    top: 18px;
    right: 25px;
    width: 14px;
    height: 13px;
}

.user-interests .text-popup {
    margin: 15px 30px 30px 0;
}

.user-interests .text-popup p {
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
}

.fill-blue {
    background-color: $color-26;
    border: none !important;
}

.fill-blue a {
    color: $color-white !important;
}

.listInterests ul li:nth-child(9) {
    margin-right: 0;
}

img.close-icon {
    cursor: pointer;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
}

#overlay-color {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 1;
    transition: opacity .15s, top 0s;
}

.blue-btn.finalize::after {
    content: '';
    pointer-events: none;
    display: block;
    background-image: url('/static/img/blue-arrow.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 16%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 25px;
    height: 22px;
}

.listInterests ul:not([class]) li::before {
    display: none;
}

.actualidad-top {
    width: 100%;
    display: flex;
}

.actualidad-top .search-container,
.actualidad-top .title,
.actualidad-top .speciality-dropdown {
    width: 50%;
    position: relative;
}

.search-container {
    padding-top: 10px;
    position: relative;
}

.search-container input {
    position: relative;
    border: none;
    border-bottom: 1px solid $color-24;
    height: 32px;
    width: 260px;
}

.search-container input:focus {
    outline: none;
}

.search-container input::placeholder {
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 18px;
}

.search-container button {
    position: absolute;
    outline: 0;
    background-color: $color-white;
    border: none;
    width: auto;
    top: 30px;
}

.search-container button img {
    width: 20px;
}

.title {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
}

.speciality-dropdown {
    text-align: right;
}

.speciality-dropdown .input_select select {
    background-color: $color-white;
    border: none;
    width: auto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 15px;
    padding: 30px 18px 0;
}

.speciality-dropdown .input_select select:focus {
    outline: none;
}

.speciality-dropdown .input_select::after {
    width: auto;
    height: auto;
    right: 0;
    top: 28px;
}

.actualidad-top .speciality-dropdown img {
    width: 10px;
}

.listActualidad ul li {
    border: 1px solid $color-26;
}

.listActualidad ul li a {
    color: $color-26;
    font-weight: 500;
}

.input_select select option {
    padding: 10px 0;
}

.listActualidad {
    margin: 1.5rem 0;
}

.listActualidad ul {
    padding: 0;
}

.listActualidad ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid $color-24;
    border-radius: 30px;
    padding: 9px 16px;
    margin-bottom: 16px;
    margin-right: 13px;
    cursor: pointer;
    line-height: 1;
}

.listActualidad ul li a {
    text-decoration: none;
    color: $color-24;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .5px;
    text-align: center;
}

.listActualidad ul:not([class]) li::before {
    display: none;
}

.listActualidad ul li {
    border: 1px solid $color-26;
}

.listActualidad ul li a {
    color: $color-26;
    font-weight: 500;
}

/* recursos profesionales styles */
.recursos-profesionales {
    margin: 0 auto;
}

.recursos-profesionales .title h2 {
    color: $color-black;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 34px;
    margin: 20px 0 50px;
}

.recursos-profesionales .content {
    width: 100%;
    text-align: center;
    display: flex;
}

.recursos-profesionales .box-content {
    width: 33.1%;
    border-top: 8px solid $color-26;
    background-color: $color-white;
    box-shadow: 0 0 34px 0 rgba(0, 124, 207, .11);
    margin: 0 1.5rem;
}

.recursos-profesionales .box-content:first-child {
    margin-left: 0;
}

.recursos-profesionales .box-content:last-child {
    margin-right: 0;
}

.recursos-profesionales .content-heading {
    color: $color-black;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin: 1rem 0;
}

.recursos-profesionales .content .description {
    height: 55px;
    padding: 0 3rem;
}

.recursos-profesionales .content .content-icon {
    margin: 3.5rem 0 1rem;
}

.recursos-profesionales .content .content-icon img {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-bottom: 0;
}

.recursos-profesionales .right_arrow {
    text-align: right;
    margin: 1rem;
}

.recursos-profesionales .right_arrow a img {
    height: 20px;
    width: 21px;
    display: inline-block;
    margin-bottom: 0;
}

.mantenimiento-container {
    display: flex;
    height: 440px;
    max-height: 440px;
}

.mantenimiento-container .col-1 {
    width: 30%;
}

.mantenimiento-container .col-1.bg-img {
    background-image: url("/static/img/mantenimiento_y_reparacion_osea.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 33%;
}

.mantenimiento-container .col-2.width-30 {
    width: 33%;
    background-color: $color-26;
    color: $color-white;
    text-align: center;
    padding: 1.5rem;
    margin-bottom: 5rem;
}

.mantenimiento-container .image-block {
    margin: 0 auto;
}

.mantenimiento-container .image-block img {
    width: 33px;
    height: 32px;
    margin: 0 auto;
}

.mantenimiento-container .title-block {
    font-size: 24px;
    font-weight: 600;
    padding: 0 2rem;
    margin: .625rem 0 1rem;
    color: $color-white;
}

.mantenimiento-container .button-block {
    margin: 2.594rem 0;
}

.mantenimiento-container .content-block {
    min-height: 6rem;
}

.mantenimiento-container .col-2.width-50 {
    width: 33%;
    background-color: $color-12;
    margin-bottom: 5rem;
}

.mantenimiento-container .col-2.width-50 .block {
    margin-bottom: 6rem;
    color: $color-white;
    text-align: center;
    padding: 1.5rem;
}

.btn-right-icon::after {
    content: '';
    pointer-events: none;
    display: inline-block;
    background-image: url("/static/img/right-arrow-white.png");
    top: 50%;
    right: 1rem;
    background-repeat: no-repeat;
    position: absolute;
    transform: translateY(-50%);
    width: 18px;
    height: 16px;
}

a.redirect-btn.btn-right-icon {
    padding-right: 3rem;
}

a.redirect-btn {
    cursor: pointer;
    position: relative;
    border: 1px solid $color-white;
    text-transform: uppercase;
    border-radius: 24px;
    background-color: transparent;
    color: $color-white;
    padding: .781rem 1.513rem;
    font-size: .875rem;
    font-weight: 500;
}

.carosel-container {
    position: relative;
}

.carosel-container .button-wrapper {
    width: 100%;
    background-image: url("/static/img/teaser-temp.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 31rem;
    background-color: $color-white;
    display: flex;
    align-items: center;
}

.carosel-container .button-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .59);
    position: relative;
}

.carosel-container .content-block {
    margin: 0 5rem;
    position: relative;
    min-height: 15rem;
}

.carosel-container .content-block h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $color-white;
    text-align: left;
    margin-bottom: 1.5rem;
}

.carosel-container .content-block h2::after {
    display: none;
}

.carosel-container .content-block p {
    font-size: 1rem;
    color: $color-white;
    text-align: left;
    margin-bottom: 1rem;
}

.transparent-botton.teaser-btn {
    text-decoration: none;
    display: block;
    border: 1px solid $color-white;
    padding: .5rem 3rem .5rem 2rem;
    font-size: 14px;
    border-radius: 2rem;
    transition: .3s ease;
    color: $color-white;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    right: 0;
}

.transparent-botton.teaser-btn.btn-right-white::after {
    content: '';
    pointer-events: none;
    display: block;
    background-image: url("/static/img/right-arrow-btn.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 10%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
}

.carosel-container .content-block .registerhcpbt {
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.carosel-container .button-slider {
    display: flex;
    text-align: center;
    position: absolute;
    right: 4rem;
    bottom: 2rem;
}

.carosel-container .button-slider img {
    height: 14px;
    width: 14px;
    margin-bottom: 0;
}

.carosel-container .button-slider .next {
    padding: .8rem;
    border: .85px solid $color-white;
    border-radius: 50%;
    margin-left: 1rem;
}

.carosel-container .button-slider .perv {
    padding: .8em;
    border: .85px solid $color-white;
    border-radius: 50%;
}

.active-left {
    position: relative;
    animation: mymove 1s;
    overflow: hidden;
    z-index: 0;
}

@keyframes mymove {
    from {
        transform: translateX(25%);
    }

    to {
        transform: translateX(0%);
    }
}

.active-right {
    position: relative;
    animation: mymove1 1s;
    z-index: 0;
}

@keyframes mymove1 {
    from {
        transform: translateX(-17%);
    }

    to {
        transform: translateX(0%);
    }
}

/* Add Css */
.disabled {
    pointer-events: none !important;
    opacity: .3 !important;
}

.encloser .actaulidadInnerrow {
    max-width: 24%;
    display: block;
    width: 100%;
}

.encloser .actaulidadInnerrow,
.rightcol {
    display: block;
    width: inherit;
    padding: 0 8px;
}

.actaulidadInnerrow .rightcol h2 {
    margin: .5rem 0;
    text-transform: none;
    font-size: 1.75rem;
    text-align: left;
}

.actaulidadInnerrow .rightcol p {
    margin: .5rem 0;
    text-transform: none;
    font-size: 1rem;
    text-align: left;
}

.actaulidadInnerrow .rightcol .h2::after,
.actaulidadInnerrow .rightcol h2::after {
    display: none;
}

.date-share-contanier,
.hashTag {
    display: flex;
}

.date {
    width: 45%;
    color: $color-24;
    font-size: .8rem;
    font-weight: 600;
    margin-top: .5rem;
}

.share-book {
    width: 50%;
    text-align: right;
    margin-top: .5rem;
}

.share-image,
.book-image {
    box-sizing: border-box;
    width: 20px;
    height: 24px;
    margin-bottom: 0;
    margin-right: .75rem;
}

.share-book ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.share-book ul li {
    display: inline-block;
    margin-right: 10px;
}

.share-book ul.block-grid li {
    display: inline-block;
    margin-right: 15px;
}

.rightcol .hashtag ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: .5rem;
}

.rightcol .hashtag ul li {
    display: inline-block;
    color: $color-26;
    font-size: .85rem;
    padding-left: .5rem;
    cursor: pointer;
}

.rightcol .hashtag ul li:first-child {
    padding-left: 0;
}

.date-share-contanier .share-book ul li::before,
.rightcol .hashtag ul:not([class]) li::before,
.event-box .hashtag ul:not([class]) li::before, {
    display: none;
}

.btn-tooltip {
    position: relative;
    cursor: pointer;
}

span.btn-tooltip .tooltip-coming-soon {
    display: none;
    width: 100px;
    background-color: $color-black;
    color: $color-white;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.rightcol .divider {
    height: 1px;
    background-color: $color-6;
    margin: 1rem 0;
}

.hashTag .hashTag-item {
    width: 30%;
    font-size: 18px;
    display: block;
    color: $color-26;
    text-decoration: none;
    cursor: pointer;
}

.congresos-filter {
    padding: 5rem 0;
}

.congresos-filter .p.form-header {
    font-size: 1.5rem;
    margin-bottom: 5rem;
}

.congresos-filter .input-group {
    display: inline;
}

.congresos-filter .input-group .search-box {
    float: left;
    position: relative;
}

.congresos-filter .input-group .search-box svg {
    position: absolute;
    right: 0;
    bottom: 8px;
}

.congresos-filter .input-group .event-select {
    float: right;
}

.congresos-filter .input-group .search-box input.form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid $color-24;
    height: 30px;
    width: 260px;
    font-weight: bold;
}

.congresos-filter .toggle-switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
}

.congresos-filter .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.congresos-filter .switch-label {
    padding-right: 10px;
}

.congresos-filter .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-6;
    -webkit-transition: .4s;
    transition: .4s;
}

.congresos-filter .slider::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 3px;
    background-color: $color-white;
    -webkit-transition: .4s;
    transition: .4s;
}

.congresos-filter input:checked + .slider {
    background-color: $color-27;
}

.congresos-filter input:focus + .slider {
    box-shadow: 0 0 1px $color-27;
}

.congresos-filter input:checked + .slider::before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.congresos-filter .slider.round {
    border-radius: 34px;
}

.congresos-filter .slider.round::before {
    border-radius: 50%;
}

.congresos-filter .listInterests ul {
    padding: 0;
}

.congresos-filter .listInterests ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid $color-6;
    border-radius: 24px;
    padding: 7px 16px;
    margin-bottom: 16px;
    margin-right: 13px;
    cursor: pointer;
}

.congresos-filter .listCommon ul li {
    border: 1px solid $color-26;
}

.congresos-filter .listCommon ul li a {
    color: $color-black;
    font-weight: 500;
    text-decoration: none;
}

.congresos-filter .fill-blue {
    background-color: $color-26;
    color: $color-white !important;
}

.congresos-filter .active {
    background-color: $color-white;
    color: $color-black;
}

/* .congresos-filter .active a {
    background-color: $color-26;
    color: $color-white !important;
} */

.congresos-filter .clear {
    clear: both;
}

.congresos-filter span.switch-label {
    font-size: 12px;
}

.congresos-filter .arrow {
    border: solid $color-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    margin-bottom: 2px;
}

.congresos-filter .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

div.custom-select {
    height: 40px;
    width: 140px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 20px;
}

div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    position: relative;
}

div.selected i.arrow.down {
    position: absolute;
    right: 14px;
    top: 14px;
}

div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
}

div.select-box {
    display: none;
    width: 100%;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
}

div.select-box.active {
    display: block;
}

div.select-box.drop-up {
    top: auto;
    bottom: 100%;
}

ul.select-list {
    margin: 0;
    padding: 5px;
    list-style-type: none;
}

ul.select-list li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
    font-size: .8rem;
}

ul.select-list li:hover,
ul.select-list li.active {
    background-color: $color-19;
}

div.select-box.date {
    position: absolute;
    left: -65%;
    width: 20rem;
}

.formacion-filter .fill-blue {
    background-color: $color-26;
    color: $color-white !important;
}

.apply-btn input {
    cursor: pointer;
    float: right;
    -webkit-appearance: none;
    background-color: $color-white;
    box-shadow: none;
    color: $color-26;
    border: none;
    padding: .5rem 1rem;
    font-size: 14px;
    font-weight: bold;
}

.apply-btn input:focus {
    -webkit-appearance: none;
    border: none;
    outline: none;
}

.formacion-filter {
    padding: 5rem 0;
}

.formacion-filter .p.form-header {
    font-size: 1.5rem;
    margin-bottom: 5rem;
}

.formacion-filter .input-group {
    display: inline;
}

.formacion-filter .input-group .search-box {
    float: left;
    position: relative;
}

.formacion-filter .input-group .search-box svg {
    position: absolute;
    right: 0;
    bottom: 8px;
}

.formacion-filter .input-group .event-select {
    float: right;
}

.formacion-filter .input-group .search-box input.form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid $color-24;
    height: 30px;
    width: 260px;
    font-weight: bold;
}

.formacion-filter .switch-content .toggle-switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
}

.formacion-filter .switch-content .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.formacion-filter .switch-content .switch-label {
    padding-right: 10px;
}

.formacion-filter .sliderfrm {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-6;
    -webkit-transition: .4s;
    transition: .4s;
}

.formacion-filter .sliderfrm::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 3px;
    background-color: $color-white;
    -webkit-transition: .4s;
    transition: .4s;
}

.formacion-filter .switch-content input:checked + .sliderfrm {
    background-color: $color-14;
}

.formacion-filter .switch-content input:focus + .sliderfrm {
    box-shadow: 0 0 1px $color-14;
}

.formacion-filter input:checked + .sliderfrm::before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.formacion-filter .sliderfrm.round {
    border-radius: 34px;
}

.formacion-filter .sliderfrm.round::before {
    border-radius: 50%;
}

.formacion-filter .listInterests ul {
    padding: 0;
}

.formacion-filter .listInterests ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid $color-6;
    border-radius: 24px;
    padding: 7px 16px;
    margin-bottom: 16px;
    margin-right: 13px;
    cursor: pointer;
}

.formacion-filter .listCommon ul li {
    border: 1px solid $color-26;
}

.formacion-filter .listCommon ul li a {
    color: $color-black;
    font-weight: 500;
    text-decoration: none;
}

.formacion-filter .fill-blue {
    background-color: $color-26;
    color: $color-white !important;
}

.formacion-filter .active {
    background-color: $color-white;
    color: $color-black;
}

.formacion-filter .active a {
    background-color: $color-26;
    color: $color-white !important;
}

.formacion-filter .listCommon ul li.btn-greybg {
    padding: 9px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid $color-13;
    background-color: $color-13;
    border-radius: 50px;
}

.formacion-filter .listCommon ul li.btn-greybg a {
    background-color: $color-13;
    color: $color-black !important;
}

.formacion-filter .clear {
    clear: both;
}

.formacion-filter .switch-content span.switch-label {
    font-size: 12px;
}

.formacion-filter .arrow {
    border: solid $color-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-bottom: -2px;
}

.formacion-filter .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.formacion-filter div.custom-select {
    height: 40px;
    width: 170px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 20px;
}

.formacion-filter div.custom-select.sm-wid {
    width: 140px;
}

.formacion-filter div.custom-select {
    height: 40px;
    width: 185px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 20px;
}

.formacion-filter div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    position: relative;
}

.formacion-filter div.selected i.arr-pt {
    position: absolute;
    right: 35px;
    top: 14px;
}

.formacion-filter div.selected i.arr-nt {
    position: absolute;
    right: 11px;
    top: 14px;
}

.formacion-filter div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
}

.formacion-filter div.select-box {
    display: none;
    width: 100%;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
}

.formacion-filter div.select-box.active {
    display: block;
}

.formacion-filter div.select-box.drop-up {
    top: auto;
    bottom: 100%;
}

.formacion-filter ul.select-list {
    margin: 0;
    padding: 5px;
    list-style-type: none;
}

.formacion-filter ul.select-list li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
}

.formacion-filter ul.select-list li:hover,
.formacion-filter ul.select-list li.active {
    background-color: $color-19;
}

.formacion-filter .switch-content {
    display: inline-block;
    background-color: transparent;
    float: left;
    margin-left: 20px;
    margin-top: 9px;
    font-size: 13px;
}

.highlightshcp {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 1167px;
}

.highlightshcp .container_title {
    height: 32px;
    opacity: .9;
    color: $color-black;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 32px;
    margin: 4.188rem 0 2.438rem;
}

.highlightshcp .highlights_grid {
    display: flex;
}

.highlights_grid .events {
    height: 448px;
    width: 360px;
    position: relative;
    margin-right: 2.5rem;
}

.highlights_grid .events .creditos_label {
    height: 32px;
    position: absolute;
    top: 24px;
    left: 0;
    z-index: 99;
    width: 112px;
    border-radius: 0 6.25rem 6.25rem 0;
    background-color: $color-26;
}

.highlights_grid .events .creditos_label div {
    padding: .563rem 1rem .5rem;
    height: 15px;
    color: $color-white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
}

.highlights_grid .events .event_img {
    height: auto;
    width: 100%;
    background-color: $color-7;
}

.highlights_grid .events .event_img img {
    margin-bottom: 0;
}

.events .blue-rectangle {
    height: 56px;
    width: 10px;
    background-color: $color-26;
    position: absolute;
    top: 0;
    left: 0;
}

.bg-container {
    background-color: $color-white;
    box-shadow: 0 12px 24px 0 rgba(0, 72, 120, .06);
    position: relative;
}

.event_content .title {
    height: 24px;
    color: $color-black;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 1rem 0 .5rem;
    text-align: left;
}

.event_content .title_highlight {
    height: 32px;
    color: $color-black;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-transform: initial;
    margin-bottom: .5rem;
}

.bg-container .event_content .content {
    height: 72px;
    width: 267px;
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
}

.bg-container .event_content {
    margin-left: 2rem;
}

.bg-container:hover {
    box-shadow: 0 32px 24px 0 rgba(0, 72, 120, .11);
}

.bg-container .event_content:hover {
    cursor: context-menu;
}

.post-details {
    margin-top: 1.5rem;
}

.post-details span {
    text-transform: uppercase;
    height: 24px;
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
}

.post-details span:first-child {
    margin-right: 2rem;
}

.events .right_arrow {
    text-align: right;
    padding: 0 1rem 1rem 0;
}

.events .right_arrow a img {
    height: 13px;
    width: 15px;
    display: inline-block;
    margin-bottom: 0;
}

.hcpsearchBar {
    height: 120px;
    background-color: $color-black;
}

.hcpsearchBar input {
    background-color: $color-black;
    color: $color-white;
    border: none;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 2.375rem 7rem;
    width: 1000px;
}

.hcpsearchBar input:focus {
    outline: none;
}

.hcpsearchBar input::-webkit-input-placeholder {
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
}

.hcpsearchBar input:-ms-input-placeholder {
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
}

.hcpsearchBar input::placeholder {
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
}

.hcpsearchBar .searchIcon {
    position: absolute;
    top: 40px;
    left: 75px;
}

.hcpsearchBar .closeIcon {
    position: absolute;
    top: 40px;
    right: 75px;
}

.hcpsearchBar .searchIcon img,
.hcpsearchBar .closeIcon img {
    height: 33.5px;
    width: 31.5px;
}

.hcpsearchPage {
    max-width: 1200px;
    margin: 0 auto;
}

.hcpsearchresult-container {
    max-width: 1200px;
    margin: 0 auto;
}

.hcpsearch-page .title {
    color: $color-black;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1;
    margin: 2.5rem 0 1.5rem;
    text-align: left;
}

.hcpsearch-page .eventscardgrid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.hcpsearch-page .result-found-title {
    color: $color-24;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 1.313rem 0 0;
    padding: 8px 0;
}

.hcpsearchPage.search-results .title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

hr {
    color: $color-5;
    margin: 0 auto;
}

.hcpsearch-page .result-found-title p {
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 1.5rem 0;
}

.hcpsearchcards {
    width: 23%;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
}

.hcpsearchcards:nth-child(4n) {
    margin-right: 0;
}

.hcpsearchcards .event-img-container img {
    height: 145px;
    width: 100%;
    margin-bottom: 0;
}

.hcpsearchcards.event-img-container a img {
    opacity: .8;
}

.hcpsearchcards .event-img-container {
    position: relative;
}

.hcpsearchcards .event-img-container .middle {
    width: 100%;
    height: 145px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .4);
    overflow: hidden;
}

.hcpsearchcards .box_title {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 15px;
    margin: .5rem 0;
    text-transform: uppercase;
    width: 75%;
}

.hcpsearchcards .event_brief {
    height: 63px;
    width: 260px;
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}

.hcpsearchcards .share-save-container {
    display: flex;
}

.hcpsearchcards .box_title {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 15px;
    margin: .5rem 0;
    text-transform: uppercase;
    width: 75%;
}

.hcpsearchcards .share-book {
    display: block;
    width: 25%;
    text-align: right;
}

.hcpsearchcards ul.block-grid,
.hashtag ul {
    padding: 0;
    margin: 0;
}

.hcpsearchcards ul.block-grid li {
    width: auto;
    margin-right: .5rem !important;
    padding: 0;
    display: inline-block;
}

.hcpsearchcards ul.block-grid li:last-child {
    margin-left: 1rem;
}

.hcpsearchcards .post-heading {
    width: 100%;
    margin: .7rem 0;
}

.hcpsearchcards .post-heading a {
    text-decoration: none;
}

.hcpsearchcards .post-heading h4 {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    margin-bottom: 1rem;
}

.hcpsearchcards .hashtag ul {
    text-align: left;
}

.hcpsearchcards .searchcardcnt {
    padding: 10px;
}

.hcpsearchcards .hashtag ul li {
    width: auto;
    color: $color-26;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .1px;
    line-height: 18px;
    margin: 0;
    margin-right: .7rem;
    list-style: none;
    display: inline-block;
}

.hcpsearchcards .hashtag ul:not([class]) li::before {
    display: none;
}

.hcpsearchcards:hover .post-heading h4 {
    color: $color-26;
}

.hcpsearchcards:hover .middle {
    opacity: 1;
}

.hcpsearchcards:hover .event-img-container a img {
    opacity: .8;
}

.hcpsearchcards .box_title.event_date {
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
}

.hcpsearchcards .post-details {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
}

.hcpsearchcards .post-details {
    text-transform: uppercase;
    height: 24px;
    color: $color-24;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
}

.hcpsearchcards .line-event {
    height: 9px;
    margin: 0 .5rem;
    width: 68px;
    border-bottom: 1px solid $color-24;
}

.hcpsearchcards span.btn-tooltip {
    position: relative;
}

.hcpsearchcards span.btn-tooltip .tooltip-coming-soon {
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    min-width: 50px;
    max-width: 300px;
    top: -45px;
    left: 0;
    height: 20px;
    text-transform: uppercase;
    width: 98px;
    opacity: .6;
    background-color: $color-black;
    font-size: 13px;
    line-height: 20px;
    display: none;
}

.recursos-container .tab .tab-link:hover {
    border-bottom: 4px solid $color-22;
}

.recursos-container .tab .tab-link:hover a {
    color: $color-22;
}

.recursos-container {
    max-width: 1200px;
    margin: 0 auto;
}

.recursos-container .tab {
    border-bottom: 1px solid $color-27;
    display: flex;
    justify-content: center;
}

.recursos-container .tab .tab-link {
    margin: 2.664rem 5rem 0;
    padding-bottom: 1.25rem;
}

.recursos-container .tab .tab-link a {
    font-family: "IBMPlexRg";
    color: $color-5;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.recursos-container .tab .tab-link.active {
    border-bottom: 4px solid $color-22;
}

.recursos-container .tab .tab-link.active a {
    color: $color-22;
}

.recursos-container .title {
    margin-top: 4.563rem;
    width: 534px;
    color: $color-black;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
}

.recursos-container .inner-tab-continer .speciality-dropdown {
    text-align: right;
    float: right;
    margin-left: auto;
    position: relative;
}

.recursos-container .inner-tab-continer .custom-select {
    height: 40px;
    width: 180px;
    position: relative;
    background-color: transparent;
    display: table-header-group;
    margin-left: -8px;
    margin-right: 1rem;
}

.recursos-container .inner-tab-continer .selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    position: relative;
    text-align: center;
}

.recursos-container .inner-tab-continer div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: $color-5;
    font-family: "IBMPlexRg";
}

.recursos-container i.arrow.down {
    border: solid $color-22;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
}

.recursos-container .inner-tab-continer div.selected i.arrow.down {
    position: absolute;
    right: 14px;
    top: 14px;
}

.recursos-container .inner-tab-continer .select-box {
    display: none;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
}

.recursos-container ul.select-list li:hover,
.recursos-container ul.select-list li.active {
    background-color: $color-19;
}

.recursos-container .inner-tab-continer ul.select-list {
    padding: 5px 15px;
    max-height: 300px;
    overflow-y: scroll;
    background-color: $color-white;
    list-style-type: none;
    margin: 0;
}

.recursos-container .inner-tab-continer .select-list li {
    padding: 13px 10px;
    font-family: "IBMPlexRg";
    color: $color-black;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
}

.select-box.active {
    display: block;
}

.recursos-container .Sociedades-cientificas-container {
    display: flex;
    margin-top: 3rem;
}

.recursos-container .Sociedades-cientificas-container .container a {
    text-decoration: none;
}

.recursos-container .Sociedades-cientificas-container .image-container {
    height: 160px;
    width: 260px;
    background-color: $color-6;
    position: relative;
}

.recursos-container .Sociedades-cientificas-container .image-container .overlayimg {
    transition: .5s ease;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-black;
    opacity: 0;
}

.recursos-container .Sociedades-cientificas-container .container:hover .overlayimg {
    opacity: 20%;
}

.recursos-container .Sociedades-cientificas-container .container {
    margin: 4rem 2.5rem 2rem 0;
    width: 100%;
    max-width: 260px;
}

.recursos-container .Sociedades-cientificas-container .container .title-block {
    padding-top: 16px;
    color: $color-black;
    font-size: 1rem;
    font-family: "ExoRg";
    line-height: 24px;
    text-decoration: none;
}

.recursos-container .Sociedades-cientificas-container .container:hover .title-block {
    color: $color-22;
}

.recursos-container .Sociedades-cientificas-container .container .para-breaker {
    height: 1px;
    background: $color-27;
    margin: 1rem 0;
}

.recursos-container .Sociedades-cientificas-container .container .link-container {
    font-size: .875rem;
    font-family: "IBMPlexRg";
    line-height: 24px;
    color: $color-22;
    text-align: right;
    text-transform: uppercase;
}

.recursos-container .Sociedades-cientificas-container .container .link-container img {
    width: auto !important;
    margin: 0;
    display: inline-block;
}

.recursos-container .scientific-articles {
    margin-top: 7.5rem;
}

.recursos-container .scientific-articles-container {
    display: flex;
    align-items: center;
}

.recursos-container .scientific-articles-container .col-1.sa-spoti-img img {
    height: 343px;
    width: 100%;
    max-width: 561px;
}

.recursos-container .scientific-articles-container .col-2.width-30 {
    padding-left: 39px;
}

.recursos-container .scientific-articles-container .col-2.width-30 .cont-block {
    width: 100%;
    max-width: 396px;
    font-size: 30px;
    font-family: "ExoRg";
    line-height: 40px;
    margin-bottom: 2rem;
}

.recursos-container .scientific-articles-container .col-2.width-30 a.sa-redirect-btn {
    cursor: pointer;
    position: relative;
    border: 1px solid $color-22;
    text-transform: uppercase;
    border-radius: 24px;
    background-color: transparent;
    color: $color-22;
    padding: .981rem 3.513rem .981rem 1.513rem;
    font-size: .875rem;
    font-weight: 600;
}

.recursos-container .scientific-articles-container .col-2.width-30 .btn-redir-icon::after {
    content: "";
    pointer-events: none;
    display: inline-block;
    background-image: url("/static/img/redo.png");
    top: 50%;
    right: 1.7rem;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 14px;
    height: 13px;
    background-size: 14px 13px;
    background-repeat: no-repeat;
}

.recursos-container .scientific-articles-container .col-2.width-30 a.sa-redirect-btn:hover {
    background-color: $color-22;
    color: $color-white;
}

.recursos-container .scientific-articles-container .col-2.width-30 a.sa-redirect-btn:hover::after {
    background-image: url("/static/img/redo-white.png");
}

.recursos-container .clinical-cases {
    padding: 5rem 0;
}

.recursos-container .clinical-cases .p.form-header {
    font-size: 1.5rem;
    font-family: "ExoRg";
    margin-bottom: 5rem;
    width: 100%;
    max-width: 57%;
    line-height: 28px;
    color: $color-black;
}

.recursos-container .clinical-cases .input-group {
    display: inline;
}

.recursos-container .clinical-cases .input-group .search-box {
    float: left;
    position: relative;
}

.recursos-container .clinical-cases .input-group .search-box svg {
    position: absolute;
    right: 0;
    bottom: 8px;
}

.recursos-container .clinical-cases .input-group .event-select {
    float: right;
}

.recursos-container .clinical-cases .input-group .search-box input.form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid $color-24;
    height: 36px;
    width: 260px;
    font-weight: bold;
    font-size: 14px;
    font-family: "IBMPlexRg";
    letter-spacing: .5px;
    line-height: 18px;
}

.recursos-container .clinical-cases .input-group .search-box form img.search-icon {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 9px;
    right: 0;
}

.recursos-container .recursos-container .clinical-cases .input-group .search-box input.form-control::placeholder {
    color: $color-black;
    opacity: 1;
}

.recursos-container .recursos-container .clinical-cases .toggle-switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
}

.recursos-container .recursos-container .clinical-cases .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.recursos-container .recursos-container .clinical-cases .switch-label {
    padding-right: 10px;
}

.recursos-container .recursos-container .clinical-cases .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-6;
    -webkit-transition: .4s;
    transition: .4s;
}

.recursos-container .recursos-container .clinical-cases .slider::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 3px;
    background-color: $color-white;
    -webkit-transition: .4s;
    transition: .4s;
}

.recursos-container .clinical-cases input:checked + .slider {
    background-color: $color-14;
}

.recursos-container .clinical-cases input:focus + .slider {
    box-shadow: 0 0 1px $color-14;
}

.recursos-container .clinical-cases input:checked + .slider::before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.recursos-container .clinical-cases .slider.round {
    border-radius: 34px;
}

.recursos-container .clinical-cases .slider.round::before {
    border-radius: 50%;
}

.recursos-container .clinical-cases .fill-blue {
    background-color: $color-22;
    color: $color-white !important;
}

.recursos-container .clinical-cases .active {
    background-color: $color-white;
    color: $color-black;
}

.recursos-container .clinical-cases .active a {
    background-color: $color-22;
    color: $color-white !important;
}

.recursos-container .clinical-cases .clear {
    clear: both;
}

.recursos-container .clinical-cases span.switch-label {
    font-size: 12px;
}

.recursos-container .clinical-cases .arrow {
    border: solid $color-22;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    margin-bottom: 2px;
}

.recursos-container .clinical-cases .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.recursos-container .clinical-cases-container {
    display: flex;
    margin-top: 3rem;
    flex-wrap: wrap;
}

.recursos-container .clinical-cases-container .cc-container a {
    text-decoration: none;
}

.recursos-container .clinical-cases-container .image-container {
    height: 160px;
    width: 260px;
    background-color: $color-6;
    position: relative;
}

.recursos-container .clinical-cases-container .image-container:hover {
    background-color: $color-22;
    opacity: 95%;
}

.recursos-container .clinical-cases-container .image-container:hover .textOverImage .case,
.textOverImage .disease {
    color: $color-white;
}

.recursos-container .clinical-cases-container .image-container:hover .textOverImage .disease {
    color: $color-white;
}

.recursos-container .clinical-cases-container .cc-container {
    margin: 2rem 2.5rem 2rem 0;
    width: 100%;
    max-width: 260px;
}

.recursos-container .clinical-cases-container .container .title-block {
    padding-top: 16px;
    color: $color-black;
    font-size: 1rem;
    font-family: "ExoRg";
    line-height: 24px;
    text-decoration: none;
}

.recursos-container .clinical-cases-container .cc-container .download-link {
    font-size: .875rem;
    font-family: "IBMPlexRg";
    line-height: 18px;
    margin-top: 1rem;
    color: $color-22;
    text-align: left;
    text-transform: uppercase;
}

.recursos-container .clinical-cases-container .cc-container .download-link::after {
    background-image: url("/static/img/download-awb.png");
    background-size: 9px 11px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 10px;
    height: 11px;
    content: "";
}

.recursos-container .clinical-cases-container .cc-container .textOverImage {
    text-align: center;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.recursos-container .clinical-cases-container .cc-container .textOverImage .case {
    font-size: 12px;
    font-family: "IBMPlexRg";
    color: $color-24;
    text-transform: uppercase;
    line-height: 22px;
}

.recursos-container .clinical-cases-container .cc-container .textOverImage .disease {
    font-size: 20px;
    font-family: "ExoRg";
    font-weight: 700;
    color: $color-black;
    line-height: 26px;
    opacity: 90%;
}

.recursos-container .clinical-cases-container .cc-container .download-eyeview {
    display: flex;
    justify-content: space-between;
}

.recursos-container .clinical-cases-container .cc-container .eye-view {
    position: relative;
    margin-top: 1rem;
    color: $color-22;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "IBMPlexRg";
}

.clinical-cases-container .cc-container .eye {
    position: absolute;
    bottom: 0;
    right: 0;
}

.recursos-container .clinical-cases-container .cc-container .eye::before {
    background-image: url("/static/img/eye.png");
    background-size: 20px 16px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 34px;
    height: 27px;
    content: "";
}

.recursos-container .clinical-cases-container .cc-container .eye-view::after {
    background-image: url("/static/img/eye-blue.png");
    background-size: 15px 11px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 11px;
    content: "";
}

.recursos-container .clinical-cases div.custom-select {
    height: 40px;
    width: 140px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 20px;
}

.recursos-container .clinical-cases div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.recursos-container .clinical-cases div.selected i.arrow.down {
    position: absolute;
    right: 14px;
    top: 14px;
}

.recursos-container .clinical-cases div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-family: "IBMPlexRg";
}

.recursos-container .clinical-cases div.select-box {
    display: none;
    width: 100%;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid $color-27;
    box-shadow: 0 8px 16px 0 $color-27;
}

.recursos-container .clinical-cases div.select-box.active {
    display: block;
}

.recursos-container .clinical-cases div.select-box.drop-up {
    top: auto;
    bottom: 100%;
}

.recursos-container .clinical-cases ul.select-list {
    margin: 0;
    padding: 10px;
    list-style-type: none;
}

.recursos-container .clinical-cases ul.select-list li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
}

.recursos-container .clinical-cases ul.select-list li:hover,
.recursos-container .clinical-cases ul.select-list li.active {
    background-color: $color-19;
}

.recursos-container .clinical-cases span.downArrowBar {
    font-size: 14px;
    padding-left: 5px;
}

.audiovisual-container {
    max-width: 1200px;
    margin: 0 auto;
}

.audiovisual-container .audiovisual-content {
    padding: 5rem 0 .5rem;
}

.audiovisual-container .audiovisual-content .p.form-header {
    font-size: 1.5rem;
    margin-bottom: 5rem;
    font-family: "ExoRg";
    color: $color-black;
    line-height: 28px;
    text-align: left;
}

.audiovisual-container .audiovisual-content .input-group {
    display: inline;
}

.audiovisual-container .audiovisual-content .input-group .search-box {
    float: left;
    position: relative;
}

.audiovisual-container .audiovisual-content .input-group .search-box form {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    height: 42px;
    width: 260px;
}

.audiovisual-container .audiovisual-content .input-group .search-box form img.search-icon {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 9px;
    right: 0;
}

.audiovisual-container .audiovisual-content .input-group .event-select {
    float: right;
}

.audiovisual-container .audiovisual-content .input-group .search-box input.form-control {
    outline: none;
    border: none;
    width: 230px;
    font-weight: bold;
    font-family: "IBMPlexRg";
    font-size: 14px;
}

.audiovisual-container .audiovisual-content .input-group .search-box input.form-control::placeholder {
    color: $color-black;
    font-size: 14px;
    font-family: "IBMPlexRg";
    letter-spacing: .5px;
    line-height: 18px;
    opacity: 1;
}

.audiovisual-container .audiovisual-content .listInterests ul {
    padding: 0;
}

.audiovisual-container .audiovisual-content .listInterests ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid #d1d1d1;
    border-radius: 24px;
    padding: 7px 16px;
    margin-bottom: 16px;
    margin-right: 13px;
    cursor: pointer;
}

.audiovisual-container .audiovisual-content .listCommon ul li {
    border: 1px solid $color-26;
}

.audiovisual-container .audiovisual-content .listCommon ul li:hover {
    background-color: $color-26;
}

.audiovisual-container .audiovisual-content .listCommon ul li:hover a {
    color: $color-white;
}

.audiovisual-container .audiovisual-content .listCommon ul li a {
    color: $color-26;
    font-weight: 500;
    text-decoration: none;
    font-size: 12px;
    font-family: "IBMPlexRg";
    letter-spacing: .5px;
}

.audiovisual-container .audiovisual-content .clear {
    clear: both;
}

.audiovisual-container .audiovisual-content .arrow {
    border: solid $color-26;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    margin-bottom: 2px;
}

.audiovisual-container .audiovisual-content .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.audiovisual-container .audiovisual-content div.custom-select {
    height: 40px;
    width: 140px;
    position: relative;
    background-color: transparent;
    display: inline-block;
    float: left;
    margin-left: 15px;
}

.audiovisual-container .audiovisual-content div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.audiovisual-container .audiovisual-content div.selected i.arrow.down {
    position: absolute;
    right: 14px;
    top: 14px;
}

.audiovisual-container .audiovisual-content div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-family: "IBMPlexRg";
    text-transform: uppercase;
}

.audiovisual-container .audiovisual-content div.select-box {
    display: none;
    width: 100%;
    z-index: 10029;
    position: absolute;
    border-radius: 3px;
    border: 1px solid #e4e7eb;
    box-shadow: 0 8px 16px 0 #e4e7eb;
}

.audiovisual-container .audiovisual-content div.select-box.active {
    display: block;
}

.audiovisual-container .audiovisual-content div.select-box.drop-up {
    top: auto;
    bottom: 100%;
}

.audiovisual-container .audiovisual-content div.select-box ul.select-list {
    margin: 0;
    padding: 10px;
    list-style-type: none;
}

.audiovisual-container .audiovisual-content div.select-box ul.select-list li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
}

.audiovisual-container .audiovisual-content div.select-box ul.select-list li:hover,
.audiovisual-content div.select-box ul.select-list li.active {
    background-color: $color-19;
}

.audiovisual-container .audiovisual-events .events h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 2.5rem;
    text-transform: none;
    text-align: left;
}

.audiovisual-container .audiovisual-events .events h2::after {
    display: none;
}

.audiovisual-events .events .event-box-container {
    display: flex;
    margin-bottom: 3.5rem;
}

.audiovisual-events .events a {
    text-decoration: none;
}

.audiovisual-events .events .event-box-container .event-box {
    width: 100%;
    max-width: 260px;
    margin-right: 40px;
}

.audiovisual-events .events .event-img-container {
    width: 100%;
    max-width: 260px;
    max-height: 168px;
    position: relative;
}

.audiovisual-events .events .event-img-container img {
    width: 100%;
    max-width: 260px;
    max-height: 168px;
}

.audiovisual-events .events .event-img-container .head-play {
    background-size: 20px auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 39%;
    left: 41%;
    background-color: $color-white;
    border-radius: 50%;
    padding: .7rem;
    z-index: 1;
}

.audiovisual-events .events .event-img-container .wavy-earphone {
    background-image: url("/static/img/earphone-blue.png");
}

.audiovisual-events .events .event-img-container .play-music {
    background-image: url("/static/img/play-blue.png");
    background-size: 12px auto;
    background-position: 17px center;
}

.audiovisual-events .events .event-box-container .event-box .box_content:hover .event-img-container .wavy-earphone {
    background-image: url("/static/img/earphone-white.png");
    background-color: $color-26;
}

.audiovisual-events .events .event-box-container .event-box .box_content:hover .event-img-container .play-music {
    background-image: url("/static/img/play-white.png");
    background-color: $color-26;
}

.audiovisual-events .events .event-box-container .event-box .box_content:hover .event-img-container .middle {
    height: 168px;
    width: inherit;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    z-index: 0;
}

.audiovisual-events .img-btm-content .share-save-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}

.audiovisual-events .img-btm-content .share-save-container .share-book ul.block-grid {
    list-style: none;
    margin: 0;
    display: flex;
}

.audiovisual-events .img-btm-content .share-save-container .box_title {
    font-size: 12px;
    font-family: "IBMPlexRg";
    line-height: 22px;
    letter-spacing: .5px;
    color: $color-black;
    text-transform: uppercase;
}

.audiovisual-events .img-btm-content .post-heading {
    text-transform: initial !important;
    font-size: 16px;
    font-family: "ExoRg";
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 1.5rem;
    color: $color-black;
    text-align: left;
}

.audiovisual-events .events .event-box-container .event-box .hashtag ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 260px;
    margin: 0;
    padding: 0;
}

.audiovisual-events .events .event-box-container .event-box .hashtag ul a {
    margin-right: 1rem;
    font-size: 14px;
    font-family: "IBMPlexRg";
    line-height: 24px;
    letter-spacing: .5px;
    color: $color-26;
}

.audiovisual-events .share-book ul.block-grid span.btn-tooltip {
    position: relative;
    cursor: pointer;
}

.audiovisual-events .share-book ul.block-grid span.btn-tooltip .tooltip-coming-soon {
    min-width: 111px;
    max-width: 300px;
    font-family: "IBMPlexRg";
    height: 16px;
    top: -40px;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    text-transform: uppercase;
    opacity: .6;
    background-color: $color-black;
}

.audiovisual-bigEvent .bigEvent-box-container {
    width: 100%;
    max-width: 1161px;
    margin-bottom: 4.5rem;
}

.audiovisual-bigEvent .bigEvent-box-container .event-box a {
    text-decoration: none;
}

.audiovisual-bigEvent .bigEvent-img-container {
    max-height: 648px;
    max-width: 1161px;
    width: 100%;
    position: relative;
}

.audiovisual-bigEvent .bigEvent-img-container img {
    max-width: 1161px;
    width: 100%;
    max-height: 648px;
}

.audiovisual-bigEvent .bigEvent-img-container .video-area .video-js.vjs-fluid {
    padding-top: 22%;
}

.audiovisual-bigEvent .img-btm-content .share-save-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.audiovisual-bigEvent .img-btm-content h4.post-heading {
    text-transform: none;
    font-family: "IBMPlexRg";
    letter-spacing: 0;
    line-height: 38px;
    margin-top: 10px;
    margin-bottom: 1.5rem;
    color: $color-black;
    text-align: left;
    width: 100%;
    max-width: 802px;
    font-size: 1.6rem;
    font-weight: bold;
}

.audiovisual-bigEvent .img-btm-content h4.post-heading .presenter-detail {
    font-size: 1rem;
    color: $color-black;
}

.audiovisual-bigEvent .img-btm-content .p {
    font-size: 16px;
    font-family: "IBMPlexRg";
    line-height: 26px;
    letter-spacing: normal;
    color: $color-black;
    margin-bottom: 1.5rem;
    max-width: 802px;
    width: 100%;
}

.audiovisual-bigEvent .event-box .hashtag ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 224px;
    margin: 0;
    padding: 0;
}

.audiovisual-bigEvent .event-box .hashtag ul a {
    margin-right: 1rem;
    font-size: 14px;
    font-family: "IBMPlexRg";
    line-height: 18px;
    letter-spacing: .5px;
    color: $color-26;
}

.audiovisual-bigEvent .img-btm-content .share-save-container .share-book ul.block-grid {
    list-style: none;
    margin: 0;
    display: flex;
}

.audiovisual-bigEvent .img-btm-content .share-save-container .box_title {
    font-size: 12px;
    font-family: "IBMPlexRg";
    line-height: 22px;
    letter-spacing: .5px;
    color: $color-black;
    text-transform: uppercase;
}

.audiovisual-bigEvent .share-book ul.block-grid span.btn-tooltip {
    position: relative;
    cursor: pointer;
}

.audiovisual-bigEvent .share-book ul.block-grid span.btn-tooltip .tooltip-coming-soon {
    min-width: 111px;
    max-width: 300px;
    font-family: "IBMPlexRg";
    height: 16px;
    top: -40px;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    text-transform: uppercase;
    opacity: .6;
    background-color: $color-black;
}

.audiovisual-episode-content .audiovisual-events .events .event-box-container .event-box:nth-child(1) .event-img-container::after {
    position: absolute;
    max-width: 119px;
    width: 100px;
    height: 8px;
    background-color: $color-26;
    bottom: 0;
    left: 0;
    z-index: 0;
    content: '';
}
