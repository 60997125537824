.contact-teaser {
    &_btn {
        width: 100%;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .contact-teaser {
        &_subheading {
            text-align: center;
        }

        &_paragraph {
            padding-left: 60px;
        }
    }

    .layout-column-contact-teaser-content {
        margin-left: layout-column-width(4);
        width: layout-column-width(4);
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column-contact-teaser-content {
        margin-left: layout-column-width(4.5);
        width: layout-column-width(3);
    }
}
