@font-face {
    font-family: 'TradegothicLtStd';
    src: url('../fonts/tradegothic/tradegothicltstd.eot');
    src:
        url('../fonts/tradegothic/tradegothicltstd.woff') format('woff'),
        url('../fonts/tradegothic/tradegothicltstd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TradeGothicLTStdBoldCondensed';
    src: url('../fonts/tradegothic/tradegothicltstd-bdcn20-webfont.eot');
    src:
        url('../fonts/tradegothic/tradegothicltstd-bdcn20-webfont.woff') format('woff'),
        url('../fonts/tradegothic/tradegothicltstd-bdcn20-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tradegothicltstdcn18';
    src: url('../fonts/tradegothic/tradegothicltstd-cn18.eot');
    src:
        url('../fonts/tradegothic/tradegothicltstd-cn18.woff') format('woff'),
        url('../fonts/tradegothic/tradegothicltstd-cn18.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
