$cookie-bar-color-background: $color-7 !default;

.layout-row--cookie-bar {
    margin: auto;
    padding: 2em 0;
}

.cookie-bar {
    background: $cookie-bar-color-background;
    display: none;
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-row--cookie-bar {
        position: relative;
        padding: 2em 0 0;
    }

    .cookie-bar {
        &_text {
            padding-right: 30%;
        }

        &_btn {
            position: absolute;
            right: 10px;
            transform: translateY(-50%);
            top: 50%;
        }
    }
}
