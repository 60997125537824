$color-white: #fff !default;
$color-black: #000 !default;
$color-blue: aliceblue !default;

picture {
    position: relative;
}

:focus {
    outline: none;
}

.vjs-playlist .vjs-playlist-duration {
    display: block;
}

#owl_carousel_video_wrapper {
    margin-top: 2rem;
    background: transparent;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 30px;
    width: 40px;
    height: 40px;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    background: rgba(0, 0, 0, 6) !important;
    border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot {
    background: transparent;
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: rgba(0, 0, 0, 1) !important;
}

.vjs-playlist .vjs-relatedPlaylist-name,
.vjs-playlist .vjs-playlist-duration,
.vjs-playlist-name {
    font-size: 12px;
}

.bcls-playlist-wrapper {
    background-color: transparent;
    width: 100%;
    line-height: 76px;
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    white-space: nowrap;
    margin-top: 3em;
    padding: 0;
}

#left-arrow {
    color: $color-blue;
    font-size: 40px;
    position: absolute;
    margin: auto;
    background: rgba(245, 41, 5, 1);
    left: 0;
    z-index: 1;
    cursor: pointer;
}

.vjs-mouse.vjs-playlist .vjs-playlist-item {
    height: auto;
}

.video-js {
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    color: $color-white;
    background-color: $color-black;
    position: relative;
    padding: 28% 0;
    font-size: 10px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    word-break: initial;
    width: 100%;
}

.vjs-mouse.vjs-playlist .vjs-playlist-item .vjs-relatedPlaylist-item {
    height: auto;
}

.vjs-playlist .vjs-playlist-duration {
    background-color: rgba(26, 26, 26, .8);
    left: 0;
    padding: 2px 8px;
    position: absolute;
    top: 0;
}

.vjs-mouse.vjs-playlist .vjs-playlist-thumbnail {
    width: 100%;
    height: auto;
    position: relative;
    display: block;
}

div {
    display: block;
}

.vjs-playlist .vjs-playlist-item-list {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.vjs-mouse.vjs-playlist {
    min-width: auto;
    background: transparent;
    padding: 0;
    margin-top: 0;
}

.vjs-playlist .vjs-relatedPlaylist-name {
    display: block;
    max-height: 2.5em;
    padding: 0 0 4px 2px;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 20px;
    color: $color-black;
}

.vjs-mouse.vjs-playlist .vjs-selected .vjs-playlist-thumbnail::before {
    height: 100%;
    line-height: 8em;
    width: 100%;
}

.vjs-mouse.vjs-playlist .vjs-playlist-item:focus .vjs-relatedPlaylist-item:focus .vjs-playlist-thumbnail::before,
.vjs-mouse.vjs-playlist .vjs-playlist-item:hover .vjs-relatedPlaylist-item:hover .vjs-playlist-thumbnail::before {
    border-radius: 5px;
    font-family: VideoJS;
    height: 34px;
    line-height: 34px;
    top: 38px;
    width: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
}

.video-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.video-player-container {
    width: 100%;
    min-height: 10em;
    position: relative;
}

.related-videos-list li a,
.most-popular-videos li a {
    text-decoration: none;
    display: block;
    color: $color-black;
}

.related-videos-list li {
    width: 100%;
    position: relative;
    margin-bottom: .5em;
}

.video-player {
    width: 100%;
    position: relative;
}

.related-videos {
    width: 100%;
}

.related-videos-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.related-videos-list li a picture::after,
.most-popular-videos li a picture::after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.related-videos-list li a:hover picture::after,
.most-popular-videos li a:hover picture::after {
    background: $color-black;
    opacity: .5;
}

.related-videos-list li picture,
.most-popular-videos li picture {
    max-width: 100%;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    padding: 0;
    margin: 0;
    position: relative;
}

.related-videos-list li picture img,
.most-popular-videos li picture img {
    width: 100%;
    display: block;
    margin: 0;
}

.most-popular-videos {
    margin-top: 3em;
    width: 100%;
    overflow: hidden;
}

.most-popular-videos ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.most-popular-videos li {
    width: 100%;
    margin-bottom: 1em;
    display: inline-block;
}

.related-videos {
    margin-top: 3em;
}

picture {
    width: 100%;
    height: 100%;
    position: absolute;
}

.vjs-playlist {
    min-width: 100%;
    background: transparent;
}

.video-container h2,
.video-main-title {
    font-size: 1.2rem;
    margin: 0 0 3rem;
}

.video-main-title {
    width: 70%;
}

.video-main-title::after {
    bottom: -1.25rem;
    width: 125px;
}

.video-container .h2::after,
.video-container h2::after {
    bottom: -1.25rem;
    width: 125px;
}

.vjs-playlist .vjs-playlist-title-container {
    position: relative;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: .5rem .8rem;
    text-shadow: none;
    color: $color-black;
}

.vjs-playlist .vjs-relatedPlaylist-name {
    padding: .3rem;
}

.video-description {
    padding: 1rem;
}

@media only screen and (min-width: 600px) {
    .video-container {
        width: 95%;
    }

    .related-videos-list li a,
    .most-popular-videos li a {
        display: flex;
    }

    .most-popular-videos li,
    .related-videos-list li {
        margin-bottom: 1em;
    }

    .related-videos-list li figcaption,
    .most-popular-videos li figcaption {
        flex: 5;
    }

    .related-videos-list li picture,
    .most-popular-videos li picture {
        flex: 1;
    }
}

@media only screen and (min-width: 769px) {
    .video-container {
        display: flex;
    }

    .video-player {
        width: 80%;
        height: auto;
        margin-right: 2em;
    }

    .related-videos {
        width: 18%;
    }

    .video-player-container {
        width: 100%;
        background: #ccc;
        min-height: 30em;
    }

    .most-popular-videos li {
        margin-left: 1em;
        margin-bottom: 0;
    }

    .most-popular-videos li:first-child {
        margin-left: 0;
    }

    .most-popular-videos li:first-child {
        margin-left: 0;
    }

    .related-videos-list li a,
    .most-popular-videos li a {
        display: block;
    }

    .related-videos {
        margin-top: 0;
    }

    .related-videos .vjs-mouse.vjs-playlist {
        height: 1050px;
    }
}

@media only screen and (min-width: 1201px) {
    .video-container {
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    .owl-prev,
    .owl-next {
        top: 16px;
    }
}
