.overly-sign {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    display: none;
}

#registration-form {
    display: none;
}

.sign-up-form.thankyou {
    display: none;
}

.sign-up-form {
    position: absolute;
    top: 0;
    z-index: 11;
    width: 100%;
    max-width: 680px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.slovakia-login {
    box-shadow: 0 10px 14px -5px rgba(29, 75, 163, .3);
    margin: 0 auto;
    display: flex;
    max-width: 1200px;
}

.slovakia-login .col1 {
    background: $color-12;
    width: 50%;
    padding: 10rem 4rem;
}

.slovakia-login .col2 {
    background: $color-white;
    width: 50%;
    padding: 50px;
}

.slovakia-login .col2 .p {
    color: $color-5;
    text-align: center;
    margin: 1rem 0;
}

.slovakia-login .col1 .p {
    color: $color-white;
    position: relative;
    top: 30%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.slovakia-login .col1 .p.blue-txt {
    color: $color-22;
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

.slovakia-login .amgen-img {
    width: 34%;
    margin: 0 auto;
}

.slovakia-login .form {
    padding: 2rem;
}

.slovakia-login .submit-btn {
    display: flex;
    justify-content: left;
    margin-top: .7rem;
    padding: 0 2rem;
}

.slovakia-login .btn {
    text-transform: uppercase;
    font-weight: bold;
    padding: 1rem 1.5rem;
    color: $color-white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width: auto;
    opacity: .9;
    font-size: 1rem;
}

.slovakia-login .btn:focus {
    outline: none;
}

.slovakia-login .btn:hover {
    opacity: 1;
}

.slovakia-login .btn1 {
    background: $color-5;
    margin-right: 2rem;
}

.slovakia-login .btn2 {
    background: $color-20;
}

.slovakia-login .input-container {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    border: 1px solid$color-4;
    border-radius: 13px;
}

.slovakia-login .icon {
    padding: 10px;
    border: 1px solid $color-6;
    color: $color-6;
    min-width: 50px;
    text-align: center;
    border-right: none;
}

.slovakia-login input.input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background: transparent;
}

.slovakia-login label {
    color: $color-5;
}

.slovakia-login a.terms-link {
    color: $color-5;
    font-weight: bold;
}

.slovakia-login span.login-icon {
    margin: .3rem 0;
    padding: 0 .5rem;
    border-right: 1px solid $color-4;
}

.slovakia-login span.login-icon img {
    width: 1.5rem;
    height: 2.5rem;
    margin: 0;
}

.slovakia-login .privacy-container {
    display: flex;
}

.slovakia-login hr {
    margin: 1.5rem 0;
}

.slovakia-login .privacy-container input {
    margin-right: 1rem;
}

.slovakia-signup form {
    max-width: 500px;
    margin: auto;
    padding: 1rem 0 2rem;
}

.slovakia-signup .input-container {
    width: 100%;
    margin-bottom: 8px;
}

.sign-up-error-message {
    color: $color-error;
    font-size: 10px;
    display: none;
    padding: 5px 0 0;
}

.sign-in-error-message {
    color: $color-error;
    font-size: 10px;
    display: none;
    padding: 5px 0 0;
    position: absolute;
    bottom: -16px;
}

.slovakia-signup .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
}

.slovakia-signup .input-field:focus {
    border: 2px solid $color-4;
}

.slovakia-signup .btn {
    text-transform: uppercase;
    font-weight: bold;
    color: $color-white;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 27%;
    opacity: .9;
    background: $color-20;
    border-radius: 5px;
}

.slovakia-signup .btn:hover {
    opacity: 1;
}

.slovakia-signup {
    background: $color-white;
    box-shadow: 0 10px 14px -5px rgba(29, 75, 163, .3);
    margin: 5rem auto 0;
    position: relative;
}

.slovakia-signup h2 {
    background: $color-12;
    padding: 1rem;
    text-align: center;
    color: $color-white;
    margin: 0;
}

.slovakia-signup .submit-btn {
    text-align: center;
}

.slovakia-signup .close-icon {
    position: absolute;
    display: flex;
    right: 1rem;
    flex-direction: row;
    justify-content: flex-end;
    top: .9rem;
}

.slovakia-signup .close-icon img {
    height: auto;
    cursor: pointer;
}

.slovakia-thankyou-form {
    background: white;
    box-shadow: 0 10px 14px -5px rgba(29, 75, 163, .3);
    min-height: 20rem;
    text-align: center;
    max-width: 1200px;
    position: relative;
}

.slovakia-thankyou-form h2 {
    background: $color-12;
    padding: 2rem 1rem;
    text-align: center;
    color: $color-white;
}

.slovakia-thankyou-form .icon {
    padding: 10px;
    color: $color-20;
    min-width: 50px;
    text-align: center;
    font-size: 10rem;
}

.slovakia-thankyou-form p {
    font-weight: bold;
    color: $color-6;
}

.slovakia-thankyou-form .close-icon {
    position: absolute;
    display: flex;
    right: 1rem;
    flex-direction: row;
    justify-content: flex-end;
    top: .9rem;
}

.slovakia-thankyou-form .close-icon img {
    width: 20%;
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

@media (max-width: 768px) {
    .slovakia-login {
        display: block;
    }

    .slovakia-login .col1 {
        width: 50%;
        margin: 0 auto;
    }

    .slovakia-login .col2 {
        margin: 0 auto;
        width: 63%;
    }
}

@media (max-width: 540px) {
    .slovakia-login .col2 {
        margin: 0 auto;
        width: 74%;
    }

    .slovakia-signup {
        width: 93%;
    }

    .slovakia-thankyou-form {
        width: 93%;
    }
}

@media (max-width: 414px) {
    .slovakia-login .col1 {
        width: 66%;
        padding: 10rem 4rem;
        margin: 0 auto;
    }

    .slovakia-login .submit-btn {
        justify-content: center;
    }

    .slovakia-login .btn {
        padding: .5rem;
    }
}
