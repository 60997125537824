$teaser-color-details: $color-6 !default;
$teaser-color-background: $color-4 !default;
$teaser-color-background-hover: $color-2 !default;
$teaser-color-text-hover: $color-white !default;
$teaser-color-text-video-hover: $color-2 !default;

$row--standard-teaser-background-color: #dbdadb !default;

.layout-row--teaser-press-row {
    @include component-margin;
}

.layout-row--standard-teaser {
    @include component-margin;
    background-color: $row--standard-teaser-background-color;
}

.layout-column--standard {
    padding-top: 2em;

    &_content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &_title {
        background-color: $row--standard-teaser-background-color;
        padding-top: em(60px);
        margin-left: layout-column-width(2);
        width: layout-column-width(8); // 4van12
    }
}

.teaser {
    position: relative;
    margin-bottom: $layout-element-margin-default;
    padding: 20px;
    background: $teaser-color-background;
    display: flex;
    flex-direction: column;

    .layout-row--standard-teaser & {
        background: $color-white;
        flex: 1;
    }

    &_figure {
        display: block;
        margin: -20px -20px 20px;

        .teaser--standard &,
        .teaser--slider &,
        .teaser--video & {
            margin: 0 0 20px;
            min-height: 1px; // counter IE11- flex sizing bug
        }
    }

    &_image {
        max-width: 100%;
        margin: 0;
        display: inherit;
    }

    &_category {
        display: inline-block;
        margin: 0 -4px 20px 0;
        font-size: em(15px);
        color: $teaser-color-details;

        &::after {
            content: '|';
            display: inline-block;
            margin: -1px 8px 0;
            font-size: 1.067em;
        }
    }

    &_date {
        display: inline-block;
        margin: 0 0 20px;
        font-size: em(15px);
        color: $teaser-color-details;
    }

    &_text {
        flex-grow: 1;

        &--secondary {
            color: $teaser-color-details;
        }
    }

    &_anchor {
        text-decoration: none;

        .layout-column--slider &,
        .teaser--video & {
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &:hover {
        &:not(.teaser--standard):not(.teaser--video):not(.teaser--nolink),
        .layout-column--slider &:not(.teaser--nolink) {
            background: $teaser-color-background-hover;
            transition: background .3s ease;
            cursor: pointer;

            * {
                color: $teaser-color-text-hover;
                transition: color .3s ease;
            }
        }
    }

    &--slider {
        .btn {
            align-self: flex-start;
        }
    }

    &--video {
        background-color: $color-white;
        padding: 0;

        * {
            transition: color .3s ease;
        }

        .teaser_text--secondary {
            margin-bottom: 0;
        }

        &:hover {
            * {
                color: $teaser-color-text-video-hover;
            }

            .teaser_figure {
                &::before {
                    background-color: rgba($teaser-color-text-video-hover, .85);
                }
            }
        }

        .teaser_figure {
            position: relative;

            &::after {
                @include position(absolute, 50% false false 50%);
                transform: translate(-50%, -50%);
                display: block;
                content: '';
                height: 0;
                width: 0;
                pointer-events: none;
                border-left: 25px solid $color-white;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
            }

            &::before {
                @include position(absolute, 0 0 0 0);
                content: '';
                pointer-events: none;
                background-color: transparent;
                transition: background-color .3s ease;
            }
        }
    }
}

.slider .layout-column--teaser-video-row:first-child {
    margin-left: 0;
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--teaser-press-overview {
        width: layout-column-width(4);

        > .teaser {
            &:not(:last-child) {
                margin-bottom: $layout-gutter-large;
            }
        }
    }

    .layout-column--teaser-press-row {
        width: layout-column-width(6);
    }

    .layout-column--facebook-api-row {
        width: layout-column-width(6);
    }

    .layout-column--teaser-video-row-header {
        margin-left: layout-column-width(1);
        width: layout-column-width(9);
    }

    .layout-column--teaser-video-row {
        width: layout-column-width(3);

        &:first-child {
            margin-left: layout-column-width(1);
        }
    }

    .layout-column--standard {
        &_content {
            flex-wrap: nowrap;
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--teaser-press-overview {
        width: layout-column-width(3);
    }

    .layout-column--standard {
        width: layout-column-width(4);

        .layout-row--2-column & {
            width: layout-column-width(6); // 4van12
        }

        .layout-row--1-column & {
            margin-left: layout-column-width(2);
            width: layout-column-width(8); // 4van12
        }
    }

    .layout-column--teaser-press-row {
        width: layout-column-width(3); // 3van12
    }

    .layout-column--facebook-api-row {
        width: layout-column-width(4); // 4van12
    }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait) {
    .layout-column--teaser-video-row {
        width: 50%;
    }

    .layout-column--teaser-video-row:first-child {
        margin-left: 0;
    }
}

@media only screen and (max-device-width: 736px) and (min-device-width: 414px) and (orientation: landscape) {
    .layout-column--teaser-video-row {
        width: 50%;
    }

    .layout-column--teaser-video-row:first-child {
        margin-left: 0;
    }
}
