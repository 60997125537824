.breadcrumb {
    display: none;
    width: 100%;
    padding: rem(10px);
    text-transform: uppercase;
    border: 1px solid $color-2;

    > li {
        display: inline-block;

        a {
            text-decoration: none;
        }

        &:last-child {
            a {
                color: $color-2;
            }
        }
    }

    > li + li {
        margin-left: rem(10px);

        &::before {
            content: '/';
            margin-right: rem(10px);
        }
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .breadcrumb {
        display: block;
    }
}
