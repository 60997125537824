$form-color-element-background: $color-white !default;
$form-color-filter-background: $color-4 !default;
$form-color-element-border: $color-4 !default;
$form-select-arrow-img: bg-dropdown-blue-repatha !default;
$form-color-error-border: $color-error !default;
$form-color-valid-border: $color-4 !default;
$form-color-error-text: $color-error !default;
$form-color-focus: $color-5 !default;

input,
select,
textarea {
    @include font-primary;
    border: 1px solid $form-color-element-border;
    padding: em(13px) em(13px) em(9px) em(25px);
    width: 100%;

    &[type='radio'],
    &[type='checkbox'] {
        width: auto;
    }

    .form-layout--disabled & {
        cursor: not-allowed;
        pointer-events: none;
    }

    + label {
        margin-left: em(10px);
    }

    &:focus {
        border-color: $form-color-focus;
        transition: border-color .3s ease-in-out;
        outline: none;
    }
}

[type='text'],
[type='number'],
[type='email'],
select {
    min-height: 49px;
    background: $form-color-element-background;
}

select {
    appearance: none;
    padding: em(13px) em(80px) em(9px) em(25px);
    background: $form-color-element-background url('../img/#{$form-select-arrow-img}.svg') no-repeat 100% 50%;
    border-radius: none;

    &::-ms-expand {
        display: none;
    }
}

[type='radio'] + label {
    font-weight: 700;
}

textarea {
    resize: vertical;
    height: auto;
    min-height: 130px;
}

form {
    [aria-invalid='true'] {
        border: solid 1px $form-color-error-border;
        transition: border-color .3s ease-in-out;
        outline: none;
    }

    [aria-invalid='false'] {
        border: solid 1px $form-color-valid-border;
        transition: border-color .3s ease-in-out;
        outline: none;
    }
}

.terms-of-conditions,
.form-layout-item_error {
    &[aria-hidden='true'] {
        display: none;
    }

    &[aria-hidden='false'] {
        display: block;
    }
}

.form-layout {
    @include component-margin;

    &-column {
        &--1-column {
            width: layout-column-width(6);
        }
    }

    &-item {
        &:not(:only-of-type) {
            margin-bottom: rem(20px);
        }

        &:first-child:not(:only-of-type) {
            margin-top: rem(20px);
        }

        &_label {
            display: block;
            padding: rem(10px) 0;
        }

        &_error {
            border: solid 1px $form-color-error-border;
            color: $form-color-error-text;
            font-weight: 700;
            padding: rem(10px);
        }

        .error-message {
            color: $form-color-error-text;
        }

        &-terms-of-conditions {
            border-top: solid 1px $form-color-element-border;
            padding-top: 2em;

            p {
                max-width: 50em;
            }

            .terms-of-conditions_btn {
                float: right;
                display: none;

                &[data-initialized='toggle/Trigger'] {
                    display: block;
                }

                &[aria-expanded='true'] {
                    .terms-of-conditions_btn--more {
                        display: none;
                    }

                    .terms-of-conditions_btn--less {
                        display: block;

                        &::before {
                            font-size: rem($heading-h3-font-size);
                            content: '-';
                            display: inline-block;
                            margin-right: em(10px);
                            line-height: 1;
                        }
                    }
                }

                &[aria-expanded='false'] {
                    .terms-of-conditions_btn--more {
                        display: block;

                        &::before {
                            font-size: rem($heading-h3-font-size);
                            content: '+';
                            display: inline-block;
                            margin-right: em(10px);
                            line-height: 1;
                        }
                    }

                    .terms-of-conditions_btn--less {
                        display: none;
                    }
                }
            }
        }
    }

    &--filter {
        background: $form-color-filter-background;
        padding: rem(11px);
        margin-bottom: rem(40px);
    }

    &--brochure-teaser {
        margin-top: 1em;
        padding-top: em(20px);
        border-top: solid 1px $form-color-element-border;
    }

    [data-module='form/NumericStepper'] {
        text-align: center;
        margin: 0 em(10px);
        padding: 13px 13px 9px;
        width: rem(52px);
        max-width: rem(52px);
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }
}

.form-input-group {
    display: table;
    width: 100%;

    &-input {
        display: table-cell;
        vertical-align: top;
        border-right: 0;
        width: 100%;
        height: 42px;
        text-overflow: ellipsis;

        .header_search & {
            padding-left: .6em;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-btn-wrapper {
        display: table-cell;
        vertical-align: top;
        width: 1%;
        white-space: nowrap;
    }

    &-btn {
        height: 42px;
        width: 42px;
        padding: 0;
        min-height: 0;
        overflow: hidden;
        text-indent: 99999px;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .form-layout {
        &-column {
            width: layout-column-width(4);
        }

        &-item {
            &_label {
                display: inline-block;
                padding: 0 em(20px) 0 0;
            }
        }

        &--filter {
            overflow: hidden;

            select {
                float: right;
                width: auto;
            }

            .form-layout-item_label {
                transform: translateY(50%);
            }
        }
    }
}

