$header-color-active: $color-1 !default;
$header-color-background: #fff !default;
$header-color-nav-border-desktop: $color-4 !default;
$header-color-nav-border-mobile: $color-1 !default;
$header-color-nav-text: #eee;
$header-font-size: em(18px) !default;
$header-icon-nav-link: arrow-small-right-blue-repatha !default;
$header-icon-desktop-subnav: arrow-xsmall-right-blue-repatha !default;
$header-icon-desktop-subnav-large: arrow-small-right-blue-repatha !default;
$header-icon-desktop-subnav-active: arrow-xsmall-right-red-repatha !default;
$header-icon-desktop-subnav-active-large: arrow-small-right-red-repatha !default;
$header-search-width-large: 200px;

// $header-breakpoint was 768 but with a couple more items on everything below 1167 it some items would be impossible to reach
$header-breakpoint: 1167px !default;
$header-maxwidthbp: ($header-breakpoint - 1px) !default;
$language-selector--backgroundColor: red;

.language-selector {
    position: relative;
}

.language-selector {
    background: $btn-primary-color-background;
    height: fit-content;
    width: fit-content;
    z-index: 10;

    &_nav,
    &_subnav {
        @include blank-list;
        margin-bottom: 1em;
        border-bottom: 1px solid #eee;
        width: 101px;

        &-link {
            color: $header-color-nav-text;
            margin-left: 27px;

            &:not(:first-child) {
                :link,
                :active,
                :hover,
                :visited {
                    margin-left: 15px;
                }
            }
        }

        &-subnav-heading {
            display: block;
            text-decoration: none;
            color: $header-color-nav-text;
        }

        &_subnav-item {
            background-color: $btn-primary-color-background;
        }
    }

    .has-js &_subnav {
        font-size: $base-font-size;
    }

    ul[data-toggle-active=true] {
        background-color: $btn-primary-color-background;
        display: block;
        padding: 0;
        top: 1.5rem;
        position: absolute;
    }

    ul[data-toggle-active=false] {
        display: none;
    }

    &_nav {
        font-size: rem($header-font-size);

        &[data-processed='true'] {
            .header_subnav {
                display: block;
            }
        }

        &-subnav-heading {
            .has-js & {
                cursor: pointer;
            }
        }

        &-link-subnav-container {
            position: relative;
        }
    }
}

@media (max-width: $header-maxwidthbp) {
    .language-selector {
        &_nav {
            border-top: 1px dotted $header-color-nav-border-mobile;
            text-transform: uppercase;
            line-height: 1.2;

            &-link,
            &-subnav-heading {
                padding: .75em 30px .65em 5px;
                position: relative;
            }

            &-link {
                &::before {
                    @include icon;
                    @include icon-props($header-icon-nav-link);
                    @include position(absolute, 50% 5px false false);
                    transform: translateY(-50%);
                    right: 7px;
                }

                .header_nav-link-subnav-container & {
                    width: calc(100% - 35px); // compensate for padding right and left on -link

                    &::before {
                        display: none;
                    }
                }
            }

            &-subnav-heading {
                &::before,
                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 2px;
                    background-color: currentColor;
                    @include position(absolute, 50% 5px false false);
                    transform: translateY(-50%);
                    transition: opacity .3s ease;
                }

                &::after {
                    width: 2px;
                    height: 12px;
                    right: 10px;
                }

                &[aria-selected='true'] {
                    &::after {
                        opacity: 0;
                    }
                }

                .header_nav-link-subnav-container & {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    padding-right: 1em;
                    transform: translateY(-50%);
                }
            }

            &-item {
                border-bottom: 1px dotted $header-color-nav-border-mobile;
            }
        }

        &_subnav {
            border-top: 1px dotted $header-color-nav-border-mobile;
            margin: 0;
            text-transform: none;

            &-item {
                &:not(:first-child) {
                    border-top: 1px solid #eee;
                    width: 101px;

                    &:active {
                        margin-left: em(-17px);
                    }
                }
            }

            &-link {
                padding: .6em 0 .3em 1em;
            }
        }

        ul[data-toggle-active=true] {
            background-color: $btn-primary-color-background;
            display: block;
            padding: 0;
            top: rem(50px);
            position: absolute;
        }
    }
}

@media (min-width: $header-breakpoint) {
    .language-selector {
        &_nav {
            @include position(absolute, false ($header-search-width-large + $layout-gutter-large) 0 0);
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            &-item {
                flex: 0 1 auto;
                margin: .5em 0 0 -1px;
                position: relative;

                .collapsible-transition {
                    position: absolute;
                    top: calc(100% - 1px);
                    z-index: 1;
                    min-width: 300px;
                }

                + .header_nav-item > .header_nav-link,
                .header_nav-subnav-heading {
                    border-left-color: $header-color-nav-border-desktop;
                }
            }

            &-link,
            &-subnav-heading {
                border: 1px solid transparent;
                padding: 0 em(5px);
                display: block;

                &--active {
                    color: $header-color-active;
                }
            }

            &-link-subnav-container {
                border-left: 1px solid $header-color-nav-border-desktop;
            }

            &-link {
                .header_nav-link-subnav-container & {
                    padding-right: 0;
                    margin-right: 2em;
                }
            }

            &-subnav-heading {
                &::before {
                    @include icon;
                    @include icon-props($header-icon-desktop-subnav);
                    @include position(absolute, 50% 1em false false);
                    transform: rotate(90deg) translateX(-50%);
                }

                .header_nav-link-subnav-container & {
                    position: absolute;
                    top: 50%;
                    right: .5em;
                    transform: translateY(-50%);
                    padding: 10px;
                    border-color: transparent !important;

                    &::before {
                        @include icon-props($header-icon-desktop-subnav-large);
                        @include position(absolute, 50% .334em false false);
                        transform: rotate(90deg) translateX(-50%);
                    }
                }

                &--active {
                    &::before {
                        @include icon-props($header-icon-desktop-subnav-active, position);
                    }

                    .header_nav-link-subnav-container & {
                        &::before {
                            @include icon-props($header-icon-desktop-subnav-active-large, position);
                        }
                    }
                }

                &[aria-selected='true'] {
                    border-color: $header-color-nav-border-desktop $header-color-nav-border-desktop $color-white;
                }
            }
        }

        &_subnav {
            border: 1px solid $header-color-nav-border-desktop;
            padding: .5em 1em .3em;
            background: $color-white;

            &-item {
                &:not(:first-child) {
                    border-top: 1px solid #eee;
                    width: 101px;

                    &:active {
                        margin-left: em(-17px);
                    }
                }
            }

            &-link {
                &:not(:first-child) {
                    padding-right: 50px;
                }
                position: relative;
                line-height: 1.2;
                padding-right: 45px !important;

                &::before {
                    @include icon;
                    @include icon-props($header-icon-desktop-subnav);
                    @include position(absolute, 50% 0 false false);
                    transform: translateY(-50%);
                }

                &--active {
                    color: $header-color-active;

                    &::before {
                        @include icon-props($header-icon-desktop-subnav-active, position);
                    }
                }
            }
        }
    }
}
