.container {
    width: 100%;
}

.newsletter {
    width: 100%;
    background: #171a30;
    color: $color-white;
    text-align: center;
    padding: 6rem 2rem 2rem;
    position: relative;
    margin: 1rem 0;
    box-sizing: border-box;
}

.container .newsletter h2 {
    font-size: 2rem;
    color: $color-white;
    margin-bottom: 2rem;
}

.container .newsletter h2::before {
    content: "";
    background: url(/static/img/email-icon.png);
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: block;
    font-size: 3rem;
    position: absolute;
    top: -4rem;
    left: 47%;
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.container .newsletter h2::after {
    content: '';
    display: block;
    width: 80px;
    height: 2px;
    background: #2189c2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: .5rem;
    bottom: -1rem;
}

.newsletter a {
    color: $color-white;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: bold;
}

input {
    width: 90%;
    padding: .25rem;
    margin: .5rem 0;
}

.newsbtn {
    width: auto;
    padding: .2rem 1.5rem;
    border: 3px solid #2189c2;
    background: #171a30;
    color: $color-white;
    font-weight: bold;
    font-size: 1.125rem;
}

.p-news {
    font-size: 10px;
    font-weight: normal;
}

.check {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
}

#submission {
    display: none;
}
