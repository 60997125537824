#contact input {
    width: 100%;
}

#contact input[type=radio] {
    width: auto;
}

#contact .labelcontentbld {
    font-weight: bold;
}

#contact input[type=checkbox] {
    width: auto;
}

#contact .labelcontent {
    width: 100%;
    float: left;
    margin: .75rem 0;
    font-weight: bold;
}

.layout-row {
    word-spacing: normal;
}

#contact .splitcol1,
#contact .splitcol2 {
    width: 48%;
    float: left;
}

#contact .splitcol2 {
    margin-left: 4%;
}

#contact .small {
    font-size: .75rem;
    margin: .5rem;
}

#contact .rht {
    float: right;
    margin-top: 1.5rem;
}

#contact .error-message {
    float: left;
    width: 100%;
}

@media screen and (min-width: 1024px) {
    #contact select {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    #contact .splitcol1,
    #contact .splitcol2 {
        width: 100%;
        float: left;
    }

    #contact .splitcol2 {
        margin-left: 0;
    }
}

.repathalayout-container {
    max-width: 1200px;
    padding: 0 10px;
    margin: auto;
}

.repathacontact-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 1rem 0;
}

.repathacontact-labelcol {
    width: 25%;
}

.repathacontact-inputcol {
    width: 75%;
}

.repatha-errormessage {
    color: $color-1;
    display: none;
    width: 100%;
    padding-left: 25%;
}

.repathainput-flex {
    display: flex;
    flex-direction: row;
}

.repathainput-area {
    width: 25%;
}

input.repatha-radiobox {
    position: absolute;
}

input.repatha-radiobox:checked,
input.repatha-radiobox:not(:checked) {
    position: absolute;
    left: -9999px;
}

input.repatha-radiobox:checked + label.check-radio,
input.repatha-radiobox:not(:checked) + label.check-radio {
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    display: inline-block;
    color: $color-6;
    line-height: 0;
}

input.repatha-radiobox:checked + label.check-radio::before,
input.repatha-radiobox:not(:checked) + label.check-radio::before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: $color-white;
}

input.repatha-radiobox:not(:checked) + label.check-radio::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

input.repatha-radiobox:checked + label.check-radio::after,
input.repatha-radiobox:not(:checked) + label.check-radio::after {
    content: "";
    width: 20px;
    height: 20px;
    background: $color-1;
    position: absolute;
    top: -9px;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

span.repathaclr-red {
    color: $color-error;
}

select.repatha-dropdown {
    width: 100%;
    padding: 1rem;
    color: $color-5;
    background-color: $color-4;
    border-color: $color-4;
    border-radius: 10px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background-image: url("static/img/arrow-down.svg");
    background-size: 50px 8px;
    background-position: right;
    background-repeat: no-repeat;
    cursor: pointer;
}

select.repatha-dropdown option.repatha-optionlist {
    outline: 0;
    border: 0;
    padding: 1rem;
    font-size: 1rem;
}

.repathacontactform input.inputbox {
    padding: 1rem;
    width: -webkit-fill-available;
    border-radius: 10px;
    border: 0;
    background-color: $color-4;
    outline: 0;
}

.repathainputcheck-area input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.repathainputcheck-area label {
    position: relative;
    cursor: pointer;
}

.repathainputcheck-area label::before {
    content: "";
    -webkit-appearance: none;
    border: 2px solid $color-5;
    border-radius: 5px;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 1rem;
}

.repathainputcheck-area input:checked + label::after {
    content: "\2713";
    position: absolute;
    top: 0;
    left: 4px;
}

.repatha-emptyflexcol {
    width: 25%;
}

.repathacontactbtn {
    width: 50%;
    margin: auto;
}

a.btn.btn-primary.contact-btn {
    background: $color-1;
    border-color: $color-1;
    color: $color-white;
    width: auto;
    padding: 1rem;
    text-decoration: none;
}

.repatha-privacycontent {
    margin-top: 3rem;
    font-size: .9rem;
}

.repatha-privacycontent .sm-p {
    margin-bottom: 1.2rem;
}
