.hypteaser-articlelist {
    justify-content: space-evenly;
    margin: 0 auto;
    line-height: 1.4;
}

.hypteaser {
    width: 22.5%;
    margin-right: 1rem;
    display: block;
    float: left;
    margin-bottom: 2rem;
}

.hyparticles {
    margin-bottom: 2rem;
}

.hypteaser img {
    max-width: none;
    width: 100%;
}

.hypread-more-faux-link {
    display: block;
    font-size: .8em;
    color: $color-21;
}

.hypteaser h2 {
    font-size: 1.25rem;
    margin: 0;
    color: $color-5;
    text-transform: none;
    text-align: left;
    margin-bottom: .5rem;
}

.hypteaser h2::after {
    display: none;
}

.hyptime {
    display: block;
    margin: 0 0 1em;
    font-size: .75em;
    color: $color-6;
}

.hypteaser a {
    color: $color-21;
    text-decoration: none;
    display: inline;
}

.hypdesc {
    color: $color-5;
    margin-bottom: 1rem;
}

.hypteaser > a:hover .hypread-more-faux-link {
    text-decoration: underline;
}

.hyparticles .inhalte {
    float: left;
    font-size: 18px;
}

.hyparticles .inhalte h1 {
    font-size: 1em;
    margin: .5rem;
    text-transform: none;
    text-align: left;
}

.hyparticles .inhalte .h1::after,
.hyparticles .inhalte h1::after {
    display: none;
}

.hyparticles .Berichte {
    float: right;
    padding-top: 10px;
}

.hyparticles .Berichte a {
    font-size: 1em;
    text-decoration: none;
}

.hyparticles::after {
    display: block;
    content: "";
    clear: both;
}

.hyptags {
    width: 100%;
    display: flex;
    margin-top: 1rem;
}

.hyptags ul:not([class]) li::before {
    display: none;
}

.hyptags ul {
    display: inline-flex;
    padding: 0;
    margin: 0;
}

.hyptags ul li a {
    color: $color-white;
}

.hyptags ul li {
    margin-right: .5rem;
    background: $color-4;
    padding: 3px 8px;
    border-radius: 10px;
}

.hyphead h1 {
    font-size: 1.25rem;
    text-align: left;
    margin: 0 0 2rem;
    text-transform: none;
}

.hyphead h1::after {
    display: none;
}

.hypcontainer-articles #hypdrop {
    margin: .67em 0;
    line-height: 1.4;
    padding: 0;
}

.hypcontainer-articles label {
    cursor: default;
}

.hypcontainer-articles select#hypspeciality {
    width: 20%;
}

.hypbtn {
    display: inline;
}
