.infl-main-quiz-content {
    display: none;
}

.inflammation-quizcurrent {
    display: block;
}

.infl-quiz-graident-bg {
    background: linear-gradient(201.86deg, $color-49 31.84%, $color-22 85.68%);
    padding: 3rem 0;
}

.infl-quiz-outer-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
}

h2.infl-quiz-heading {
    font-size: 2.2rem;
    color: $color-white;
    font-weight: 400;
    margin-top: 0;
}

.infl-mainheading-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: baseline;
    -webkit-align-items: baseline;
}

.infl-quiz-heading-area {
    width: 45%;
}

.infl-quiznumber-dropdown-container {
    width: 45%;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    flex-direction: row;
    -webkit-flex-direction: row;
    color: $color-white;
}

.infl-quiz-question-number {
    font-size: .9rem;
}

.infl-quiz-filter-selected {
    position: relative;
    font-size: .8rem;
    text-transform: uppercase;
}

.quiz-selectBtn {
    cursor: pointer;
    position: relative;
    padding-right: 1.8rem;

    &::after {
        content: "";
        background-image: url('/static/img/inflquiz/quiz-dropdown.svg');
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        background-position: center;
        background-size: cover;
    }
}

.quiz-selectDropdown {
    overflow: hidden;
    z-index: 1;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    transition: .2s ease;
    background: $color-22;
    padding: .5rem;
    margin-top: .5rem;
    position: absolute;
    width: 100%;
    display: none;
}

.quiz-selectDropdown.quizdropdown-toggle {
    display: block;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.infl-quiz-selectDropdown-conatiner {
    min-width: 200px;
    position: absolute;
    right: rem;
}

.quiz-dropdown-option {
    position: relative;
    cursor: pointer;
    padding: .2rem 0;
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 32px;
}

.infl-question-container-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    color: $color-white;
}

.infl-question-details {
    width: 45%;
}

.infl-quiz-sm-heading {
    font-size: 14px;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.infl-quiz-sm-p {
    font-size: 14px;
    margin-bottom: 1.5rem;
    opacity: .7;
}

.infl-question-img-section {
    width: 55%;
    position: relative;
}

.infl-quizimg-handler {
    position: absolute;
    top: 10px;
    right: 10px;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: .9rem;
    cursor: pointer;

    span.inf-img-hide {
        display: none;
    }
}

.infl-quizimg-handler.inf-image-overlay-txt {
    span.inf-img-show {
        display: none;
    }
}

.infl-quizimg-caption {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 14px;
}

.infl-quizimg-handler.inf-image-overlay-txt {
    span.inf-img-hide {
        display: block;
    }
}

.inf-img-blur-section {
    background: #c4c4c4;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;

    img {
        filter: blur(10px);
        margin-bottom: 0;
    }
}

.infl-question-img-section.inf-img-blurred {
    .inf-img-blur-section {
        img {
            filter: blur(1px);
        }
    }
}

.infl-mainquestion-container {
    width: 45%;
    border-left: 1px solid rgba(255, 255, 255, .2);
    padding-left: 3.5rem;
    position: relative;
}

.inf-question-heading {
    font-size: 1.8rem;
    font-weight: 400;
    color: $color-white;
}

.inf-question-options-container {
    margin-bottom: 4rem;
}

.inf-quest-options {
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked {
            + {
                label.inf-quest-label-txt {
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 1.15rem;
                        left: 1.35rem;
                        width: 5px;
                        height: 10px;
                        border: solid $color-22;
                        border-width: 0 1px 1px 0;
                        transform: rotate(45deg);
                    }

                    &::before {
                        background-color: $color-white;
                    }
                }

                label.inf-quest-label-txt.infl-wrong-selection {
                    &::after {
                        content: "\2715";
                        background-color: transparent;
                        border: 0;
                        transform: rotate(0);
                        top: 14px;
                        left: 18px;
                    }

                    &::before {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.inf-quest-options label.inf-quest-label-txt {
    position: relative;
    cursor: pointer;
    display: block;
    background: rgba(255, 255, 255, .05);
    padding: 1rem;
    margin-bottom: 5px;

    &::before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid $color-white;
        box-shadow: 0 1px 2px $color-52, inset 0 -15px 10px -12px $color-52;
        width: 14px;
        height: 14px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
    }
}

label.inf-quest-label-txt.infl-wrong-selection {
    &::before {
        content: "";
        -webkit-appearance: none;
        background-color: $color-black;
        border: 1px solid $color-white;
        box-shadow: none;
        width: 14px;
        height: 14px;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
    }
}

span.inf-label-optionletter {
    position: absolute;
    right: 1rem;
    display: block;
    top: 1rem;
}

.infl-quiz-button-container {
    span.back-icon {
        width: 1.9rem;
        height: auto;
        display: block;
        margin-right: .5rem;

        img {
            margin-bottom: 0;
        }
    }

    .inf-back-button {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        text-transform: uppercase;
        font-size: 14px;
        align-items: baseline;
        -webkit-align-items: baseline;
    }
    position: absolute;
    bottom: 0;
    width: 90%;
    left: auto;
}

.infl-btn-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: baseline;
    -webkit-align-items: baseline;

    .click-invalid {
        opacity: .5;
        cursor: not-allowed;
    }

    .click-valid {
        opacity: 1;
        cursor: pointer;
    }
}

a.infl-check-btn {
    text-decoration: none;
    padding: 12px 20px;
    border: 1px solid $color-white;
    color: $color-white;
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 14px;
}

.infl-nxtbtn-container {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: baseline;
    -webkit-align-items: baseline;
}

span.infl-next-icon {
    width: 2rem;
    display: block;
    margin-left: 13px;

    img {
        margin-bottom: 0;
    }
}

a.infl-next-btn {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    background-color: $color-white;
    padding: 1rem 1.8rem;
    border-radius: 25px;
    color: $color-12;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    align-items: end;
    font-weight: 600;
}

span.inf-quest-answer {
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    right: 3rem;
    top: .8rem;
}

span.inf-quest-answer.infl-btnwrong-ans {
    background-color: $color-white;
    color: $color-50;
}

span.inf-quest-answer.infl-btnright-ans {
    background-color: $color-white;
    color: $color-51;
}

.infl-answer-popoup {
    position: absolute;
    right: 0;
    left: -400px;
    z-index: 10;
    overflow: hidden;
    background-color: $color-white;
    width: 370px;
    top: 0;
}

.inf-right-answer-area {
    &::before {
        position: absolute;
        border-left: solid 15px $color-white;
        border-top: solid 15px transparent;
        border-bottom: solid 15px transparent;
        left: -2rem;
        top: 1rem;
        content: "";
        display: block;
    }
}

.infl-hidden-content {
    padding: 1rem;

    .p {
        font-size: 12px;
        color: $color-46;
        line-height: 16px;
    }
}

label.inf-quest-label-txt.infl-correct-selection.infl-correct-answer {
    span.inf-quest-answer.infl-btnright-ans {
        background-color: $color-white;
        color: $color-51;
    }
}

label.inf-quest-label-txt.infl-correct-answer {
    span.inf-quest-answer.infl-btnright-ans {
        background-color: rgba(255, 255, 255, .1);
        color: $color-white;
    }
}

@media screen and (max-width: 1024px) {
    .infl-mainquestion-container {
        padding-left: 2rem;
        width: 100%;
        border-left: 0;
    }

    .infl-question-img-section {
        width: 80%;
    }
}

@media screen and (max-width: 1024px) {
    .infl-quiz-heading-area {
        width: 100%;
    }

    .infl-quiznumber-dropdown-container {
        width: 100%;
    }

    .infl-mainheading-flex {
        margin-bottom: 2rem;
    }

    h2.infl-quiz-heading {
        font-size: 1.8rem;
    }

    .infl-question-details {
        width: 100%;
    }

    .infl-mainquestion-container {
        width: 100%;
        border-left: 0;
        padding-left: 0;
    }

    .infl-quiz-button-container {
        width: 100%;
    }

    .infl-question-img-section {
        width: 100%;
    }
}
