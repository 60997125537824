$carousel-btn-color-background: $color-1 !default;
$carousel-btn-color-background-hover: $color-2 !default;
$carousel-pager-item-size: 10px !default;

.carousel {
    .has-js & {
        @include component-margin;
        overflow: hidden;

        .layout-column--carousel {
            > :last-child {
                margin-bottom: 0;
            }
        }
    }

    &_container {
        white-space: nowrap;
        transition: transform .3s ease-out;
        will-change: transform;
        margin-bottom: $layout-element-margin-default * 1.5;

        > * {
            white-space: normal;
        }
    }

    &_btn {
        display: block;
        background: $carousel-btn-color-background;
        width: 30px;
        height: 40px;
        transition: background .3s ease, opacity .3s ease;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0 3px 0 rgba($color-black, .15);

        &:focus,
        &:hover {
            background-color: $carousel-btn-color-background-hover;
        }

        &--disabled {
            opacity: 0;
            cursor: default;
            pointer-events: none;
        }

        &-prev {
            left: 0;
        }

        &-next {
            right: 0;
        }
    }

    &_pager {
        position: absolute;
        bottom: $layout-element-margin-default / 2;
        left: 50%;
        transform: translateX(-50%);
        line-height: 0;
        padding: 1px; // fixes strange bug for FF & Chrome where circle is cut off
    }

    &_pager-item {
        display: inline-block;
        width: $carousel-pager-item-size;
        height: $carousel-pager-item-size;
        background: $carousel-btn-color-background;
        transition: background .3s ease;
        border-radius: 50%;

        &--active {
            background-color: $carousel-btn-color-background-hover;
        }

        &--active {
            cursor: default;
            pointer-events: none;
        }

        &:not(:first-child) {
            margin-left: $carousel-pager-item-size;
        }
    }
}
.has-js .carousel { margin-bottom: 0; }

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--carousel {
        width: layout-column-width(6);
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--carousel {
        width: layout-column-width(4);
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--single-slides {
        width: layout-column-width(12);
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--single-slides {
        width: layout-column-width(12);
    }
}
