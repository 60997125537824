.contact-teaser {
    &_btn {
        width: 100%;
    }
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.mrgbtm {
    margin-bottom: 40px;
}

.left_content {
    width: 100%;
    padding-right: 80px;
}

.right_content {
    width: 100%;
    padding-left: 80px;
}

.ENVOYER_button {
    background: white;
    border: 2px solid #f15c01;
    border-radius: 24px;
    padding: 1rem 12.5rem;
    display: inline-block;
    -webkit-box-shadow: 1px 11px 9px -9px rgb(142, 142, 142);
    -moz-box-shadow: 1px 11px 9px -9px rgb(142, 142, 142);
    box-shadow: 1px 11px 9px -9px rgb(142, 142, 142);
    margin-top: 55px;
    margin-bottom: 55px;
    text-decoration: none;
}

h2 {
    text-transform: uppercase;
    text-align: center;
}

@media (min-width: $layout-breakpoint-tablet) {
    .contact-teaser {
        &_subheading {
            text-align: center;
        }

        &_paragraph {
            padding-left: 60px;
        }
    }

    .layout-column-contact-teaser-content {
        margin-left: layout-column-width(4);
        width: layout-column-width(4);
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column-contact-teaser-content {
        margin-left: layout-column-width(4.5);
        width: layout-column-width(3);
    }
}
