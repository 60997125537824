.da-news-article-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

h2.news-article-heading {
    color: $color-15;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 49px;
    text-transform: uppercase;
    text-align: center;
}

.da-news-filter {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

a.da-filter-btns {
    margin-right: 1rem;
    text-decoration: none;
    color: $color-5;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
    cursor: pointer;
}

a.da-filter-btns:last-child {
    margin-right: 0;
}

a.da-filter-btns.da-filter-current {
    color: $color-white;
    background-color: $color-23;
    padding: 2px 5px;
    border-radius: 5px;
}

.da-newsboxgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.da-newsbox {
    width: 23%;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
}

.da-newsbox-img-area img {
    height: auto;
    width: 100%;
    margin: 0;
}

.da-newsbox-heading-bookmark-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 0 1rem;
}

.da-newsbox-heading-col {
    width: 90%;
}

.da-newsbox-bookmark-col {
    width: 10%;
    text-align: right;
}

.da-newsbox-bookmark-col img {
    width: 50%;
    height: auto;
    margin: 0;
}

.da-read-more {
    margin-top: 1rem;
    color: $color-23;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

h3.da-newsbox-heading-area {
    color: $color-15;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 1rem 0;
}

.da-newsbox-content-area {
    width: 90%;
    padding: 0 1rem 1.5rem;
}

.da-newsbox-content-area a,
.da-newsbox-heading-col a {
    text-decoration: none;
}

.da-newsbox-img-area {
    position: relative;
}

.da-img-thumb {
    position: absolute;
    top: .5rem;
    left: .5rem;
    color: $color-23;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    background-color: $color-white;
    padding: 1px 5px;
    border-radius: 5px;
    text-transform: uppercase;
}

.da-newsbox-button {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    background-color: $color-23;
    width: 50%;
    margin: 1rem auto;
    padding: .5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.da-newsbox-content-area .p {
    color: $color-35;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

@media screen and (max-width: 1024px) {
    .da-newsbox {
        width: 30%;
    }
}

@media screen and (max-width: 700px) {
    .da-newsbox {
        width: 100%;
        margin-right: 0;
    }

    a.da-filter-btns {
        margin-bottom: 1rem;
    }

    .da-newsboxgroup {
        margin-top: 1rem;
    }

    .da-newsbox-button {
        width: auto;
    }
}

.da-news-slider-heading {
    color: $color-23;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
}

.da-newsboxgroup-slider-wrapper {
    max-width: 1200px;
    margin: 2rem auto;
}

.da-newsboxgroup-slider-wrapper .owl-nav {
    position: absolute;
    top: -4rem;
    right: 0;
}

.da-newsboxgroup.da-newsboxgroup-slider {
    position: relative;
}

span.nav-main-slider-btn {
    font-size: 2.5rem;
    color: $color-23;
}

span.nav-main-slider-btn.chevron-left {
    margin-right: .5rem;
}

.da-newsboxgroup.da-newsboxgroup-slider .da-newsbox-bookmark-col img {
    width: 50%;
    margin: auto 0 auto auto;
}

.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider .owl-nav button.owl-next,
.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider .owl-nav button.owl-prev {
    background: transparent !important;
    border-radius: 0;
    position: initial;
}

.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider button.owl-prev:focus,
.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider button.owl-next:focus,
.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider span.nav-main-slider-btn:focus {
    outline: 0;
}

.da-newsboxgroup.owl-carousel.da-newsboxgroup-slider .owl-nav button.disabled span.nav-main-slider-btn {
    color: $color-18;
    cursor: auto;
}

.da-recent-news-wrapper {
    width: 100%;
    margin: auto;
}

.da-recent-news-container {
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
}

h3.da-recent-news-heading {
    color: $color-23;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    padding: 1rem;
}

.da-recent-viewed-news {
    padding: 1rem;
    display: block;
}

h3.da-recent-viewed-news-heading {
    width: 100%;
    color: $color-15;
    margin-bottom: 0;
    display: inline;
    padding-inline-end: 4rem;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
}

.da-viewed-news-area {
    position: relative;
    margin-bottom: 2rem;
}

.da-recent-viewed-news-date {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $color-6;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
}

.draSF-item-container {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    width: 100%;
}

.draSF-item-container .sf-item {
    width: 100%;
    max-width: 285px;
    margin-bottom: 23px;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
}

.draSF-item-container .sf-item a {
    text-decoration: none;
    cursor: pointer;
}

.draSF-item-container .sf-img-holder {
    width: 100%;
    position: relative;
}

.draSF-item-container .sf-img-holder img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
    margin: 0;
}

.draSF-item-container .sf-img-holder .draSF-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: $color-white;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
}

.draSF-item-container .sf-img-holder .icon-home-linkedin {
    background-image: url("/static/img/icon-home-linkedin.svg");
}

.draSF-item-container .sf-img-holder .icon-home-instagram {
    background-image: url("/static/img/icon-home-instagram.svg");
}

.draSF-item-container .sf-item .sf-txt-content .p {
    color: $color-5;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 6px 17px 18px;
}

.draSF-showmore {
    margin-top: 3rem;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.draSF-showmore a {
    display: inline-block;
    width: 100%;
    max-width: 580px;
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: $color-12;
    padding: .5625rem 0;
    cursor: pointer;
}

@media (max-width: 980px) {
    .draSF-item-container {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 768px) {
    .draSF-item-container {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 480px) {
    .draSF-item-container {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }

    .draSF-item-container .sf-item {
        max-width: 100%;
    }
}

.draLoginToRa-modal {
    display: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .85);
}

.draLoginToRa-modal .drAccedi-popUpContainer {
    width: 100%;
    max-width: 997px;
    height: 628px;
    margin: 0 auto;
}

.draLoginToRa-modal .drAccedi-content {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .28);
    height: 100%;
}

.draLoginToRa-modal .drAccedi-content .col-3 {
    width: 30%;
    padding: 0;
}

.draLoginToRa-modal .drAccedi-content .col-9 {
    width: 70%;
    padding: 90px;
    position: relative;
    box-sizing: border-box;
}

.draLoginToRa-modal .drAccedi-content .lab-img img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px 0 0 5px;
}

.draLoginToRa-modal .drAccedi-content .rt-col-cont {
    position: relative;
}

.draLoginToRa-modal .drAccedi-content .rt-col-cont h1::after {
    display: none;
}

.draLoginToRa-modal .drAccedi-content .close-dralogin {
    text-align: right;
    position: absolute;
    top: 46px;
    right: 41px;
}

.draLoginToRa-modal .drAccedi-content .close-dralogin > img {
    cursor: pointer;
    width: auto;
    height: auto;
    display: inline-block;
    margin-bottom: 0;
}

.draLoginToRa-modal .drAccedi-content .header {
    color: $color-39;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 49px;
    margin: 0;
    padding: 0;
}

.draLoginToRa-modal .drAccedi-content .sub-header {
    color: $color-39;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
    margin: 28px 0 24px;
}

.draLoginToRa-modal .drAccedi-content .services-wrap {
    display: flex;
    flex-wrap: wrap;
}

.draLoginToRa-modal .drAccedi-content .services-wrap .prod-container {
    display: flex;
    align-items: center;
    width: 33.33%;
    margin-bottom: 26px;
}

.draLoginToRa-modal .drAccedi-content .services-wrap .prod-container img {
    width: auto;
    height: auto;
    margin: 0;
}

.draLoginToRa-modal .drAccedi-content .services-wrap .prod-txt {
    color: $color-16;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 11px;
}

.draLogin-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 19px;
}

.draLogin-form .form-field {
    position: relative;
    width: 100%;
    margin-right: 9px;
}

.draLogin-form .input-txt {
    display: block;
    width: 100%;
    padding: 0;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    border: none;
    border-bottom: 2px solid $color-black;
    background-color: transparent;
    min-height: 30px;
}

.draLogin-form .label {
    position: absolute;
    left: 0;
    bottom: 5px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    transition: transform .2s ease-in-out;
    pointer-events: none;
    outline: none;
}

.draLogin-form .input-txt:focus {
    outline: none;
}

.draLogin-form .input-txt:focus + .label,
.draLogin-form .input-txt:valid + .label {
    transform: translateY(-14px);
    color: $color-17;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.draLogin-form small {
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 0;
    color: $color-error;
    display: block;
}

.draLogin-submit {
    width: 100%;
    margin: 36px 0 26px;
}

.draLogin-submit input.accedi-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    background-color: $color-16;
    border: none;
    border-radius: 5px;
    appearance: none;
    padding: 14px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.draLogin-btm-txt {
    display: flex;
    justify-content: space-between;
}

.draLogin-btm-txt .register,
.draLogin-btm-txt a.forget-password {
    font-size: 16px;
    letter-spacing: 0;
}

.draLogin-btm-txt a {
    color: $color-16;
}

.draHome-login-content {
    width: 100%;
    max-width: 488px;
    padding: 31px;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 10px 24px 3px rgba(0, 19, 45, .22);
    box-sizing: border-box;
}

.draHome-login-content h1::after {
    display: none;
}

.draHome-login-title {
    color: $color-34;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 49px;
    margin: 0 0 12px;
}

.drahome-col-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drahome-col-2 .col-md-1 {
    width: 64%;
}

.drahome-col-2 .lt-text {
    font-size: 16px;
    line-height: 22px;
    color: $color-5;
}

.drahome-col-2 .col-sm-1 {
    width: 20%;
}

.image-home-one-key {
    height: 62px;
    width: 76px;
    margin: 0;
}

.draHomeLogin-form {
    margin-top: 19px;
}

.draHomeLogin-form .form-field {
    position: relative;
    width: 100%;
    margin: 0 9px 32px 0;
}

.draHomeLogin-form .input-txt {
    display: block;
    width: 100%;
    padding: 0;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    border: none;
    border-bottom: 2px solid $color-black;
    background-color: transparent;
}

.draHomeLogin-form .label {
    position: absolute;
    left: 0;
    bottom: 5px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    transition: transform .2s ease-in-out;
    pointer-events: none;
    outline: none;
}

.draHomeLogin-form .input-txt:focus {
    outline: none;
}

.draHomeLogin-form .input-txt:focus + .label,
.draHomeLogin-form .input-txt:valid + .label {
    transform: translateY(-14px);
    color: $color-17;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.draHomeLogin-form small {
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 0;
    color: $color-error;
    display: block;
}

.draHomeLogin-submit {
    width: 100%;
    margin: 26px 0 22px;
}

.draHomeLogin-submit input.accedi-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    background-color: $color-12;
    border: none;
    border-radius: 5px;
    appearance: none;
    padding: 12px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.draHomeLogin-btm-txt {
    display: flex;
    justify-content: space-between;
}

.draHomeLogin-btm-txt .register,
.draHomeLogin-btm-txt a.forget-password {
    font-size: 16px;
    letter-spacing: 0;
}

.draHomeLogin-btm-txt a {
    color: $color-12;
}

.draRPW-title {
    color: $color-black;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
    margin: 78px 0 52px;
}

.draRPW-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.draRPW-form .form-field {
    position: relative;
    width: 65%;
    padding-top: 42px;
}

.draRPW-form .draRPW-submit {
    width: 30.7%;
}

.draRPW-form .input-txt {
    display: block;
    width: 100%;
    padding: 0 0 20px;
    color: $color-5;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    border: none;
    border-bottom: 2px solid $color-black;
    outline: none;
}

.draRPW-form .label {
    position: absolute;
    left: 0;
    top: 0;
    color: $color-6;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.draRPW-submit input.rpw-recovery-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    background-color: $color-12;
    border: none;
    border-radius: 5px;
    appearance: none;
    padding: 14px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.draRPW-btm-txt {
    margin-top: 27px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.draRPW-btm-txt a {
    color: $color-12;
}

.draRPW-confirmation {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 84px 0 126px;
}

.draRPW-check-img {
    margin-bottom: 14px;
}

.draRPW-check-img img {
    width: 30px;
    margin: 0 auto;
}

.confirmation-txt {
    font-size: 32px;
    color: $color-black;
    letter-spacing: 0;
    line-height: 40px;
}

.rpw-confirm-mail {
    font-weight: 700;
    font-size: 36px;
    display: inline-block;
    word-break: break-all;
}

.draRPW-form small.error {
    visibility: hidden;
    margin-top: 10px;
    color: $color-error;
}

.nuovo-acc-item {
    border-bottom: 3px solid $color-6;
    margin-bottom: 33px;
}

.nuovo-acc-item.border {
    border-color: transparent;
}

.nuovo-acc-item .accordion-title {
    width: 100%;
    border: none;
    background: none;
    text-align: left;
    font-size: 26px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    padding-bottom: 15px;
    margin: 0 0 1rem;
}

.nuovo-acc-item .accordion-title span.icon {
    background: url('/static/img/icon-nuovo-account-angle-down.svg') right center/18px 12px no-repeat;
    display: inline-block;
    width: 18px;
    height: 12px;
    margin-right: 21px;
}

.nuovo-acc-item .accordion-title.active span.icon {
    background-image: url("/static/img/icon-nuovo-account-angle-up.svg");
    transform: rotate(0deg);
}

.nuovo-acc-item .accordion-title.active {
    color: $color-22;
}

.nuovo-acc-item .panel {
    display: none;
}

.nuovo-form-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nuovo-form-container .field-select-wrap {
    display: block;
    width: 49%;
    user-select: none;
    position: relative;
}

.nuovo-form-container .field-select-wrap .default-option {
    list-style-type: none;
    border-bottom: 2px solid $color-black;
    margin: 0 0 24px;
    padding-bottom: 15px;
    cursor: pointer;
    color: $color-5;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.mandatory-field-note {
    color: $color-5;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.nuovo-form-container .field-select-wrap small {
    color: $color-5;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
    display: block;
    margin-bottom: 10px;
}

.nuovo-form-container .field-select-wrap .default-option .input-dd-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 21px;
}

.nuovo-form-container .field-select-wrap span.dropdown-icon {
    background: url('/static/img/icon-nuovo-account-angle-down.svg') right center/18px 12px no-repeat;
    display: inline-block;
    width: 18px;
    height: 12px;
}

.nuovo-form-container .field-select-wrap .select-list {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 2;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
}

.nuovo-form-container .field-select-wrap .select-list.active {
    display: block;
}

.nuovo-form-container .field-select-wrap .select-option {
    list-style-type: none;
    padding: 0 20px;
    height: 190px;
    overflow-x: inherit;
    overflow-y: scroll;
    margin-right: 10px;
}

.nuovo-form-container .field-select-wrap .select-option.active {
    display: block;
}

.nuovo-form-container .field-select-wrap .select-option li {
    padding: 10px;
    cursor: pointer;
}

.nuovo-form-container .field-select-wrap .select-option li:hover {
    background-color: $color-4;
}

.nuovo-accordion-formcontrol .nuovo-form-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.nuovo-accordion-formcontrol .nuovo-form-container .form-field {
    display: flex;
    flex-direction: column;
    width: 49%;
    position: relative;
    margin-bottom: 40px;
}

.nuovo-accordion-formcontrol .form-field .input-txt {
    padding: 12px;
    border: 1px solid $color-black;
    background-color: transparent;
    border-color: $color-black;
    outline: none;
}

.nuovo-accordion-formcontrol .form-field .label {
    position: absolute;
    left: 12px;
    bottom: 10px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    transition: transform .2s ease-in-out;
    pointer-events: none;
    outline: none;
}

.nuovo-accordion-formcontrol .form-field .input-txt:focus + .label,
.nuovo-accordion-formcontrol .form-field .input-txt:valid + .label {
    transform: translateY(-18px);
    background: $color-white;
    padding: 0 2px;
    color: $color-5;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.nuovo-accordion-formcontrol .form-field small.errorMsg,
.nuovo-form-container .terms-of-conditions .form-layout-item small.errorMsg {
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 0;
    color: $color-error;
    display: block;
}

.nuovo-form-container .field-select-wrap.nuovo-acc-1 small.errorMsg {
    visibility: hidden;
    position: absolute;
    bottom: 16px;
    left: 0;
    color: $color-error;
    display: block;
}

.nuovo-form-container .field-select-wrap.nuovo-acc-2 small.errorMsg {
    visibility: hidden;
    position: absolute;
    bottom: 6px;
    left: 0;
    color: $color-error;
    display: block;
}

.nuovo-form-container .terms-of-conditions label {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    padding-left: 10px;
}

.nuovo-form-container .terms-of-conditions .form-layout-item {
    margin-bottom: 24px;
    position: relative;
    display: flex;
}

.draRegister-submit {
    width: 100%;
    margin: 54px 0 18px;
}

.draRegister-submit input.accedi-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    background-color: $color-22;
    border: none;
    border-radius: 5px;
    appearance: none;
    padding: 15px 0;
    width: 100%;
    max-width: 368px;
    text-align: center;
    cursor: pointer;
    opacity: .5;
}

.draRegister-btm-txt {
    display: flex;
    justify-content: space-between;
}

.draRegister-btm-txt .register,
.draRegister-btm-txt a.forget-password {
    font-size: 16px;
    letter-spacing: 0;
}

.draRegister-btm-txt a {
    color: $color-22;
}

.dr-bookmark-area {
    color: $color-22;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    cursor: pointer;
}

.dr-bookmark-fav-default {
    display: block;
}

.dr-bookmark-fav-active {
    display: none;
}

span.dr-bookmark-fav-default {
    display: flex;
    flex-direction: row;
}

span.dr-bookmark-fav-default img.dr-fav-bookmark,
span.dr-bookmark-fav-active img.dr-fav-bookmark {
    margin-bottom: 0;
    height: auto;
    width: 20px;
}

.resorse-per-wrapper {
    max-width: 1200px;
    margin: auto;
}

.resorse-per-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.resorse-per-col {
    width: 48%;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
    margin-bottom: 2rem;
}

h3.resorse-heading {
    color: $color-8;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
}

.resorse-per-col-img-area {
    height: 13.6rem;
    overflow: hidden;
    background-color: $color-4;
    position: relative;
}

.resorse-per-col-content-area {
    padding: 1rem;
}

.comp-heading {
    color: $color-26;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
}

.risorse-readmore-text {
    padding: 1rem;
}

.risorse-readmore-text a {
    color: $color-26;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    text-decoration: none;
}

.resorse-per-wrapper {
    max-width: 1200px;
    margin: auto;
}

.resorse-fav-area {
    color: $color-26;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    width: 30%;
    text-align: right;
}

.resorse-fav-area span.fav-img-area img {
    width: 7%;
    height: auto;
    display: inline;
    margin-bottom: 0;
    top: 4px;
    position: relative;
}

.heading-fav-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.resorse-heading-area {
    width: 70%;
}

span.resorse-per-img-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 5px;
    background-color: $color-26;
    padding: .5rem;
}

a.img-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
}

.resorse-per-col-btn-area {
    width: 50%;
    margin: 1rem auto;
    text-align: center;
}

a.risrorse-btn {
    background-color: $color-22;
    color: $color-white;
    text-decoration: none;
    display: block;
    padding: .5rem;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
}

.resorse-per-flex-slider.owl-carousel.owl-theme.owl-loaded {
    margin-top: 4rem;
}

.resorse-per-col-img-area {
    border-radius: 5px 5px 0 0;
}

.resorse-per-col-slider.owl-item {
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
    margin-bottom: 2rem;
}

.resorse-per-flex-slider .owl-nav {
    position: absolute;
    top: -4rem;
    right: 0;
}

.resorse-per-flex-slider {
    position: relative;
}

span.nav-main-slider-btn {
    font-size: 2.5rem;
    color: $color-26;
}

span.nav-main-slider-btn.chevron-left {
    margin-right: .5rem;
}

.resorse-per-flex-slider.owl-carousel .owl-nav button.owl-next,
.resorse-per-flex-slider.owl-carousel .owl-nav button.owl-prev {
    background: transparent !important;
    border-radius: 0;
    position: initial;
}

.resorse-per-flex-slider button.owl-prev:focus,
.resorse-per-flex-slider button.owl-next:focus,
.resorse-per-flex-slider span.nav-main-slider-btn:focus {
    outline: 0;
}

.resorse-per-flex-slider .owl-nav button.disabled span.nav-main-slider-btn {
    color: $color-5;
    cursor: auto;
}

.bookmark-popupopen {
    position: fixed;
    background: rgba(0, 0, 0, .5);
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    height: 100vh;
}

.bookmark-popupopen-container {
    max-width: 500px;
    margin: 2rem auto;
    background: $color-white;
    z-index: 1000;
    left: 0;
    right: 0;
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;
    height: auto;
    border-radius: 10px;
    top: 25%;
    transform: translateY(25%);
    padding: 2rem;
}

.bookmark-popup-close {
    position: absolute;
    color: $color-36;
    font-size: 2rem;
    right: 1rem;
    top: .5rem;
    font-weight: bold;
    cursor: pointer;
}

.bookmark-content .p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.bookmark-content {
    margin-top: 1.5rem;
}

.bookmarkcheckbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.dr-checkbox-area {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.checkbox-remove-area {
    width: 40%;
    text-align: center;
}

a.druncheck-btn {
    text-decoration: none;
    color: $color-white;
    background-color: $color-36;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 600;
}

.dr-checkbox-area input:checked,
.dr-checkbox-area input:not(:checked) {
    position: absolute;
    left: -9999px;
}

label.drbookmark-check {
    padding-left: 2.2rem;
    position: relative;
    font-size: .9rem;
}

label.drbookmark-check::before {
    border: 1px solid $color-5;
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 0;
    top: 6px;
}

.dr-checkbox-area input:checked ~ label.drbookmark-check::after {
    content: '\2713';
    position: absolute;
    left: 1px;
    top: 2px;
    font-size: 1.1rem;
    color: $color-36;
    font-weight: bold;
}

.dra-login-btn .logbutton {
    font-size: 1rem;
    padding: .8rem;
    height: auto;
    position: relative;
    padding-left: 2.5rem;
    border-radius: 5px;
    background-color: $color-white;
    color: $color-black;
    display: inline-block;
    font-weight: 700;
    border: $color-white;
    padding-bottom: .9rem;
}

.dra-login-btn span.drloginpop-txt {
    cursor: pointer;
}

.dra-login-btn span.drlog-btn-dropdown {
    cursor: pointer;
}

.profile-logout-container.shown-close-dropdown {
    display: block;
}

.dra-login-btn .logbutton::before {
    content: "";
    background-image: url("/static/img/icon-menu-user.svg");
    left: 1rem;
    position: absolute;
    top: .9rem;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
}

.profile-logout-container {
    display: none;
}

ul.dr-prologoutsesion {
    list-style: none;
    font-weight: bold;
    margin-bottom: 0;
    display: inline-block;
    margin-top: 1px;
    margin-left: 4rem;
    box-shadow: 1px 0 12px $color-black;
    background-color: $color-white;
    text-align: center;
    padding: 0;
}

ul.dr-prologoutsesion li.dr-logout {
    background-color: $color-white;
    padding: .5rem 2rem;
    cursor: pointer;
}

.draLoginToRa-modal.drloginCustpop {
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, .85);
}

.draLoginToRa-modal.drloginCustpop .drAccedi-popUpContainer {
    width: 100%;
    max-width: 900px;
    margin: 3rem auto;
    overflow: hidden;
    box-shadow: 0 0 13px 1px $color-4;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .28);
    height: 100%;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .col-3 {
    width: 30%;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .col-9 {
    width: 70%;
    padding: 60px;
    position: relative;
    box-sizing: border-box;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .lab-img img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px 0 0 5px;
    margin: 0;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .rt-col-cont {
    position: relative;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .close-dralogin {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .close-dralogin > img {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    margin-bottom: 0;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .header {
    color: $color-39;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 49px;
    margin-top: 0;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .sub-header {
    color: $color-39;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
    margin: 28px 0 24px;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap .prod-container {
    display: flex;
    align-items: center;
    width: 33.33%;
    margin-bottom: 26px;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap .prod-container a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap .prod-container img {
    width: 2rem;
    height: 2rem;
}

.draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap .prod-txt {
    color: $color-26;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 11px;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 19px;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form .form-field {
    position: relative;
    width: 100%;
    margin-right: 9px;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form .input-txt {
    display: block;
    width: 100%;
    padding: 0;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    border: none;
    border-bottom: 2px solid $color-black;
    background-color: transparent;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form .label {
    position: absolute;
    left: 0;
    bottom: 5px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    transition: transform .2s ease-in-out;
    pointer-events: none;
    outline: none;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form .input-txt:focus {
    outline: none;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form .input-txt:focus + .label,
.draLoginToRa-modal.drloginCustpop .draLogin-form .input-txt:valid + .label {
    transform: translateY(-14px);
    color: $color-6;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
}

.draLoginToRa-modal.drloginCustpop .draLogin-form small {
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 0;
    color: $color-error;
    display: block;
}

.draLoginToRa-modal.drloginCustpop .draLogin-submit {
    width: 100%;
    margin: 15px 0;
}

.draLoginToRa-modal.drloginCustpop .draLogin-submit input.accedi-btn {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    background-color: $color-26;
    border: none;
    border-radius: 5px;
    appearance: none;
    padding: 14px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.draLoginToRa-modal.drloginCustpop .draLogin-btm-txt {
    display: flex;
    justify-content: space-between;
}

.draLoginToRa-modal.drloginCustpop .draLogin-btm-txt .register,
.draLoginToRa-modal.drloginCustpop .draLogin-btm-txt a.forget-password {
    font-size: 16px;
    letter-spacing: 0;
}

.draLoginToRa-modal.drloginCustpop .draLogin-btm-txt a {
    color: $color-26;
}

.draLoginToRa-modal.drloginCustpop input[type='password']::-ms-reveal,
.draLoginToRa-modal.drloginCustpop input[type='password']::-ms-clear {
    display: none;
}

@media screen and (max-width: 1167px) {
    .draLoginToRa-modal.drloginCustpop .drAccedi-popUpContainer {
        max-width: 700px;
    }

    .draLoginToRa-modal.drloginCustpop .drAccedi-content .services-wrap .prod-container {
        width: 50%;
    }

    .draLoginToRa-modal.drloginCustpop .drAccedi-content .col-9 {
        padding: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .draLoginToRa-modal.drloginCustpop .drAccedi-content .col-3 {
        width: 100%;
    }

    .draLoginToRa-modal.drloginCustpop .drAccedi-content .col-9 {
        width: 100%;
        padding: 1rem;
    }

    .draLoginToRa-modal.drloginCustpop .drAccedi-content {
        margin: 1rem;
    }
}

.bg-grey.grey-wrapper {
    background-color: $color-7;
}

.container-wrapper {
    max-width: 1200px;
    margin: auto;
}

.dr-profolio-col {
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .3);
    padding: 1rem;
    width: 40%;
    position: relative;
    top: 3rem;
}

.dr-profolio-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

h2.profolio-heading {
    color: $color-35;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0  0 2rem;
    text-transform: none;
}

.profolio-sub-inline-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.profolio-modify {
    color: $color-26;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 5px;
}

label.profolio-label {
    display: block;
    color: $color-5;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.4;
}

.form-field-da {
    margin-bottom: 1rem;
}

input.profolio-input {
    color: $color-5;
    font-size: 16px;
    letter-spacing: 0;
    border: 0;
    border-bottom: 2px solid $color-black;
    width: 85%;
    outline: 0;
    margin: 0;
}

select.profolio-select {
    width: 87%;
    border-radius: 25px;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    outline: 0;
    padding: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
}

select.profolio-select::before {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0;
    color: $color-26;
    font-size: 1.2rem;
}

span.check-txt {
    color: $color-35;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

a.check-link {
    color: $color-36;
}

h2.reiplogo-heading {
    color: $color-35;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0  0 2rem;
    text-align: left;
    text-transform: none;
}

.dr-repleigo-col {
    width: 54%;
    padding-left: 2rem;
    margin-top: 3rem;
}

.repligo-cont-box {
    color: $color-35;
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 1.5rem;
}

span.repligo-icon {
    color: $color-36;
    margin-right: .5rem;
}

span.repligo-icon .fa {
    color: $color-36;
    margin-right: .5rem;
    padding: 0;
}

span.modify-txt .fa {
    color: $color-36;
    margin-right: .5rem;
    padding: 0;
}

.repligo-info {
    color: $color-6;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 1.5rem;
    margin-top: 5px;
}

a.repligo-link {
    color: $color-36;
}

.dr-formbtn-area {
    text-align: center;
    margin: 2rem auto;
}

a.drformbtn {
    text-align: center;
    text-decoration: none;
    color: $color-white;
    background-color: $color-36;
    padding: 8px 20px;
    border-radius: 15px;
    font-weight: bold;
}

a.profilo-modify-link {
    text-decoration: none;
    color: $color-26;
}

@media screen and (max-width: 1024px) {
    .dr-repleigo-col {
        width: 50%;
    }
}

@media screen and (max-width: 700px) {
    .dr-profolio-col,
    .dr-repleigo-col {
        width: 100%;
        margin-bottom: 1rem;
        top: 0;
    }
}

.drbook-image {
    width: 20px;
    margin: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .draLoginToRa-modal.drloginCustpop input[type='password']::-ms-reveal,
    .draLoginToRa-modal.drloginCustpop input[type='password']::-ms-clear {
        display: none;
    }
}

/* admin dashboard login popup */
.drdashbaord-loginpoup-container {
    background-color: $color-white;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
    display: block;
}

.drdashbaord-loginpoup {
    max-width: 500px;
    background-color: $color-white;
    border-radius: 10px;
    margin: auto;
    text-align: center;
    position: relative;
    width: 100%;
}

.drdashboard-loginwrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 10rem;
    background: $color-white;
    border-radius: 10px;
    padding: 2rem;
    margin: 1rem;
    box-shadow: 3px 2px 20px $color-4;
}

.drdashbaord-password-area input {
    border: 0;
    box-shadow: 0 2px 16px 2px $color-4;
    width: 92%;
    padding: 1rem;
    outline: 0;
}

.drdashboard-btn-area {
    margin: 2rem 0 0;
}

button.drdahboard-btn {
    padding: .5rem 2rem;
    background-color: $color-22;
    color: $color-white;
    border-radius: 5px;
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.dr-dashboard-login-error,
.dr-dashboard-login-wrong-password {
    text-align: left;
    margin-top: 1rem;
    color: $color-error;
    font-family: 'Open Sans Condensed';
    font-weight: 700;
}

h3.dashboard-login-heading {
    text-align: left;
    font-family: 'Open Sans Condensed';
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
}

/* admin dashboard form */
ul.admindashaboard-catagory-area {
    display: none;
}

.dr-admin-dashboard-wrapper {
    max-width: 1200px;
    margin: 2rem auto;
    font-family: 'Open Sans Condensed', sans-serif;
}

.admin-catagory-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.admincatagory-input {
    width: 15%;
}

.admin-dropdown-selected {
    width: 80%;
}

.admin-select-drop-area {
    box-shadow: 0 2px 6px 0 $color-4;
    padding: .8rem 1rem;
    width: 100%;
    border: 0;
    outline: 0;
}

/* select styles */
.cus-select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $color-black;
    width: 100%;
}

.cus-select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    color: $color-22;
    font-weight: bold;
    padding: 8px 15px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.cus-select-styled::after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: $color-black transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
}

.cus-select-styled:active,
.cus-select-styled.select-activated {
    background-color: $color-white;
    color: $color-22;
}

.cus-select-styled:active::after,
.cus-select-styled.select-activated::after {
    top: 9px;
    border-color: transparent transparent $color-black transparent;
}

.cus-select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $color-white;
    box-shadow: 0 2px 6px 0 $color-4;
}

.cus-select-options li {
    margin: 0;
    padding: 12px 0;
    font-weight: bold;
    text-indent: 15px;
    border-top: 1px solid $color-4;
    -moz-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
}

.cus-select-options li:hover {
    color: $color-22;
}

.cus-select-options li[rel="hide"] {
    display: none;
}

.admin-select-drop-area option.admin-catagory {
    padding: 1rem;
    border: 0;
}

label.catogoryinput-area {
    font-size: 1.3rem;
    font-weight: bold;
}

span.ad-drop-txt {
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
}

ul.admindashaboard-catagory-area {
    list-style: none;
    line-height: 2;
    padding: 0;
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
}

li.admin-catagory {
    border-bottom: 1px solid $color-4;
    font-weight: 600;
}

li.admin-catagory:last-child {
    border-bottom: 0;
}

.adminerror-msg {
    font-family: 'Open Sans', sans-serif;
    color: $color-error;
    margin: .5rem 0;
    margin-left: 20%;
    display: none;
}

.admin-dashboardnews-filter,
.dr-news-title-wrapper,
.dr-news-shortdesc-wrapper,
.admin-news-image-upload {
    margin-bottom: 2rem;
}

.admin-news-title-flex,
.admin-news-shortdesc-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: space-between;
}

.newstitle-label-area,
.news-shortdesc-label-area {
    width: 20%;
}

.adminnews-title-area,
.adminnews-shortdesc-area {
    width: 80%;
}

label.newstitle-label,
label.news-shortdesc-label {
    font-size: 1.3rem;
    font-weight: bold;
}

input.admin-news-titletxt,
input.admin-news-shortdesc-txt {
    box-shadow: 0 2px 6px 0 $color-4;
    padding: .8rem 1rem;
    width: 97%;
    margin: 0;
    outline: 0;
    border: 0;
    position: relative;
}

input.admin-news-titletxt:focus,
input.admin-news-shortdesc-txt:focus {
    border: 0;
    outline: 0;
}

.admin-upload-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
}

.admin-imglabel-area {
    width: 20%;
}

.admin-img-area {
    width: 80%;
}

.admin-img-area input {
    position: relative;
    width: auto;
    font-size: 1rem;
}

input[type="file" i]::-webkit-file-upload-button {
    background-color: $color-22;
    color: $color-white;
    outline: 0;
    border: 0;
    font-size: 1rem;
    padding: .5rem 1rem;
    cursor: pointer;
}

/* rich text editor */
.admin-richtexteditor-wrapper .editor-content .tox-statusbar {
    display: none;
}

.admin-richtexteditor-wrapper button.toggle-text {
    background: none;
    border: 0;
    color: $color-22;
    margin-top: 1rem;
    cursor: pointer;
}

button.admin-newssubmit {
    background-color: $color-22;
    color: $color-white;
    outline: 0;
    border: 0;
    font-size: 1rem;
    padding: .5rem 1rem;
    cursor: pointer;
}

textarea.plain-text-container {
    width: 80%;
    min-height: 10rem;
}

.adminupload-news {
    margin: 2rem auto;
}

.admin-richtexteditor-wrapper .toggle-text {
    position: absolute;
    bottom: -2rem;
    right: 0;
    font-weight: 700;
}

.admin-shortdesc-content.editor-content {
    width: 80%;
}

.admin-shortdesc-content {
    width: 80%;
}

.admin-shortdesc-content .tox .tox-statusbar {
    display: none;
}

textarea#editorShort,
textarea#editorLong {
    width: 79.5%;
    margin: 0;
    min-height: 10rem;
}

.admin-news-shortdesc-flex {
    position: relative;
}

.admin-longdesc-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
}

.admin-longdesc-area {
    width: 20%;
}

.admin-logindesc-content.editor-content {
    width: 80%;
}

label.newsdesc-label {
    font-size: 1.3rem;
    font-weight: bold;
}

/* calendar */
.admin-newsdate-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
}

.admin-news-date {
    margin: 3rem 0;
}

.admin-newsdatelabel-area {
    width: 20%;
}

label.newsdate-label {
    font-size: 1.3rem;
    font-weight: bold;
}

.admin-datefield-area {
    width: 80%;
}

input.admin-newsdate-field {
    box-shadow: 0 2px 6px 0 $color-4;
    padding: .8rem 1rem;
    width: 10rem;
    margin: 0;
    outline: 0;
    border: 0;
    position: relative;
    color: $color-black;
}

input.admin-newsdate-field:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

input.admin-newsdate-field::-webkit-input-placeholder {
    color: $color-black;
}

/* media quiries */
@media screen and (max-width: 700px) {
    .admincatagory-input,
    .admin-dropdown-selected,
    .newstitle-label-area,
    .adminnews-title-area,
    .admin-imglabel-area,
    .admin-img-area,
    .newstitle-label-area,
    .news-shortdesc-label-area,
    .admin-shortdesc-content,
    textarea#editorShort,
    textarea#editorLong,
    .admin-newsdatelabel-area {
        width: 100%;
        margin-bottom: 1rem;
    }

    .adminerror-msg {
        margin-left: 0;
    }

    input.admin-news-titletxt,
    .admin-img-area input {
        left: 0;
    }

    input.admin-news-titletxt {
        width: 92%;
    }

    form#admindashboard-form {
        padding: 0 20px;
    }
}
