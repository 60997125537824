$brochure-teaser-color-background: $color-7 !default;
$brochure-teaser-color-border: $color-5 !default;
$brochure-teaser-color-title--new: $color-1 !default;

.layout-column--brochure-teaser {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: em(20px);
}

.brochure-teaser {
    background: $brochure-teaser-color-background;
    margin: em(10px);
    padding: em(20px);
    width: 100%;

    &_title--new {
        color: $brochure-teaser-color-title--new;
    }

    &_figure {
        margin: em(10px) 0 em(20px);

        .brochure-teaser--notavailable & {
            position: relative;

            &::after {
                content: '';
                background: $color-black;
                opacity: .7;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 99;
                width: 100%;
            }
        }
    }

    &_image {
        border: solid 1px $brochure-teaser-color-border;
        box-shadow: 3px 3px 3px rgba($color-black, .15);
        margin: 0;
    }

    &_container {
        overflow: hidden;
    }

    &_text {
        margin: 0 0 1em;
    }

    .form-layout {
        .form-layout-item_label {
            display: block;
        }
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .brochure-teaser {
        .layout-row--2-column & {
            width: 50%;
            max-width: calc(50% - #{$layout-gutter-large});
            flex: 2 1 50%;
            display: flex;
            flex-direction: column;

            &_row {
                overflow: hidden;
                flex-grow: 1;
            }

            .form-layout--brochure-teaser {
                overflow: hidden;
                width: 100%;
            }

            &_figure {
                max-width: 160px;
                margin: 0 em(20px) em(32px) 0;
                float: none;
            }
        }

        .layout-row--4-column & {
            width: 50%;
            max-width: calc(50% - #{$layout-gutter-large});
            flex: 2 1 50%;
            display: flex;
            flex-direction: column;

            &_row {
                overflow: hidden;
                flex-grow: 1;
            }

            .form-layout--brochure-teaser {
                overflow: hidden;
                width: 100%;
            }

            &_figure {
                max-width: 160px;
                margin: 0 em(20px) em(32px) 0;
                float: none;
            }
        }
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .brochure-teaser {
        .layout-row--2-column & {
            .form-layout--brochure-teaser {
                .form-layout-item {
                    float: right;
                    padding-right: 20%;
                }

                .form-layout-item_label {
                    display: inline-block;
                }
            }

            &_figure {
                float: left;
            }
        }

        .layout-row--4-column & {
            width: 25%;
            max-width: calc(25% - #{$layout-gutter-large});
            flex: 4 1 25%;

            .form-layout--brochure-teaser {
                overflow: hidden;
                width: 100%;
            }

            &_figure {
                max-width: none;
                margin: em(10px) 0 em(20px);
                float: none;
            }
        }
    }
}
