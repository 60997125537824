.form-submitted {
    img {
        margin: 0 auto;
        max-width: 150px;
    }

    p {
        text-align: center;
    }
}
