.social-media {
    @include blank-list;
    max-width: rem(184px);

    li {
        display: inline-block;

        a {
            display: block;
            width: rem(24px);
            height: rem(24px);
            background-size: contain;
        }
    }
}
