$footer-color-background: $color-8 !default;
$footer-color-text: $color-5 !default;
$footer-color-arrowlist: inherit !default;

footer {
    background: $footer-color-background;
    padding-top: 3.5em;
    color: $footer-color-text;

    a {
        color: $footer-color-text;
        text-decoration: none;
    }
}

.footer_heading {
    display: flex;
    align-items: baseline;
}

.footer_logo {
    width: auto;
    margin-bottom: 0;
    max-height: $layout-element-margin-default * 2;
}

.layout-column--footer {
    &-copyright {
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: rem(16px);

        img {
            margin: -35px 5px 5px 0;
            width: 90px;
        }
    }

    &-nav {
        display: flex;

        a + a {
            margin-left: rem(20px);
        }
    }
}

// === => tablet (700px) ===
@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--footer {
        &-copyright {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }
}

// === desktop (1024px) => ===
@media (min-width: $layout-breakpoint-desktop) {
    .layout-row__footer {
        display: flex;
        justify-content: space-between;
    }

    .layout-column--footer {
        &--nav {
            // width: 250px;
        }
    }
}
