#countrycheck {
    display: none;
}

.redirectpopup01 {
    width: 70%;
    height: auto;
    padding: 1rem;
    z-index: 99999;
    background: #fff;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.redirectpopup01 img {
    width: 200px;
    height: auto;
    margin: 2rem auto;
}

.redirectpopup01 .btn {
    margin-bottom: 25px;
}

.boldhb {
    margin-bottom: 2rem;
    font-weight: bold;
}

.btnhb {
    padding: .5rem 1rem;
    display: inline-block;
    border: 1px solid;
    border-radius: .5rem;
    position: relative;
}

.dropdownhb {
    display: none;
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    left: 0;
    top: -100%;
    border: 1px solid;
    border-radius: .5rem;
}

.dropdownhb ul li::before {
    content: none;
}

.dropdownhb ul {
    padding-left: 1rem;
    margin-bottom: 0;
}

.dropdownhb ul li {
    text-align: left;
    padding: .1rem .2rem;
}

.dropdownhb ul li a {
    text-decoration: none;
}
