.bro-form-container {
    width: 1200px;
    margin: 0 auto;
    max-width: 100%;
    padding: 1.5rem;
}

.bro-form-container #form-dynamic .switch-container .electronic {
    width: auto;
    float: left;
    margin-right: 2rem;
}

.bro-form-container #form-dynamic .switch-container .printed {
    width: auto;
    float: left;
}

.switch-container {
    float: left;
    width: 100%;
}

.bro-form-container #form-dynamic .switch-container input {
    width: auto;
}

.bro-form-container #form-dynamic label,
.bro-form-container #form-dynamic input,
.bro-form-container #form-dynamic select {
    display: block;
    margin-bottom: .5rem;
    width: 100%;
    padding: .3rem;
    box-sizing: border-box;
}

.bro-form-container #form-dynamic .switch-container input[type="radio"] {
    width: auto;
    display: inline-block;
}

.foot-note {
    text-align: center;
}

.bro-form-container #form-dynamic .switch-container input[type="radio"] {
    background-color: $color-white;
    border: 1px solid $color-5;
    border-radius: 50%;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: .5rem;
    -ms-appearance: none;
}

.bro-form-container #form-dynamic .switch-container input[type='radio']::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: $color-white;
    content: '';
    display: inline-block;
    visibility: visible;
}

.bro-form-container #form-dynamic .switch-container input[type='radio']:checked::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: $color-3;
    content: '';
    display: inline-block;
    visibility: visible;
}

.repathalayout-container {
    max-width: 1200px;
    padding: 0 10px;
    margin: auto;
}

h3.bestellanheading {
    font-size: 1.8rem;
    color: $color-2;
}

.repathabestellan-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 1rem 0;
}

.repathabestellan-labelcol {
    width: 25%;
}

.repathabestellan-inputcol {
    width: 75%;
}

.repathabestellanform input.inputbox {
    padding: 1rem;
    width: -webkit-fill-available;
    border-radius: 10px;
    border: 1;
    border-color: $color-5;
    outline: 0;
}

.repathabestellanbtn {
    width: 50%;
    margin: auto;
}

a.btn.btn-primary.bestellan-btn {
    background: $color-1;
    border-color: $color-1;
    color: $color-white;
    width: auto;
    padding: 1rem;
    text-decoration: none;
}

.repatha-organistaioninputbox {
    margin-bottom: 1rem;
}

.bestellan-privacy-p {
    width: 75%;
    margin: 2rem 0;
}

.bestellan-sm-p {
    font-size: .9rem;
    margin-bottom: 1.5rem;
}

.repatha-errormessage {
    color: $color-error;
    display: none;
    width: 100%;
    padding-left: 25%;
}
