$newsletter-teaser-color-devider: $color-4 !default;

.layout-column--newsletter-teaser {
    padding-top: rem(45px);
    padding-bottom: rem(45px);
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        background: $newsletter-teaser-color-devider;
        height: 2px;
        position: absolute;
        left: $layout-gutter-small / 2;
        right: $layout-gutter-small / 2;
        top: 0;
    }

    &::after {
        top: auto;
        bottom: 0;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--newsletter-teaser {
        text-align: center;
        padding-top: rem(90px);
        padding-bottom: rem(90px);
    }
}

.newsletter-teaser {
    @include component-margin;
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--newsletter-teaser {
        &::before,
        &::after {
            left: $layout-gutter-large / 2;
            right: $layout-gutter-large / 2;
        }
    }
}
