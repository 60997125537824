.RegForm-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    box-sizing: border-box;
    background-color: $color-4;
    font-family: 'Futura Std', sans-serif;
}

.RegForm-container h1 {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: $color-65;
    width: 40%;
    margin: auto;
    text-align: center;
    font-weight: 400;
}

.RegForm-container .p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: .4px;
    color: $color-65;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.RegForm-container .panel {
    width: 50%;
    margin: auto;
}

.vectibix-form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.vectibix-form-container .form-field {
    display: flex;
    flex-direction: column;
    width: 49%;
    position: relative;
    margin-bottom: .6rem;
}

.vectibix-accordion-formcontrol .form-field {
    margin-bottom: .6rem;
}

.vectibix-form-container .form-field label .label,
.vectibix-accordion-formcontrol .form-field label.label {
    font-size: 18px;
    letter-spacing: .4px;
    line-height: 28px;
    color: $color-65;
}

.vectibix-form-container .form-field .input-txt,
.vectibix-accordion-formcontrol .form-field .input-txt {
    padding: 11px 16px;
    border: none;
    border-radius: 5px;
    outline: none;
    color: $color-65;
    letter-spacing: .4px;
    font-size: 18px;
    line-height: 28px;
    background-color: $color-white;
}

.RegForm small.errorMsg {
    display: none;
    color: $color-66;
    text-align: right;
}

.RegForm input.invalid + .errorMsg {
    display: block;
    position: relative;
}

.RegForm input.confirmbox.invalid + .errorMsg {
    text-align: left;
}

.RegForm input.input-txt.invalid-input.invalid {
    border: 3px solid $color-66;
    position: relative;
}

.RegForm-container .form-layout-item .check-lable input.invalid ~ .cust-check {
    border: 3px solid $color-66;
}

.RegForm input.input-txt.invalid-input.invalid + small.errorMsg::before {
    content: "";
    background-image: url("/static/img/alert_2.svg");
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    top: -2.3rem;
    right: 0;
}

.vectibix-accordion-formcontrol .form-field .input-txt {
    width: -webkit-fill-available;
}

.RegForm-container .form-layout-item .errorMsg {
    color: $color-66;
}

.RegForm-container .form-layout-item {
    margin-bottom: 1rem;
    position: relative;
    padding: 1rem;
}

.RegForm-container .form-layout-item label {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .4px;
    color: $color-65;
}

.RegForm-container .form-layout-item input {
    margin-right: .5rem;
    width: 42px;
    height: 24px;
    border-radius: 3px;
}

.RegForm-container .form-layout-item input:focus {
    background-color: $color-38;
}

.RegForm-container .panel.required-field .p {
    margin: 1rem 0;
    text-align: left;
}

.RegForm-container .RegForm-button input[type="submit"] {
    display: flex;
    background: $color-38;
    width: 15%;
    margin: auto;
    padding: 12px 32px;
    border-radius: 36px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $color-6;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2.5px;
    border: none;
    outline: none;
}

.RegForm-container .panel .form-field select.input-txt {
    background: url(/static/img/vectibix_uk_bg_dropdown.png);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: right;
    background-color: $color-white;
    appearance: none;
    color: rgba(24, 44, 76, .3);
}

.RegForm-container .form-field.error::before {
    content: url('/static/img/alert_2.svg');
    position: absolute;
    right: 1rem;
    top: 2.6rem;
    visibility: hidden;
}

.RegForm-container .form-field.error {
    position: relative;
}

.RegForm-container .form-field input:focus::placeholder {
    color: transparent;
}

.RegForm-container .form-layout-item .check-lable {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

.RegForm-container .form-layout-item .check-lable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.RegForm-container .form-layout-item .cust-check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $color-white;
}

.RegForm-container .form-layout-item .check-lable:hover input ~ .cust-check {
    background-color: $color-white;
}

.RegForm-container .form-layout-item .check-lable input:checked ~ .cust-check {
    background-color: $color-38;
}

.RegForm-container .form-layout-item .cust-check::after {
    content: '';
    position: absolute;
    display: none;
}

.RegForm-container .form-layout-item .check-lable input:checked ~ .cust-check::after {
    display: block;
}

.RegForm-container .form-layout-item .check-lable .cust-check::after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

@media screen and (max-width: 1024px) {
    .RegForm-container .panel {
        width: 70%;
    }

    .RegForm-container h1 {
        width: 65%;
    }
}

@media screen and (max-width: 700px) {
    .RegForm-container .panel {
        width: 88%;
    }

    .RegForm-container h1 {
        width: 100%;
        font-size: 24px;
    }

    .RegForm-container .p {
        width: 80%;
        margin: 2rem auto;
    }

    .vectibix-form-container .form-field {
        width: 100%;
    }

    .RegForm-container .RegForm-button .p {
        width: 25%;
    }

    .RegForm-container .check-box-content {
        position: relative;
    }

    .RegForm-container .panel.required-field .p {
        position: absolute;
        top: 1rem;
    }

    .RegForm-container .form-layout-item {
        position: absolute;
        top: 3rem;
    }

    .RegForm-container .form-layout-item {
        padding: 1rem 1rem 3rem;
    }
}

