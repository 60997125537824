.depo-quiz-section {
    display: none;
    padding: 2rem 0;
}

.depo-quiz-container {
    max-width: 700px;
    position: relative;
    margin: auto;
    background-color: $color-7;
}

.depo-prev,
.depo-next {
    cursor: pointer;
    position: absolute;
    top: 35%;
    width: auto;
    padding: 1rem;
    color: $color-22;
    font-weight: bold;
    font-size: 2rem;
    transition: .6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.depo-question-p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 2rem;
    color: $color-35;
    line-height: 1.5;
    font-weight: bold;
}

.depo-next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.depo-ques-text {
    color: $color-22;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.depo-quiz-box {
    max-width: 600px;
    min-height: 400px;
    background-color: $color-7;
    margin: 0 auto;
    padding: 0 5rem;
}

.depo-quiz-p {
    font-size: 1rem;
    color: $color-35;
    line-height: 1.5;
    margin-bottom: 1rem;
}

textarea.depo-quiz-comments {
    width: 100%;
    min-height: 6rem;
    border-color: $color-22;
}

.depo-input-area {
    margin-bottom: .5rem;
}

.depo-quiz-start-btn-area {
    max-width: 75%;
    margin: 2rem auto;
    text-align: center;
}

.depo-quiz-start-btn {
    border: 2px solid $color-22;
    padding: 1rem;
    border-radius: 10px;
    color: $color-22;
}

span.depo-ques-decoration {
    color: $color-22;
    text-decoration: underline;
}

.depo-ques-redirect-flex {
    display: flex;
    flex-direction: column;
}

.depo-ques-redirect-col {
    margin-bottom: 1.5rem;
}

.depo-input-area {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.2rem;
}

.depo-input-area input[type="radio"]:checked,
.depo-input-area input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.depo-input-area input[type="radio"]:checked + label.check-radio,
.depo-input-area input[type="radio"]:not(:checked) + label.check-radio {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $color-5;
}

.depo-input-area input[type="radio"]:checked + label.check-radio::before,
.depo-input-area input[type="radio"]:not(:checked) + label.check-radio::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 13px;
    height: 13px;
    border: 1px solid $color-5;
    border-radius: 100%;
    background: $color-white;
}

.depo-input-area input[type="radio"]:not(:checked) + label.check-radio::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.depo-input-area input[type="radio"]:checked + label.check-radio::after,
.depo-input-area input[type="radio"]:not(:checked) + label.check-radio::after {
    content: '';
    width: 15px;
    height: 15px;
    background: $color-22;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

input.depo-date-field {
    width: 94%;
    padding: 1rem;
    outline: 0;
    position: relative;
}

.depo-datefieldinput {
    margin-top: 3rem;
}

.depo-datefieldinput input[type="date" i]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 94%;
    height: 100%;
    opacity: 0;
}

textarea.depo-quiztextarea-feild {
    width: 94%;
    padding: 1rem;
}

h4.quiz-smheading {
    margin-bottom: .5rem;
    color: $color-22;
}

.depo-quiz-section.question-wrapperone-4 .depo-datefieldinput {
    margin-top: 0;
}

.depo-quiz-downloadbtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3rem 0;
}

.depo-quiz-download,
.depo-quiz-nextquestionarie {
    color: $color-22;
    border: 1px solid $color-22;
    padding: 1rem 2rem;
    border-radius: 8px;
}

.depo-quizimgtext-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.depo-quiz-imgtextarea-col {
    width: 50%;
}

.depo-quizimg-col {
    width: 45%;
}

.depo-quiz-imgtextarea-col textarea.depo-quiztextarea-feild {
    width: 85%;
    min-height: 5rem;
    outline: 0;
}

.depo-feedback-option-input-col {
    display: flex;
    justify-content: space-around;
    border-radius: 30px;
    padding: .8rem 2rem;
    background: linear-gradient(to right, $color-valid 50%, $color-22 100%);
}

.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:checked,
.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:checked + label.check-radio,
.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:not(:checked) + label.check-radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $color-5;
    z-index: 1;
}

.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:checked + label.check-radio::after,
.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:not(:checked) + label.check-radio::after {
    content: '';
    width: 40px;
    height: 40px;
    background: #069acd;
    position: absolute;
    top: -13px;
    left: -12px;
    border: 3px solid $color-white;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.depo-feedback-quest-options .depo-feedback-option-input-col [type="radio"]:not(:checked) + label.check-radio::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

span.depo-quiz-numbers {
    position: relative;
    z-index: 1;
    padding-left: 6px;
    color: $color-white;
}

.depo-feedback-option-inputname-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .6rem 2.5rem;
    text-align: center;
}
/*image check box*/
.quiz-checkboximg-data input[type="checkbox"]:checked, .quiz-checkboximg-data input[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.quiz-checkboximg-data input[type="checkbox"]:checked + label, .quiz-checkboximg-data input[type="checkbox"]:not(:checked) + label{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.quiz-checkboximg-data input[type="checkbox"]:checked + label:before, .quiz-checkboximg-data input[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.quiz-checkboximg-data input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.quiz-checkboximg-data input[type="checkbox"]:checked + label:after, .quiz-checkboximg-data input[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: #bfd736;
    position: absolute;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.quizimg-col {
    position: relative;
}
.quiz-checkboximg-data{
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-1{
    top: 0;
    right: 0;
}
.quiz-checkboximg-data.quiz-checkboximg-data-2 {
    top: 4%;
    right: 46%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-2 span.qcheckimg-txt {
    left: -3.5rem;
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-3 {
    top: 18%;
    right: 30%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-3 span.qcheckimg-txt {
    right: -6.5rem;
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-4 {
    top: 28%;
    left: 19%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-4 span.qcheckimg-txt {
    left: -3rem;
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-5 {
    top: 35%;
    left: 40%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-5 span.qcheckimg-txt {
    left: -7rem;
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-6 {
    top: 43%;
    left: 43%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-6 span.qcheckimg-txt {
    left: -6rem;
    position: absolute;
    top: 2rem;
}
.quiz-checkboximg-data.quiz-checkboximg-data-7 {
    top: 43%;
    right: 5%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-7 span.qcheckimg-txt {
    position: absolute;
    top: 2rem;
    left: 0;
}
.quiz-checkboximg-data.quiz-checkboximg-data-8 {
    bottom: 35%;
    left: 39%;
}
.quiz-checkboximg-data.quiz-checkboximg-data-8 span.qcheckimg-txt {
    left: -3rem;
    position: absolute;
}
.quiz-checkboximg-data.quiz-checkboximg-data-9 {
    bottom: 10%;
    right: 7%;
}