.cross-brand-search-container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.cross-brand-search-container h2 {
    color: $color-5;
    font-size: 26px;
    padding-bottom: 30px;
}

.cross-brand-search-container .material-text-area {
    display: flex;
    align-items: center;
    padding: 12px 30px;
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 1px 1px 5px $color-5;
    margin-bottom: 25px;
}

.cross-brand-search-container .material-text {
    width: 95%;
}

.cross-brand-search-container .material-play-btn {
    align-self: center;
    width: 5%;
    cursor: pointer;
}

.cross-brand-search-container .material-text .material-title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
    color: $color-5;
}

.cross-brand-search-container .material-date {
    font-size: 18px;
    font-weight: 600;
    color: $color-5;
}

.cross-brand-search-container .material-text .p {
    font-size: 16px;
    line-height: 23px;
    color: $color-6;
}

.search-view-more {
    text-align: right;
    margin-top: 40px;
}

.search-view-more a {
    color: $color-4;
    text-decoration: none;
    border: 1px solid $color-4;
    padding: 7px 25px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px grey;
}

.vect-material-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, .8);
    box-sizing: border-box;
    padding: 10px;
    z-index: 100;
    display: none;
}

.vect-material-close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    color: $color-white;
    font-size: 40px;
    cursor: pointer;
}

.vect-material-video-popup {
    width: 50%;
    height: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    border: 3px solid $color-white;
}

@media (max-width: 1024px) {
    .cross-brand-search-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

