.embed-wrapper {
    position: relative;
    margin-bottom: $layout-element-margin-default;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.embed-wrapper--16x9 { // Ratio 16:9 landscape
    padding-bottom: 56.25%;
}

.embed-wrapper--9x16 { // Ratio 16:9 portrait
    padding-bottom: 177.78%;
}

.embed-wrapper--4x3 { // Ratio 16:9 landscape
    padding-bottom: 75%;
}

.embed-wrapper--3x4 { // Ratio 16:9 portrait
    padding-bottom: 133.33%;
}

.embed-wrapper--1x1 { // Ratio 1:1 square
    padding-bottom: 100%;
}

.embed-wrapper--fixedheight { // No ratio, height is set inline
    height: auto;
}
