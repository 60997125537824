$font-weight-primary-bold: $font-weight-primary * 2;

.side-navigation {
    font-weight: $font-weight-primary;
    line-height: $base-line-height;
    margin: 0 0 3rem;

    &__title,
    &__title a,
    &__list {
        color: $color-2;
        font-weight: $font-weight-primary;
        list-style-type: none;
        padding: .25rem 0;
        text-decoration: none;

        .icon-arrow-small-left-blue::before {
            top: 56% !important;
            left: -2px;
        }

        li {
            background: #efefef;
            list-style-type: none;
            margin: 3px 0;
            padding: .2rem .5rem;

            a {
                background-color: transparent;
                color: $color-2;
                line-height: 1.125rem;
                margin: 2px 0;
                padding: .2rem .5rem;
                text-decoration: none;
            }

            ul.side-navigation__submenu-inactive {
                display: none !important;
            }
        }

        li.side-navigation__link-active,
        li.side-navigation__submenu-active {
            // background
            background-color: $color-white !important;
            border: 1px solid $color-2;
            border-width: 1px 0;
            padding-left: 0;
            border-top: 1px solid $color-2;
            border-bottom: 1px solid $color-2;

            ul {
                background: $color-white;
                display: block;
                padding-left: 1rem;

                li {
                    background: $color-white;
                    border-top: 1px solid #efefef !important;
                    border-bottom: none !important;
                }
            }

            // remove the custom red bullets (list-style-type)
            li::before {
                background-color: transparent;
            }

            ul {
                display: block;
            }
        }
    }

    &__title {
        font-weight: $font-weight-primary-bold;
        text-transform: uppercase;
    }
}
