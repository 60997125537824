$contact-color-border: $color-1 !default;
$contact-breakpoint: 768px;
$contact-color-btn-bg-mobile: $color-1 !default;
$contact-color-btn-bg-desktop: $color-white !default;
$contact-color-btn-text-mobile: $color-white !default;
$contact-icon-mail-mobile: mail-small-white !default;
$contact-icon-phone-mobile: phone-small-white !default;
$contact-icon-mail-desktop: mail-small-red-repatha !default;
$contact-icon-phone-desktop: phone-small-red-repatha !default;

.contact {
    margin-bottom: 1em;

    &_navlist {
        @include blank-list;
        margin: 0;

        &_item_container {
            position: relative;
        }

        &_icon {
            position: absolute;
            top: 50%;
            left: 11px;
            width: 22px;
            height: 22px;
            transform: translateY(-50%);
            z-index: 1;
        }

        &_link {
            text-decoration: none;
            display: block;
            position: relative;
            text-align: left;
        }
    }
}

@media (max-width: ($contact-breakpoint - 1)) {
    .contact {
        &_navlist {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -.5em;

            &_item {
                flex: 1 0 auto;
                margin: 0 .5em .5em;
            }

            &_link {
                background: $contact-color-btn-bg-mobile;
                text-transform: uppercase;
                color: $contact-color-btn-text-mobile;
                padding: rem(11px 24px 8px) 3.35rem; // 'inherited' from .btn.icon-left

                &::before {
                    @include position(absolute, 50% false false 12px);
                    transform: translateY(-50%);
                }
            }
        }

        &_contentpanel {
            padding-top: 1em;

            &[aria-hidden='true'] {
                display: none;
            }

            &[aria-hidden='false'] {
                display: block;
            }
        }
    }
}

@media (min-width: $contact-breakpoint) {
    .has-js .contact {
        @include position(fixed, 33.3333% 0 false false);
        z-index: 10;
        transform: translateY(-50%);
        border: 1px solid $contact-color-border;
        border-right: 0;
        background: $color-white;
        padding: 0 10px;

        &_navlist {
            &_item {
                &:not(:first-child) {
                    border-top: 1px dotted $contact-color-border;
                }
            }

            &_icon {
                position: static;
                transform: none;
                margin: 11px auto 0;
                cursor: pointer;
            }

            &_link {
                padding: 10px 15px;
                line-height: 1;
                text-align: center;

                &::before {
                    margin: 0 auto 10px;
                }

                &--mail {
                    &::before {
                        @include icon-props($contact-icon-mail-desktop);
                    }
                }

                &--phone {
                    &::before {
                        @include icon-props($contact-icon-phone-desktop);
                    }
                }
            }
        }

        &_contentpanel {
            display: none;
            position: absolute;
            right: calc(100% + 20px);
            width: 290px;
            padding: 12px 18px;
            background: $color-white;
            border: 1px solid $contact-color-border;
            transition: transform .3s ease, opacity .2s ease, right 0s;

            &[aria-hidden='true'] {
                opacity: 0;
                transform: translateX(40px) scale(.8);
                right: 1000px;
                pointer-events: none;
                transition: transform .3s ease, opacity .2s ease, right 0s .3s;
            }

            &[aria-hidden='false'] {
                opacity: 1;
                transform: translateX(0) scale(1);
            }

            &::after,
            &::before {
                left: 100%;
                top: 30px;
                border: solid transparent;
                content: '';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &::after {
                border-color: rgba($color-white, 0);
                border-left-color: $color-white;
                border-width: 5px;
                margin-top: -5px;
            }

            &::before {
                border-color: rgba($contact-color-border, 0);
                border-left-color: $contact-color-border;
                border-width: 6px;
                margin-top: -6px;
            }

            > :last-child {
                margin-bottom: 0;
            }

            &_heading {
                margin-bottom: 5px;
            }

            &--mail {
                top: 0;
            }

            &--phone {
                top: 64px;
            }
        }

        &[data-initialized] {
            .contact_contentpanel {
                display: block;
            }
        }
    }
}
