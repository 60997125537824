.hem-mailanchor {
    margin: 2rem auto;
}

a.hem-skbtn {
    background-color: $color-54;
    border: 2px solid $color-54;
    color: $color-white;
    text-align: center;
    padding: .7rem 2rem;
    text-decoration: none;
    border-radius: 1.5rem;
    cursor: pointer;
    min-height: auto;

    &:hover {
        color: $color-54;
        background-color: $color-white;
    }
}

.hem-mailtrigger-close {
    position: absolute;
    right: 20px;
    font-weight: bold;
    color: $color-white;
    cursor: pointer;
    top: 1rem;
    background-color: $color-39;
    border-radius: 50%;
    font-size: 2rem;
    padding: 0 10px;
}

.hem-mail-innercontainer {
    max-width: 1000px;
    margin: 2rem auto;
    background: $color-white;
    z-index: 1000;
    left: 0;
    right: 0;
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;
    height: 92vh;
    border-radius: 10px;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-5;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }
}

.hem-mailpop-outer-container {
    position: fixed;
    background: $color-55;
    opacity: .5;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    height: 100vh;
}

.hem-mail-trigger-content {
    padding: 2rem;
    margin-top: 2rem;
}

form.hempsk-mail-form {
    margin-top: 3rem;
}

h3.hem-mailtrigger-heading {
    font-size: 2rem;
    color: $color-39;
    font-weight: 500;
    text-align: center;
    margin-bottom: .5rem;
}

h4.hem-mailtrigger-subheading {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 0;
    font-weight: 500;
    color: $color-39;
}

label.hem-inputtxt {
    color: $color-39;
    display: block;
    margin-bottom: .5rem;
}

select.hem-selct-area {
    width: 100%;
    padding: .5rem;
    color: $color-39;

    &:focus {
        outline: 0;
    }

    option {
        color: $color-39;
    }
}

.hem-sk-input-area {
    margin-bottom: 1.5rem;
    margin-right: 2rem;
}

.hem-forminput-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
}

.hem-sk-input-area.hem-sk-colsm {
    width: 10%;
}

.hem-sk-input-area.hem-sk-colsm-md {
    width: 25%;
}

.hem-sk-input-area.hem-sk-colmd {
    width: 25%;
}

.hem-sk-input-area.hem-sk-collg {
    width: 35%;
}

input.hem-sk-inputbox-field {
    padding: .5rem;
    width: 93%;

    &:focus {
        outline: 0;
    }
}

textarea.hem-sk-textarea-field {
    width: 99.5%;
    min-height: 6rem;

    &:focus {
        outline: 0;
    }
}

.hem-accept-box-area {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.hem-checkbox-accept-content {
    margin-left: 1rem;
}

.hem-sk-submit-btn-area {
    text-align: right;
}

.hem-cancelsubmit-btn-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
    max-width: 700px;
    margin: 2rem auto 3rem;
}

.hem-cancel-btn {
    color: $color-39;
    border: 1px solid $color-39;
    padding: .5rem 2rem;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1;
    min-height: auto;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
}

button.hem-mailsubmit {
    background-color: $color-39;
    color: $color-white;
    padding: .8rem 2.5em;
    box-shadow: none;
    appearance: none;
    border-radius: 6px;
    cursor: pointer;
    outline: 0;
    border: 0;
}

.hem-mandatory-fields {
    color: $color-5;
    font-size: 14px;
}

.hem-required-field {
    margin-top: .5rem;
    font-size: .9rem;
    color: $color-50;
    display: none;
}

.hem-mailconfirmation-conatiner {
    display: block;
    max-width: 400px;
    margin: 2rem auto;
    background: $color-white;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 40%;
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;
    height: auto;
    border-radius: 10px;
    padding: 2rem 1rem;
}

.hem-mailconf-close {
    top: 0;
    position: absolute;
    right: 1rem;
    font-size: 2rem;
}

.mail-conf-msg {
    margin-top: 1rem;
    font-size: 1rem;
}

.hem-spinner-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hem-loaderwrapper {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}

.hem-loader {
    border: 8px solid $color-4;
    border-radius: 50%;
    border-top: 8px solid $color-49;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes "spin" {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes "spin" {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 700px) {
    .hem-mail-innercontainer {
        margin: 1rem;
    }

    .hem-sk-input-area.hem-sk-collg {
        width: 100%;
    }

    .hem-sk-input-area.hem-sk-colsm-md {
        width: 100%;
    }

    .hem-sk-input-area.hem-sk-colsm {
        width: 100%;
    }

    .hem-sk-input-area.hem-sk-colmd {
        width: 100%;
    }

    .hem-mailconfirmation-conatiner {
        margin: 1rem;
    }
}
