.kyp-contactwrapper {
    font-family: 'Roboto', sans-serif;
    padding: 0 10px;
    max-width: 800px;
    margin: 2rem auto;
}

.kypcontact-form-container {
    font-family: 'Roboto', sans-serif;
    padding: 0 10px;
    max-width: 800px;
    margin: 0 auto;
}

h3.kyp-contact-heading {
    color: $color-37;
    text-align: center;
    font-size: 1.75rem;
}

.kyp-contact-content .p,
.kypcontact-p {
    margin-bottom: 1rem;
    color: $color-5;
    line-height: 1.8;
}

.kyp-contact-content {
    color: $color-5;
    line-height: 1.8;
}

.kypmulti-contact-wrapper {
    color: $color-5;
    line-height: 1.8;
}

.kyprequired-content {
    margin-bottom: 1.2rem;
}

span.pink-icon {
    color: $color-37;
}

.kyp-contact-redirect {
    width: fit-content;
    cursor: pointer;
    margin-bottom: .5rem;
}

.kyp-contact-inputs {
    left: 1.2rem;
    position: relative;
}

.kyp-contact-inputs label.kyp-radio-label {
    cursor: pointer;
    position: relative;
}

.kyp-contact-inputs label.kyp-radio-label::before {
    content: '';
    position: absolute;
    left: -1.2rem;
    top: 9px;
    width: 12px;
    height: 12px;
    border: 1px solid $color-37;
    border-radius: 100%;
    background: $color-white;
}

.kyp-contact-inputs label.kyp-radio-label::after {
    content: '';
    width: 13px;
    height: 13px;
    background: $color-37;
    position: absolute;
    top: 9px;
    left: -1.2rem;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.kyp-contact-inputs input.kyp-radio-input[type="radio"]:not(:checked) + label.kyp-radio-label::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.kyp-contact-inputs input.kyp-radio-input[type="radio"]:checked,
.kyp-contact-inputs input.kyp-radio-input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.kypcontact-first-section {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.kypcontact-first-section .kypcontact-section {
    width: 49%;
}

.kypcontact-section {
    margin-bottom: 1.2rem;
}

.kypcontact-form-container .radio-info {
    margin-bottom: 3rem;
}

.kyp-error-message {
    color: $color-error;
    display: none;
    margin: 0 auto 1.2rem;
}

.kyp-email-field {
    margin: 1rem 0 .5rem;
}

.kypcontact-section textarea {
    min-height: 200px;
    resize: none;
    outline: 0;
}

.kypcontact-section textarea.kyp-sm-text-area {
    min-height: 100px;
    resize: none;
    outline: 0;
}

.kyp-contact-check .kyp-error-message {
    margin: .5rem auto 1.2rem;
}

.kypcontact-form-container input[type=text],
.kypcontact-form-container select,
.kypcontact-form-container textarea,
.kypcontact-form-container input[type=email],
.kyp-tel-flex .kyp-tel-col.kyp-tel-num-col input[type="tel"] {
    width: 100%;
    padding: 12px;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 16px;
    resize: vertical;
    border-top: 1px solid $color-6;
    border-left: 1px solid $color-6;
    border-right: 2px solid $color-37;
    border-bottom: 1px solid $color-37;
    background: $color-4;
    margin-top: .3rem;
    outline: 0;
}

.kypcontact-form-container span.pink-icon {
    color: $color-37;
}

.kypcontact-form-container input[type=submit] {
    background-color: $color-white;
    color: $color-37;
    padding: 1rem 5rem;
    border: 1px solid $color-37;
    border-radius: 4px;
    cursor: pointer;
}

.kypcontact-form-container input[type=submit]:hover {
    background-color: $color-37;
    color: $color-white;
}

.kypcontact-form-container .submit-btn {
    text-align: center;
}

.kypcontact-form-container form .kyp-form-wrapper {
    max-width: 575px;
}

.kypcontact-form-container .contact-section {
    margin-right: 1rem;
}

.kyp-check-info {
    margin: 2rem auto;
}

.kyp-contact-check {
    margin-bottom: 1rem;
    color: $color-5;
    line-height: 1.2;
    font-size: .9rem;
    position: relative;
    left: 1.2rem;
}

input.kyp-inputcheck {
    position: absolute;
    left: -9999px;
}

label.kyp-contactcheckbox {
    position: relative;
    cursor: pointer;
}

label.kyp-contactcheckbox::before {
    content: '';
    position: absolute;
    left: -1.2rem;
    top: 2px;
    width: 14px;
    height: 14px;
    border: 1px solid $color-37;
    border-radius: 100%;
    background: $color-white;
}

label.kyp-contactcheckbox::after {
    content: '\2713';
    color: $color-37;
    position: absolute;
    top: 3px;
    left: -1rem;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-weight: bold;
    font-size: .8rem;
}

.kyp-contact-check input.kyp-inputcheck[type="checkbox"]:not(:checked) + label.kyp-contactcheckbox::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.kyp-contact-check a {
    color: $color-5;
}

.kyp-knkt-field {
    margin: 2rem auto;
}

.kyp-knkt-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
}

.kyp-knkt-col-1,
.kyp-knkt-col-2 {
    width: 49%;
}

.kypsubmit-btn {
    margin: 2rem auto;
    text-align: center;
}

.kyp-appointment-field {
    margin: 2rem auto;
    line-height: 1.8;
}

.kyp-tel-flex,
.kyp-time-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
}

.kyp-tel-col,
.kyp-time-col {
    width: 49%;
}

input.kyp-custdatepicker {
    width: 38%;
    padding: 12px;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 16px;
    resize: vertical;
    border-top: 1px solid $color-6;
    border-left: 1px solid $color-6;
    border-right: 2px solid $color-37;
    border-bottom: 1px solid $color-37;
    background: $color-4;
    margin-top: .3rem;
    outline: 0;
    position: relative;
}

input.kyp-custdatepicker[type="date" i]::-webkit-calendar-picker-indicator {
    background-image: url("/static/img/calendar-icon-de.svg");
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: .5rem;
}

input.kyp-time-field {
    width: 100%;
    padding: 12px;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 16px;
    resize: vertical;
    border-top: 1px solid $color-6;
    border-left: 1px solid $color-6;
    border-right: 2px solid $color-37;
    border-bottom: 1px solid $color-37;
    background: $color-4;
    margin-top: .3rem;
    outline: 0;
    position: relative;
}

input.kyp-time-field[type="time" i]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    background-image: url("/static/img/down-arrow-de.svg");
    position: absolute;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    top: 14px;
}

.kypcontact-section select {
    background-image: url("/static/img/down-arrow-de.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
    background-size: 20px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button.kypcontact-submit {
    background-color: $color-white;
    color: $color-37;
    padding: 1rem 5rem;
    border: 1px solid $color-37;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

button.kypcontact-submit:hover {
    background-color: $color-37;
    color: $color-white;
}

@media screen and (max-width: 700px) {
    .kyp-contactwrapper,
    .kypcontact-form-container form .kyp-form-wrapper {
        max-width: 100%;
    }
}
