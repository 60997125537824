$anchor-color: $color-1 !default;
$anchor-primary-color: $color-2 !default;

a {
    color: $anchor-color;

    &:hover {
        text-decoration: none;
    }
}

.anchor {
    &-primary {
        color: $anchor-primary-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &.icon-left {
            &::before {
                transform: translateY(-60%);
            }
        }
    }
}
