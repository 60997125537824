section.infl-artcle-container {
    max-width: 1200px;
    margin: auto;
}

.infl-article-filter-selected {
    position: relative;
    font-size: 14px;
}

.article-dropdown-filter-conatiner {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-end;
}

.article-selectBtn {
    cursor: pointer;
    position: relative;
    padding-right: 1.8rem;
    color: $color-22;
    text-transform: uppercase;
}

.article-dropdown-option.articletriggered {
    color: $color-22;
}

.article-selectDropdown {
    overflow: hidden;
    z-index: 1;
    border: 1px solid $color-black;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    transition: .2s ease;
    background-color: $color-white;
    margin-top: .5rem;
    position: absolute;
    width: 100%;
    display: none;
    font-weight: 600;
}

.article-selectDropdown.articledropdown-toggle {
    display: block;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.infl-article-selectDropdown-conatiner {
    min-width: 212px;
    position: absolute;
    right: .5rem;
}

.article-dropdown-option {
    position: relative;
    cursor: pointer;
    padding: .5rem;
}

.article-dropdown-option:hover {
    background-color: $color-47;
    color: $color-22;
}

.article-selectBtn:after {
    content: "";
    background-image: url('/static/img/article-dropdown.svg');
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    background-position: center;
    background-size: cover;
}

.inflamattion-article-tags {
    background-color: $color-22;
}

ul.inflammation-tabs {
    margin: 2rem auto 0;
    padding: 1rem 1.5rem;
    list-style: none;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    text-transform: uppercase;
}

li.inflammation-tab-link.inflammation-tabcurrent {
    opacity: 1;
}

li.inflammation-tab-link {
    opacity: .7;
    color: $color-white;
    font-size: 14px;
}

.inflarticle-author-tags-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.infl-article-author-content {
    font-size: 14px;
    color: $color-black;
    font-weight: 600;
}

.inflarticle-tags-area {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.infl-artitags {
    color: $color-45;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 1rem;
}

.infl-artitags:last-child {
    margin-right: 0;
}

h3.infl-article-heading {
    font-size: 24px;
    color: $color-black;
}

.infl-resource-date-flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

span.resourse-icon {
    width: 1rem;
    display: block;
    margin-right: 10px;
}

span.resourse-icon img {
    margin-bottom: 0;
}

.infl-resource-btn {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    background-color: $color-22;
    color: $color-white;
    padding: .6rem 1rem;
    font-size: 12px;
    text-transform: uppercase;
    align-items: center;
    -webkit-align-items: center;
    margin-right: 1rem;
}

.infl-resource-area {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
}

.infl-access-btn {
    background-color: $color-30;
    color: $color-white;
    font-size: 12px;
    text-transform: uppercase;
    padding: .6rem 1rem;
}

.infl-date-area {
    color: $color-46;
    opacity: .7;
    font-size: 14px;
}

.inflamationtab-content {
    border-bottom: 1px solid $color-48;
    padding: 3rem 0;
}

.inflamationtab-content:last-child {
    border-bottom: 0;
}

.inflamationtab-content {
    display: none;
}

.inflamationtab-content.inflammation-tabcurrent {
    display: block;
}

a.infltag-linkunstyled {
    text-decoration: none;
}

a.infl-artcle-more-btn {
    border: 1px solid $color-black;
    padding: 1rem 2rem;
    color: $color-black;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
}

.show-more-articlebutton {
    margin: 2rem 0;
}

@media screen and (max-width: 700px) {
    ul.inflammation-tabs {
        overflow-x: scroll;
    }

    ul.inflammation-tabs li {
        min-width: 9rem;
    }

    .infl-article-author {
        margin-bottom: 1rem;
    }

    .infl-resource-date-flex {
        align-items: center;
        -webkit-align-items: center;
    }

    .infl-resource-area {
        margin-bottom: 1rem;
    }
}
.tooltip-content {
	display: none;
	position: absolute;
	left: -68%;
	width: 63%;
	background-color: #fff;
	padding: 1rem;
	color: #000;
	box-shadow: 5px 2px 20px 0px #ccc;
	box-sizing: border-box;
}
.infl-article-heading-area:hover .tooltip-content {
	display: block;
}
.infl-article-heading-area {
	position: relative;
}
.tooltip-content:before {
	position: absolute;
	border-left: solid 15px #fff;
	border-top: solid 15px transparent;
	border-bottom: solid 15px transparent;
	left: 100%;
	top: 35%;
	content: "";
}