.login {
    @include component-margin;
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column--login_text {
        width: layout-column-width(6);
    }

    .layout-column--login_iframe {
        width: layout-column-width(6);
    }
}

@media (min-width: $layout-breakpoint-desktop) {
    .layout-column--login_text {
        width: layout-column-width(8);
    }

    .layout-column--login_iframe {
        width: layout-column-width(4);
    }
}
