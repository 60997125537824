.collapsible[data-initialized] {
    animation: collapsible-init 1s;
}

.collapsible-transition {
    transition: height .3s ease-in-out;
    overflow: hidden;
}

// Hides the collapsible for a second, to give JS a chance to initialize
// This way, without js the collapsible will still become visible after a second
@keyframes collapsible-init {
    0%,
    100% {
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }
}
