.comTog_agenda_content {
    padding: 0 2rem;
    background: $color-white;
}

.comtog_agenda_title {
    padding: 1.125rem 0;
    margin-bottom: 2.5rem;
    background-color: $color-21;
    text-align: center;
    color: $color-white;
    font-size: 2.25rem;
    font-family: "IBMPlexSemiBd";
}

.comtag_agenda_eventos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.comtog_event {
    width: 30%;
    margin-bottom: 2.5rem;
}

.comtog_event_box a {
    text-decoration: none;
}

.comtog_event_box .event-date-section {
    display: flex;
    color: $color-6;
    font-size: 12px;
    font-weight: 600;
    font-family: IBMPlexRg;
    text-transform: uppercase;
    padding-top: 8px;
}

.comtog_event_box .event_type {
    font-family: "IBMPlexRg";
    font-size: .75rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    padding-top: 5px;
    text-transform: uppercase;
}

.comtog_event_box .image-container {
    position: relative;
    height: 195px;
}

.comtog_event_box .image-container .middle {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .4);
    overflow: hidden;
}

.comtog_event_box:hover h4.comtog_eventos_title {
    color: $color-22;
}

.comtog_event_box:hover .middle {
    opacity: 1;
}

.comtog-event-contanier {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.add-eventplus {
    width: 28px;
    height: auto;
    margin-right: .75rem;
    cursor: pointer;
}

.agenda_img {
    width: 100%;
    height: 100%;
}

.hashtag ul li::before,
.filterInterests ul li::before {
    display: none;
}

h4.comtog_eventos_title {
    font-weight: 500;
    font-size: 1.125rem;
    color: $color-black;
    text-align: left;
    font-family: "IBMPlexMd";
    margin: 8px 0 16px;
}

.liner-bw {
    border-top: 1px solid #cdcdcd;
    border-bottom: none;
    height: 9px;
    margin: .5rem;
    width: 50px;
}

.hashtag ul {
    display: flex;
    padding: 0;
    list-style-type: none;
    margin-top: 8px;
}

.hashtag a {
    font-size: 1rem;
    font-family: "IBMPlexSemiBd";
    font-weight: 600;
    color: $color-22;
    text-decoration: none;
    line-height: 24px;
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .comtog_event {
        width: 45%;
    }
}

@media (max-width: 767px) {
    .comtag_agenda_eventos {
        flex-direction: column;
    }

    .comtog_event {
        width: 100%;
    }

    .comTog_agenda_content {
        padding: 0 30px;
    }

    .add-eventplus {
        width: 24px;
    }

    .comtog_event {
        margin-bottom: 30px;
    }

    .comtog_event_box .image-container {
        height: auto;
    }
}

.comtog_agenda_title {
    padding: 1.125rem 0;
    margin-bottom: 2.5rem;
    background-color: $color-21;
    text-align: center;
    color: $color-white;
    font-size: 2.25rem;
    font-family: "IBMPlexSemiBd";
}

.contenido_event_box .image-container .middle {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .4);
    overflow: hidden;
}

.contenido_event_sec:hover .middle {
    opacity: 1;
}

.contenido_event_sec:hover h4.comtog_eventos_title {
    color: $color-22;
}

.comTog_contenido_lp {
    padding: 0 2rem 2rem;
    background: $color-white;
    max-width: 1200px;
    margin: 0 auto;
}

.contenido_title {
    font-size: 1.5rem;
    margin-bottom: 4rem;
    font-family: 'IBMPlexSemiBd';
    color: $color-black;
    line-height: 28px;
    text-align: left;
}

.contenido_input_group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.contenido_input_group ul.select-list {
    list-style: none;
}

.comtog-event-contanier {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.comtog-event-contanier .event_type {
    font-family: "IBMPlexRg";
    font-size: .75rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    padding-top: 5px;
    text-transform: uppercase;
}

.contenido_input_group div.selected {
    width: inherit;
    cursor: pointer;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
}

.contenido_input_group div.selected > .text {
    padding: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: $color-black;
    font-family: 'IBMPlexMd';
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 1.875rem;
}

.contenido_input_group div.selected > .text::after {
    top: 13px;
    text-transform: uppercase;
    content: '';
    margin-left: 1rem;
    border: solid $color-22;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    white-space: normal;
}

.contenido_input_group div.selected.selected-active > .text::after {
    transform: rotate(-135deg);
    transition: all .3s ease-in;
    top: 18px;
}

.contenido_input_group div.select-box {
    display: none;
    width: 100%;
    max-width: 178px;
    z-index: 10029;
    position: absolute;
    right: 0;
    border-radius: 3px;
    border: 1px solid #e4e7eb;
    background-color: $color-white;
    box-shadow: 0 8px 16px 0 #e4e7eb;
}

.contenido_input_group div.select-box ul.select-list {
    margin: 0;
    padding: 10px;
    list-style-type: none;
}

.contenido_input_group ul.select-list li {
    padding: 5px 10px;
    font-family: IBMPlexMd;
    white-space: normal;
    word-break: break-word;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contenido_input_group div.select-box ul.select-list li:hover {
    background-color: $color-7;
}

.block-grid .download-image {
    box-sizing: border-box;
    width: 20px;
    height: 24px;
    margin-bottom: 0;
    margin-right: .75rem;
}

.view-more-btn-container {
    text-align: center;
}

.viewmore {
    width: auto;
    cursor: pointer;
    border: 1px solid $color-22;
    text-transform: uppercase;
    border-radius: 24px;
    background-color: transparent;
    color: $color-22;
    padding: .781rem 1.513rem;
    font-size: .875rem;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0;
    transition: .5s ease;
    font-family: IBMPlexSemiBd;
}

.viewmore:hover {
    color: $color-white;
    background-color: $color-22;
}

.filterInterests ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-bottom: 4.5rem;
    flex-wrap: wrap;
}

.filterInterests ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid $color-22;
    border-radius: 24px;
    padding: 7px 16px;
    margin-right: 13px;
    cursor: pointer;
}

.filterInterests ul li a {
    text-decoration: none;
    color: $color-22;
}

.fill-blue {
    background-color: $color-22;
}

.fill-blue a {
    color: $color-white !important;
}

.contenido_eventos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.contenido_event_box {
    width: 23.5%;
    max-width: 263px;
    margin-bottom: 2rem;
    position: relative;
}

.contenido_event_sec .image-container {
    width: 100%;
    max-width: 263px;
    height: 147px;
    position: relative;
}

.contenido_event_box .event-date-section {
    display: flex;
    color: $color-6;
    font-size: 12px;
    font-weight: 600;
    font-family: IBMPlexRg;
    text-transform: uppercase;
    padding-top: 8px;
}

.contenido_event_box .share-save-container {
    display: flex;
    justify-content: space-between;
}

.contenido_event_box ul.block-grid {
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
}

.contenido_event_box ul.block-grid li {
    margin-right: .85rem;
    cursor: pointer;
}

.comtog-btm-content a {
    text-decoration: none;
}

.latest_comTog {
    padding: 6px 25px;
    background: $color-22;
    display: inline-block;
    color: $color-white;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'IBMPlexMd';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    border-radius: 0 6.25rem 6.25rem 0;
    position: absolute;
    top: -16px;
    left: 0;
    z-index: 1;
}

.play-icon-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.manuale-pad {
    background: url('/static/img/doc-copia-big.png') center center/57px 79px no-repeat;
    width: 57px;
    height: 79px;
}

.podcast-play {
    background: url('/static/img/play-tunes.png') center center/189px 48px no-repeat;
    width: 189px;
    height: 48px;
}

.webinar-play {
    background: url('/static/img/player.png') center center/48px 48px no-repeat;
    width: 48px;
    height: 48px;
}

@media (max-width: 1166px) {
    .contenido_event_box {
        width: 31%;
    }

    .contenido_event_sec .image-container img {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .contenido_title {
        margin-bottom: 2rem;
    }

    .contenido_input_group div.select-box {
        left: 0;
    }

    .contenido_event_box {
        width: 100%;
        margin-bottom: 35px;
        max-width: 100%;
    }

    .filterInterests ul {
        list-style-type: none;
    }

    .filterInterests ul li {
        margin-bottom: 10px;
    }

    .contenido_input_group {
        justify-content: flex-start;
        margin-bottom: 2.5rem;
    }

    .comTog_contenido_lp {
        padding: 0 30px;
    }

    .contenido_event_sec .image-container {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .contenido_event_sec .image-container img {
        width: 100%;
    }
}
