// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
    box-sizing: border-box; // Apply a natural box layout model to all elements
    overflow-x: hidden;
}

* {
    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: $layout-element-margin-default;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $color-4;
    margin: 0 0 $layout-element-margin-default;
}

// table
table {
    border-collapse: collapse;
}

thead {
    text-align: left;
    border-bottom: 1px solid $color-4;
}

th {
    @include font-secondary;
}

th,
td {
    padding-top: $layout-gutter-small / 2;
    padding-bottom: $layout-gutter-small / 2;
    padding-right: $layout-gutter-small;
}

th + th,
td + td {
    padding-left: $layout-gutter-small;
    border-left: 1px solid $color-4;
}
