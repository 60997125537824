/* Header styles */

.sphcpheader {
    width: 100%;
    height: 96px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .05);
}

.header_container {
    max-width: 1313px;
    display: flex;
    padding: 2.125rem 0;
    margin: 0 auto;
    position: relative;
}

.sphcpheader .amgen_logo {
    height: 31px;
    width: 148px;
    margin: 0 3.875rem;
}

.sphcpheader .amgen_logo img {
    margin: 0;
    width: 200px;
}

.sphcpheader .nav_menu {
    height: 28px;
    width: 850px;
}

.sphcpheader .amgen_space {
    width: 24%;
    height: 28px;
}

.sphcpheader .nav_menu ul,
.sphcpheader .amgen_space ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sphcpheader .nav_menu ul li {
    display: inline-flex;
    margin-right: 2rem;
    padding-bottom: 1.6rem;
    margin-top: .313rem;
}

.sphcpheader .nav_menu ul li a,
.sidebar_menu ul li a {
    color: $color-5;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 23px;
    text-decoration: none;
}

.sphcpheader .amgen_space ul li {
    display: inline-block;
}

.sphcpheader .amgen_space ul li a.espacio_title {
    color: $color-white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
    text-decoration: none;
    background-color: $color-26;
    border-radius: 16px;
    padding: .313rem 1.125rem .313rem 1.188rem;
}

.sphcpheader .nav_menu ul li:hover {
    border-bottom: 8px solid $color-26;
}

.sphcpheader .amgen_space ul li img {
    margin-left: 1.488rem;
    height: 21px;
    width: 24px;
}

.hover_content {
    margin-left: 4.875rem;
    margin-right: -1rem;
}

.hover_content .content-main {
    display: flex;
    margin-left: 3rem;
    background-color: $color-29;
    height: 432px;
}

.hover_content .sidebar_menu ul {
    list-style-type: none;
    margin: 0;
    padding-top: 2.613rem;
}

.hover_content .sidebar_menu ul li {
    display: block;
    color: $color-5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 40px;
    cursor: pointer;
    padding-bottom: 0;
    margin-top: 0;
    position: relative;
}

.hover_content .sidebar_menu ul li:hover {
    outline: none;
    border: 0;
    background-color: $color-26;
}

.hover_content .sidebar_menu ul li:hover a {
    color: $color-white;
}

.hover_content .sidebar_menu ul li:hover .third-menu a {
    color: $color-5;
}

.hover_content .sidebar_menu .third-menu ul li:hover a {
    color: $color-white;
}

.hover_content .sidebar_menu ul li::before {
    display: none;
}

.sphcpheader .nav_menu ul li::before {
    display: none;
}

.sidebar_menu ul li a {
    padding-left: 4.375rem;
}

.hover_content .sidebar_menu {
    width: 25%;
    border-right: 1px solid rgba(184, 184, 184, .33);
    margin-right: 2.5rem;
}

.content_right {
    width: 75%;
    padding-top: 2.813rem;
}

.hover_content .content-main .content_right .title {
    color: $color-black;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.5;
    margin: .7rem 0;
    text-align: left;
    font-weight: bold;
}

.content_right .events {
    display: flex;
}

.content_right .box_content {
    width: 28.33%;
    margin-right: 1.5rem;
    position: relative;
}

.box_content .creditos_label {
    height: 32px;
    position: absolute;
    top: 8px;
    left: 0;
    z-index: 99;
    border-radius: 0 6.25rem 6.25rem 0;
    background-color: $color-26;
}

.box_content .creditos_label div {
    padding: .5rem;
    height: 15px;
    color: $color-white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
}

.box_content .event-img-container {
    position: relative;
}

.box_content .event-img-container img {
    width: 100%;
}

.box_content .box_title {
    color: $color-black;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 15px;
    margin: .5rem 0;
    text-transform: uppercase;
    width: 75%;
}

.box_content .event_brief {
    height: 63px;
    width: 260px;
    color: $color-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}

.content_right.amgen_space {
    width: 58%;
    margin: 0 auto;
}

.amgen_space .event-img-container {
    height: 223px;
    width: 660px;
    background-color: $color-27;
    position: relative;
}

.amgen_space .content_rect {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.recursosProf .sidebar_menu ul li::after {
    content: ">";
    font-size: 15px;
    font-weight: 900;
    text-align: right;
    float: right;
    margin-right: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
}

.third-level .third-menu {
    display: none;
    position: absolute;
    top: 0;
    right: -215px;
    width: 200px;
}

.third-level .third-menu ul {
    padding-top: 0;
}

.third-level .content_right {
    margin-left: 215px;
}

.third-level .content_right .box_content {
    width: 37.3%;
}

.third-level .hover_content .sidebar_menu {
    width: 33%;
}

.recursosProf .sidebar_menu .submenu ul li::after {
    content: "";
}

.submenu ul {
    list-style-type: none;
}

.submenu ul li a {
    color: $color-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 40px;
    text-decoration: none;
}

#index #block_top_menu {
    margin-bottom: -4.25rem;
}

.sf-contener {
    clear: both;
    float: left;
    width: 100%;
}

.sf-menu ul {
    position: absolute;
    top: -999em;
}

.sf-menu ul li {
    width: 100%;
}

.sf-menu li:hover {
    visibility: inherit;
}

.sf-menu > li > a {
    display: block;
    color: $color-5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none;
}

.sf-menu li li li a {
    display: inline-block;
    position: relative;
    color: $color-3;
    padding-bottom: .625rem;
}

.sf-menu li li li a::before {
    display: inline-block;
    padding-right: .625rem;
}

.sf-menu li li li a:hover {
    color: $color-5;
}

.sf-menu li ul.ul-show {
    display: none;
    left: 0;
    top: 84px;
    z-index: 99;
    padding: .75rem 0 1.125rem;
}

.sf-menu li li ul {
    position: static;
    display: block !important;
    opacity: 1 !important;
    background: none;
    padding-bottom: 0;
    -moz-box-shadow: transparent 0 0 0;
    -webkit-box-shadow: transparent 0 0 0;
    box-shadow: transparent 0 0 0;
}

.sf-menu li li li ul {
    padding: 0 0 0 1.25rem;
    width: 220px;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
    top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
    left: 200px;
    top: 0;
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
    top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
    left: 200px;
    top: 0;
}

.sf-menu > li > ul {
    padding: 1.625rem 1.875rem 1.938rem;
    width: 100%;
}

.sf-menu > li > ul > li > a {
    text-transform: uppercase;
    color: $color-5;
}

.sf-menu > li > ul > li > a:hover {
    color: $color-8;
}

.sf-menu > li > ul > li {
    float: left;
    width: 20%;
    padding-right: .938rem;
}

.sf-menu > li > ul > li#category-thumbnail {
    width: 100% !important;
    float: none;
    clear: both;
    overflow: hidden;
    padding-right: 0;
}

.sf-menu > li > ul > li#category-thumbnail > div {
    float: left;
    padding-left: .625rem;
    width: 33.333%;
}

.sf-menu > li > ul > li#category-thumbnail > div:first-child {
    padding-left: 0;
}

.sf-menu > li > ul > li#category-thumbnail > div img {
    max-width: 100%;
    display: block;
}

.cat-title,
.close-menu {
    display: none;
}

.sidebar_menu ul {
    display: block !important;
    position: static;
    box-shadow: none !important;
}

.display_mobile {
    display: none !important;
}

ul.block-grid {
    text-align: right;
    position: initial !important;
}

.share-save-container {
    display: flex;
}

ul.block-grid li {
    width: auto;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block !important;
}

ul.block-grid li:hover,
.hashtag ul li:hover {
    border-bottom: none !important;
}

.share-book {
    display: block !important;
    width: 25%;
}

.hashtag ul {
    text-align: left;
    position: static;
}

.hashtag ul li {
    width: auto;
    color: $color-26;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .1px;
    line-height: 18px;
    margin: 0 !important;
    margin-right: .7rem !important;
}

.post-heading {
    text-transform: initial !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    margin-bottom: 1rem;
}

.box_content:hover .event-img-container a img {
    opacity: .8;
}

.event-img-container .middle {
    width: 100%;
    height: 126.19px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, .4);
    overflow: hidden;
}

.box_content:hover .middle {
    opacity: 1;
}

.box_content:hover h4.post-heading {
    color: $color-26;
}

span.btn-tooltip {
    position: relative;
}

span.btn-tooltip .tooltip-coming-soon {
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    min-width: 50px;
    max-width: 300px;
    top: -45px;
    left: 0;
    height: 20px;
    text-transform: uppercase;
    width: 98px;
    opacity: .6;
    background-color: $color-black;
    font-size: 13px;
    line-height: 20px;
    display: none;
}

.sphcpheader .nav_menu ul li:last-child a.ltbtn {
    display: inline-block;
}

/* Responsive styles */

@media (max-width: 1167px) {
    .sf-menu {
        display: none;
    }

    .sf-menu ul {
        position: relative;
    }

    .sf-menu > li {
        float: none;
        position: relative;
        border-right: none;
        width: 100%;
    }

    .sf-menu > li span {
        position: absolute;
        right: 6px;
        top: 16px;
        width: 70px;
        height: 40px;
        color: $color-white;
        z-index: 2;
    }

    .sf-menu > li > a {
        text-align: left;
        padding: .313rem 0 .625rem;
    }

    .sf-menu li ul {
        top: 0;
    }

    .sphcpheader .nav_menu ul li:hover {
        border: none;
    }

    .display_hoverContent:hover ul.menu-mobile {
        display: block;
        padding: 0;
    }

    .sf-menu li li ul {
        padding-bottom: 1.25rem;
    }

    .sf-menu > li > ul > li {
        width: 50%;
    }

    .sf-menu > li > ul > li.first-in-line-lg {
        clear: left;
    }

    .cat-title {
        text-transform: uppercase;
        color: $color-black;
        display: block;
        padding: 3rem 0;
        position: relative;
        margin-top: 2.5rem;
    }

    .cat-title:hover {
        background: none;
    }

    .cat-title::after {
        display: block;
        position: absolute;
        right: 15px;
        top: 18px;
        font-size: 26px;
    }

    .cat-title span img {
        width: 20px;
        height: 20px;
        margin: 0 2rem;
        float: right;
    }

    #index #block_top_menu {
        margin-bottom: 1.875rem;
    }

    .sphcpheader {
        display: block;
        box-shadow: none;
    }

    .sphcpheader .amgen_logo,
    .sphcpheader .nav_menu,
    .sphcpheader .amgen_space {
        width: 100%;
        margin-bottom: 1rem;
        margin-top: 0;
    }

    .cat-title.active .menu-mobile {
        display: block !important;
    }

    .hover_content,
    .content_right .events {
        display: block;
    }

    .hover_content .sidebar_menu,
    .content_right,
    .content_right .box_content,
    .box_content .event-img-container,
    .box_content .event_brief,
    .amgen_space .event-img-container {
        width: 100%;
    }

    .sphcpheader .nav_menu ul li {
        display: block !important;
        padding-bottom: 0;
        background-color: $color-26 !important;
        margin-top: 0;
    }

    .sphcpheader .nav_menu ul li:last-child {
        padding: 2rem 0;
        border: none;
    }

    .sphcpheader .nav_menu ul li:last-child a {
        border: 1px solid $color-white;
        min-width: 150px;
        border-radius: 28px;
        margin-left: 6rem;
        padding: 1rem 2.813rem;
        text-transform: capitalize;
    }

    .hover_content {
        margin: 0;
    }

    .hover_content .sidebar_menu {
        border-right: none;
        margin: 0;
        background-color: $color-26;
    }

    .hover_content .sidebar_menu ul {
        padding: 0;
    }

    .sphcpheader .amgen_space ul li a,
    .sphcpheader .amgen_space ul li img {
        display: none;
    }

    .recursosProf .sidebar_menu ul li::after {
        transform: rotate(90deg);
    }

    .cat-title.active img.hamburger-menu {
        display: none;
    }

    .cat-title.active .close-menu {
        display: block !important;
    }

    .cat-title.active .close-menu img {
        width: 20px;
        height: 20px;
        margin: 0 2rem;
        float: right;
    }

    .cat-title.active .close-menu span.title {
        float: right;
        margin-right: 2rem;
        color: $color-26;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: .57px;
        line-height: 23px;
    }

    .cat-title.active .close-menu span.title a {
        color: $color-26;
    }

    .cat-title.active .close-menu span.title span {
        color: $color-black;
    }

    .display_mobile {
        display: block;
    }

    .hide_mobile {
        display: none !important;
    }

    .nav_menu {
        position: absolute;
        top: 0;
        right: 0;
    }

    .menu-content.first-menu {
        margin-top: 2rem;
    }

    ul.first-menu li a {
        color: $color-white !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        letter-spacing: .5px !important;
        line-height: 23px !important;
        padding: 1.5rem 2rem;
    }

    ul.first-menu li {
        border-bottom: 1px solid rgba(255, 255, 255, .21);
    }

    .cat-title.closeIcon.active {
        background: $color-white;
        padding: 3.63rem 0;
    }

    .sidebar_menu ul li a {
        color: $color-white !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        letter-spacing: .44px !important;
        line-height: 20px !important;
        text-transform: uppercase !important;
    }

    .recursosProf .sidebar_menu ul li::after {
        margin-right: 6rem;
    }

    .sidebar_menu ul li {
        background-color: $color-white !important;
        padding: 1rem 0;
    }

    .nav_menu #block_top_menu {
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    .sphcpheader .amgen_logo {
        margin: 0 2rem;
    }

    a.show_childmenu::after,
    .sidebar_menu ul li a::after {
        content: ">";
        transform: rotate(90deg);
        font-size: 15px;
        font-weight: 900;
        text-align: right;
        float: right;
        margin-right: 0;
    }

    .hover_content .sidebar_menu ul li:last-child {
        padding: .5rem 0;
    }

    .hover_content .sidebar_menu ul li:last-child a {
        margin-left: 0;
        border-radius: 0;
        min-width: 100%;
        width: 100%;
        border: none;
        padding: 1.875rem 2rem;
    }

    .sphcpheader .nav_menu ul li.hide_mobile {
        display: none !important;
    }

    span.btn-tooltip .tooltip-coming-soon {
        text-align: center;
        color: $color-white;
        padding: 7px;
        border-radius: 5px;
        position: absolute;
        min-width: 50px;
        max-width: 300px;
        display: none;
        top: -45px;
        left: 0;
        height: 20px;
        text-transform: uppercase;
        width: 98px;
        opacity: .6;
        background-color: $color-black;
        font-size: 13px;
        line-height: 20px;
    }

    .sf-menu li ul.ul-show {
        top: 0;
    }

    .hover_content .content-main {
        height: auto;
    }

    .third-level .hover_content .sidebar_menu {
        width: 100%;
    }

    .third-level .third-menu {
        position: static;
        margin-left: 1.5rem;
        margin-right: 0;
        width: 305px;
    }

    .sidebar_menu ul li a::after {
        margin-right: 2rem;
        margin-top: .4rem;
    }

    .third-level .third-menu ul li {
        padding-top: 0;
    }

    .hover_content .third-menu ul li:last-child {
        padding: 0;
    }

    .third-menu li::after,
    .third-menu li a::after,
    .recursosProf .sidebar_menu ul li::after {
        content: "";
        display: none;
    }

    .third-menu li:last-child a,
    .hover_content .third-menu ul li a {
        padding: 1rem 2rem !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .sf-menu > li > ul > li.first-in-line-xs {
        clear: left;
    }
}

@media (max-width: 479px) {
    .sf-menu > li > ul > li {
        width: 100%;
    }

    .sf-menu > li > ul > li#category-thumbnail > div {
        width: 100%;
        padding-left: 0;
        padding-top: .625rem;
        text-align: center;
    }
}

.small-text {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: .875rem;
    letter-spacing: 0;
    line-height: 24px;
}

.drop-select-option ul::-webkit-scrollbar-thumb {
    background: $color-20;
}

.drop-select-option ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.drop-select-option ul::-webkit-scrollbar {
    width: .625rem;
}

.selected-active.input-one::after {
    transform: rotate(225deg);
}

.drop-select-option.active {
    display: block;
}

.drop-select-option ul li:hover {
    background-color: $color-19;
}

.drop-select-option ul li {
    padding: .8125rem .625rem;
    font-family: "IBMPlexRg";
    color: $color-black;
    text-align: left;
    cursor: pointer;
}

.drop-select-option ul {
    max-height: 100px;
    overflow-y: scroll;
    margin: 0;
    padding: .625rem;
    list-style-type: none;
    border: 1px solid $color-27;
    background-color: $color-28;
    border-top: none;
}

.drop-select-option {
    display: none;
    position: absolute;
    width: 100%;
}

.form-field > div {
    width: 50%;
    position: relative;
}

a.Solicitar-accept:hover {
    color: $color-white;
    border: 1px solid $color-22;
    background-color: $color-22;
}

.Solicitar-accept-container {
    text-align: right;
}

.visita-textarea {
    margin-bottom: 3rem;
}

.Solicitar-accept {
    text-transform: uppercase;
    padding: .95rem 1.25rem;
    border: 1px solid $color-22;
    border-radius: 35px;
    text-decoration: none;
    color: $color-22;
    font-size: .875rem;
    line-height: 16px;
    text-align: center;
    letter-spacing: .5px;
    font-weight: 600;
    font-family: "IBMPlexRg";
}

.visita-textarea textarea {
    padding: 16px 0 0 16px;
    height: 121px;
    border: 1px solid $color-27;
    background-color: $color-28;
    width: 98%;
    margin-top: 26px;
    resize: none;
}

.form-field {
    display: flex;
}

.input-one::after {
    text-transform: uppercase;
    content: "";
    margin-left: 1rem;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
}

.form-field > div:first-child {
    margin-right: 1rem;
}

.form-field > div:last-child {
    margin-left: 1rem;
}

.input-one {
    padding: 16px;
    position: relative;
    border: 1px solid $color-27;
    background-color: $color-28;
    color: $color-18;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 15px;
}

.transferencia-title {
    width: 80%;
    font-family: "ExoRg";
}

.link-transferencia {
    width: 15%;
    position: relative;
    color: $color-22;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 24px;
    text-align: right;
}

.link-transferencia a {
    text-decoration: none;
    color: $color-22;
}

.link-transferencia::after {
    top: 8px;
    text-transform: uppercase;
    content: "";
    margin-left: .5rem;
    border: solid $color-22;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(315deg);
    position: absolute;
}

.transferencia {
    border: 1px solid $color-27;
    background-color: $color-white;
    padding: 31.17px 24.45px;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
}

.area-amgen {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: 22px;
}

.accept-button {
    text-align: right;
    margin: 2rem 0;
}

.more-button {
    margin: 1.5rem 0;
}

.more-button .btn-submit {
    padding: .95rem 4.3rem;
    display: inline-block;
}

.btn-submit:hover {
    background-color: $color-22;
    color: $color-white;
    border: 1px solid $color-22;
}

.btn-submit {
    text-transform: uppercase;
    padding: .95rem 1.25rem;
    border: 1px solid $color-22;
    border-radius: 35px;
    text-decoration: none;
    color: $color-22;
    font-size: .875rem;
    line-height: 16px;
    text-align: center;
    letter-spacing: .5px;
    font-weight: 600;
    font-family: "IBMPlexRg";
}

.content-switch.user-interset {
    margin: 31px 0;
}

.user-list-details {
    margin-top: 38px;
}

.details-user-container {
    display: flex;
    margin-bottom: 1rem;
}

.details-user-container .label {
    width: 15%;
    justify-content: left;
    align-items: center;
    display: flex;
}

.details-user-container .input {
    width: 83%;
}

.details-user-container .input input {
    width: 100%;
}

.details-user-container .input input[value] {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
}

.details-user-container:last-child {
    margin-bottom: 0;
}

.user-list-details input {
    border: 1px solid $color-27;
    background-color: $color-28;
    height: 48px;
    padding-left: 16px;
}

.owa-redirect {
    display: flex;
}

.owa-button a.blue-btn-link {
    text-transform: uppercase;
    cursor: pointer;
    color: $color-22;
    font-family: "IBMPlexRg";
    font-size: .875rem;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    padding: .875rem 60px .875rem 24px;
    border: 1px solid $color-22;
    border-radius: 24px;
    position: relative;
}

.owa-button a.blue-btn-link:hover {
    background: $color-22;
    color: $color-white;
}

.owa-button a.blue-btn-link::after {
    content: "";
    pointer-events: none;
    display: block;
    background-image: url(/static/img/redo.png);
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 17%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 16px;
    height: .875rem;
}

.owa-button a.blue-btn-link::after {
    background-image: url(/static/img/redo-white.png);
}

.owa-button {
    margin-left: 61px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.owa-content {
    color: $color-5;
    font-family: "IBMPlexRg";
    font-size: .875rem;
    letter-spacing: 0;
    line-height: 24px;
    width: 65%;
}

.col-12 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}

.col-9 {
    width: 67%;
}

.col-3 {
    width: 28%;
    padding: 0 0 0 39px;
}

.tab {
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: 22px;
    padding-bottom: .5rem;
    color: $color-18;
    padding-left: 20px;
    margin-right: 62.45px;
    cursor: pointer;
    position: relative;
}

.Personal::before {
    content: "";
    background: url("/static/img/IPman-grey-icon.png") left center/11px 14px no-repeat;
    width: 15px;
    height: 21px;
    display: inline-block;
    position: absolute;
    left: 0;
}

.Favoritos::before {
    content: "";
    background: url("/static/img/share-grey-icon.png") left center/12px 15px no-repeat;
    width: 15px;
    height: 21px;
    display: inline-block;
    position: absolute;
    left: 0;
}

.tab.active {
    color: $color-22;
    border-bottom: 2px solid $color-22;
}

.tab.Favoritos.active::before {
    background: url("/static/img/share-blue-icon.png") left center/12px 15px no-repeat;
}

.tab.Personal.active::before {
    background: url("/static/img/IPman-blue-icon.png") left center/11px 14px no-repeat;
}

.button-switch {
    display: flex;
    padding: 0 2.53125rem;
}

.hcp-dashboard-main {
    background-color: $color-29;
    padding-top: 4.6875rem;
    padding-bottom: 4rem;
}

.tab-container {
    display: block;
}

.owa-redirect {
    display: flex;
    padding-bottom: 41px;
    border-bottom: 1px solid $color-27;
}

.content-switch {
    border: 1px solid $color-27;
    background-color: $color-white;
    padding: 51.73px 2.53125rem;
}

.hcp-dashboard-banner {
    position: relative;
    height: 292px;
    width: 100%;
    background-color: $color-22;
}

.profile-container {
    max-width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    justify-content: left;
}

.image {
    height: 160px;
    width: 160px;
    background-color: $color-white;
    border-radius: 50%;
    position: relative;
}

.user-more-info {
    color: $color-white;
    font-family: "IBMPlexRg";
    font-size: .875rem;
    letter-spacing: 0;
    line-height: 28px;
}

.user-name {
    color: $color-white;
    font-family: "ExoRg";
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
}

.user-symbol {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 74px;
    font-family: "IBMPlexRg";
}

.user-details {
    margin-left: 1.375rem;
}

.adcModal-accept-user {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .8);
}

.adcModal-Solicitar {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .8);
}

.adcModal-Solicitar .adc-modal-content {
    background-color: $color-white;
    margin: 7% auto;
    padding: 1.5rem 2rem 0 2.5rem;
    border: 1px solid $color-20;
    width: 60%;
    height: 418px;
    margin-bottom: 1rem;
}

.adcModal-Solicitar .closeAdc {
    color: $color-black;
    float: right;
    font-size: 2rem;
    font-family: "ExoRg";
    font-weight: normal;
    cursor: pointer;
}

.adcModal-Solicitar .calender-img {
    padding: 2rem 0 0;
}

.adcModal-Solicitar .calender-img img {
    max-width: 40px;
    height: 40px;
    margin-bottom: 1.25rem;
}

.adcModal-Solicitar .adc-modal-content .p {
    font-size: 1.125rem;
    color: $color-black;
    line-height: 26px;
    text-align: left;
    padding-bottom: 26px;
    font-family: "ExoRg";
    font-weight: 700;
}

.adcModal-accept-user .adc-modal-content {
    background-color: $color-white;
    margin: 13% auto;
    padding: 1.5rem 2rem 0 2.5rem;
    border: 1px solid $color-20;
    width: 49%;
    height: 192px;
    margin-bottom: 1rem;
}

.adcModal-accept-user .closeAdc {
    color: $color-black;
    float: right;
    font-size: 2rem;
    font-family: "ExoRg";
    font-weight: normal;
    cursor: pointer;
}

.adcModal-accept-user .calender-img {
    padding: 2rem 0 0;
}

.adcModal-accept-user .calender-img img {
    max-width: 40px;
    height: 40px;
    margin-bottom: 1.25rem;
}

.adcModal-accept-user .adc-modal-content .p {
    font-size: 1.125rem;
    color: $color-black;
    line-height: 26px;
    text-align: left;
    padding-bottom: .75rem;
    font-family: "ExoRg";
    font-weight: 700;
}

.favorites-inner-container {
    margin-top: -1rem;
    width: 100%;
}

.favorites-events .events .event-box-container {
    display: flex;
    margin-bottom: 2rem;
}

.favorites-events .events .title-sect {
    display: flex;
    justify-content: space-between;
    width: 93%;
}

.favorites-events .events .title-sect .section {
    margin-bottom: 1.5rem;
    color: $color-black;
    font-size: 14px;
    font-family: "ExoRg";
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}

.favorites-events .events .title-sect .see-everything {
    color: $color-22;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: .5px;
    line-height: 24px;
    text-align: right;
}

.favorites-events:not(:last-child)::after {
    content: "";
    border-bottom: 1px solid $color-27;
    display: block;
    margin-bottom: 2rem;
}

.favorites-events .events .event-box-container .event-box {
    width: 100%;
    max-width: 208px;
    margin-right: 24px;
}

.favorites-events .events a {
    text-decoration: none;
}

.favorites-events .events .event-img-container {
    width: 100%;
    max-width: 208px;
    max-height: 120px;
    position: relative;
}

.favorites-events .events .event-img-container img {
    width: 100%;
    max-width: 208px;
    max-height: 120px;
}

.favorites-events .events .event-box-container .event-box .box_content:hover .event-img-container .middle {
    height: 100%;
    width: inherit;
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    z-index: 0;
}

.favorites-events .img-btm-content .share-save-container .box_title {
    color: $color-black;
    font-family: "IBMPlexRg";
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: 22px;
}

.favorites-events .img-btm-content .share-save-container .box_date {
    font-size: 12px;
    font-family: "IBMPlexRg";
    text-transform: uppercase;
    color: $color-24;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 22px;
}

.favorites-events .img-btm-content .share-save-container {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
}

.favorites-events .img-btm-content .share-save-container .share-book ul.block-grid {
    list-style: none;
    margin: 0;
    display: flex;
}

.favorites-events .img-btm-content .share-save-container .share-book ul.block-grid li:first-child {
    padding-right: 15px;
}

.favorites-events .img-btm-content .share-save-container .share-book ul.block-grid li img {
    width: 10px;
    height: 12px;
}

.favorites-events .share-book ul.block-grid span.btn-tooltip {
    position: relative;
    cursor: pointer;
}

.favorites-events .share-book ul.block-grid span.btn-tooltip .tooltip-coming-soon {
    min-width: 111px;
    max-width: 300px;
    font-family: "IBMPlexRg";
    height: 16px;
    top: -40px;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: $color-white;
    padding: 7px;
    border-radius: 5px;
    position: absolute;
    text-transform: uppercase;
    opacity: .6;
    background-color: $color-black;
}

.favorites-events .img-btm-content .post-heading {
    text-transform: initial !important;
    font-size: 12px;
    font-family: "ExoRg";
    font-weight: 500;
    text-align: left;
    color: $color-black;
    letter-spacing: 0;
    line-height: 22px;
}
