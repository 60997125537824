/// Function used to retrieve a percentage based on a given number of columns
/// @author Arno Wolkers
/// @param {integer} $columns - The number of columns used
///// @return {percentage}
@function layout-column-width($columns) {
    @return percentage($columns / $layout-columns-total);
}

html,
body {
    height: 100%;
}

// Layout structure explained
//
// |___.layout-row
// | |___.layout-column
// |___.layout-row
// | |___.layout-column.layout-column-variant-x
// | |___.layout-column.layout-column-variant-y
// word-spacing: rem(-3.55px);

.layout-row {
    word-spacing: rem($layout-wordspacing);
    margin: 0 auto;
    width: 100%;
    max-width: $layout-max-width;
    padding-right: $layout-row-margin;
    padding-left: $layout-row-margin;

    &.layout-row--nomargin {
        padding: 0;
    }
}

.layout-column {
    word-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 0 $layout-gutter-small;
    box-sizing: border-box;

    > .layout-row > .layout-column {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: $layout-breakpoint-tablet) {
    .layout-column {
        padding: 0 $layout-gutter-large / 2;
    }

    @for $i from 1 through $layout-columns-total {
        .layout-column--#{$i}-12 {
            width: layout-column-width($i);
        }
    }
}

.full-background {
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
}
