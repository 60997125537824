.swedencase-list {
    width: 100%;
}

.swedencase-list section {
    margin: 2rem 0;
}

.swedenmaincontainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

h1.sweden-main-heading {
    font-size: 1.5rem;
    text-align: left;
    margin: 1rem 0;
}

h2.sweden-secondary-heading {
    font-size: 1.2rem;
    text-align: left;
    margin: 1rem 0;
}

h3.sweden-tertiary-heading {
    font-size: 1rem;
    margin: 1rem 0;
    text-align: left;
}

h4.swedencase-tags-heading {
    font-size: 0.8rem;
    margin: 1rem 0;
    text-align: left;
}
.swedencase-list-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    position: relative;
    align-items: center;
    justify-content: center;
}

.case-edit {
    position: absolute;
    z-index: 5;
    right: 0;
    cursor: pointer;
    background: rgba(0, 128, 0, .3);
    border-radius: 0 0 0 5px;
    padding: 5px;
}

.swedencases {
    width: 22.999%;
    margin: 10px;
    background: $color-31;
    position: relative;
    overflow: hidden;
}

.swedencase-content {
    padding: 10px;
}

.swedencase-content p {
    min-height: 55px;
    margin-bottom: 0;
}

.swedencase-tags {
    padding: 10px;
}

.swedencase-tags ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.swedencase-view section {
    margin-bottom: 2rem;
}

.swedencase-tags ul li {
    display: inherit;
    margin: 2px;
}

.swedencase-tags ul li a {
    background: transparent;
    border: 1px solid $color-32;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: none;
    color: $color-33;
    font-size: .8rem;
}

.swedencase-tags ul:not([class]) li::before,
.swedendownload ul:not([class]) li::before,
.swedenoption ul:not([class]) li::before {
    display: none;
}

.swedencase-img {
    max-height: 210px;
    overflow: hidden;
}

.swedencase-img img {
    width: 100%;
}

.swedencase-tags h1 {
    font-size: 16px;
    padding: 0 0 10px;
    margin: 0;
    color: $color-33;
    text-align: left;
}

.swedencase-content h1::after,
.swedencase-tags h1::after,
.swedenmaincontainer .h1::after,
.swedenmaincontainer h1::after {
    display: none;
}

.swedencheckbox1 input[type=checkbox],
.swedenoption input[type=checkbox] {
    display: block;
    opacity: 1;
    margin: .3rem 0 0;
}

.swedencase-content h1 {
    font-size: 20px;
    color: $color-33;
    margin: 0;
    padding: 0;
    text-align: left;
}

.swedenreadmore {
    font-size: 12px;
    color: $color-33;
    padding: 10px;
    text-align: right;
}

.swedenreadmore {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.swedenreadmore a.edit-btn {
    background-color: $color-26;
    color: $color-white;
    padding: .5rem 1rem;
    border-radius: 5px;
    font-size: 9px;
}

.swedenreadmore a {
    color: $color-33;
    text-decoration: none;
}

.swedenfilters {
    display: flex;
    flex-wrap: wrap;
}

.swedenfilters .swedencheckbox1 {
    margin-right: 15px;
    display: inline-flex;
}

.swedenfilters label {
    font-size: 14px;
    padding-right: 10px;
}

.swedenfilter-title {
    font-size: 16px;
    color: $color-33;
    margin: 1rem 0;
    text-align: left;
}

.swedenshowmore {
    text-align: center;
}

.swedenshowmore a {
    background: transparent;
    border: 1px solid $color-32;
    border-radius: 5px;
    padding: 5px 50px;
    text-decoration: none;
    color: $color-33;
    font-size: 16px;
}

#slider {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0;
    border-radius: 4px;
}

.swedenslider-content-container {
    position: relative;
    margin: 0;
    padding: 0;
    height: 200px;
}

.swedenslider-content {
    position: relative;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 500px;
    height: 300px;
    text-align: center;
    line-height: 300px;
}

.swedendownload {
    margin-bottom: .5rem;
}

.swedendownload ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}

.swedendownload ul li {
    display: inherit;
    margin-left: 5px;
    margin-bottom: 5px;
}

.swedendownload ul li a {
    border: 1px solid $color-32;
    border-radius: 5px;
    color: $color-33;
    padding: 5px 10px;
    text-decoration: none;
}

.swedenarrow {
    position: absolute;
    top: 40%;
    z-index: 999;
    display: block;
    padding: 4% 3%;
    width: auto;
    height: auto;
    background: $color-34;
    color: $color-white;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    opacity: .8;
    cursor: pointer;
}

.swedenarrow:hover {
    opacity: 1;
    -webkit-transition: all .2s ease;
}

.swedenleft-arrow {
    border-radius: 0 2px 2px 0;
}

.swedenright-arrow {
    right: 0;
    border-radius: 2px 0 0 2px;
}

.swedencase-view .swedenoption ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#swedenchart-container {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

#swedenchart-container .swedensuccess {
    background-color: $color-valid;
    margin-bottom: 5px;
}

#swedenchart-container .swedensuccess p,
#swedenchart-container .swedenerror p {
    padding: 5px 15px;
    margin-bottom: 5px;
}

#swedenchart-container .swedenerror {
    background-color: $color-error;
    margin-bottom: 5px;
}

.feedback-publish {
    display: flex;
    flex-wrap: wrap;
}

.feedback-option {
    width: 60%;
}

.feedback-image {
    width: 39%;
}

.feedback-option li {
    display: flex;
    margin: 10px 0;
}

.feedback-option li label {
    margin-left: 20px;
}

.feedback-image img {
    width: 100%;
}

@media screen and (max-width: 990px) {
    .swedencases {
        width: 22.666%;
        margin: 10px;
        background: $color-31;
    }
}

@media screen and (max-width: 768px) {
    .swedencases {
        width: 45.999%;
        margin: 10px;
        background: $color-31;
    }
}

@media screen and (max-width: 640px) {
    .swedencases {
        width: 100%;
        margin: 10px;
        background: $color-31;
    }
}

#swedenslider {
    margin: 0 auto;
    width: 100%;
    height: 90vh;
}

.swedenslide_viewer {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.swedenslide_group {
    height: 570px;
    position: relative;
    width: 97%;
    overflow-y: auto;
    overflow-x: hidden;
}

.swedenslide {
    display: none;
    position: absolute;
    width: 100%;
}

.swedenslide_group::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.swedenslide_group::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color-6;
    border-radius: 10px;
}

/* Handle */
.swedenslide_group::-webkit-scrollbar-thumb {
    background: $color-36;
    border-radius: 10px;
}

/* Handle on hover */
.swedenslide_group::-webkit-scrollbar-thumb:hover {
    background: $color-5;
}

.swedenslide-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 5%;
}

.swedenslide:first-child {
    display: block;
}

.swedenslide-text {
    width: 70%;
    margin-right: 5%;
}

.swedenslide_buttons {
    position: absolute;
    bottom: 10px;
    text-align: center;
    left: 50%;
    right: 50%;
}

a.swedenslide_btn {
    color: $color-35;
    font-size: 42px;
    margin: 0 .175em;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.swedenslide_btn.active,
.swedenslide_btn:hover {
    color: $color-36;
    cursor: pointer;
}

.swedenprevious_btn {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 10px;
}

.swedennext_btn {
    top: 50%;
    right: 0;
    position: absolute;
}

.swedenprevious_btn,
.swedennext_btn {
    background-color: $color-16;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: $color-black;
    cursor: pointer;
    height: 35px;
    opacity: .5;
    -webkit-transition: opacity .4s ease-in-out;
    -moz-transition: opacity .4s ease-in-out;
    -ms-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
    width: 35px;
}

.swedenslide-text h1 {
    font-size: 1.25rem;
    text-align: left;
    margin: 1rem 0;
}

.swedenprevious_btn:hover,
.swedennext_btn:hover {
    opacity: 1;
}

@media only screen and (max-width: 767px) {
    .swedenprevious_btn {
        left: 50px;
        top: 12%;
    }

    .swedennext_btn {
        right: 50px;
        top: 12%;
    }
}

.ind-editor {
    width: 900px;
    margin: 30px 0;
    padding: 7px 20px;
    border: 1px solid $color-35;
    border-radius: 10px;
    background: $color-29;
}

.ind-editor .ind-editor-toolbar {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-35;
    padding: 8px 20px;
    overflow: hidden;
}

.ind-editor .ind-editor-content {
    margin-top: 30px;
    min-height: 200px;
    outline: none;
    background-color: $color-white;
    border: 1px solid $color-12;
}

.ind-editor .icon {
    float: left;
    height: 30px;
    width: 40px;
    margin-right: 10px;
    background: $color-29;
    font-size: .8rem;
    color: $color-35;
    text-align: center;
    cursor: pointer;
}

.ind-editor .icon input {
    padding: 0;
}

.swedenwrapper.swedencoe_form {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem .5rem;
}

.swedencoe_form .exp-collapse {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem .5rem;
}

.swedencoe_form .expand-controls.collapse {
    margin-right: 0;
}

.swedencoe_form .expand-controls,
.clinical-case .expand-controls {
    background-color: $color-26;
    color: $color-white;
    font-weight: bold;
    padding: .5rem 1rem;
    margin-right: 1rem;
    border-radius: .5rem;
    font-size: .8rem;
    cursor: pointer;
}

.swedencoe_form .swedenaccordian-container .acc-label,
.clinical-case .acc-label {
    font-size: .9rem;
    color: $color-white;
    background-color: $color-26;
    padding: 5px 15px;
    border: 1px solid $color-26;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.swedencoe_form .acc-content {
    padding: 1;
}

.swedencoe_form h3 {
    color: $color-26;
    font-size: 2.125rem;
    text-align: left;
}

.swedencoe_form .acc-info {
    width: 100%;
    margin: 0 auto;
    padding: 15px 0 0;
}

.swedencoe_form .acc-info select {
    width: 100%;
    margin: 10px 0 20px;
    border: 1px solid $color-26;
    border-radius: .5rem;
    padding: 10px;
}

.swedencoe_form input {
    width: 95%;
    border: 1px solid $color-26;
    padding: 10px;
    border-radius: .5rem;
    margin-bottom: .8rem;
}

.swedencoe_form label {
    color: $color-26;
    font-size: .9rem;
    font-weight: bold;
    margin: .7rem 0 .5rem;
    display: block;
}

.swedencoe_form .add-toggle,
.clinical-case .add-toggle {
    font-size: 1.5rem;
    font-weight: bold;
}

.swedencoe_form textarea {
    padding: .5rem;
    border: 1px solid $color-26;
    width: 100%;
    border-radius: .5rem;
}

.swedencoe_form .textarea-1 {
    display: block;
    flex-direction: row;
    margin-bottom: 1rem;
}

.swedencoe_form .browse-file {
    margin-top: auto;
    padding-bottom: 0;
    margin-bottom: 0;
    width: 25%;
}

.swedencoe_form .browse-file input {
    border: none;
    margin-bottom: 0;
}

.swedencoe_form .add-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.swedencoe_form .plus-control,
.minus-control {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: $color-26;
    border: 1px solid $color-26;
    border-radius: 5px;
    padding: 0 .5rem;
    margin: .5rem;
    cursor: pointer;
}

.swedencoe_form .option div {
    display: flex;
    flex-direction: row;
}

.swedencoe_form .answer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.swedencoe_form .answer input {
    width: auto;
    margin-right: 1rem;
}

.swedencoe_form .answer select {
    width: 100px;
    margin-right: 1rem;
}

.swedencoe_form .options {
    margin: 1rem 0;
}

.swedencoe_form .question {
    padding: 1rem;
    margin: 2rem 0;
    -webkit-box-shadow: 0 1px 2px 0 rgb(208, 203, 203);
    -moz-box-shadow: 0 1px 2px 0 rgb(208, 203, 203);
    box-shadow: 0 1px 2px 0 rgb(208, 203, 203);
}

.swedencoe_form #quiz {
    margin-bottom: -1rem;
}

.swedencoe_form .quiz-answer {
    margin-top: -3rem;
}

.swedencoe_form .answer span {
    font-weight: bold;
    color: $color-26;
    margin-right: .6rem;
}

.swedencoe_form .map-btn .add-btn {
    border: 1px solid $color-26;
    background-color: $color-26;
    font-size: 1rem;
    font-weight: bold;
    color: $color-white;
    text-align: center;
    margin: 0 auto;
    border-radius: 5px;
    padding: .5rem;
    width: 10%;
    cursor: pointer;
}

.swedencoe_form .map-btn .add-btn a {
    color: $color-white;
    text-decoration: none;
}

.swedencoe_form .minus-control-options {
    font-size: .8rem;
    padding: .3rem .5rem;
}

.swedencoe_form .plus-control-options {
    font-size: .8rem;
    padding: .3rem .5rem;
}

.swedencoe_form .upload_img {
    margin-bottom: 1rem;
}

.coeswedtags-main {
    display: flex;
    margin: 2rem 0 0;
}

.coeswedtag label {
    color: $color-black;
    display: initial;
    margin: 0 10px;
}

.coeswedtag input {
    width: auto;
}

.coeswedtag {
    display: flex;
    margin-right: 20px;
}

.action-btn > ul {
    display: flex;
    list-style: none;
    margin: 0 auto;
    width: 360px;
}

.action-btn .ulist {
    display: flex;
    justify-content: center;
}

.action-btn .ulist button {
    margin: 5px;
    cursor: pointer;
    padding: 5px 10px;
}

.action-btn > ul li {
    padding: 10px 15px;
    background: $color-33;
    border-radius: 3px;
    margin: 5px;
    color: $color-white;
    cursor: pointer;
}

.Promomats-detail {
    display: flex;
}

.Promomats-fld {
    margin: 10px;
}
