.layout-row--hcp-registration {
    display: block;
}

.layout-column--hcp-registration {
    img {
        max-width: 33%;
    }
}

.overlay_wrapper {
    margin: 5vh auto 0;
}

.rgs_btn {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    display: block;
    color: $color-white;
    font-size: 1.125rem;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    padding: .6875rem 1.5rem .5rem;
    width: 15rem;
    min-height: 3.0625rem;
    transition: background .3s ease, border-color .3s ease, color .3s ease;
    background: #0f6db7;
}

#acknowlage {
    display: none;
    color: $color-white;
    font-size: 1.125rem;
    text-decoration: none;
    text-align: center;
    padding: .6875rem 1.5rem .5rem;
    width: auto;
    min-height: 3.0625rem;
    transition: background .3s ease, border-color .3s ease, color .3s ease;
    background: #0f6db7;
}

#acknowlage h2 {
    color: $color-white;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media screen and (min-width: $layout-breakpoint-tablet) {
    .hcp-registration__form-title {
        img {
            width: 75px;
            margin: rem(64px) auto 0 auto;
        }
    }

    .layout-column--hcp-registration--pop {
        width: layout-column-width(12);
    }
}

@media screen and (min-width: $layout-breakpoint-desktop) {
    .hcp-registration__form-title {
        position: relative;
        text-align: center;

        img {
            position: absolute;
            top: 0;
            left: $layout-gutter-large / 2;
            margin: 0;
        }
    }

    .layout-column--hcp-registration--pop {
        h2 {
            margin: rem(15px) 0 rem(40px);
        }

        .h3 {
            line-height: 1.5;
            font-size: 1.15rem;
            margin: .5rem 0 .75rem;
        }

        h3 {
            line-height: 1.5;
            font-size: 1.15rem;
            margin: .5rem 0 .75rem;
        }
    }

    .form-layout {
        margin-bottom: 1.5rem;
    }

    .form-layout-item:not(:only-of-type) {
        margin-bottom: .25rem;
        font-size: .9rem;
    }

    .form-layout-item-terms-of-conditions {
        padding-top: .5em;
    }

    [type=email],
    [type=number],
    [type=text],
    select {
        min-height: 40px;
        padding: .5rem 1rem;
    }
}
