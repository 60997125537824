// font family
$font-stack-primary: TradegothicLtStd, Helvetica, sans-serif !default;
$font-stack-secondary: TradeGothicLTStdBoldCondensed, Helvetica, sans-serif !default;
$font-stack-tertiary: Tradegothicltstdcn18, Helvetica, sans-serif;
$font-weight-primary: 400 !default;
$font-weight-secondary: 400 !default;
$font-weight-tertiary: 400 !default;

@mixin font-primary {
    font-family: $font-stack-primary;
    font-weight: $font-weight-primary;
}

@mixin font-secondary {
    font-family: $font-stack-secondary;
    font-weight: $font-weight-secondary;
}

@mixin font-tertiary {
    font-family: $font-stack-tertiary;
    font-weight: $font-weight-tertiary;
}

// base
$base-font-size: 16px !default;
$base-font-color: $color-5 !default;
$base-line-height: 1.5 !default;

// h1
$heading-h1-font-size-mobile: 30px !default;
$heading-h1-font-size-tablet: 41px !default;
$heading-h1-font-size-desktop: 48px !default;
$heading-h1-font-color: $color-2 !default;
$heading-h1-divider-color: $color-4 !default;
$heading-h1-letter-spacing: inherit !default;

// h2
$heading-h2-font-size-mobile: 26px !default;
$heading-h2-font-size-tablet: 34px !default;
$heading-h2-font-size-desktop: 40px !default;
$heading-h2-font-color: $color-2 !default;
$heading-h2-divider-color: $color-1 !default; // niet naar 5

// h3
$heading-h3-font-size-mobile: 24px !default;
$heading-h3-font-size-tablet: 24px !default;
$heading-h3-font-size-desktop: 28px !default;
$heading-h3-font-size: 24px !default;
$heading-h3-font-color: $color-2 !default;

// h4
$heading-h4-font-size: $base-font-size !default;
$heading-h4-font-color: $color-5 !default;

// p
$paragraph-subline-font-size: 18px !default;

html {
    @include font-primary;
    font-size: $base-font-size;
    color: $base-font-color;
    line-height: $base-line-height;
}

p {
    margin: 0 0 em($layout-element-margin-default);

    &.subline {
        font-size: rem($paragraph-subline-font-size);
        margin: 0 0 1.2em;
    }

    + p {
        margin: - em($layout-element-margin-default / 2) 0 em($layout-element-margin-default);
    }
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.screenreader {
    @include screenreader;
}

h1,
.h1 {
    @include font-secondary;
    font-size: rem($heading-h1-font-size-mobile);
    line-height: 1.066;
    letter-spacing: $heading-h1-letter-spacing;
    text-align: left;
    color: $heading-h1-font-color;
    margin: rem(30px) 0 rem(45px);
    text-transform: uppercase;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 60px;
        height: 1px;
        background: $heading-h1-divider-color;
        position: absolute;
        bottom: rem(-15px);
    }
}

h2,
.h2 {
    @include font-secondary;
    font-size: rem($heading-h2-font-size-mobile);
    line-height: 1.154;
    color: $heading-h2-font-color;
    margin: rem(30px) 0 rem(45px);
    text-transform: uppercase;
    position: relative;

    &:first-child {
        margin: 0 0 rem(80px);
    }

    &::after {
        content: '';
        display: block;
        width: 60px;
        height: 1px;
        background: $heading-h2-divider-color;
        position: absolute;
        bottom: rem(-15px);
    }
}

.h2--no-divider {
    text-transform: none;
    margin: rem(30px) 0 rem(15px);

    &::after {
        content: none;
    }
}

h3,
.h3 {
    @include font-secondary;
    line-height: 1.67;
    font-size: rem($heading-h3-font-size-mobile);
    color: $heading-h3-font-color;
    margin: rem(20px) 0;
}

h4,
.h4 {
    @include font-secondary;
    font-size: rem($heading-h4-font-size);
    color: $heading-h4-font-color;
    margin: 0 0 .9em;
}

.tgcn18 {
    @include font-tertiary;
}

// media queries
@media screen and (min-width: $layout-breakpoint-tablet) {
    p {
        &.subline {
            font-size: rem($paragraph-subline-font-size * 1.2);
        }
    }

    h1,
    .h1 {
        font-size: rem($heading-h1-font-size-tablet);
        line-height: 1.122;
        margin: rem(60px) 0 rem(55px);
        text-align: center;
        letter-spacing: rem(1px);

        &::after {
            left: 50%;
            transform: translateX(-50%);
            width: 285px;
            bottom: rem(-20px);
        }
    }

    h2,
    .h2 {
        font-size: rem($heading-h2-font-size-tablet);
        line-height: 1.06;
        margin: rem(60px) 0 rem(55px);
        text-align: center;

        &::after {
            left: 50%;
            transform: translateX(-50%);
            width: 285px;
            bottom: rem(-20px);
        }
    }

    .h2--no-divider {
        margin: rem(60px) 0 rem(20px);
        text-align: left;
    }

    h3,
    .h3 {
        font-size: rem($heading-h3-font-size-tablet);
    }
}

@media screen and (min-width: $layout-breakpoint-desktop) {
    h1,
    .h1 {
        line-height: 1.125;
        font-size: rem($heading-h1-font-size-desktop);
        margin: rem(90px) 0 rem(80px);

        &::after {
            height: 2px;
            bottom: -30px;
        }
    }

    h2,
    .h2 {
        line-height: 1.1;
        font-size: rem($heading-h2-font-size-desktop);
        margin: rem(90px) 0 rem(80px);

        &::after {
            height: 2px;
            bottom: rem(-30px);
        }
    }

    .h2--no-divider {
        margin: rem(90px) 0 rem(30px);
    }

    h3,
    .h3 {
        line-height: 1.143;
        font-size: rem($heading-h3-font-size-desktop);
        margin: rem(20px) 0 rem(25px);
    }
}
