.carousel_extnded_wrap {
    word-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
 .carousel_extnded_overlay_wrapper {
      
    }
.teaser-stage_carousel {
    margin-bottom: 2.8125rem;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.carousel_cont {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.carousel_extnded {
    width: 100%;
    padding: 0;
    text-align: center;
    position: relative;
}

.carousel_extnded_img {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background: $color-4;
}

.carousel_extnded_img img {
    background-size: cover;
    position: relative;
}

.carousel_extnded .caption {
    top: 6rem;
}

.carousel_extnded_title {
    color: $color-12;
    margin: 1rem 0;
}

.carousel_extnded p {
    margin: 0 0 1rem;
    min-height: 1.5rem;
}

.teaser-stage_carousel .btn_teaser_blue {
    width: fit-content;
    margin: .7rem auto;
}

.teaser-stage_carousel .btn_teaser_white {
    width: fit-content;
    margin: .7rem auto;
}

.carousel_extnded .caption h1 {
    color: $color-12;
    margin: 0 0 1rem;
    min-height: 3.3rem;
}

.carousel_extnded .caption h1::after {
    content: none;
}

.carousel_extnded .caption h3 {
    color: $color-12;
    min-height: 2rem;
}

.carousel_extnded .play_videoicon img {
    margin-top: -25px;
    width: 75%;
    margin-bottom: 10px;
}

.carousel_extnded .play_videoicon {
    position: relative;
    text-align: center;
    width: max-content;
    margin: 0 auto;
    z-index: 100;
}

.carousel_extnded .play_videoicon:hover {
    cursor: pointer;
}

.carousel_extnded .title_class {
    margin-top: 10px;
    text-align: center;
    position: relative;
    z-index: 10;
}

.carousel_extnded .title_class span {
    color: $color-white;
    cursor: default;
    pointer-events: none;
    padding: 10px;
    background-color: $color-12;
}

.carousel_extnded_overlay_wrapper {
    background: $color-white;
    width: 800px;
    min-height: 200px;
    padding: 15px;
    display: none;
	position: fixed;
    z-index: 1002;  
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

#carousel_extnded_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, .6);
    opacity: 1;
    transition: opacity .15s, top 0;
    display: none;
}
