// the 'shared' styling should only describe those properties that are needed to make the component work as designed
// refrain, as much as possible from using colors, margins, sizes
// Any of these styles could be overridden because the 'shared' stylesheet will always precede the label's styling
[data-module*=ExternalLinkHelper] {
    .has-js & {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background-color: transparent;
        z-index: 99999;
        opacity: 0;
        overflow: hidden;
    }

    &.visible {
        height: 100%;
        opacity: 1;
        background-color: rgba(255, 255, 255, .25); // sass-lint:disable-line no-color-literals
        transition: opacity .25s ease-out, height .25s ease-in;
    }

    &.hidden {
        height: 0;
        opacity: 0;
        transition: opacity .5s ease-out, height .5s ease-in;
        overflow: hidden;
    }
}

.external-link-helper_dialog {
    .has-js & {
        position: absolute;
        width: 95%;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        background-color: rgba(255, 255, 255, .95); // sass-lint:disable-line no-color-literals
        padding: 2em;
        box-shadow: 0 0 1em transparentize($color-black, .4);

        @media screen and (min-width: 960px) {
            width: 40%;
        }
    }
}

.external-link-helper_no-js-msg {
    .has-js & {
        display: none;
    }
}

.dialog-type-dependent {
    display: none;
    margin-top: 1em;

    .popup-type--amgen-commercial &.for-amgen-comm {
        display: block;
    }

    .popup-type--amgen-non-commercial &.for-amgen-noncomm {
        display: block;
    }

    .popup-type--non-amgen &.for-non-amgen {
        display: block;
    }
}

.external-link-helper_button {
    display: none;
    margin-bottom: 1em;

    .has-js & {
        display: block;

        span {
            span {
                display: inline;
            }
        }
    }
}
